<div class="list-group" *appRole="['Expedientes.Ver']">
    <button type="button" [routerLinkActive]="['active']" [routerLink]="'/expedientes/' + idExpediente" class="list-group-item list-group-item-action">
<div class="with-icon">
<mat-icon >person_pin</mat-icon><span class="aligned-with-icon"> Expediente</span>
</div>
</button>

    <!-- <button type="button" *appRole="['Documentos.Ver']" [routerLinkActive]="['active']" routerLink='/documentospersona' class="list-group-item list-group-item-action">
    <div class="with-icon">
      <mat-icon>folder</mat-icon> <span class="aligned-with-icon">Documentos</span>
    </div>
</button> -->
</div>