import { CommonModule } from "@angular/common";
import { SharedModule } from "./shared.module";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormCasoCreateComponent } from "@views/casos/form-caso-create/form-caso-create.component";
import { FormCasoViewComponent } from "@views/casos/form-caso-view/form-caso-view.component";
import { FormCasoUpdateComponent } from "@views/casos/form-caso-update/form-caso-update.component";
import { ListCasoComponent } from "@views/casos/list-caso/list-caso.component";
import { BuscarCasoComponent } from "@views/busquedas/buscar-caso/buscar-caso.component";
import { ExpedienteModule } from "./expediente.module";
import { ListExpedienteComponent } from "@views/expedientes/list-expediente/list-expediente.component";
import { BusquedasModule } from "./busquedas.module";
import { DialogCasoCreateComponent } from "@views/casos/dialog-caso-create/dialog-caso-create.component";

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BusquedasModule,
    ExpedienteModule
  ],
  declarations: [
    FormCasoCreateComponent,
    FormCasoUpdateComponent,
    FormCasoViewComponent,
    ListCasoComponent,
    DialogCasoCreateComponent
  ],
  exports: [
    FormCasoCreateComponent,
    FormCasoUpdateComponent,
    FormCasoViewComponent,
    ListCasoComponent,
    DialogCasoCreateComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CasoModule { }
