import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { ListOrganismosComponent } from '@views/organismos/list-organismos/list-organismos.component';
import { DialogOrganismoGenericComponent } from '@views/organismos/dialog-organismo-generic/dialog-organismo-generic.component';
import { LocalidadModule } from './localidad.module';
import { FormOrganismoViewComponent } from '@views/organismos/form-organismo-view/form-organismo-view.component';
import { FormOrganismoPartesComponent } from '@views/organismos/form-organismo-partes/form-organismo-partes.component';
import { PersonaModule } from './persona.module';



@NgModule({
  declarations: [
    ListOrganismosComponent,
    DialogOrganismoGenericComponent,
    FormOrganismoViewComponent,
    FormOrganismoPartesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    LocalidadModule,
    PersonaModule
  ],
  exports:[
    ListOrganismosComponent,
    DialogOrganismoGenericComponent,
    FormOrganismoViewComponent,
    FormOrganismoPartesComponent
  ]
})
export class OrganismoModule { }
