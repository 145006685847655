<div class="position-relative">


    <ngx-spinner name="spEliminarMenu" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>

    <div>
        ¿Esta seguro que desea eliminar el registro {{data.descripcion != "" || null ? data.descripcion : ""}}?
        <br>
        <br>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6 col-lg-3">
            <button mat-raised-button color="primary" *appRole="['Menus.Eliminar']" (click)="doAction()">Eliminar</button>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-3">
            <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancelar</button>
        </div>
    </div>

</div>
