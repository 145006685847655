import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, TiposMoneda } from '@models/base/identificadores.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { CreateMovimientoRequest } from '@models/movimiento/createMovimientoRequest.model';
import { UpdateMovimientoRequest } from '@models/movimiento/updateMovimientoRequest.model';
import { LoginService } from '@services/login/login.service';
import { MovimientoService } from '@services/movimiento/movimiento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoMovimientoService } from '@services/tipoMovimiento/tipoMovimiento.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogCreateMovimientoComponent } from '../dialog-create-movimiento/dialog-create-movimiento.component';
import { MovimientoDto } from '@models/movimiento/movimientoDto.model';

@Component({
  selector: 'app-dialog-update-movimiento',
  templateUrl: './dialog-update-movimiento.component.html',
  styleUrls: ['./dialog-update-movimiento.component.css']
})
export class DialogUpdateMovimientoComponent {

  formBuilder: FormBuilder = new FormBuilder;
  form: any;
  tiposMovimiento!: BaseDto[];
  expedienteDto!: ExpedienteDto;
  movimientoDto!: MovimientoDto;


  constructor(
    private loginService: LoginService,
    public dialogRef: MatDialogRef<DialogCreateMovimientoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private movimientoService: MovimientoService,
    private tipoMovimientoService: TipoMovimientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) {

    this.form = this.formBuilder.group({
      Descripcion: ['', Validators.compose([Validators.maxLength(200)])],
      TipoMovimiento: ['', Validators.compose([Validators.required])],
      Importe: ['', Validators.compose([Validators.required])],
      EgresoIngreso: ['', Validators.compose([Validators.required])],
      FechaMovimiento: ['', Validators.compose([Validators.required])],
      TipoMoneda: [''],
    })
  }
  ngOnInit() {
    this.getAllTipoMovimiento()
    this.getMovimientoById(this.data.id)
    this.form.controls["EgresoIngreso"].setValue("1")
  }

  selectExpediente($event: any) {
    this.expedienteDto = $event
  }


  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const updateMovimientoRequest: UpdateMovimientoRequest = new UpdateMovimientoRequest
    updateMovimientoRequest.id = this.movimientoDto.id
    updateMovimientoRequest.descripcion = isFalsy(this.form.controls['Descripcion'].value) ? null : this.form.controls['Descripcion'].value;
    updateMovimientoRequest.idTipoMovimiento = isFalsy(this.form.controls['TipoMovimiento'].value) ? null : this.form.controls['TipoMovimiento'].value;
    updateMovimientoRequest.fechaMovimiento = isFalsy(this.form.controls['FechaMovimiento'].value) ? null : this.form.controls['FechaMovimiento'].value;
    updateMovimientoRequest.idTipoMonedaOrigen = isFalsy(this.form.controls['TipoMoneda'].value) ? null : this.form.controls['TipoMoneda'].value;
    let signo = isFalsy(this.form.controls['EgresoIngreso'].value) ? 0 : parseInt(this.form.controls['EgresoIngreso'].value);
    updateMovimientoRequest.importe = isFalsy(this.form.controls['Importe'].value) ? 0 : this.form.controls['Importe'].value * signo;
    updateMovimientoRequest.idTipoMonedaLocal = TiposMoneda.PesoArgentino
    updateMovimientoRequest.idExpediente = this.movimientoDto.expediente.id
    updateMovimientoRequest.idEmpresa = this.movimientoDto.empresa.id
    updateMovimientoRequest.idUsuario = this.movimientoDto.usuario.id
    updateMovimientoRequest.activo = this.movimientoDto.activo
    this.spinner.show("spLoadUpdate")

    this.movimientoService.update(updateMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spLoadUpdate")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "Exito");
          this.dialogRef.close({ data: this.movimientoDto, event: AccionesDialog.Modificar });
        },
        error => {
          this.spinner.hide("spLoadUpdate")
          this.snackBar.showError(error, "Error");
        })
  }


  completaFormulario(movimiento: MovimientoDto) {
    this.form.controls['Descripcion'].setValue(movimiento.descripcion)
    this.form.controls['TipoMovimiento'].setValue(movimiento.tipoMovimiento?.id)
    this.form.controls['Importe'].setValue (movimiento.importe)
    this.form.controls['TipoMoneda'].setValue(movimiento.tipoMonedaOrigen?.id)
    this.form.controls['FechaMovimiento'].setValue(movimiento.fechaMovimiento)
    if (movimiento.importe < 0)
      this.form.controls['EgresoIngreso'].setValue("-1")
    else
      this.form.controls['EgresoIngreso'].setValue("1")

  }

  getAllTipoMovimiento() {
    this.spinner.show("spTipoMovimiento")
    this.tipoMovimientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTipoMovimiento")
          this.tiposMovimiento = data

        },
        error => {
          this.spinner.hide("spTipoMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }

  getMovimientoById(id:number) {
    this.spinner.show("spLoadUpdate")
    this.movimientoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spLoadUpdate")
          this.movimientoDto = data
          this.completaFormulario(data)
        },
        error => {
          this.spinner.hide("spLoadUpdate")
          this.snackBar.showError(error, "Error");
        })
  }
}
