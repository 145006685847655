<mat-card>
  <mat-card-header>
      <div *appRole="['Contactos.Crear']">
          <button mat-raised-button color="primary" (click)="openDialogCreate()" #tooltip="matTooltip" matTooltip="Crear contacto">
      <mat-icon>add</mat-icon>
      Nueva contacto
    </button>
      </div>

      <br>
      <br>
  </mat-card-header>
  <mat-card-content>
      <div class="container-fluid">
          <mat-card *appRole="['Contactos.Listar']">
              <mat-card-header>
                  <form [formGroup]="formFilter">
                      <div class="row">
                          <div class="col-sm-12 col-md-6 col-lg-3">
                              <button mat-stroked-button color="primary" (click)="filtrar()">
                <mat-icon>filter_alt</mat-icon>
                Filtrar
              </button>
                          </div>
                      </div>
                  </form>
                  <br>
                  <br>
              </mat-card-header>

              <mat-card-content>
                  <ngx-spinner [zIndex]="100" name="spListado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                  </ngx-spinner>
                  <br>
                  <div class="row">
                      <div class="col-sm-12 col-md-6 col-lg-4">
                          <mat-form-field *ngIf=data appearance="outline" class="full-width-x100">
                              <mat-label>Buscar</mat-label>
                              <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                          </mat-form-field>
                      </div>
                  </div>

                  <table mat-table [dataSource]="dataSource" *ngIf=data matSort matSortStart="asc">

                      <ng-container matColumnDef="tipoContacto">
                          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Tipo Contacto</strong>
                          </mat-header-cell>
                          <mat-cell mat-cell *matCellDef="let contacto">
                              <span>{{contacto.tipoContacto?.descripcion | titlecase}}</span>

                          </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="valorContacto">
                          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Valor</strong></mat-header-cell>
                          <mat-cell mat-cell *matCellDef="let contacto">
                              <span> {{contacto.valorContacto== undefined ? "":contacto.valorContacto}}</span>
                          </mat-cell>
                      </ng-container>

                      <ng-container matColumnDef="preferido">
                          <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Preferencia</strong>
                          </mat-header-cell>
                          <mat-cell mat-cell *matCellDef="let contacto">
                              <span> {{contacto.preferido== true ? "Si":"No"}}</span>

                          </mat-cell>
                      </ng-container>



                      <!-- Accion Column -->
                      <ng-container matColumnDef="accion">
                          <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </mat-header-cell>
                          <mat-cell mat-cell *matCellDef="let contacto" class="action-link">
                              <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
                              <mat-menu #menu="matMenu">
                                  <button mat-menu-item (click)="openDialogView(contacto)" *appRole="['Contactos.Ver']">
                  <mat-icon>visibility</mat-icon>
                  <span>Ver</span>
                </button>
                                  <button mat-menu-item (click)="openDialogUpdate(contacto)" *appRole="['Contactos.Modificar']">
                  <mat-icon>edit</mat-icon>
                  <span>Editar</span>
                </button>
                                  <button mat-menu-item (click)="openDialogDelete(contacto)" *appRole="['Contactos.Eliminar']">
                  <mat-icon>delete</mat-icon>
                  <span>Eliminar</span>
                </button>
                              </mat-menu>
                          </mat-cell>
                      </ng-container>

                      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                      <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


                  </table>
                  <div class="no-data-table">
                      <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
            datos
            para mostrar.</span>
                  </div>
                  <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
                  </mat-paginator>
              </mat-card-content>

          </mat-card>
      </div>
  </mat-card-content>

</mat-card>
