<div *appRole="['Documentos.Ver']">
    <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="formDocuementos">
        <div class="row">
            <div class=" col-lg-4">
                <mat-form-field appearance="outline">
                    <mat-label>Asunto</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="Asuntos" (selectionChange)="selectAsunto($event)">
                        <mat-option [value]="asunto.id" *ngFor="let asunto of lstAsuntos ">
                            {{asunto.descripcion}}</mat-option>
                    </mat-select>

                    <mat-error *ngIf="formDocuementos.controls['Asuntos'].hasError('required')">Este campo es
                        <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Nombre</mat-label>
                    <input matInput name="NombreDocumento" type="text" formControlName="NombreDocumento" />
                    <mat-error *ngIf="formDocuementos.controls['NombreDocumento'].hasError('required')">Este campo es
                        <strong>requerido</strong>.
                    </mat-error>
                </mat-form-field>

            </div>
        </div>
        <div class="row">
            <div class="col-lg-8">
                <mat-form-field class="max-width" appearance="outline">
                    <mat-label>Etiquetas</mat-label>
                    <!-- <mat-chip-list #chipList aria-label="Etiquetas seleccionadas">
                        <mat-chip *ngFor="let etiqueta of lstEtiquetas;let i=index;trackBy:trackByItems" [selectable]="selectable" [removable]="removable" (removed)="remove(etiqueta)">
                            {{etiqueta}}
                            <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                        </mat-chip>
                        <input placeholder="Nueva etiqueta..." formControlName="Etiquetas" [matChipInputFor]="chipList" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="addOnBlur" (matChipInputTokenEnd)="add($event)">
                    </mat-chip-list> -->
                </mat-form-field>
            </div>
        </div>
        <br>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-card>
                    <mat-card-content>
                        <ngx-spinner name="spinnerFile" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                        </ngx-spinner>
                        <mat-card-subtitle>
                            Archivo actual
                        </mat-card-subtitle>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <span>Nombre archivo: </span> {{updateDocumentoRequest.archivo.descripcion }}
                                <button mat-icon-button #tooltip="matTooltip" color="accent" (click)="descargarArchivo(updateDocumentoRequest.archivo)" matTooltipPosition="right" matTooltip="Descargar archivo." color="primary" aria-label="Example icon button with a menu icon">
                  <mat-icon>file_download</mat-icon>
                </button>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span>Tipo archivo: </span> {{updateDocumentoRequest.archivo.contentType}}
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span>Fecha alta: </span> {{updateDocumentoRequest.archivo.fechaAlta | date}}
                            </div>
                        </div>


                    </mat-card-content>
                </mat-card>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-card>
                    <mat-card-content>
                        <mat-card-subtitle>
                            Nuevo archivo
                        </mat-card-subtitle>
                        <app-file-upload (eventoArchivo)="seleccionArchivo($event)"></app-file-upload>
                    </mat-card-content>

                </mat-card>
            </div>
        </div>
        <br>
        <div class="row">
            <mat-form-field class="example-full-width col-md-12" appearance="outline">
                <mat-label>Descripcion</mat-label>
                <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
                <mat-error *ngIf="formDocuementos.controls['Descripcion'].hasError('maxlength')">Supero el maximo de caracteres.
                </mat-error>
                <mat-hint align="start"><strong>Amplie información</strong> </mat-hint>
                <mat-hint align="end">{{message.value.length}} / 200</mat-hint>
                <mat-error *ngIf="formDocuementos.controls['Descripcion'].hasError('required')">Este campo es
                    <strong>requerido</strong>.
                </mat-error>
            </mat-form-field>
        </div>

        <br>
        <div class="col-auto my-1">
            <button mat-raised-button color="primary" mat-dialog-close style="margin-right:3px;" (click)="guardarDocumento()" name="Guardar" [disabled]="!formDocuementos.valid" *appRole="['Documentos.Modificar']">Guardar</button>
            <button mat-button color="warn" [routerLink]="['/documentos']" style="margin-right:3px;" mat-dialog-close name="Cancelar">Cancelar</button>

        </div>
    </form>
</div>
