import { ArchivoDto } from "../archivo/archivoDto.model";

export class UpdateDocumentoRequest {
    public idFile!:number
    public fileContent!: string 
    public fileType!: string 
    public fileName!: string 
    public idDocumento!:number 
    public idTipoEntidad?: number;
    public idEntidad?: number;
    public idAsunto?: number;
    public etiquetas!: string 
    public nombre!: string 
    public descripcion!: string 
    public archivo!: ArchivoDto 
}
