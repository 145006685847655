<button mat-raised-button color="primary" *appRole="['Casos.Crear']" [routerLink]="['/casos/create']" #tooltip="matTooltip" matTooltip="Crear caso">
  <mat-icon>add</mat-icon>
  Nuevo caso
</button>
<br>
<br>
<div class="container-fluid">
    <mat-card *appRole="['Casos.Listar']">
        <mat-card-header>
            <form [formGroup]="formFilter">
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <mat-form-field appearance="outline">
                            <mat-label>Caratula</mat-label>
                            <input matInput type="text" maxlength="200" style="text-transform: uppercase;" formControlName="Caratula" />
                        </mat-form-field>
                    </div>
                    <div class="col">
                        <button mat-stroked-button color="primary" (click)="filtrar()">
              <mat-icon>filter_alt</mat-icon>
              Filtrar
            </button>
                    </div>
                    <div class="col">
                        <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
                            Filtros Avanzados </mat-slide-toggle>
                    </div>
                </div>
                <div class="row">
                    <div class="col" *ngIf="checkedFiltroAvanzado">
                        <mat-form-field appearance="outline" class="full-width-x100">
                            <ngx-spinner [zIndex]="-1" name="spBusquedaTipoCaso" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                            <mat-label>Tipo Caso </mat-label>
                            <mat-select placeholder="Seleccione opcion" formControlName="IdTipoCasoCaso">
                                <mat-option [value]="tipoCasos.id" *ngFor="let tipoCasos of tiposCasos ;let i=index;trackBy:trackByItems">
                                    {{tipoCasos.descripcion}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <br>
        </mat-card-header>
        <br>
        <mat-card-content>
            <ngx-spinner [zIndex]="100" name="spCaso" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <mat-form-field *ngIf=data appearance="outline" class="full-width-x100">
                        <mat-label>Buscar</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                    </mat-form-field>
                </div>
            </div>

            <table mat-table [dataSource]="dataSource" *ngIf=data matSort matSortStart="asc">


                <ng-container matColumnDef="caratula">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Caratula</strong>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let caso">
                        {{caso.caratula | uppercase}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fechaComienzo">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Fecha
              Comienzo</strong></mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let caso">
                        {{caso.fechaComienzo | date:('dd/MM/yyyy')}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fechaFinalizacion">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Fecha Finalización</strong>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let caso">
                        {{caso.fechaFinalizacion | date:('dd/MM/yyyy')}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="tipoCaso">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Tipo
              Caso</strong></mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let caso">
                        {{caso.tipoCaso == undefined ? "" : caso.tipoCaso.descripcion | uppercase}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="estadoCaso">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Estado Caso</strong>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let caso">
                        {{caso.estadoCaso == undefined ? "" : caso.estadoCaso.descripcion | uppercase}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="activo">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Estado</strong> </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let caso">
                        <div *ngIf="caso.activo == true">
                            <span class="badge bg-success text-light">Activo</span>
                        </div>
                        <div *ngIf="caso.activo == false">
                            <span class="badge bg-danger text-light">Baja</span>
                        </div>
                    </mat-cell>
                </ng-container>



                <!-- Accion Column -->
                <ng-container matColumnDef="accion">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let audiencia" class="action-link">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['/casos/',audiencia.id]">
                <mat-icon>visibility</mat-icon>
                <span>Ver</span>
              </button>
                            <button mat-menu-item [routerLink]="['/casos/edit/',audiencia.id]">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
                            <button mat-menu-item (click)="openDialog('Eliminar',audiencia)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


            </table>
            <div class="no-data-table">
                <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
          datos
          para mostrar.</span>
            </div>
            <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
            </mat-paginator>
        </mat-card-content>

    </mat-card>
</div>
