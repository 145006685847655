import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { DialogExpedienteNotaUpdateComponent } from '@views/expedientesNotas/dialog-expediente-nota-update/dialog-expediente-nota-update.component';
import { DialogExpedienteNotaCreateComponent } from '@views/expedientesNotas/dialog-expediente-nota-create/dialog-expediente-nota-create.component';
import { ListExpedienteNotaComponent } from '@views/expedientesNotas/list-expediente-nota/list-expediente-nota.component';
import { DialogExpedienteNotaGenericComponent } from '@views/expedientesNotas/dialog-expediente-nota-generic/dialog-expediente-nota-generic.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
],
declarations: [
  DialogExpedienteNotaUpdateComponent,
  DialogExpedienteNotaCreateComponent,
  ListExpedienteNotaComponent,
  DialogExpedienteNotaGenericComponent
],

providers: [

],
schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
exports: [
  DialogExpedienteNotaUpdateComponent,
  DialogExpedienteNotaCreateComponent,
  ListExpedienteNotaComponent,
  DialogExpedienteNotaGenericComponent
]
})
export class ExpedienteNotaModule { }
