<mat-card *appRole="['Casos.Ver']">
    <mat-card-header>
        <mat-card-title>
            <h2>Ver caso</h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <div class="row" *appRole="['Casos.Ver']">
            <mat-tab-group *ngIf="casoDto">
                <mat-tab label="Detalle">
                    <ngx-spinner [zIndex]="100" name="spExpediente" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                    </ngx-spinner>
                    <ng-template matTabContent>
                        <br>
                        <button mat-button color="primary" [matMenuTriggerFor]="menu">Acciones</button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['/casos/edit/'+ casoDto.id]">Editar</button>
                        </mat-menu>
                        <div class="mat-card-expediente">
                            <mat-card>
                                <mat-card-content>
                                    <ngx-spinner [zIndex]="100" name="spView" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                                    </ngx-spinner>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <h5>Caratula: {{!isFalsy(casoDto.caratula) ? casoDto.caratula : "" | uppercase}}</h5>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <span><strong>Fecha Comienza: </strong></span>
                                            <span>{{casoDto.fechaComienzo | date:('short')}}</span>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <span><strong>Fecha Finaliza: </strong></span>
                                            <span>{{casoDto.fechaFinalizacion | date:('short')}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <span><strong>Tipo Caso: </strong></span>
                                            <span>{{casoDto.tipoCaso? casoDto.tipoCaso.descripcion : "" | titlecase}}</span>
                                        </div>
                                        <div class="col-sm-12 col-md-6 col-lg-6">
                                            <span><strong>Estado Caso: </strong></span>
                                            <span>{{casoDto.estadoCaso? casoDto.estadoCaso.descripcion : "" | titlecase}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12">
                                            <span><strong>Descripción: </strong></span>
                                            <span>{{casoDto.descripcion}}</span>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </ng-template>
                </mat-tab>
                <mat-tab label="Expedientes">
                    <ng-template matTabContent>
                        <br>
                        <app-list-expediente [idCaso]="casoDto.id"></app-list-expediente>
                    </ng-template>
                </mat-tab>
            </mat-tab-group>
        </div>




    </mat-card-content>
</mat-card>
