import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCreateUpdateDeleteComponent } from '../views/genericos/dialog-create-update-delete/dialog-create-update-delete.component';
import { SharedModule } from 'src/app/modules/shared.module';
import { DialogDeleteComponent } from '../views/genericos/dialog-delete/dialog-delete.component';
import { GenericDialogComponent } from '../views/genericos/generic-dialog/generic-dialog.component';
import { DialogNuevoEmailComponent } from '@views/genericos/dialog-nuevo-email/dialog-nuevo-email.component';
import { MenuesDesplegablesComponent } from '@views/menues-desplegables/menues-desplegables.component';
import { ListTipoPericiaComponent } from '@views/tipos-pericia/list-tipo-pericia/list-tipo-pericia.component';



@NgModule({
  declarations: [
    DialogCreateUpdateDeleteComponent,
    DialogDeleteComponent,
    GenericDialogComponent,
    DialogNuevoEmailComponent,
    MenuesDesplegablesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports:[
    DialogCreateUpdateDeleteComponent,
    DialogDeleteComponent,
    GenericDialogComponent,
    DialogNuevoEmailComponent,
    MenuesDesplegablesComponent,
  ]
})
export class GenericoModule { }
