import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateExpedienteNotaRequest } from '@models/expedienteNota/createExpedienteNotaRequest.model';
import { ExpedienteNotaService } from '@services/expedienteNota/expedienteNota.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { Editor } from 'ngx-editor';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog-expediente-nota-create',
  templateUrl: './dialog-expediente-nota-create.component.html',
  styleUrls: ['./dialog-expediente-nota-create.component.css']
})
export class DialogExpedienteNotaCreateComponent {
  formBuilder: FormBuilder = new FormBuilder;
  form: any;
  editor!: Editor
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogExpedienteNotaCreateComponent>,
    private expedienteNotaService: ExpedienteNotaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) { }
ngOnInit(){
  this.editor = new Editor();
  this.form = this.formBuilder.group({
    Descripcion: ['',Validators.compose([Validators.required])],

  });
}

ngOnDestroy(): void {
  this.editor.destroy();
}

onSubmit() {
  if (this.form.invalid) {
    return;
  }
  const createExpedienteNotaRequest: CreateExpedienteNotaRequest = new CreateExpedienteNotaRequest
  createExpedienteNotaRequest.descripcion = this.form.controls['Descripcion'].value == "" ? null : this.form.controls['Descripcion'].value;
  createExpedienteNotaRequest.idExpediente = this.data.idExpediente
  createExpedienteNotaRequest.idEmpresa = 1
  this.spinner.show("spLoadCreate")
  this.expedienteNotaService.create(createExpedienteNotaRequest)
    .subscribe(
      data => {
        this.spinner.hide("spLoadCreate")
        this.snackBar.showSuccess("Se agrego el registro correctamente.", "");
      },
      error => {
        this.spinner.hide("spLoadCreate")
        this.snackBar.showError(error, "Error");
      })
}

}
