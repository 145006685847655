import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CasoDto } from '@models/caso/casoDto.model';
import { CasoFilter } from '@models/caso/casoFilter.model';
import { CasoService } from '@services/caso/caso.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoCasoService } from '@services/tipoCaso/tipoCaso.service';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-list-caso',
  templateUrl: './list-caso.component.html',
  styleUrls: ['./list-caso.component.css']
})
export class ListCasoComponent {


  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<CasoDto>();
  formBuilder: FormBuilder = new FormBuilder;
  formFilter: any;
  casoFilter!: CasoFilter;
  checkedFiltroAvanzado: boolean= false;
  tiposCasos: BaseDto[] = [];

  constructor(
    private casoService: CasoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private tipoCasoService: TipoCasoService,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["caratula", "accion"] :
        ["caratula","fechaComienzo", "fechaFinalizacion", "tipoCaso", "estadoCaso","activo", "accion"];
    });

    this.formFilter = this.formBuilder.group({
      Caratula: [''],
      FechaComienzoDesde: [''],
      FechaComienzoHasta: [''],
      FechaAltaDesde: [''],
      FechaAltaHasta: [''],
      FechaFinalizacionHasta: [''],
      FechaFinalizacionDesde: [''],
      IdEstadoCaso: [''],
      IdTipoCasoCaso: [''],
    })
  }

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
  }

  filtrar() {
    this.casoFilter = new CasoFilter
    this.casoFilter.caratula = isFalsy(this.formFilter.controls['Caratula'].value) ? null : this.formFilter.controls['Caratula'].value;
    this.casoFilter.fechaComienzoDesde = isFalsy(this.formFilter.controls['FechaComienzoDesde'].value) ? null : this.formFilter.controls['FechaComienzoDesde'].value;
    this.casoFilter.fechaComienzoHasta = isFalsy(this.formFilter.controls['FechaComienzoHasta'].value) ? null : this.formFilter.controls['FechaComienzoHasta'].value;
    this.casoFilter.fechaAltaDesde = isFalsy(this.formFilter.controls['FechaAltaDesde'].value) ? null : this.formFilter.controls['FechaAltaDesde'].value;
    this.casoFilter.fechaAltaHasta = isFalsy(this.formFilter.controls['FechaAltaHasta'].value) ? null : this.formFilter.controls['FechaAltaHasta'].value;

    this.casoFilter.fechaFinalizacionHasta = isFalsy(this.formFilter.controls['FechaFinalizacionHasta'].value) ? null : this.formFilter.controls['FechaFinalizacionHasta'].value;
    this.casoFilter.fechaFinalizacionDesde = isFalsy(this.formFilter.controls['FechaFinalizacionDesde'].value) ? null : this.formFilter.controls['FechaFinalizacionDesde'].value;
    this.casoFilter.idEstadoCaso = isFalsy(this.formFilter.controls['IdEstadoCaso'].value) ? null : this.formFilter.controls['IdEstadoCaso'].value;
    this.casoFilter.idTipoCasoCaso = isFalsy(this.formFilter.controls['IdTipoCasoCaso'].value) ? null : this.formFilter.controls['IdTipoCasoCaso'].value;

    this.getAllCasoByFilter(this.casoFilter);
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllCasoByFilter(casoFilter:CasoFilter) {
    this.spinner.show('spCaso')
    this.casoService.getAllCasoByFilter(casoFilter)
      .subscribe(
        data => {
          this.spinner.hide('spCaso')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spCaso')
          this.snackBar.showError(error, "Error");
        })
  }

  filtrosAvanzados(event: any) {
    if (event.checked == true)
      this.checkedFiltroAvanzado = true
    else
      this.checkedFiltroAvanzado = false

    this.getAllTiposCasos();

  }

  getAllTiposCasos() {
    this.spinner.show("spBusquedaTipoCasos")
    this.tipoCasoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaTipoCasos")
          let todos = new BaseDto
          todos.id = 0
          todos.descripcion = "Todos"
          data.push(todos)
          this.tiposCasos = data
        },
        error => {
          this.spinner.hide("spBusquedaTipoCasos")
          this.snackBar.showError(error, "Error");
        })
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  deleteRowData(id: number) {
    this.spinner.show('spCaso')
    this.casoService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide('spCaso')
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.getAllCasoByFilter(this.casoFilter);
        },
        error => {
          this.spinner.hide('spCaso')
          this.snackBar.showError(error, "Error");
        });
  }


  trackByItems(index: number, item: any): any { return item; }
}
