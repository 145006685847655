<h4 mat-dialog-title>Acción realizada :: <strong>{{action}}</strong></h4>
<mat-dialog-content>
    <ngx-spinner [zIndex]="100" name="spLoad" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="form">
        <div class="row">
            <ngx-editor-menu [editor]="editor" *ngIf="action != 'Eliminar'; else elseTemplate"> </ngx-editor-menu>
            <ngx-editor [editor]="editor" formControlName="Descripcion" [placeholder]="'Escriba aqui...'"></ngx-editor>
        </div>
    </form>
    <ng-template #elseTemplate>
        ¿Esta seguro que desea eliminar el registro?
    </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button *appRole="['ExpedientesNotas.Crear','ExpedientesNotas.Modificar','ExpedientesNotas.Eliminar']" color="primary" (click)="doAction()" [disabled]="!form.valid">{{action}}</button>
    <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</mat-dialog-actions>