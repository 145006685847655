<div class="position-relative">

    <ngx-spinner name="spBusquedaRoles" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline">
                    <input placeholder="Descripción" type="text" formControlName="Descripcion" matInput>
                    <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es
                        <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
