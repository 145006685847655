export class ExpedienteFilter {
  public caratula?: string | null
  public cuij?: string | null
  public numero?: string | null
  public idEstadoExpediente?: string | null
  public idOrganismo?: number | null
  public idTipoExpediente?: string | null
  public idEstadoExpedientePerito?: string | null
  public filtroAvanzado:boolean=  false
  public idPersona!:number | null
  public idsEstadoExpedientePerito!:number[] | null
  public idsEstadoExpediente!:number[] | null
  public sinClave!: boolean | null
}
