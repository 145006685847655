import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { CreateExpedienteRequest } from '@models/expediente/createExpedienteRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateExpedienteRequest } from '@models/expediente/updateExpedienteRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { ExpedienteSummaryDto } from '@models/expediente/expedienteSummary.model';
import { DeleteExpedienteRequest } from '@models/expediente/deleteExpedienteRequest.model';
import { ExpedienteFilter } from '@models/expediente/expedienteFilter.model';
import { CantidadPorTipoDto } from '@models/base/cantidadPorTipoDto.model';
import { CantidadPorEstadoDto } from '@models/base/cantidadPorEstadoDto.mode';

@Injectable({
  providedIn: 'root'
})
export class ExpedienteService {
  expedientes!: ExpedienteDto[];
  private expedienteFilter!: ExpedienteFilter;
  expedienteDto!: ExpedienteDto;

  constructor(private http:HttpClient,private snackBar: SnackBarService) {

  }
  setExpedientes(expedientes: ExpedienteDto[]) {
    this.expedientes = expedientes;
  }

  getExpedientes() {
    return this.expedientes;
  }

  setFilters(expedienteFilter: ExpedienteFilter) {
    this.expedienteFilter = expedienteFilter;
  }

  getFilters() {
    return this.expedienteFilter;
  }

  setExpedienteById(id: number) {
    if (id != 0)
      this.getById(id).subscribe(
        data => {
          localStorage.removeItem("expedienteDto")
          localStorage.setItem("expedienteDto", JSON.stringify(data))
          this.expedienteDto = data
        },
        error => {
          this.snackBar.showError(error, "Error");
        })
  }

  setExpedienteDto(expedienteDto: ExpedienteDto) {
    if (expedienteDto){

    localStorage.removeItem("expedienteDto")
    localStorage.setItem("expedienteDto", JSON.stringify(expedienteDto))
    this.expedienteDto = expedienteDto
    }
  }

  getExpediente(): ExpedienteDto {
    let persona = JSON.parse(localStorage.getItem("expedienteDto") as string == "" ? "" : localStorage.getItem("expedienteDto") as string) as ExpedienteDto
    if (isFalsy(persona))
      return this.expedienteDto
    else
      return persona
  }

  create(createExpedienteRequet:CreateExpedienteRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Expediente/Create",createExpedienteRequet);
  }

  update(updateExpedienteRequest:UpdateExpedienteRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Expediente/Update",updateExpedienteRequest);
  }

  deleteExpediente(deleteExpedienteRequest:DeleteExpedienteRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Expediente/DeleteExpediente",deleteExpedienteRequest);
  }

  getAll():Observable<ExpedienteDto[]> {
    return   this.http.get<ExpedienteDto[]>("/api/Expediente/GetAll");
  }

  getAllExpedientesByFilter(expedienteFilter:ExpedienteFilter):Observable<ExpedienteDto[]> {
    return   this.http.post<ExpedienteDto[]>("/api/Expediente/GetAllExpedientesByFilter",expedienteFilter);
  }

  getById(id:number):Observable<ExpedienteDto>{
    return  this.http.get<ExpedienteDto>("/api/Expediente/GetById?id="+id);
  }

  getAllByCaso(idCaso:number):Observable<ExpedienteDto[]>{
    return  this.http.get<ExpedienteDto[]>("/api/Expediente/GetAllByCaso?idCaso="+idCaso);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Expedientes/Delete?id=" + id);
    else
      return of(null)
  }

  getAllExpedienteSummary():Observable<ExpedienteSummaryDto[]> {
    return   this.http.get<ExpedienteSummaryDto[]>("/api/Expediente/GetAllExpedienteSummary");
  }

  getCantidadPorTipoDto(): Observable<CantidadPorTipoDto[]> {
    return this.http.get<CantidadPorTipoDto[]>("/api/Expediente/GetCantidadPorTipoExpedienteDto");
  }

  getCantidadPorEstadoDto(): Observable<CantidadPorEstadoDto[]> {
    return this.http.get<CantidadPorEstadoDto[]>("/api/Expediente/GetCantidadPorEstadoDto");
  }

  getCantidadPorEstadoPeritoDto(): Observable<CantidadPorEstadoDto[]> {
    return this.http.get<CantidadPorEstadoDto[]>("/api/Expediente/GetCantidadPorEstadoPeritoDto");
  }

  getAllExpedienteSinClave(idsExpedientesFiltrar: number[]): Observable<CantidadPorEstadoDto[]> {
    // Asegúrate de que los IDs estén en el formato que necesita el endpoint
    const params = { idsExpedientesFiltrar: idsExpedientesFiltrar.join(',') };
    return this.http.get<CantidadPorEstadoDto[]>('/api/Expediente/GetAllExpedienteSinClave', { params });
  }

  solicitarClavePorCorreoAJuzgado(idsExpedientesFiltrar: number[]): Observable<CantidadPorEstadoDto[]> {
    // Asegúrate de que los IDs estén en el formato que necesita el endpoint
    const params = { idsExpedientesFiltrar: idsExpedientesFiltrar.join(',') };
    return this.http.get<CantidadPorEstadoDto[]>('/api/Expediente/SolicitarClavePorCorreoAJuzgado', { params });
  }


}
