import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { LoginService } from '@services/login/login.service';
import {  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  constructor(
    private loginService: LoginService,
    private injector: Injector,
    private router:Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {

      const token = this.loginService.getToken();

      if(token && token != undefined){
        req = req.clone({ headers: req.headers.set('Authorization', 'Bearer ' + token) });// This clones HttpRequest and Authorization header with Bearer token added
        req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
        req = req.clone({ headers: req.headers.set('Accept', 'application/json') });
      }
      else{
        this.router.navigate(['/login']);
      }

      return next.handle(req)
          .pipe(
             catchError((error: HttpErrorResponse) => {
                  return throwError(error);
             })
          );
    }
}
