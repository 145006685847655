import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDto } from '@models/base/baseDto.model';
import { CantidadPorEstadoDto } from '@models/base/cantidadPorEstadoDto.mode';
import { CantidadPorTipoDto } from '@models/base/cantidadPorTipoDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CasoDto } from '@models/caso/casoDto.model';
import { CasoFilter } from '@models/caso/casoFilter.model';
import { CreateCasoRequest } from '@models/caso/createCasoRequest.model';
import { UpdateCasoRequest } from '@models/caso/updateCasoRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class CasoService {

  constructor(private http: HttpClient) { }


  create(createCasoRequest: CreateCasoRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Caso/Create", createCasoRequest);
  }

  getAllCasoByFilter(casoFilter: CasoFilter): Observable<CasoDto[]> {
    return this.http.post<CasoDto[]>("/api/Caso/GetAllCasoByFilter", casoFilter);
  }

  update(updateCasoRequest: UpdateCasoRequest): Observable<any> {
    return this.http.put<any>("/api/Caso/Update", updateCasoRequest);
  }

  getAll(): Observable<CasoDto[]> {
    return this.http.get<CasoDto[]>("/api/Caso/GetAll");
  }

  getById(id: number): Observable<CasoDto> {
    return this.http.get<CasoDto>("/api/Caso/GetById?id=" + id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Caso/Delete?id=" + id);
    else
      return of(null)
  }

  getAllCaratula(): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/Caso/GetAllCaratula");
  }

}
