import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { ActualizarCasoUsoComponent } from '@views/seguridad/casos-uso/actualizar-caso-uso/actualizar-caso-uso.component';
import { DialogUpdateCasoUsoComponent } from '@views/seguridad/casos-uso/actualizar-caso-uso/dialog-update-caso-uso/dialog-update-caso-uso.component';
import { CrearCasoUsoComponent } from '@views/seguridad/casos-uso/crear-caso-uso/crear-caso-uso.component';
import { DialogCreateCasoUsoComponent } from '@views/seguridad/casos-uso/crear-caso-uso/dialog-create-caso-uso/dialog-create-caso-uso.component';
import { DialogDeleteCasoUsoComponent } from '@views/seguridad/casos-uso/eliminar-caso-uso/dialog-delete-caso-uso/dialog-delete-caso-uso.component';
import { EliminarCasoUsoComponent } from '@views/seguridad/casos-uso/eliminar-caso-uso/eliminar-caso-uso.component';
import { ListadoCasoUsoComponent } from '@views/seguridad/casos-uso/listar-caso-uso/listado-caso-uso.component';
import { DialogViewCasoUsoComponent } from '@views/seguridad/casos-uso/ver-caso-uso/dialog-view-caso-uso/dialog-view-caso-uso.component';
import { VerCasoUsoComponent } from '@views/seguridad/casos-uso/ver-caso-uso/ver-caso-uso.component';
import { ActualizarMenuComponent } from '@views/seguridad/menus/actualizar-menu/actualizar-menu.component';
import { DialogUpdateMenuComponent } from '@views/seguridad/menus/actualizar-menu/dialog-update-menu/dialog-update-menu.component';
import { CrearMenuComponent } from '@views/seguridad/menus/crear-menu/crear-menu.component';
import { DialogCreateMenuComponent } from '@views/seguridad/menus/crear-menu/dialog-create-menu/dialog-create-menu.component';
import { DialogDeleteMenuComponent } from '@views/seguridad/menus/eliminar-menu/dialog-delete-menu/dialog-delete-menu.component';
import { EliminarMenuComponent } from '@views/seguridad/menus/eliminar-menu/eliminar-menu.component';
import { ListadoMenuComponent } from '@views/seguridad/menus/listar-menu/listado-menu.component';
import { DialogViewMenuComponent } from '@views/seguridad/menus/ver-menu/dialog-view-menu/dialog-view-menu.component';
import { VerMenuComponent } from '@views/seguridad/menus/ver-menu/ver-menu.component';
import { ActualizarPermisoComponent } from '@views/seguridad/permisos/actualizar-permiso/actualizar-permiso.component';
import { DialogUpdatePermisoComponent } from '@views/seguridad/permisos/actualizar-permiso/dialog-update-permiso/dialog-update-permiso.component';
import { CrearPermisoComponent } from '@views/seguridad/permisos/crear-permiso/crear-permiso.component';
import { DialogCreatePermisoComponent } from '@views/seguridad/permisos/crear-permiso/dialog-create-permiso/dialog-create-permiso.component';
import { DialogDeletePermisoComponent } from '@views/seguridad/permisos/eliminar-permiso/dialog-delete-permiso/dialog-delete-permiso.component';
import { EliminarPermisoComponent } from '@views/seguridad/permisos/eliminar-permiso/eliminar-permiso.component';
import { ListadoPermisoComponent } from '@views/seguridad/permisos/listar-permiso/listado-permiso.component';
import { DialogViewPermisoComponent } from '@views/seguridad/permisos/ver-permiso/dialog-view-permiso/dialog-view-permiso.component';
import { VerPermisoComponent } from '@views/seguridad/permisos/ver-permiso/ver-permiso.component';
import { ActualizarRolComponent } from '@views/seguridad/roles/actualizar-rol/actualizar-rol.component';
import { DialogUpdateRolComponent } from '@views/seguridad/roles/actualizar-rol/dialog-update-rol/dialog-update-rol.component';
import { CrearRolComponent } from '@views/seguridad/roles/crear-rol/crear-rol.component';
import { DialogCreateRolComponent } from '@views/seguridad/roles/crear-rol/dialog-create-rol/dialog-create-rol.component';
import { DialogDeleteRolComponent } from '@views/seguridad/roles/eliminar-rol/dialog-delete-rol/dialog-delete-rol.component';
import { EliminarRolComponent } from '@views/seguridad/roles/eliminar-rol/eliminar-rol.component';
import { ListadoRolComponent } from '@views/seguridad/roles/listado-rol/listado-rol.component';
import { DialogViewRolComponent } from '@views/seguridad/roles/ver-rol/dialog-view-rol/dialog-view-rol.component';
import { VerRolComponent } from '@views/seguridad/roles/ver-rol/ver-rol.component';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ListadoMenuComponent,
    VerMenuComponent,
    CrearMenuComponent,
    ActualizarMenuComponent,
    EliminarMenuComponent,
    DialogCreateMenuComponent,
    DialogUpdateMenuComponent,
    DialogViewMenuComponent,
    DialogDeleteMenuComponent,
    VerCasoUsoComponent,
    ListadoCasoUsoComponent,
    CrearCasoUsoComponent,
    ActualizarCasoUsoComponent,
    EliminarCasoUsoComponent,
    DialogViewCasoUsoComponent,
    DialogCreateCasoUsoComponent,
    DialogUpdateCasoUsoComponent,
    DialogDeleteCasoUsoComponent,
    VerPermisoComponent,
    ListadoPermisoComponent,
    CrearPermisoComponent,
    EliminarPermisoComponent,
    ActualizarPermisoComponent,
    DialogViewPermisoComponent,
    DialogCreatePermisoComponent,
    DialogUpdatePermisoComponent,
    DialogDeletePermisoComponent,
    ListadoRolComponent,
    CrearRolComponent,
    EliminarRolComponent,
    VerRolComponent,
    ActualizarRolComponent,
    DialogViewRolComponent,
    DialogCreateRolComponent,
    DialogUpdateRolComponent,
    DialogDeleteRolComponent

  ],
  exports: [
    ListadoMenuComponent,
    VerMenuComponent,
    CrearMenuComponent,
    ActualizarMenuComponent,
    EliminarMenuComponent,
    DialogCreateMenuComponent,
    DialogUpdateMenuComponent,
    DialogViewMenuComponent,
    DialogDeleteMenuComponent,
    ListadoCasoUsoComponent,
    VerCasoUsoComponent,
    CrearCasoUsoComponent,
    ActualizarCasoUsoComponent,
    EliminarCasoUsoComponent,
    DialogViewCasoUsoComponent,
    DialogCreateCasoUsoComponent,
    DialogUpdateCasoUsoComponent,
    DialogDeleteCasoUsoComponent,
    VerPermisoComponent,
    ListadoPermisoComponent,
    CrearPermisoComponent,
    ActualizarPermisoComponent,
    EliminarPermisoComponent,
    DialogViewPermisoComponent,
    DialogCreatePermisoComponent,
    DialogUpdatePermisoComponent,
    DialogDeletePermisoComponent,
    ListadoRolComponent,
    CrearRolComponent,
    EliminarRolComponent,
    VerRolComponent,
    ActualizarRolComponent,
    DialogViewRolComponent,
    DialogCreateRolComponent,
    DialogUpdateRolComponent,
    DialogDeleteRolComponent
  ]
})
export class SeguridadModule { }
