import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { MustMatch } from 'src/app/helper/validations/must-match.validator';
import { ChangePasswordRequest } from '@models/usuario/changePasswordRequest.model';
import { LoginService } from '@services/login/login.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuarioService } from '@services/usuario/usuario.service';

@Component({
  selector: 'app-changePassword',
  templateUrl: './changePassword.component.html',
  styleUrls: ['./changePassword.component.css']
})
export class ChangePasswordComponent implements OnInit {
  titulo: string = '';
  subMenuActive: boolean = false;
  submitted = false;
  parametro: string = '';
  idTipoDocumento: number = 0;
  idEstadoCivil: number = 0;
  idPersona: number = 0;
  valor: any
 // loading: boolean = false;
  errorMessage: string = "";
  hide = true;
  get p() { return this.passwordForm.controls; }

  passwordForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private userService: UsuarioService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {

    this.passwordForm = this.formBuilder.group({
      ClaveAnterior: ['', Validators.compose([Validators.required, Validators.maxLength(50)])],
      ClaveNueva: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.minLength(6)])],
      ClaveNuevaRepetir: ['', Validators.compose([Validators.required, Validators.minLength(6), Validators.maxLength(100)])],
    }, {
      validator: MustMatch('ClaveNueva', 'ClaveNuevaRepetir')
    });
  }


  ngOnInit(): void {
  }

  changePassword() {
    this.spinner.show()
    let user = this.loginService.getCurrentUser()
    let changePasswordRequest = new ChangePasswordRequest
    changePasswordRequest.userName = user.userName
    changePasswordRequest.oldPassword = btoa(this.passwordForm.controls["ClaveAnterior"].value)
    changePasswordRequest.newPassword = btoa(this.passwordForm.controls["ClaveNuevaRepetir"].value)
    this.userService.changePassword(changePasswordRequest).subscribe(
      data => {
        this.spinner.hide()
        this.snackBar.showSuccess(data.text, "Exito")
      },
      (error) => {
        this.spinner.hide()
        this.snackBar.showError("Ups...  " + error, "Error")
      }
    )
  }
}
