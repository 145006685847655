<div class="container-fluid" *appRole="['Expedientes.Ver']">
  <br>
  <div class="row">
    <div class="col-lg-4 col-sm-12">
      <mat-form-field *ngIf=data appearance="outline" class="full-width-x100">
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Buscar..." #input>
      </mat-form-field>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" *ngIf=data matSort matSortStart="asc">

    <ng-container matColumnDef="caratula">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Caratula</strong>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let expediente">
        <span>{{expediente.caratula | uppercase}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="cuij">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Cuij</strong> </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let expediente">
        <span>{{expediente.cuij | uppercase}}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="juzgado">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Juzgado</strong>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let expediente">
        <span>{{expediente.juzgado == undefined ? "" : expediente.juzgado }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="correo">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Correo</strong>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let expediente">
        <span>{{expediente.correo== undefined ? "" :expediente.correo }}</span>
      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="correoEnviado">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Enviado</strong>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let expediente">
        <mat-icon color="primary" *ngIf="expediente.correoEnviado == true">check_cicle</mat-icon>
        <mat-icon color="warning" *ngIf="expediente.correoEnviado == false">warning</mat-icon>
      </mat-cell>
    </ng-container>
    <ng-container matColumnDef="fallaEnvioCorreo">
      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Falla</strong>
      </mat-header-cell>
      <mat-cell mat-cell *matCellDef="let expediente">
        <span>{{expediente.fallaEnvioCorreo == true ? "Si" : "No"}}</span>
      </mat-cell>
    </ng-container>


    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>

  </table>
  <div class="no-data-table">
    <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
      datos para mostrar.</span>
  </div>
  <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="20"
    [pageSizeOptions]="[5, 10, 20,50,100,200]">
  </mat-paginator>
</div>
