import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';
import { ContactoDto } from '@models/contacto/contactoDto.model';
import { CreateContactoRequest } from '@models/contacto/createContactoRequest.model';
import { UpdateContactoRequest } from '@models/contacto/updateContactoRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {
  constructor(private http:HttpClient) {
  }

  create(createContactoRequest:CreateContactoRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Contacto/Create",createContactoRequest);
  }

  update(updateContactoRequest:UpdateContactoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Contacto/Update",updateContactoRequest);
  }

  getAll():Observable<ContactoDto[]> {
    return   this.http.get<ContactoDto[]>("/api/Contacto/GetAll");
  }

  getById(id:number):Observable<ContactoDto>{
    return  this.http.get<ContactoDto>("/api/Contacto/GetById?id="+id);
  }

  getAllByIdPersona(idPersona:number):Observable<ContactoDto[]>{
    return  this.http.get<ContactoDto[]>("/api/Contacto/GetAllByIdPersona?idPersona="+idPersona);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Contacto/Delete?id=" + id);
    else
      return of(null)
  }
}
