export class PericiaFilter {
  idExpediente: number | null;
  idTipoPericia: number | null;
  idEstadoPericia: number | null;
  notificado: boolean | null;
  lugar: string | null;
  fechaAltaDesde: Date | null;
  fechaAltaHasta: Date | null;
  fechaPropuestaDesde: Date | null;
  fechaPropuestaHasta: Date | null;
  fechaRealizadaDesde: Date | null;
  fechaRealizadaHasta: Date | null;
  fechaModificacionDesde: Date | null;
  fechaModificacionHasta: Date | null;

  constructor() {
    this.idExpediente = null;
    this.idTipoPericia = null;
    this.idEstadoPericia = null;
    this.notificado = null;
    this.lugar = null;
    this.fechaAltaDesde = null;
    this.fechaAltaHasta = null;
    this.fechaPropuestaDesde = null;
    this.fechaPropuestaHasta = null;
    this.fechaRealizadaDesde = null;
    this.fechaRealizadaHasta = null;
    this.fechaModificacionDesde = null;
    this.fechaModificacionHasta = null;
  }
}
