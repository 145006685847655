
import { DepartamentoDto } from "../usuario/departamentoDto";

export class LocalidadDto {

   public id!: number
   public categoria!: string
   public centroideLon!: number
   public centroideLat!: number
   public departamento!: DepartamentoDto 
   public fuente!: string
   public municipio!: string
   public nombreCompleto!: string
   public descripcion!: string

    
}
