<h1 mat-dialog-title><strong>Descargar archivos</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
  <mat-selection-list (selectionChange)="onSelectionChange($event)">
    <mat-list-option *ngFor="let file of data.data" [value]="file">
      {{ file.fileName }}
    </mat-list-option>
  </mat-selection-list>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions align="end">

  <button mat-raised-button color="primary" (click)="downloadFiles()">
    <mat-icon>download</mat-icon> Descargar</button>
</div>
