import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CantidadPorTipoDto } from '@models/base/cantidadPorTipoDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateMovimientoRequest } from '@models/movimiento/createMovimientoRequest.model';
import { DeleteMovimientoRequest } from '@models/movimiento/deleteMovimientoRequest.model';
import { MovimientoDto } from '@models/movimiento/movimientoDto.model';
import { MovimientoFilter } from '@models/movimiento/movimientoFilter.model';
import { UpdateMovimientoRequest } from '@models/movimiento/updateMovimientoRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class MovimientoService {

  constructor(
    private http:HttpClient
  ) { }

  create(createMovimientoRequest:CreateMovimientoRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Movimiento/Create",createMovimientoRequest);
  }

  update(updateMovimientoRequest:UpdateMovimientoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Movimiento/Update",updateMovimientoRequest);
  }

  getAll():Observable<MovimientoDto[]> {
    return   this.http.get<MovimientoDto[]>("/api/Movimiento/GetAll");
  }

  getById(id:number):Observable<MovimientoDto>{
    return  this.http.get<MovimientoDto>("/api/Movimiento/GetById?id="+id);
  }

  getAllByIdExpediente(idExpediente:number):Observable<MovimientoDto[]>{
    return  this.http.get<MovimientoDto[]>("/api/Movimiento/GetAllByIdExpediente?idExpediente="+idExpediente);
  }

 deleteLogic(deleteMovimientoRequest:DeleteMovimientoRequest):Observable<boolean>
  {
    return  this.http.put<any>("/api/Movimiento/DeleteLogic",deleteMovimientoRequest);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Movimiento/Delete?id=" + id);
    else
      return of(null)
  }

  getCantidadPorTipoDto(): Observable<CantidadPorTipoDto[]> {
    return this.http.get<CantidadPorTipoDto[]>("/api/Movimiento/GetCantidadPorTipoDto");
  }

  getAllByFilter(movimientoFilter:MovimientoFilter):Observable<MovimientoDto[]> {
    return   this.http.post<MovimientoDto[]>("/api/Movimiento/GetAllByFilter",movimientoFilter);
  }

}
