<div *appRole="['Pericias.Crear']">
    <button mat-raised-button color="primary" [routerLink]="['/pericias/create']" #tooltip="matTooltip" matTooltip="Crear pericia">
    <mat-icon>add</mat-icon>
    Nueva pericia
  </button>
    <br>
    <br>
</div>

<div class="container-fluid">
    <mat-card *appRole="['Pericias.Listar']">
      <mat-card-content>

        <form [formGroup]="formFilter">

          <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-3">
                  <mat-form-field class="full-width-x100" appearance="outline">
                      <mat-label>Lugar</mat-label>
                      <input matInput type="text" maxlength="200"  formControlName="Lugar" />
                  </mat-form-field>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="checkedFiltroAvanzado">
                <mat-form-field appearance="outline" class="full-width-x100">
                    <ngx-spinner [zIndex]="-1" name="spBusquedaTipoPericia" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Tipo Pericia</mat-label>
                    <mat-select placeholder="Seleccione opcion"  formControlName="TipoPericia">
                        <mat-option [value]="tipoPericia.id" *ngFor="let tipoPericia of tiposPericia ;let i=index;trackBy:trackByItems">
                            {{tipoPericia.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="checkedFiltroAvanzado">
                <mat-form-field appearance="outline" class="full-width-x100">
                    <ngx-spinner [zIndex]="-1" name="spBusquedaEstadoPericia" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Estado Pericia </mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="EstadoPericia">
                        <mat-option [value]="estadoPericia.id" *ngFor="let estadoPericia of estadosPericia ;let i=index;trackBy:trackByItems">
                            {{estadoPericia.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="checkedFiltroAvanzado">
              <mat-checkbox formControlName="Notificado"> Notificado
              </mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="checkedFiltroAvanzado">
              <mat-form-field class="full-width-x100" appearance="outline">
                <mat-label>Rango fecha alta</mat-label>
                <mat-date-range-input
                  [rangePicker]="campaignOnePicker">
                  <input matStartDate placeholder="Fecha comienzo" formControlName="FechaAltaDesde">
                  <input matEndDate placeholder="Fecha Fin" formControlName="FechaAltaHasta">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker></mat-date-range-picker>
              </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-3" *ngIf="checkedFiltroAvanzado">
              <mat-form-field class="full-width-x100" appearance="outline">
                <mat-label>Rango fecha propuesta</mat-label>
                <mat-date-range-input
                  [rangePicker]="campaignOnePicker2">
                  <input matStartDate placeholder="Fecha comienzo" formControlName="FechaPropuestaDesde">
                  <input matEndDate placeholder="Fecha Fin" formControlName="FechaPropuestaHasta">
                </mat-date-range-input>
                <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matSuffix [for]="campaignOnePicker2"></mat-datepicker-toggle>
                <mat-date-range-picker #campaignOnePicker2></mat-date-range-picker>
              </mat-form-field>
            </div>

              <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-3">
                      <button mat-stroked-button color="primary" (click)="filtrar()">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
        </button>
                  </div>
                  <div class="col-sm-12 col-md-6 col-lg-3">
                      <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
                          Filtros Avanzados </mat-slide-toggle>
                  </div>
              </div>
          </div>
      </form>
      <br>
      <br>
      </mat-card-content>

        <mat-card-content>
            <ngx-spinner [zIndex]="100" name="spListado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-4">
                    <mat-form-field *ngIf=data appearance="outline" class="full-width-x100">
                        <mat-label>Buscar</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                    </mat-form-field>
                </div>
            </div>

            <table mat-table [dataSource]="dataSource" *ngIf=data matSort matSortStart="asc">

                <ng-container matColumnDef="expediente">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Caratula</strong> </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let pericia">
                        <a [routerLink]="'/expedientes/'+pericia.expediente.id"><span>{{pericia.expediente == undefined ? "":pericia.expediente.caratula | uppercase}}</span></a>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="lugar">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Lugar</strong></mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let pericia">
                        <span>{{pericia.lugar | titlecase}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fechaPropuesta">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Fecha Propuesta</strong></mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let pericia">
                        <span>{{pericia.fechaPropuesta | date:'short'}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="fechaRealizada">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Fecha Realizada </strong></mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let pericia">
                        <span>{{pericia.fechaRealizada | date:'short'}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="estadoPericia">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Estado</strong> </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let pericia">
                        <span>{{pericia.estadoPericia? pericia.estadoPericia.descripcion : "" | uppercase}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="notificacionRealizada">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong matTooltip="Partes notificadas">Not.</strong> </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let pericia">
                        <span>{{pericia.notificacionRealizada == true ? "Si" : "No" | uppercase}}</span>
                    </mat-cell>
                </ng-container>


                <!-- Accion Column -->
                <ng-container matColumnDef="accion">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let pericia" class="action-link">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
            <mat-icon>more_vert</mat-icon>
          </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['/pericias/',pericia.id]" *appRole="['Pericias.Ver']">
              <mat-icon>visibility</mat-icon>
              <span>Ver</span>
            </button>
                            <button mat-menu-item [routerLink]="['/pericias/edit/',pericia.id]" *appRole="['Pericias.Modificar']">
              <mat-icon>edit</mat-icon>
              <span>Editar</span>
            </button>
                            <button mat-menu-item (click)="openDialog('Eliminar',pericia)" *appRole="['Pericias.Eliminar']">
              <mat-icon>delete</mat-icon>
              <span>Eliminar</span>
            </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


            </table>
            <div class="no-data-table">
                <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron datos
        para mostrar.</span>
            </div>
            <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
            </mat-paginator>
        </mat-card-content>

    </mat-card>
</div>
