import { FormGroup } from '@angular/forms';

// export function EmailValidator(controlName: string) {
//     return (formGroup: FormGroup) => {
//         const control = formGroup.controls[controlName];

//         // set error on matchingControl if validation fails
//         if (control.value == "pepe" || control.value == "") {
//             control.setErrors({ EmailValidator: true });
//         } else {
//             control.setErrors(null);
//         }
//     }
// }

export class CorreoValidator {


  static validateEmail(email: string):string {
    let validatedEmail: string;
    if (this.isValidEmail(email)) {
      validatedEmail = email;
    } else {
      alert('Por favor, ingrese un correo electrónico válido.');
      validatedEmail = "";
    }
    return validatedEmail
  }

  static sendEmail(validatedEmail: string,subject: string,body: string) {
    if (validatedEmail) {
      const mailtoLink = `mailto:${validatedEmail}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink, '_blank');
    } else {
      alert('Por favor, valide el correo electrónico antes de enviar.');
    }
  }

  static isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
}
