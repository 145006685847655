import { BaseDto } from "@models/base/baseDto.model"
import { CasoDto } from "@models/caso/casoDto.model"
import { EmpresaDto } from "@models/empresa/empresaDto.model"
import { OrganismoDto } from "@models/organismo/organismoDto.model"
import { PersonaDto } from "@models/persona/personaDto.model"

export class ExpedienteDto{
public id!: number
public descripcion!: string
public ubicacion!:	string
public caratula!:	string
public cuij!:	string
public numero!:	string
public demanda!:	string
public demandado!:	string
public causa!:	string
public fechaAlta!:	Date
public fechaBaja!: Date
public estadoExpedientePerito!:	BaseDto
public estadoExpediente!:BaseDto
public organismo!:	OrganismoDto
public empresa!:	EmpresaDto
public activo!: boolean
public tipoExpediente!: BaseDto
public claveSisfeExpediente!:	string
public sufijo!:	string
public bis!:	string
public fechaIngresoMEU!: Date
public fechaModificacion!: Date
public montoDemanda!: number
public estadoProcesal!:	BaseDto
public caso!:	CasoDto
}
