<mat-tab-group>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">gavel</mat-icon>
      Expedientes
    </ng-template>
    <br>
    <div class="tab-content">
      <app-dashboard-expedientes></app-dashboard-expedientes>
    </div>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">gavel</mat-icon>
      Pericias
    </ng-template>
    <br>
    <div class="tab-content">
      <app-dashboard-pericias></app-dashboard-pericias>
    </div>

  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">task</mat-icon>
      Tareas
    </ng-template>
    <br>
    <div class="tab-content">
      <app-dashboard-tareas></app-dashboard-tareas>
    </div>

  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="example-tab-icon">account_balance</mat-icon>
      Movimientos
    </ng-template>
    <br>
    <div class="tab-content">
      <app-dashboard-movimientos></app-dashboard-movimientos>
    </div>

  </mat-tab>


</mat-tab-group>
