import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PericiaDto } from '@models/pericia/periciaDto.model';
import { PericiaService } from '@services/pericia/pericia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-form-pericia-view',
  templateUrl: './form-pericia-view.component.html',
  styleUrls: ['./form-pericia-view.component.css']
})
export class FormPericiaViewComponent {
  periciaDto: PericiaDto =  new PericiaDto;
  constructor(
    private PericiaService: PericiaService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnInit(): void {
    let idPericia = this.activatedRoute.snapshot.params['id']
    this.getPericiaById(idPericia)
  }

  getPericiaById(id: number) {
    this.spinner.show("spView")
    this.PericiaService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spView")
          this.periciaDto = data
        },
        error => {
          this.spinner.hide("spView")
          this.snackBar.showError(error, "Error");
        })
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }
}
