<h1 mat-dialog-title>Ver movimiento</h1>
<mat-dialog-content>
  <ngx-spinner [zIndex]="100" name="spLoadView" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
    type="ball-atom" [fullScreen]="false">
  </ngx-spinner>

  <mat-accordion>
    <mat-expansion-panel [expanded]="true" (opened)="panelOpenState = true" (closed)="panelOpenState = true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          General
        </mat-panel-title>
        <mat-panel-description>
          Informacion del Movimiento
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div class="row">
        <div class="col-12">
          <span><strong>Expediente: </strong></span>
          <span>{{movimientoDto.expediente?.caratula}}-{{movimientoDto.expediente?.cuij}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <span><strong>Fecha Movimiento: </strong></span>
          <span> {{movimientoDto.fechaMovimiento | date}}</span>
        </div>
        <div class="col-6">
          <span><strong>Estado: </strong></span>
          <span> {{movimientoDto.activo == true ? "Activo": "Baja" | titlecase}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <span><strong>Tipo Movimiento: </strong></span>
          <span> {{movimientoDto.tipoMovimiento?.descripcion | titlecase}}</span>
        </div>
        <div class="col-3">
          <span><strong>Tipo Moneda: </strong></span>
          <span> {{movimientoDto.tipoMonedaOrigen?.descripcion | titlecase}}</span>
        </div>
        <div class="col-3">
          <span><strong>Importe: </strong></span>
          <span> <strong>{{movimientoDto.importe | currency}}</strong></span>
        </div>
      </div>
        <div class="row">
          <div class="col-12">
            <span><strong>Descripción: </strong></span>
            <span> {{movimientoDto.descripcion}}</span>
          </div>
        </div>
    </mat-expansion-panel>
    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
      <mat-expansion-panel-header>
        <mat-panel-title>
          Auditoria
        </mat-panel-title>
        <mat-panel-description>
          Informacion de auditoria
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="row">
        <div class="col-6">
          <span><strong>Fecha Alta: </strong></span>
          <span> {{movimientoDto.fechaAlta | date}}</span>
        </div>
        <div class="col-6">
          <span><strong>Fecha Modificación: </strong></span>
          <span> {{movimientoDto.fechaModificacion | date}}</span>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <span><strong>Fecha Baja: </strong></span>
          <span> {{movimientoDto.fechaBaja | date}}</span>
        </div>
        <div class="col-6">
          <span><strong>Usuario: </strong></span>
          <span> {{movimientoDto.usuario?.nombre | titlecase}} {{movimientoDto.usuario?.apellido | titlecase}}</span>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>



</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" style="margin-right:3px;" name="Cancelar"
    [mat-dialog-close]="true">Cancelar</button>
</mat-dialog-actions>
