import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject,  OnInit, Optional } from '@angular/core';
import { FormBuilder,  FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { BaseDto } from '@models/base/baseDto.model';
import { EntidadDto } from '@models/base/entidadDto.model';
import { CreateDocumentoRequest } from '@models/documento/createDocumentoRequest.model';
import { AsuntoService } from '@services/asunto/asuntos.service';
import { DocumentoService } from '@services/documento/documento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UtilidadesController } from '@controllers/utilidades.controller';



@Component({
  selector: 'app-create-document',
  templateUrl: './create-document.component.html',
  styleUrls: ['./create-document.component.css']
})
export class CreateDocumentComponent implements OnInit {

  formDocuementos!: FormGroup;
  lstAsuntos: BaseDto[] = [];
  fileError: string = "";
  imageBaseData: string = "";
  theFile: any;
  fileUploadState: boolean = false
  fileImage: boolean = false;
  createDocumentoRequest: CreateDocumentoRequest = new CreateDocumentoRequest
  //Etiqueta
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  lstEtiquetas: String[] = [
  ];
  formBuilder: FormBuilder = new FormBuilder;
  //Fin Etiqueta

  get f() { return this.formDocuementos.controls; }

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: EntidadDto,
    private spinner: NgxSpinnerService,
    private documentoService: DocumentoService,
    private AsuntoService: AsuntoService,
    private snackBar: SnackBarService,
    private router: Router,
  ) {

    this.formDocuementos = this.formBuilder.group({
      Asuntos: ['', Validators.compose([Validators.required])],
      NombreDocumento:['', Validators.compose([Validators.required])],
      Descripcion: ['', Validators.compose([Validators.maxLength(200)])],
      Etiquetas: [''],
      FileUpload: ['', Validators.compose([Validators.required])],
    })
  }

  ngOnInit() {
    this.getAllAsuntos()
  }

  selectAsunto(event: any) { }

  seleccionArchivo(archivo:ArchivoDto){
    if(archivo.content != ""){
      this.createDocumentoRequest.fileContent = archivo.content
      this.createDocumentoRequest.fileName = archivo.descripcion
      this.createDocumentoRequest.fileType = archivo.contentType
      this.formDocuementos.controls["FileUpload"].setValue("Archivo Cargado")
    }
  }

  onReset() {
    this.imageBaseData = "";
    this.createDocumentoRequest = new CreateDocumentoRequest
    this.formDocuementos.reset()
    this.lstEtiquetas = []
  }

  agregarDocumento() {
    this.setCreateDocumentoRequest()
    this.createDocumento(this.createDocumentoRequest)
  }


  setCreateDocumentoRequest() {
    let idAsunto = this.formDocuementos.controls["Asuntos"].value
    let nombreDocumento = this.formDocuementos.controls["NombreDocumento"].value
    let descripcion = this.formDocuementos.controls["Descripcion"].value
    let etiquetas = this.formDocuementos.controls["Descripcion"].value

    this.createDocumentoRequest.idAsunto = idAsunto
    this.createDocumentoRequest.descripcion = descripcion
    this.createDocumentoRequest.etiquetas = UtilidadesController.unirCadena(this.lstEtiquetas)
    this.createDocumentoRequest.nombre = nombreDocumento

  }

  getAllAsuntos() {
    this.spinner.show("spBusqueda");
    this.AsuntoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda")
          this.lstAsuntos = data
        },
        error => {
          this.spinner.hide("spBusqueda")
          this.snackBar.showError( error, "Error");
        }
      )
  }

  createDocumento(createDocumentoRequest: CreateDocumentoRequest) {
    this.spinner.show()
    this.documentoService.create(createDocumentoRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showSuccess("Documento creado con exito.", "Exito");
          this.router.navigate(["/documentos"])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError( error, "Error");
        }
      )
  }

  //Etiquetas
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.lstEtiquetas.push(value);
    }

    this.formDocuementos.controls["Etiquetas"].setValue("")
  }

  remove(etiqueta: String): void {
    const index = this.lstEtiquetas.indexOf(etiqueta);

    if (index >= 0) {
      this.lstEtiquetas.splice(index, 1);
    }
  }

  dividirCadena(cadenaADividir: string, separador: string) {
    this.lstEtiquetas = cadenaADividir.split(separador);
  }

  trackByItems(index: number, item: any): any { return item; }
}
