import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { PericiaDto } from '@models/pericia/periciaDto.model';
import { UpdatePericiaRequest } from '@models/pericia/updatePericiaRequest.model';
import { EstadoPericiaService } from '@services/estadoPericia/estadoPericia.service';
import { PericiaService } from '@services/pericia/pericia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoPericiaService } from '@services/tipoPericia/tipoPericia.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-form-pericia-update',
  templateUrl: './form-pericia-update.component.html',
  styleUrls: ['./form-pericia-update.component.css']
})
export class FormPericiaUpdateComponent {

  formBuilder: FormBuilder = new FormBuilder;
  tiposAudiencia!: BaseDto[];
  periciaForm: any;
  periciaDto!: PericiaDto;
  expediente!: ExpedienteDto;

  lstTiposPericia!: BaseDto[];
  lstEstadosPericias!: BaseDto[];


  constructor(
    private PericiaService: PericiaService,

    private estadosPericia: EstadoPericiaService,
    private tiposPericia: TipoPericiaService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnInit(): void {
    let idPericia = this.activatedRoute.snapshot.params['id']
    this.getPericiaById(idPericia)

    this.getAllTipoPericia()
    this.getAllEstadosPericia()

    this.periciaForm = this.formBuilder.group({
      Descripcion: [''],
      Lugar: ['', Validators.compose([Validators.required, Validators.maxLength(200)])],
      NotificacionRealizada: [''],
      FechaPropuesta: ['', Validators.compose([Validators.required])],
      FechaRealizada: [''],
      FechaFinalizada: [''],
      TipoPericia: [''],
      EstadoPericia: [''],

    });
  }


  onSubmit() {
    if (this.periciaForm.invalid) {
      return;
    }
    const updatePericiaRequest: UpdatePericiaRequest = this.setUpdatePericiaRequest();
    this.spinner.show("spUpdate")
    this.PericiaService.update(updatePericiaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spUpdate")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "Exito");
        },
        error => {
          this.spinner.hide("spUpdate")
          this.snackBar.showError(error, "Error");
        })
  }

  getPericiaById(id: number) {
    this.spinner.show("spUpdate")
    this.PericiaService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spUpdate")
          this.periciaDto = data
          this.setPericiaDtoForm(data);
        },
        error => {
          this.spinner.hide("spUpdate")
          this.snackBar.showError(error, "Error");
        })
  }
  setPericiaDtoForm(periciaDto: PericiaDto) {


    this.periciaForm.controls['Descripcion'].setValue(periciaDto.descripcion);
    this.periciaForm.controls['Lugar'].setValue(periciaDto.lugar);
    this.periciaForm.controls['NotificacionRealizada'].setValue(periciaDto.notificacionRealizada);

    if (periciaDto.fechaPropuesta?.toString() != "0001-01-01T00:00:00") {
      this.periciaForm.controls['FechaPropuesta'].setValue(periciaDto.fechaPropuesta);
    } else {
      this.periciaForm.controls['FechaPropuesta'].setValue("");
    }

    if (periciaDto.fechaRealizada?.toString() != "0001-01-01T00:00:00") {
      this.periciaForm.controls['FechaRealizada'].setValue(periciaDto.fechaRealizada);
    } else {
      this.periciaForm.controls['FechaRealizada'].setValue("");
    }

    if (periciaDto.fechaFinalizada?.toString() != "0001-01-01T00:00:00") {
      this.periciaForm.controls['FechaFinalizada'].setValue(periciaDto.fechaFinalizada);
    } else {
      this.periciaForm.controls['FechaFinalizada'].setValue("");
    }

    this.periciaForm.controls['TipoPericia'].setValue(isFalsy(periciaDto.estadoPericia) ? "" : periciaDto.tipoPericia.id);
    this.periciaForm.controls['EstadoPericia'].setValue(isFalsy(periciaDto.tipoPericia) ? "" : periciaDto.estadoPericia.id);
    this.expediente = periciaDto.expediente
  }

  setUpdatePericiaRequest(): UpdatePericiaRequest {

    const updatePericiaRequest = new UpdatePericiaRequest();
    updatePericiaRequest.id = this.periciaDto.id
    updatePericiaRequest.descripcion = this.periciaForm.controls['Descripcion'].value;
    updatePericiaRequest.lugar = this.periciaForm.controls['Lugar'].value;
    updatePericiaRequest.notificacionRealizada = isFalsy(this.periciaForm.controls['NotificacionRealizada'].value) ? false : true;

    let fechaRealizada = this.periciaForm.controls['FechaRealizada'].value;
    if (fechaRealizada != "0001-01-01T00:00:00") {
      updatePericiaRequest.fechaRealizada = fechaRealizada;
    }
    else {
      updatePericiaRequest.fechaRealizada = "";
    }

    let fechaPropuesta = this.periciaForm.controls['FechaPropuesta'].value;
    if (fechaPropuesta != "0001-01-01T00:00:00") {
      updatePericiaRequest.fechaPropuesta = fechaPropuesta;
    }

    let fechaFinalizada = this.periciaForm.controls['FechaFinalizada'].value;
    if (fechaFinalizada != "0001-01-01T00:00:00") {
      updatePericiaRequest.fechaFinalizada = fechaFinalizada;
    }

    updatePericiaRequest.idEstadoPericia = this.periciaForm.controls['EstadoPericia'].value == "" ? null : this.periciaForm.controls['EstadoPericia'].value;
    updatePericiaRequest.idTipoPericia = this.periciaForm.controls['TipoPericia'].value == "" ? null : this.periciaForm.controls['TipoPericia'].value;
    updatePericiaRequest.idExpediente = this.periciaDto.expediente.id
    updatePericiaRequest.idEmpresa = this.periciaDto.empresa.id
    updatePericiaRequest.activo = this.periciaDto.activo

    return updatePericiaRequest
  }

  onReset() {
    this.periciaForm.reset();
  }

  getAllTipoPericia() {
    this.spinner.show('spTiposPericia')
    this.tiposPericia.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spTiposPericia')
          this.lstTiposPericia = data
        },
        error => {
          this.spinner.hide('spTiposPericia')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadosPericia() {
    this.spinner.show('spEstadosPericia')
    this.estadosPericia.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spEstadosPericia')
          this.lstEstadosPericias = data
        },
        error => {
          this.spinner.hide('spEstadosPericia')
          this.snackBar.showError(error, "Error");
        })
  }


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }


}
