import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalidadDto } from '@models/localidad/localidadDto.model';
import { BaseDto } from '@models/base/baseDto.model';

import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class LocalidadService {

  LocalidadDto:LocalidadDto= new LocalidadDto() ;
  lstLocalidadDto: LocalidadDto[] = [];

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient) {
  }

  // getAll():Observable<LocalidadDto[]> {
  //   return   this.http.get<LocalidadDto[]>("/api/Localidad/GetAll");
  // }

  getLocalidadesByProvincia(idLocalidad:number):Observable<LocalidadDto[]>{
    return  this.http.get<LocalidadDto[]>("/api/Localidad/GetAllLocalidadesByProvincia?Id_Provincia="+idLocalidad);
  }

  getLocalidadByName(nombreLocalidad:string):Observable<LocalidadDto[]>
  {
    return  this.http.get<LocalidadDto[]>("/api/Localidad/GetLocalidadByName?idLocalidad="+nombreLocalidad);
  }

  getLocalidadById(idLocalidad:number):Observable<LocalidadDto>
  {
    return  this.http.get<LocalidadDto>("/api/Localidad/GetLocalidadById?idLocalidad="+idLocalidad);
  }

  // delete(id: number): Observable<any> {
  //   if (!isFalsy(id))
  //     return this.http.delete<any>("/api/Localidad/Delete?id=" + id);
  //   else
  //     return of(null)
  // }

}
