import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';



@NgModule({
  declarations: [
    // BtnNotificacionesComponent,
    // DialogNotificacionesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports:[
    // BtnNotificacionesComponent,
    // DialogNotificacionesComponent,
    ]
})
export class NotificacionModule { }
