import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UsuarioDto } from '@models/usuario/usuarioDto.model';
import { DeleteUserRequest } from '@models/usuario/deleteUserRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuarioService } from '@services/usuario/usuario.service';
import { AccionesDialog } from '@models/base/identificadores.model';

@Component({
  selector: 'app-dialog-delete-user',
  templateUrl: './dialog-delete-user.component.html',
  styleUrls: ['./dialog-delete-user.component.css']
})
export class DialogDeleteUserComponent implements OnInit {

  @Input() UsuarioDto: UsuarioDto = new UsuarioDto;
  constructor(
    public dialogRef: MatDialogRef<DialogDeleteUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UsuarioDto,
    private UsuarioService: UsuarioService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: SnackBarService
  ) { }

  ngOnInit(): void {
  }

  eliminarUsuario(): void {
    let deleteUserRequest = new DeleteUserRequest
    deleteUserRequest.idUsuario = this.data.id
    this.UsuarioService.deleteUser(deleteUserRequest)
      .subscribe(
        data => {
          if (data == true) {
            this.dialogRef.close({event:AccionesDialog.Aceptar});
            this.snackBar.showSuccess("Se elimino el registro correctamente.", "")
            this.router.navigate(["/usuarios"])
          }
        },
        error => {
          this.snackBar.showError( error, "Error");
        }
      )
  }



  onNoClick(): void {
    this.dialogRef.close({event:AccionesDialog.Aceptar});
  }

}
