import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CantidadPorTipoDto } from '@models/base/cantidadPorTipoDto.model';
import { MovimientoService } from '@services/movimiento/movimiento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-movimientos',
  templateUrl: './dashboard-movimientos.component.html',
  styleUrls: ['./dashboard-movimientos.component.css']
})
export class DashboardMovimientosComponent {

  cardLayout:any

  formBuilder: FormBuilder = new FormBuilder;
  form!: FormGroup;
  cantidadPorTipoDto: CantidadPorTipoDto[] = [];
  constructor(

    private movimientoService: MovimientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
  ){}

  ngOnInit(): void {

      this.getCantidadPorEstadoDto()
  }



  getCantidadPorEstadoDto() {
    this.spinner.show("spCantidadPorTipo")
    this.movimientoService.getCantidadPorTipoDto().subscribe(
      data => {
        this.spinner.hide("spCantidadPorTipo")
        this.cantidadPorTipoDto = data
      },
      error => {
        this.spinner.hide("spCantidadPorTipo")
        this.snackBar.showError(error, "Error");
      })
  }
  calcularCantidadTotalMovimientoPorTipo(): number {
    let cantidadTotal = 0;
    for (const item of this.cantidadPorTipoDto) {
      cantidadTotal += item.cantidad;
    }
    return cantidadTotal;
  }
}
