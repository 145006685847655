import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListTipoPericiaComponent } from '@views/tipos-pericia/list-tipo-pericia/list-tipo-pericia.component';
import { DialogTipoPericiaGenericComponent } from '@views/tipos-pericia/dialog-tipo-pericia-generic/dialog-tipo-pericia-generic.component';
import { SharedModule } from './shared.module';



@NgModule({
  declarations: [
    ListTipoPericiaComponent,
    DialogTipoPericiaGenericComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports:[
    ListTipoPericiaComponent,
    DialogTipoPericiaGenericComponent
  ]
})
export class TipoPericiaModule { }
