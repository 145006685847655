import { Component } from '@angular/core';

@Component({
  selector: 'app-btn-create-pericia',
  templateUrl: './btn-create-pericia.component.html',
  styleUrls: ['./btn-create-pericia.component.css']
})
export class BtnCreatePericiaComponent {

}
