import { UpdateKeyValueRequest } from "@models/base/updateKeyValueRequest.model"

export class UpdateAudienciaRequest extends UpdateKeyValueRequest{

  public sala!:	string
  public lugar!:	string
  public fecha!: Date
  public idJuez!: number| null
  public idFiscal!:number| null
  public idDefensor!: number| null
  public idTipoAudiencia!: number
  public idExpediente!: number
  public idEmpresa!:number
  }
