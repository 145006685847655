import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared.module";
import { FileUploadMultipleComponent } from "../views/file-upload-multiple/file-upload-multiple.component";
import { FileUploadComponent } from "../views/file-upload/file-upload.component";
import { AsuntoModule } from "./asunto.module";
import { PersonaModule } from "./persona.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AsuntoModule,
        PersonaModule,
        PersonaModule,
        AsuntoModule
    ],
    declarations: [
        FileUploadComponent,
        FileUploadMultipleComponent
    ],

    providers: [
    ],
    exports: [
      FileUploadComponent,
      FileUploadMultipleComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ArchivoModule { }
