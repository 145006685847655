import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CasoUsoService } from '@services/casoUso/casoUso.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogDeleteCasoUsoComponent } from './dialog-delete-caso-uso/dialog-delete-caso-uso.component';

@Component({
  selector: 'app-eliminar-caso-uso',
  templateUrl: './eliminar-caso-uso.component.html',
  styleUrls: ['./eliminar-caso-uso.component.css']
})
export class EliminarCasoUsoComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogDeleteCasoUsoComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private CasoUsoService: CasoUsoService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  ngOnInit(): void {
  }

  doAction() {
    this.delete(this.data.id)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  delete(id: number) {
    this.spinner.show("spEliminarCasoUso")
    this.CasoUsoService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide("spEliminarCasoUso")
          this.snackBar.showSuccess("Registro eliminado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Eliminar });
        },
        error => {
          this.spinner.hide("spEliminarCasoUso")
          this.snackBar.showError(error, "Error");
        }
      )
  }
  trackByItems(index: number, item: any): any { return item; }
}
