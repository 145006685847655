import { Component, OnInit } from '@angular/core';
import { LoginRequest } from '@models/login/login-model.model copy';
import { TokenModel } from '@models/login/token-model.model';
import { LoginService } from '@services/login/login.service';
import { UsuarioDto } from '@models/usuario/usuarioDto.model'
import { Router } from '@angular/router';
import { UsuarioService } from '@services/usuario/usuario.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  submitted = false;
  tokenModel: TokenModel = new TokenModel
  loginRequest: LoginRequest = new LoginRequest
  UsuarioDto: UsuarioDto = new UsuarioDto;
  idPersona: number = 0;
  loginTrue:boolean=false;
  modoRecuperacion= false;
  botonIrARestarura= true;
  entorno = `${environment.entorno}`;
  constructor(
    private loginService: LoginService,
    private router: Router,
    private userService: UsuarioService,
  ) {
  }

  ngOnInit(): void {
  }

  goToLogin(){
    this.modoRecuperacion= false;
  }
  RecuperarCuenta(){
    this.modoRecuperacion= true;
  }
}
