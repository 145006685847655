<mat-card *appRole="['Pericias.Ver']">
    <mat-card-header>
        <mat-card-title>
            <h2>Ver pericia</h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ngx-spinner [zIndex]="100" name="spView" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <mat-card>
            <mat-card-header>
                <h5>Expediente:</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <span><strong>Caratula: </strong></span>
                        <span> {{!isFalsy(periciaDto.expediente.caratula) ? periciaDto.expediente.caratula : "" | uppercase}}
            </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <span><strong>CUIJ: </strong></span>
                        <span> {{!isFalsy(periciaDto.expediente.cuij) ? periciaDto.expediente.cuij : "" }}
            </span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
        <br>
        <mat-divider></mat-divider>
        <br>
        <mat-card>
            <mat-card-header>
                <h5>Pericia:</h5>
            </mat-card-header>
            <mat-card-content>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span><strong>Cuij: </strong></span>
                        <span> {{!isFalsy(periciaDto.expediente) ? periciaDto.expediente.cuij : ""}}</span>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span><strong>Notificados: </strong></span>
                        <span>{{periciaDto.notificacionRealizada == true ? "Se encuentran notificados." : "No se encuentran notificados."}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span><strong>Tipo Pericia: </strong></span>
                        <span>{{periciaDto.tipoPericia? periciaDto.tipoPericia.descripcion : "" | titlecase}}</span>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span><strong>Estado Pericia: </strong></span>
                        <span>{{periciaDto.estadoPericia? periciaDto.estadoPericia.descripcion : "" | titlecase}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span><strong>Lugar: </strong></span>
                        <span>{{periciaDto.lugar == undefined ? "" : periciaDto.lugar |
              uppercase}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span><strong>Fecha Propuesta: </strong></span>
                        <span>{{periciaDto.fechaPropuesta | date:('short')}}</span>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-6">
                        <span><strong>Fecha Realiza: </strong></span>
                        <span>{{periciaDto.fechaRealizada | date:('short')}}</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                        <span><strong>Descripción: </strong></span>
                        <span>{{periciaDto.descripcion}}</span>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

    </mat-card-content>
</mat-card>
