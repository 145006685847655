<h1 mat-dialog-title>Editar movimiento</h1>
<br>
<mat-dialog-content>
    <ngx-spinner [zIndex]="100" name="spLoadUpdate" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="form">
        <div class="row" *ngIf="movimientoDto.expediente != undefined">
            <div class="col">
                <p>{{movimientoDto.expediente.caratula}}-{{movimientoDto.expediente.cuij}}</p>
            </div>
        </div>
        <div class="row">
          <div class="col" >
              <mat-radio-group formControlName="EgresoIngreso">
                  <mat-radio-button color="primary" value="1">Ingreso</mat-radio-button>
                  <mat-radio-button color="warn" value="-1">Egreso</mat-radio-button>
              </mat-radio-group>
          </div>
          <div class="col" >
              <mat-form-field appearance="outline">
                  <mat-label>Fecha Movimiento</mat-label>
                  <input matInput [matDatepicker]="picker1" formControlName="FechaMovimiento">
                  <mat-hint>MM/DD/YYYY</mat-hint>
                  <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
          </div>
      </div>
        <div class="row">

            <div class="col">
                <mat-form-field appearance="outline" class="full-width-x100">
                    <ngx-spinner [zIndex]="-1" name="spTipoMovimiento" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Tipo movimiento</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="TipoMovimiento">
                        <mat-option [value]="tipoMovimiento.id" *ngFor="let tipoMovimiento of tiposMovimiento">
                            {{tipoMovimiento.descripcion}}</mat-option>
                    </mat-select>
                    <mat-error *ngIf="form.controls['TipoMovimiento'].hasError('required')">Este campo es
                        <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
            <div class="col" >
              <mat-form-field appearance="outline" class="full-width-x100">
                  <mat-label>Tipo Moneda</mat-label>
                  <mat-select placeholder="Seleccione opcion" formControlName="TipoMoneda">
                      <mat-option [value]="1"> Peso Arg. ($)</mat-option>
                      <mat-option [value]="2"> Dolar USA (U$D)</mat-option>
                  </mat-select>
              </mat-form-field>
          </div>
            <div class="col">
                <mat-form-field class="full-width-x100" appearance="outline">
                    <mat-label>Importe</mat-label>
                    <input type="text" currencyMask placeholder="Importe" formControlName="Importe" matInput>
                    <mat-error *ngIf="form.controls['Importe'].hasError('required')">Este campo es
                        <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
            <div class="row">
                <div class="col">

                    <mat-form-field class="example-full-width col-lg-12" appearance="outline">
                        <mat-label>Descripción</mat-label>
                        <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
                        <mat-error *ngIf="form.controls['Descripcion'].hasError('maxlength')">Supero el largo maximo.</mat-error>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" style="margin-right:3px;" name="Guardar" [disabled]="!form.valid || data.idExpediente== undefined" (click)="onSubmit()">Guardar</button>
</mat-dialog-actions>
