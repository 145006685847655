import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BuscarCasoComponent } from '@views/busquedas/buscar-caso/buscar-caso.component';
import { SharedModule } from './shared.module';
import { BuscarExpedienteComponent } from '@views/busquedas/buscar-expediente/buscar-expediente.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    BuscarCasoComponent,
    BuscarExpedienteComponent
  ],
  exports: [
    BuscarCasoComponent,
    BuscarExpedienteComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BusquedasModule { }
