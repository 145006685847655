import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, TiposMoneda } from '@models/base/identificadores.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { CreateMovimientoRequest } from '@models/movimiento/createMovimientoRequest.model';
import { LoginService } from '@services/login/login.service';
import { MovimientoService } from '@services/movimiento/movimiento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoMovimientoService } from '@services/tipoMovimiento/tipoMovimiento.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-create-movimiento',
  templateUrl: './dialog-create-movimiento.component.html',
  styleUrls: ['./dialog-create-movimiento.component.css']
})
export class DialogCreateMovimientoComponent {

  formBuilder: FormBuilder = new FormBuilder;
  form: any;
  tiposMovimiento!: BaseDto[];
  expedienteDto!: ExpedienteDto;

  constructor(
    private loginService: LoginService,
    public dialogRef: MatDialogRef<DialogCreateMovimientoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private movimientoService: MovimientoService,
    private tipoMovimientoService: TipoMovimientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) {

    this.form = this.formBuilder.group({
      Descripcion: ['', Validators.compose([Validators.maxLength(200)])],
      TipoMovimiento: ['', Validators.compose([Validators.required])],
      Importe: ['', Validators.compose([Validators.required])],
      EgresoIngreso: ['', Validators.compose([Validators.required])],
      FechaMovimiento: ['', Validators.compose([Validators.required])],
      TipoMoneda: [''],
    })
  }
  ngOnInit() {
    this.getAllTipoMovimiento()
    this.form.controls["FechaMovimiento"].setValue(new Date().toISOString())
    this.form.controls["EgresoIngreso"].setValue("1")
  }

  selectExpediente($event: any) {
    this.expedienteDto = $event
  }


  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const createMovimientoRequest: CreateMovimientoRequest = new CreateMovimientoRequest
    createMovimientoRequest.descripcion = isFalsy(this.form.controls['Descripcion'].value) ? null : this.form.controls['Descripcion'].value;
    createMovimientoRequest.idTipoMovimiento = isFalsy(this.form.controls['TipoMovimiento'].value) ? null : this.form.controls['TipoMovimiento'].value;
    createMovimientoRequest.fechaMovimiento = isFalsy(this.form.controls['FechaMovimiento'].value) ? null : this.form.controls['FechaMovimiento'].value;
    let signo = isFalsy(this.form.controls['EgresoIngreso'].value) ? 0 : parseInt(this.form.controls['EgresoIngreso'].value);
    createMovimientoRequest.importe = isFalsy(this.form.controls['Importe'].value) ? 0 : this.form.controls['Importe'].value * signo;

    if (this.data.idExpediente)
      createMovimientoRequest.idExpediente = this.data.idExpediente
    else
      createMovimientoRequest.idExpediente = this.expedienteDto.id

    createMovimientoRequest.idTipoMonedaLocal = TiposMoneda.PesoArgentino
    createMovimientoRequest.idTipoMonedaOrigen = isFalsy(this.form.controls['TipoMoneda'].value) ? null : this.form.controls['TipoMoneda'].value;
    createMovimientoRequest.idUsuario = this.loginService.getIdUser();
    createMovimientoRequest.idEmpresa = this.loginService.getCurrentEmpresa();
    createMovimientoRequest.activo = true;
    this.spinner.show("spLoadGeneric")

    this.movimientoService.create(createMovimientoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "Exito");
          this.dialogRef.close({ data: data, event: AccionesDialog.Agregar});
        },
        error => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTipoMovimiento() {
    this.spinner.show("spTipoMovimiento")
    this.tipoMovimientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTipoMovimiento")
          this.tiposMovimiento = data
        },
        error => {
          this.spinner.hide("spTipoMovimiento")
          this.snackBar.showError(error, "Error");
        })
  }
}
