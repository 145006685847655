<ngx-spinner [zIndex]="100" name="spOrganismo" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
  type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<div *appRole="['Organismos.Ver']">
  <div *ngIf="organismoDto">

    <mat-tab-group>
      <mat-tab label="Organismo">
        <mat-card>
          <mat-card-header>
            <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>Acciones</button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openDialog('Modificar',organismoDto)">
                Editar</button>
              <button mat-menu-item (click)="openDialog('Eliminar',organismoDto)">
                Eliminar</button>
            </mat-menu>
          </mat-card-header>
          <br>
          <mat-card-content>
            <div class="row">
              <div class="col">
                <!-- Información de Persona -->
                <h3>Información de Organismo</h3>
                <p><strong>Nombre:</strong> {{ organismoDto.nombre | titlecase}}</p>
              </div>
              <div class="col">
                <!-- Información de Contacto -->
                <h3>Información de Contacto</h3>
                <p><strong>Teléfono Principal:</strong> {{ organismoDto.telefono }}</p>
                <p><strong>Correo Electrónico:</strong> {{ organismoDto.correo }}</p>
                <p><strong>Localidad:</strong> {{ organismoDto.localidad?.nombreCompleto | titlecase}}</p>
                <p><strong>Provincia:</strong> {{ organismoDto.localidad?.departamento?.provincia?.nombreCompleto }}</p>
                <!-- ... Otros campos ... -->
              </div>
              <div class="col">
                <!-- Estado y Otras Informaciones -->
                <!-- <h3>Estado y Otras Informaciones</h3>
                <p [ngClass]="{'active': personaDetailDto.activo, 'inactive': !personaDetailDto.activo}">
                  <strong>Estado:</strong>{{ personaDetailDto.activo ? 'Activo' : 'Inactivo' }}
                </p>
                <p><strong>Fecha Alta:</strong> {{ organismoDto.estadoCivil?.descripcion }}</p>
                <p><strong>Ultima Modificación:</strong> {{ organismoDto.estadoCivil?.descripcion }}</p>
                <p><strong>Observaciones:</strong> {{ organismoDto.descripcion}}</p> -->
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Partes">
        <section>
          <mat-card-header>
          </mat-card-header>
          <mat-card-content>
            <app-form-organismo-partes></app-form-organismo-partes>
          </mat-card-content>
        </section>
      </mat-tab>
      <mat-tab label="Expedientes">
        <section>
          <mat-card-content>
            <div class="container-fluid">
              <mat-card *appRole="['Expedientes.Ver']">
                <mat-card-content>
                  <ngx-spinner [zIndex]="100" name="spExpediente" bdColor="rgba(255,255,255,0.8)" size="default"
                    color="#009688" type="ball-atom" [fullScreen]="false">
                  </ngx-spinner>
                  <table mat-table [dataSource]="dataSourceExpediente" *ngIf=dataExpediente matSort
                    matSortStart="asc">

                    <ng-container matColumnDef="caratula">
                      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Caratula</strong>
                      </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente">
                        <span>{{expediente.caratula | uppercase}}</span>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="cuij">
                      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Cuij</strong>
                      </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente">
                        <span>{{expediente.cuij | uppercase}}</span>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="estadoExpediente">
                      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Estado Exte.</strong>
                      </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente">
                        <span>{{expediente.estadoExpediente == undefined ? "" : expediente.estadoExpediente.descripcion
                          | uppercase}}</span>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="estadoExpedientePerito">
                      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Estado Exte.
                          Perito</strong>
                      </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente">
                        <span>{{expediente.estadoExpedientePerito== undefined ? ""
                          :expediente.estadoExpedientePerito.descripcion | uppercase}}</span>
                      </mat-cell>
                    </ng-container>

                    <!-- Accion Column -->
                    <ng-container matColumnDef="accion">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente" class="action-link">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item [routerLink]="['/expedientes/',expediente.id]"
                            *appRole="['Expedientes.Ver']">
                            <mat-icon>visibility</mat-icon>
                            <span>Ver</span>
                          </button>
                          <button mat-menu-item [routerLink]="['/expedientes/edit/',expediente.id]"
                            *appRole="['Expedientes.Modificar']">
                            <mat-icon>edit</mat-icon>
                            <span>Editar</span>
                          </button>
                        </mat-menu>
                      </mat-cell>
                    </ng-container>



                    <mat-header-row *matHeaderRowDef="displayedColumnsExpediente"></mat-header-row>
                    <mat-row matRipple *matRowDef="let row; columns: displayedColumnsExpediente;"></mat-row>


                  </table>
                  <div class="no-data-table">
                    <span class="with-icon"
                      *ngIf="dataSourceExpediente.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
                      datos
                      para mostrar.</span>
                  </div>
                  <mat-paginator showFirstLastButtons class="paginator" *ngIf=dataExpediente [pageSize]="20"
                    [pageSizeOptions]="[5, 10, 20,50,100,200]">
                  </mat-paginator>
                </mat-card-content>
              </mat-card>
            </div>

          </mat-card-content>
        </section>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>
