import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AccionesDialog } from '@models/base/identificadores.model';
import { PermisoDto } from '@models/seguridad/permisos/permisoDto.model';
import { PermisoService } from '@services/permiso/permiso.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogUpdatePermisoComponent } from '../actualizar-permiso/dialog-update-permiso/dialog-update-permiso.component';
import { DialogCreatePermisoComponent } from '../crear-permiso/dialog-create-permiso/dialog-create-permiso.component';
import { DialogDeletePermisoComponent } from '../eliminar-permiso/dialog-delete-permiso/dialog-delete-permiso.component';
import { DialogViewPermisoComponent } from '../ver-permiso/dialog-view-permiso/dialog-view-permiso.component';

@Component({
  selector: 'app-listado-permiso',
  templateUrl: './listado-permiso.component.html',
  styleUrls: ['./listado-permiso.component.css']
})
export class ListadoPermisoComponent implements OnInit {

  data = false
  dataSource = new MatTableDataSource<PermisoDto>();
  obj!: undefined
  displayedColumns: string[] = [];

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  constructor(
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private permisoService: PermisoService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['descripcion', 'accion'] :
        ['descripcion','token','casoUso','rol', 'accion'];
    });

  }


  ngOnInit() {
  }

  filtrar(){
    this.getAllPermiso();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  openDialogCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    const dialogRef = this.dialog.open(DialogCreatePermisoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.getAllPermiso();
      }
    });
  }

  openDialogUpdate(permisoDto: PermisoDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = permisoDto
    const dialogRef = this.dialog.open(DialogUpdatePermisoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Modificar) {
        this.getAllPermiso();
      }
    });
  }

  openDialogDelete(permisoDto: PermisoDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = permisoDto
    const dialogRef = this.dialog.open(DialogDeletePermisoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.getAllPermiso();
      }
    });
  }

  openDialogVer(permisoDto: PermisoDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = permisoDto
    const dialogRef = this.dialog.open(DialogViewPermisoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Ver) {
        this.getAllPermiso();
      }
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllPermiso() {
    this.spinner.show("spPermisos")
    this.permisoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spPermisos")
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spPermisos")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }


}
