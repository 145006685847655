export class PersonaFilter{
  public id!: string;
  public nombre!: string;
  public apellido!: string;
  public correo!: string;
  public nroDocumento!: string;
  public idTipoDocumento!: number;
  public idLocalidad!: number;
  public activo!: boolean;
}
