import * as moment from 'moment';
import {
  AfterViewInit, Component, EventEmitter, OnChanges, Output, Renderer2, ViewChild
} from '@angular/core';
import { MatCalendar } from '@angular/material/datepicker';
//import { MatCalendar } from '@angular/material';

@Component({
  selector: 'app-calendario',
  templateUrl: './calendario.component.html',
  styleUrls: ['./calendario.component.css']
})
export class CalendarioComponent implements AfterViewInit {
  selectedDate = moment();
  minDate!: moment.Moment;
  maxDate!: moment.Moment;

  @Output()
  dateSelected: EventEmitter<moment.Moment> = new EventEmitter();

  @Output()
  monthSelected: EventEmitter<moment.Moment> = new EventEmitter();

  @ViewChild('calendar', { static: true })
  calendar!: MatCalendar<moment.Moment>;

  constructor(private renderer: Renderer2) { }



  /**
   * @param days: Array of strings in the format "February 20, 2020"
   */
  private highlightDays(days: string[]) {
    const dayElements = document.querySelectorAll(
      'mat-calendar .mat-calendar-table .mat-calendar-body-cell'
    );

    Array.from(dayElements).forEach((element) => {
      const matchingDay = days.find((d) => d === element.getAttribute('aria-label')) !== undefined;

      if (matchingDay) {
        this.renderer.addClass(element, 'available');
        this.renderer.setAttribute(element, 'title', 'Event 1');
      } else {
        this.renderer.removeClass(element, 'available');
        this.renderer.removeAttribute(element, 'title');
      }
    });
  }

  /**
   * @param days: Array of strings in the format "February 20, 2020"
   */
  private disableDays(days: string[]) {
    const dayElements = document.querySelectorAll(
      'mat-calendar .mat-calendar-table .mat-calendar-body-cell'
    );

    Array.from(dayElements).forEach((element) => {
      const matchingDay = days.find((d) => d === element.getAttribute('aria-label')) !== undefined;

      if (matchingDay) {
        this.renderer.setAttribute(element, 'disabled', '');
        this.renderer.setAttribute(element, 'title', 'Unavailable');
      } else {
        this.renderer.removeAttribute(element, 'disabled');
        this.renderer.removeAttribute(element, 'title');
      }
    });
  }
  setMinDate() {
    this.minDate = moment().add(-10, 'day');
  }

  setMaxDate() {
    this.maxDate = moment().add(10, 'day');
  }

  ngAfterViewInit() {
    this.setupArrowButtonListeners();
  }


  private setupArrowButtonListeners() {
    const buttons = document.querySelectorAll('.mat-calendar-previous-button, .mat-calendar-next-button');

    if (buttons) {
      Array.from(buttons).forEach(button => {
        this.renderer.listen(button, 'click', () => this.arrowButtonClicked());
      });
    }
  }

  private arrowButtonClicked() {
    this.monthSelected.emit(this.calendar.activeDate);
  }

  monthChanged(date: moment.Moment) {
    this.monthSelected.emit(date);
  }

  dateChanged() {
    this.calendar.activeDate = this.selectedDate;
    this.dateSelected.emit(this.selectedDate);
  }

  prevDay() {
    const prevMoment = moment(this.selectedDate).add(-1, 'days');
    this.selectedDate = prevMoment;
    this.dateChanged();
  }

  today() {
    this.selectedDate = moment();
    this.dateChanged();
  }

  nextDay() {
    const nextMoment = moment(this.selectedDate).add(1, 'days');
    this.selectedDate = nextMoment;
    this.dateChanged();
  }
}
