import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { CreateDocumentoRequest } from '@models/documento/createDocumentoRequest.model';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {
  @Input() mostrar: boolean = true
  imgURL: any
  formDocuementos: any;
  idAsunto: number = 0
  idDocumento!: number
  fileError: string = "";
  theFile: any;
  fileUploadState: boolean = false
  archivoDto: ArchivoDto = new ArchivoDto
  createDocumentoRequest: CreateDocumentoRequest = new CreateDocumentoRequest
  @Output() eventoArchivo = new EventEmitter<ArchivoDto>();
  @Output() eventoImagen = new EventEmitter<ArchivoDto>();
  @Output() statusFileUpload = new EventEmitter<Boolean>();
  constructor(
    private spinner: NgxSpinnerService,
  ) {
    this.statusFileUpload.emit(false);
  }

  ngOnInit(): void {
  }

  fileChangeEvent(event: any): void {
    this.spinner.show("spinerArchivo")
    this.fileUploadState = false
    // Size Filter Bytes
    const max_size = 35000000;
    const allowed_types = ['image/png', 'image/png', 'image/jpeg', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
    const image_types = ['image/png', 'image/png', 'image/jpeg'];

    const max_height = 15200;
    const max_width = 25600;
    this.fileError = "";
    this.archivoDto.descripcion = ""
    this.theFile = File = event.target.files[0];

    if (this.theFile) {

      //Valida si es un tipo de archivo valido
      for (const i in allowed_types) {
        if (allowed_types[i] == this.theFile.type) {
          this.fileError = ""
          break;
        }
        else {
          this.fileError = "Tipo de archivos incompatible"
        }
      }

      if (this.theFile.size > max_size) {
        this.fileError = 'El tamaño maximo del archivo es ' + max_size / 1000 + 'Mb';
        return
      }

      if (this.fileError === "") {
        this.archivoDto.descripcion = this.theFile.name
        this.archivoDto.nombre = this.theFile.name;
        this.statusFileUpload.emit(true);
        this.convertFileToBase64(this.theFile)
      }
    }
    this.spinner.hide("spinerArchivo")
  }

  convertFileToBase64(theFile: any) {

    // Set File Information
    this.archivoDto.descripcion = theFile.name;
    this.archivoDto.nombre = theFile.name;
    this.archivoDto.contentType = theFile.type;


    // Use FileReader() object to get file to upload
    // NOTE: FileReader only works with newer browsers
    this.eventoImagen.emit(theFile);
    let reader = new FileReader();
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(theFile);
  }


  _handleReaderLoaded(e: any) {
    let reader = e.target;
    this.archivoDto.content = reader.result
    this.eventoArchivo.emit(this.archivoDto);
    this.statusFileUpload.emit(true);
    this.fileUploadState = true
  }

  cancelUpload() {
    this.archivoDto = new ArchivoDto
    this.archivoDto.descripcion = ""
    this.archivoDto.nombre = ""
    this.fileUploadState = false
    this.statusFileUpload.emit(false);
    this.eventoArchivo.emit(this.archivoDto);
  }

}
