import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateEstadoCasoRequest } from '@models/estadoCaso/createEstadoCasoRequest.model';
import { UpdateEstadoCasoRequest } from '@models/estadoCaso/updateEstadoCasoRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class EstadoCasoService {

  constructor(private http: HttpClient) { }


  create(CreateEstadoCasoRequest: CreateEstadoCasoRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/EstadoCaso/Create", CreateEstadoCasoRequest);
  }

  update(updateEstadoCasoRequest: UpdateEstadoCasoRequest): Observable<any> {
    return this.http.put<any>("/api/EstadoCaso/Update", updateEstadoCasoRequest);
  }

  getAll(): Observable<BaseDto[]> {
    return this.http.get<BaseDto[]>("/api/EstadoCaso/GetAll");
  }

  getById(id: number): Observable<BaseDto> {
    return this.http.get<BaseDto>("/api/EstadoCaso/GetById?id=" + id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/EstadoCaso/Delete?id=" + id);
    else
      return of(null)
  }
}
