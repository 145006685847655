import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { Error500Component } from "@views/errors/error500/error500.component";
import { SharedModule } from "./shared.module";

@NgModule({
  declarations: [
    Error500Component
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports:[

  ]
})
export class ErrorModule { }
