import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UpdateExpedienteNotaRequest } from '@models/expedienteNota/updateExpedienteNotaRequest.model';
import { ExpedienteNotaService } from '@services/expedienteNota/expedienteNota.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogExpedienteNotaCreateComponent } from '../dialog-expediente-nota-create/dialog-expediente-nota-create.component';

@Component({
  selector: 'app-dialog-expediente-nota-update',
  templateUrl: './dialog-expediente-nota-update.component.html',
  styleUrls: ['./dialog-expediente-nota-update.component.css']
})
export class DialogExpedienteNotaUpdateComponent {

  formBuilder: FormBuilder = new FormBuilder;
  form: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogExpedienteNotaCreateComponent>,
    private expedienteNotaService: ExpedienteNotaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) {

    this.form = this.formBuilder.group({
      Descripcion: ['', Validators.compose([Validators.required])],

    });
  }
  ngOnInit() {
    this.getNgModuleById()
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const updateExpedienteNotaRequest: UpdateExpedienteNotaRequest = new UpdateExpedienteNotaRequest
    updateExpedienteNotaRequest.descripcion = this.form.controls['Descripcion'].value == "" ? null : this.form.controls['Descripcion'].value;
    updateExpedienteNotaRequest.idExpediente = this.data.idExpediente
    updateExpedienteNotaRequest.idEmpresa = 1
    this.spinner.show("spLoadCreate")
    this.expedienteNotaService.update(updateExpedienteNotaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spLoadCreate")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "");
        },
        error => {
          this.spinner.hide("spLoadCreate")
          this.snackBar.showError(error, "Error");
        })
  }

  getNgModuleById() {
    this.spinner.show("spLoadCreate")
    this.expedienteNotaService.getById(this.data.id)
      .subscribe(
        data => {
          this.spinner.hide("spLoadCreate")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "");
        },
        error => {
          this.spinner.hide("spLoadCreate")
          this.snackBar.showError(error, "Error");
        })
  }
}
