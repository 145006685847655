<div class="position-relative">

    <ngx-spinner name="spCrearMenu" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="form">
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline">
                    <input placeholder="Descripción" type="text" formControlName="Descripcion" matInput>
                    <mat-error *ngIf="f.Descripcion.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline">
                    <input placeholder="Icono" matTooltip="Use nombre de font-awesome" type="text" formControlName="Icono" matInput>
                    <mat-error *ngIf="f.Icono.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline">
                    <input formControlName="Path" placeholder="Path" type="text" matInput>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline">
                    <input formControlName="Nivel" placeholder="Nivel" type="text" matInput>
                    <mat-error *ngIf="f.Nivel.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                <mat-form-field appearance="outline">
                    <input formControlName="IdMenuPadre" placeholder="IdMenuPadre" type="text" matInput>
                    <mat-error *ngIf="f.IdMenuPadre.errors?.required">Este campo es <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-2">
                <button mat-raised-button color="primary" *appRole="['Menus.Crear']" (click)="doAction()" [disabled]="!form.valid">Agregar</button>
            </div>
            <div class="col-sm-12 col-md-6 col-lg-2">
                <button mat-button (click)="closeDialog()" mat-flat-button color="warn">Cancelar</button>
            </div>
        </div>
    </form>
</div>
