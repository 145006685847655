<br>

<div class="container-fluid">
    <mat-card *appRole="['Personas.Listar']">
        <mat-card-content>
            <form [formGroup]="form">
                <div class="row">

                    <div class="col-sm-12 col-md-6 col-lg-2 ">
                        <mat-form-field appearance="outline" class="full-width-x100">
                            <ngx-spinner [zIndex]="-1" name="spBusquedaTipoDoc" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                            <mat-label matTooltip="Tipo de documento">Tipo Doc.</mat-label>
                            <mat-select placeholder="Seleccione opcion" formControlName="TipoDocumento">
                                <mat-option [value]="tipoDocumento.id" *ngFor="let tipoDocumento of lstTiposDocumento ">
                                    {{tipoDocumento.descripcion | uppercase}}</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-2">
                        <mat-form-field class="full-width-x100" appearance="outline">
                            <mat-label matTooltip="Numero de documento">Nro. Doc.</mat-label>
                            <input matInput type="text" maxlength="150" formControlName="NroDocumento" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-2">
                        <mat-form-field class="full-width-x100" appearance="outline">
                            <mat-label>Nombre</mat-label>
                            <input matInput type="text" maxlength="150" formControlName="Nombre" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-2">
                        <mat-form-field class="full-width-x100" appearance="outline">
                            <mat-label>Apellido</mat-label>
                            <input matInput type="text" maxlength="150" formControlName="Apellido" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-2">
                        <mat-form-field class="full-width-x100" appearance="outline">
                            <mat-label>Correo</mat-label>
                            <input matInput type="text" maxlength="150" formControlName="Correo" />
                        </mat-form-field>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-2">
                        <mat-slide-toggle formControlName="Activo">Activo</mat-slide-toggle>
                    </div>
                    <div class="col-sm-12 col-lg-1">
                        <button mat-stroked-button color="primary" (click)="filtrar()">
              <mat-icon>filter_alt</mat-icon>
              Filtrar
            </button>
                    </div>
                </div>
            </form>
            <ngx-spinner [zIndex]="100" name="spListado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <div *ngIf=data>
                <div class="row">
                    <div class="col-sm-12 col-md-6 col-lg-2">
                        <mat-form-field class="example-full-width" appearance="outline">
                            <mat-label>Buscar</mat-label>
                            <input type="search" matInput (keyup)="applyFilter($event)">
                            <mat-icon matSuffix>search</mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <table mat-table [dataSource]="dataSource" *ngIf=data matSort matSortStart="asc">

                    <!-- Name Column -->
                    <ng-container matColumnDef="nombreCompleto">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Nombre Completo</strong>
                        </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let persona"> {{persona.nombreCompleto | titlecase}}
                        </mat-cell>
                    </ng-container>

                    <!-- Documento-->
                    <ng-container matColumnDef="documento">
                        <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Documento</strong>
                        </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let persona"> {{persona.tipoDocumento}} - {{persona.nroDocumento}}
                        </mat-cell>
                    </ng-container>

                    <!-- Correo  Column -->
                    <ng-container matColumnDef="correo">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Correo</strong> </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let persona">
                            {{persona.correo}}
                            <div *appRole="['Personas.Acciones']">
                                <div *ngIf="!isFalsy(persona.correo)">
                                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                                    <mat-menu #menu="matMenu">
                                        <button disabled mat-menu-item>
                      <mat-icon>send</mat-icon>
                      <span>Enviar correo</span>
                    </button>
                                    </mat-menu>
                                </div>
                            </div>

                        </mat-cell>
                    </ng-container>

                    <!-- Estado  Column -->
                    <ng-container matColumnDef="estado">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Estado</strong> </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let persona">
                            <div *ngIf="persona.activo == true">
                                <span class="badge bg-success text-light"><strong>Activo</strong></span>
                            </div>
                            <div *ngIf="persona.activo == false">
                                <span class="badge bg-danger text-light">Baja</span>
                            </div>
                        </mat-cell>
                    </ng-container>

                    <!-- Accion Column -->
                    <ng-container matColumnDef="accion">
                        <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </mat-header-cell>
                        <mat-cell mat-cell *matCellDef="let persona" class="action-link">
                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                <mat-icon>more_vert</mat-icon>
              </button>
                            <mat-menu #menu="matMenu">
                                 <button mat-menu-item [routerLink]="['/personas/',persona.id]">
                    <mat-icon>visibility</mat-icon>
                    <span>Ver</span>
                  </button>
                                <div *appRole="['Personas.Modificar']">
                                    <button [disabled]="persona.estado" mat-menu-item (click)="editarPersona(persona)">
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>
                                </div>
                                <div *appRole="['Personas.Eliminar']">
                                    <button [disabled]="persona.estado" mat-menu-item (click)="openDialog(persona)">
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                  </button>
                                </div>
                                <div *appRole="['Personas.Eliminar']">
                                    <button [disabled]="!persona.estado" mat-menu-item (click)="reactivar(persona)">
                    <mat-icon>toggle_on</mat-icon>
                    <span>Reactivar</span>
                  </button>
                                </div>

                            </mat-menu>
                        </mat-cell>
                    </ng-container>


                    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                    <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


                </table>

            </div>
            <div class="no-data-table">
                <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
          datos
          para mostrar.</span>
            </div>
            <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
            </mat-paginator>
        </mat-card-content>


    </mat-card>
</div>
