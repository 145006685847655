import { DatePipe } from "@angular/common";

export class Helper {


  static padTo2Digits(num: any) {
    return num.toString().padStart(2, '0');
  }

  /**
   * Convierte una fecha en formato DD-MM-YYYY
   * @param date
   * @returns
   */
  static formatDateDDMMYYYY(date: { getDate: () => any; getMonth: () => number; getFullYear: () => any; }) {
    let month = date.getMonth()
    let day =date.getDate()
    return [
      date.getFullYear(),
      this.padTo2Digits(month),
      this.padTo2Digits(day),
    ].join('-');
  }

  /**
    * Convierte una fecha en formato YYYY-MM-DD
    * @param date
    * @returns
    */
  // static formatDateYYYYMMDD(date: { getDate: () => any; getMonth: () => any; getFullYear: () => any; }) {
  //   let month = date.getMonth()
  //   let day =date.getDate()
  //   return [
  //     date.getFullYear(),
  //     this.padTo2Digits(month),
  //     this.padTo2Digits(day),
  //   ].join('-');
  // }

  static formatDateYYYYMMDD(date:any) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

  static getFormatedDate(date: Date, format: string) {
    const datePipe = new DatePipe('es-ES');
    return datePipe.transform(date, format);
  }
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  static copyToClipboard(text: string) {
    // Crea un elemento de texto temporal
    const tempInput = document.createElement("textarea");
    tempInput.value = text;

    // Agrega el elemento al DOM
    document.body.appendChild(tempInput);

    // Selecciona el contenido del elemento de texto
    tempInput.select();
    tempInput.setSelectionRange(0, 99999); // Para dispositivos móviles

    // Copia el contenido al portapapeles
    document.execCommand("copy");

    // Remueve el elemento temporal del DOM
    document.body.removeChild(tempInput);
  }

  static encryptText(text: string, seed: string): string {
    let encryptedText = '';

    for (let i = 0; i < text.length; i++) {
      const charCode = text.charCodeAt(i);
      const seedCharCode = seed.charCodeAt(i % seed.length);
      const encryptedCharCode = charCode ^ seedCharCode;
      encryptedText += String.fromCharCode(encryptedCharCode);
    }

    return encryptedText;
  }

  static validarCorreoElectronico(correo: string): boolean {
    // Expresión regular para validar una dirección de correo electrónico
    const expresionRegular = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    // Utiliza el método test de la expresión regular para validar el correo
    return expresionRegular.test(correo);
  }








}
