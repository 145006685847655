import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateCasoRequest } from '@models/caso/createCasoRequest.model';
import { CasoService } from '@services/caso/caso.service';
import { EstadoCasoService } from '@services/estadoCaso/estadoCaso.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoCasoService } from '@services/tipoCaso/tipoCaso.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-caso-create',
  templateUrl: './dialog-caso-create.component.html',
  styleUrls: ['./dialog-caso-create.component.css']
})
export class DialogCasoCreateComponent {

  formBuilder: FormBuilder = new FormBuilder;
  tiposCaso!: BaseDto[];
  estadosCaso!: BaseDto[];
  casoForm: any;
    constructor(
      public dialogRef: MatDialogRef<DialogCasoCreateComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
      private tipoCasoService: TipoCasoService,
      private casoService: CasoService,
      private estadoCasoService: EstadoCasoService,
      private router: Router,
      private snackBar: SnackBarService,
      private spinner: NgxSpinnerService,

    ) {


      this.casoForm = this.formBuilder.group({
        Descripcion: [''],
        Caratula: ['',Validators.compose([Validators.required])],
        FechaComienzo: [new Date().toISOString(),Validators.compose([Validators.required])],
        FechaFinalizacion: [''],
        TipoCaso: ['',],
        EstadoCaso: [''],
      });
    }

    ngOnInit(): void {
      this.getAllTiposCaso();
      this.getAllEstadoCaso();
      this.casoForm.controls['EstadoCaso'].value = 1;
    }


    onSubmit() {
      if (this.casoForm.invalid) {
        return;
      }
      const createCasoRequest: CreateCasoRequest = this.setCreateCasoRequest();
      this.spinner.show("spCaso")
      this.casoService.create(createCasoRequest)
        .subscribe(
          data => {
            this.spinner.hide("spCaso")
            this.snackBar.showSuccess("Se agrego el registro correctamente.", "");
            this.dialogRef.close({ data: data, action: AccionesDialog.Agregar });
          },
          error => {
            this.spinner.hide("spCaso")
            this.snackBar.showError(error, "Error");
          })
    }

    getAllTiposCaso() {
      this.spinner.show("spTiposCaso")
      this.tipoCasoService.getAll()
        .subscribe(
          data => {
            this.spinner.hide("spTiposCaso")
            this.tiposCaso = data
          },
          error => {
            this.spinner.hide("spTiposCaso")
            this.snackBar.showError(error, "Error");
          })
    }

    getAllEstadoCaso() {
      this.spinner.show("spEstadosCaso")
      this.estadoCasoService.getAll()
        .subscribe(
          data => {
            this.spinner.hide("spEstadosCaso")
            this.estadosCaso = data
          },
          error => {
            this.spinner.hide("spEstadosCaso")
            this.snackBar.showError(error, "Error");
          })
    }

    setCreateCasoRequest(): CreateCasoRequest {

      const createCasoRequest = new CreateCasoRequest();

      createCasoRequest.activo = true
      createCasoRequest.caratula = this.casoForm.controls['Caratula'].value;
      createCasoRequest.fechaComienzo = this.casoForm.controls['FechaComienzo'].value;
      createCasoRequest.fechaFinalizacion = this.casoForm.controls['FechaFinalizacion'].value;
      createCasoRequest.idEmpresa = 1
      createCasoRequest.idEstadoCaso = this.casoForm.controls['EstadoCaso'].value;
      createCasoRequest.idTipoCaso = this.casoForm.controls['TipoCaso'].value;
      createCasoRequest.descripcion = this.casoForm.controls['Descripcion'].value;

      return createCasoRequest
    }

    onReset() {
      this.casoForm.reset();
    }


    isFalsy(valor: any) {
      return isFalsy(valor)
    }

    trackByItems(index: number, item: any): any { return item; }
}
