import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { NgxEditorModule } from 'ngx-editor';
import { LocalidadModule } from './localidad.module';
import { ConfiguracionComponent } from '../views/configuracion/configuracion/configuracion.component';
import { NavPanelConfiguracionComponent } from '../views/configuracion/nav-panel-configuracion/nav-panel-configuracion.component';
import { LogoComponent } from '../views/logo/logo.component';
import { ArchivoModule } from './archivo.module';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    LocalidadModule,
    ArchivoModule,
    NgxEditorModule
  ],
  declarations: [
    NavPanelConfiguracionComponent,
    ConfiguracionComponent,
    LogoComponent
  ],
  exports: [
    NavPanelConfiguracionComponent,
    ConfiguracionComponent,
    LogoComponent
  ]
})
export class ConfiguracionModule { }
