<h1 mat-dialog-title>Eliminar movimiento</h1>
<br>
<mat-dialog-content>
    <ngx-spinner [zIndex]="100" name="spLoadDelete" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    ¿Esta seguro que desea eliminar el registro?
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" (click)="doAction()"><mat-icon>delete</mat-icon> Eliminar</button>
  <button mat-button (click)="closeDialog()" color="warn">Cancelar</button>
</mat-dialog-actions>

