import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateExpedienteParteRequest } from '@models/expedienteParte/createExpedienteParteRequest.model';
import { CreateExpedienteParteResponse } from '@models/expedienteParte/createExpedienteParteResponse.model';
import { ExpedienteParteDto } from '@models/expedienteParte/expedienteParteDto.model';
import { UpdateExpedienteParteRequest } from '@models/expedienteParte/updateExpedienteParteRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class ExpedienteParteService {

  constructor(
    private http:HttpClient
  ) { }

  createCollection(createExpedienteParteRequest:CreateExpedienteParteRequest[]):Observable<CreateExpedienteParteResponse>
  {
    return  this.http.post<CreateExpedienteParteResponse>("/api/ExpedienteParte/CreateCollection",createExpedienteParteRequest);
  }

  create(createExpedienteParteRequest:CreateExpedienteParteRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/ExpedienteParte/Create",createExpedienteParteRequest);
  }

  update(updateExpedienteParteRequest:UpdateExpedienteParteRequest):Observable<any>
  {
    return  this.http.put<any>("/api/ExpedienteParte/Update",updateExpedienteParteRequest);
  }

  getAll():Observable<ExpedienteParteDto[]> {
    return   this.http.get<ExpedienteParteDto[]>("/api/ExpedienteParte/GetAll");
  }

  getById(id:number):Observable<ExpedienteParteDto>{
    return  this.http.get<ExpedienteParteDto>("/api/ExpedienteParte/GetById?id="+id);
  }

  getAllByIdExpediente(idExpediente:number):Observable<ExpedienteParteDto[]>{
    return  this.http.get<ExpedienteParteDto[]>("/api/ExpedienteParte/GetAllByIdExpediente?idExpediente="+idExpediente);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/ExpedienteParte/Delete?id=" + id);
    else
      return of(null)
  }
}
