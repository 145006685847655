import { CreateKeyValueRequest } from "@models/base/createKeyValueRequest.model"

export class CreateOrganismoRequest extends CreateKeyValueRequest{
  public nombre!: string
  public telefono!:	string
  public correo!:	string
  public idPais!:	number
  public idLocalidad!:	number

}
