<mat-dialog-content>

    <ngx-spinner [zIndex]="100" name="spCreate" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="periciaForm">
        <div class="row">
            <div class="col">
              <mat-form-field class="full-width-x100" appearance="outline">
                <ngx-spinner [zIndex]="-1" name="spLugaresFrecuentes" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <mat-label>Lugar</mat-label>
                <input type="text"
                       placeholder="Pick one"
                       aria-label="Number"
                       matInput
                       formControlName="Lugar"
                       [matAutocomplete]="auto">
                       <mat-error *ngIf="periciaForm.controls['Lugar'].hasError('required')">Este campo es
                        <strong>requerido</strong>.</mat-error>
                    <mat-error *ngIf="periciaForm.controls['Lugar'].hasError('maxlength')">Supero el largo maximo.</mat-error>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option *ngFor="let option of lugaresFrecuentes" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline" class="full-width-x100">
                    <ngx-spinner [zIndex]="-1" name="spTiposPericia" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Tipo Pericia</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="TipoPericia">
                        <mat-option [value]="tipoPericia.id" *ngFor="let tipoPericia of lstTiposPericia ;let i=index;trackBy:trackByItems">
                            {{tipoPericia.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline" class="full-width-x100">
                    <ngx-spinner [zIndex]="-1" name="spEstadosPericia" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                    <mat-label>Estado Pericia</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="EstadoPericia">
                        <mat-option [value]="estadoPericia.id" *ngFor="let estadoPericia of lstEstadosPericias ;let i=index;trackBy:trackByItems">
                            {{estadoPericia.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <mat-form-field appearance="outline" class="full-width-x100">
                    <mat-label>Fecha Propuesta</mat-label>
                    <input matInput type="datetime-local" formControlName="FechaPropuesta" placeholder="MM/DD/YYYY HH:MM">
                    <mat-error *ngIf="periciaForm.controls['FechaPropuesta'].hasError('required')">Este campo es
                        <strong>requerido</strong>.</mat-error>
                </mat-form-field>
            </div>
            <div class="col">
                <mat-form-field appearance="outline" class="full-width-x100">
                    <mat-label>Fecha Realización</mat-label>
                    <input matInput type="datetime-local" formControlName="FechaRealizada" placeholder="MM/DD/YYYY HH:MM">

                </mat-form-field>
            </div>
            <div class="col">
              <mat-form-field appearance="outline" class="full-width-x100">
                  <mat-label>Fecha Finalizada</mat-label>
                  <input matInput type="datetime-local" formControlName="FechaFinalizada" placeholder="MM/DD/YYYY HH:MM">
              </mat-form-field>
          </div>

        </div>
        <div class="row">

            <mat-form-field class="example-full-width col-lg-12" appearance="outline">
                <mat-label>Descripcion</mat-label>
                <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
                <mat-error *ngIf="periciaForm.controls['Descripcion'].hasError('required')">Este campo es
                    <strong>requerido</strong>.</mat-error>
                <mat-hint align="start">Amplie información</mat-hint>
                <mat-hint align="end">{{message.value.length}} / 2000</mat-hint>
            </mat-form-field>
        </div>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="primary" name="Guardar" [disabled]="!periciaForm.valid || data.idExpediente == undefined" (click)="onSubmit()">Agregar</button>
</mat-dialog-actions>
