import { CreateKeyValueRequest } from "@models/base/createKeyValueRequest.model"

export class CreateAudienciaRequest extends CreateKeyValueRequest{
public sala!:	string
public lugar!:	string
public fecha!: Date
public idJuez!: number | null
public idFiscal!:number| null
public idDefensor!: number | null
public idTipoAudiencia!: number
public idExpediente!: number
public idEmpresa!:number
}
