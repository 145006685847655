import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helper } from '@helpers/helper';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, EstadosTareas, TiposContacto, TiposFolios } from '@models/base/identificadores.model';
import { CreateContactoRequest } from '@models/contacto/createContactoRequest.model';
import { ExpedienteSummaryDto } from '@models/expediente/expedienteSummary.model';
import { CreateTareaRequest } from '@models/tarea/createTareaRequest.model';
import { ContactoService } from '@services/contacto/contacto.service';
import { LoginService } from '@services/login/login.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TareaService } from '@services/tarea/tarea.service';
import { TipoContactoService } from '@services/tipoContacto/tipoContacto.service';
import { DialogCreateTareaComponent } from '@views/tareas/dialog-create-tarea/dialog-create-tarea.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-create-contacto',
  templateUrl: './dialog-create-contacto.component.html',
  styleUrls: ['./dialog-create-contacto.component.css']
})
export class DialogCreateContactoComponent {
  formBuilder: FormBuilder = new FormBuilder;
  form: any;
  @Input() isDialog = false
  @Input() idPersona!: number
  tiposContacto!: BaseDto[];

  constructor(
    private loginService: LoginService,

    private tipoContactoService: TipoContactoService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogCreateContactoComponent>,
    private contactoService: ContactoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit(): void {
    this.getAllTiposContacto();

    this.form = this.formBuilder.group({
      ValorContacto: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      TipoContacto: ['', Validators.compose([Validators.required,])],
      Preferido: [false],
      EmpresaAsociada: [''],
      FechaUltimoContacto: [''],
      NivelAvance: [''],
      TipoRelacion: [''],
      FuenteContacto: [''],
      NotasSeguimiento: [''],
      EstadoContacto: [''],
      CategoriasEtiquetas: [''],
      HistorialInteracciones: [''],
      PreferenciasContacto: [''],
      Descripcion: [''],
    });

  }


  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const createContactoRequest: CreateContactoRequest = this.setCreateContactoRequest();
    this.spinner.show("spCreate")
    this.contactoService.create(createContactoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCreate")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "");
          this.dialogRef.close({ event: AccionesDialog.Agregar, data: data })
        },
        error => {
          this.spinner.hide("spCreate")
          this.snackBar.showError(error, "Error");
        })
  }

  setCreateContactoRequest(): CreateContactoRequest {

    const createContactoRequest = new CreateContactoRequest();

    createContactoRequest.descripcion = this.form.controls['Descripcion'].value;
    createContactoRequest.idTipoContacto = this.form.controls['TipoContacto'].value;

    if (createContactoRequest.idTipoContacto == TiposContacto.CorreoElectronico)
      if (Helper.validarCorreoElectronico(this.form.controls['ValorContacto'].value))
        createContactoRequest.valorContacto = this.form.controls['ValorContacto'].value;
      else
      {
        this.snackBar.showError("Formato de correo invalido.", "Error");
        throw new Error('Formato de correo invalido.');
      }

    else
      createContactoRequest.valorContacto = this.form.controls['ValorContacto'].value;

    createContactoRequest.preferido = this.form.controls['Preferido'].value;
    createContactoRequest.empresaAsociada = this.form.controls['EmpresaAsociada'].value;
    createContactoRequest.fechaUltimoContacto = this.form.controls['FechaUltimoContacto'].value;
    createContactoRequest.tipoRelacion = this.form.controls['TipoRelacion'].value;
    createContactoRequest.fuenteContacto = this.form.controls['FuenteContacto'].value;
    createContactoRequest.notasSeguimiento = this.form.controls['NotasSeguimiento'].value;
    createContactoRequest.estadoContacto = this.form.controls['EstadoContacto'].value;
    createContactoRequest.categoriasEtiquetas = this.form.controls['CategoriasEtiquetas'].value;
    createContactoRequest.historialInteracciones = this.form.controls['HistorialInteracciones'].value;
    createContactoRequest.preferenciasContacto = this.form.controls['PreferenciasContacto'].value;
    createContactoRequest.idUsuario = this.loginService.getIdUser();
    createContactoRequest.idEmpresa = 1;
    createContactoRequest.idPersona = this.data;
    return createContactoRequest
  }

  getAllTiposContacto() {
    this.spinner.show("spTipoContacto")
    this.tipoContactoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTipoContacto")
          this.tiposContacto = data
        },
        error => {
          this.spinner.hide("spTipoContacto")
          this.snackBar.showError(error, "Error");
        })
  }

  onReset() {
    this.form.reset();
  }



  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

  selectExpediente(expedienteSummaryDto: ExpedienteSummaryDto) {
    this.data.idExpediente = expedienteSummaryDto.id;
  }
}
