import { animate, query, style, transition, trigger } from '@angular/animations';
import { Component } from '@angular/core';
import {
  NavigationCancel,
  Event,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';
import { LoginService } from '@services/login/login.service';
import { Observable, fromEvent, merge, of } from 'rxjs';
import {  mapTo } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('myAnimation', [
      transition('* => *', [
        query(
          ':enter',
          [style({ opacity: 0 })],
          { optional: true }
        ),
        query(
          ':leave',
          [style({ opacity: 1 }), animate('0.3s', style({ opacity: 0 }))],
          { optional: true }
        ),
        query(
          ':enter',
          [style({ opacity: 0 }), animate('0.3s', style({ opacity: 1 }))],
          { optional: true }
        )
      ])
    ])
  ]
})
export class AppComponent {

  online$: Observable<boolean>;
  title = 'GPeritoUI';
  progressValue = 0;
  progressColor = 'primary';
  progressTimer: any;
  // This will be used to force stop (if an error occurs, or the user stops loading)
  stopProgress = false;

  constructor(
    private router: Router,
    private loginService: LoginService
  ) {
    this.router.events.subscribe((event: Event) => {
      this.navigationObserver(event);
    });

    //Check conexion internet
    this.online$ = merge(
      of(navigator.onLine),
      fromEvent(window, 'online').pipe(mapTo(true)),
      fromEvent(window, 'offline').pipe(mapTo(false))
    );

    const userDto = this.loginService.getCurrentUser();
    if (!userDto)
      this.router.navigate(["/login"])

  }

  private navigationObserver(event: Event): void {

    if (event instanceof NavigationStart) {

      // Increase 1% every 25 milliseconds, adjust it to your preference
      this.progressTimer = setInterval(() => {
        this.loading();
      }, 0);

    }

    if (event instanceof NavigationEnd) {

      // When the navigation finishes, fill the bar completely
      this.progressValue = 100;

      /*
      * Uncomment this block to simulate a delay (for testing), because if you
      * are in a local environment or the request is to a 'light' or very fast resource,
      * the progress bar will appear at 100%.
      */
      /*
      setTimeout(() => {
        this.progressValue = 100;
      }, 2000);
      */
    }

    /*
    * If the navigation is canceled or an error occurs,
    * stop the progress bar and change its color.
    */

    if (event instanceof NavigationCancel) {
      this.stopProgress = true;
      this.progressColor = 'green';
    }

    if (event instanceof NavigationError) {
      this.stopProgress = true;
      this.progressColor = 'warn';
    }

  }

  // Function to increase the value of the progress bar
  private loading(): void {
    /*
    * Leave 5% in case an unusual delay occurs, in the previous
    * function it is filled to 100% if the load ends successfully
    */
    if (this.progressValue >= 100 || this.stopProgress) {
      clearInterval(this.progressTimer);
    } else {
      this.progressValue++;
    }
  }


}

