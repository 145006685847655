import { BaseDto } from "@models/base/baseDto.model"
import { EmpresaDto } from "@models/empresa/empresaDto.model"
import { ExpedienteDto } from "@models/expediente/expedienteDto.model"

export class PericiaDto {
  public id!: number
  public descripcion!: string
  public notificacionRealizada!: boolean
  public lugar!: string
  public fechaRealizada!: Date | null
  public fechaPropuesta!: Date | null

  public fechaFinalizada!: Date | null
  public fechaAlta!: Date
  public expediente!: ExpedienteDto
  public estadoPericia!: BaseDto
  public tipoPericia!: BaseDto
  public empresa!: EmpresaDto
  public activo!:boolean
}
