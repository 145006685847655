import { Component, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { LoginService } from '@services/login/login.service';
import { AccionesDialog, ParametrosConfiguracion, Roles } from '@models/base/identificadores.model';
import { FormControl } from '@angular/forms';
import { ProcesoService } from '@services/proceso/proceso.service';
import { HotToastService, ToastConfig } from '@ngneat/hot-toast';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { ContadorContratoDto } from '@models/dashboard/contadorContratoDto.model';
import { ParametroSistemaService } from '@services/parametroSistema/parametroSistema.service';
import { NotificacionesService } from '@services/notificaciones/notificaciones.service';
import { TareaService } from '@services/tarea/tarea.service';
import { NotificacionAlertaDto } from '@models/notificacionAlerta/notificacionAlertaDto.model';
import { NotificacionesAlertaService } from '@services/notificacionesAlerta/notificacionesAlerta.service';

export class ContadorEntidades {
  public propiedades!: number
  public propietarios!: number
  public inquilinos!: number
  public garantias!: number
  public contratos!: number
  public usuarios!: number
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public mostrarDashboard = false
  matButtonToggleGroup = new FormControl();
  contadoresEntidades!: any[];
  contadorContratoDto!: ContadorContratoDto;
  notificacionesAlertaDto: NotificacionAlertaDto[] = [];
  constructor(
    private loginService: LoginService,
    private tareaService: TareaService,
    private toastService: HotToastService,
    private spinner: NgxSpinnerService,
    private ParametroSistemaService: ParametroSistemaService,
    private snackBar: SnackBarService,
    private notificacionesAlertaService: NotificacionesAlertaService
  ) {
    let toasConfig = new ToastConfig
    toasConfig.autoClose = false
    toasConfig.reverseOrder= true,
    toasConfig.position= 'bottom-right'
    this.toastService.defaultConfig = toasConfig
    const roles = this.loginService.getIdUserRol()
    this.matButtonToggleGroup.setValue('general')
    switch (roles) {
      case Roles.Usuarios:
        this.mostrarDashboard = false
        break;
      case Roles.Administradores:
        this.mostrarDashboard = true
        break;
      case Roles.Clientes:
        this.mostrarDashboard = false
        break;
      case Roles.SuperAdministradores:
        this.mostrarDashboard = true
        break;
      default:
        this.mostrarDashboard = true
        break;
    }
  }

  ngOnInit() {
    if (this.loginService.getIdUserRol() != Roles.Clientes) {
      this.setParametrosSistemaLocalStorage()
    }
  }


  setParametrosSistemaLocalStorage(){
    this.ParametroSistemaService.setParametrosSistemaLocalStorage();
  }

  getValorParametro(id: number) {
    this.spinner.show()
    this.ParametroSistemaService.getById(id)
      .subscribe(
        data => {
          const fecha = new Date();
          let hoy = '25'//moment(fecha).format("D");
          let notificacionAlertaDto = new NotificacionAlertaDto
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      );
  }


}

