import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CreateAudienciaRequest } from '@models/audiencia/createAudienciaRequest.model';
import { BaseDto } from '@models/base/baseDto.model';
import { ExpedienteSummaryDto } from '@models/expediente/expedienteSummary.model';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { AudienciaService } from '@services/audiencia/audiencia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoAudienciaService } from '@services/tipoAudiencia/tipoAudiencia.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-form-audiencia-create',
  templateUrl: './form-audiencia-create.component.html',
  styleUrls: ['./form-audiencia-create.component.css']
})
export class FormAudienciaCreateComponent {
  formBuilder: FormBuilder = new FormBuilder;
  tiposAudiencia!: BaseDto[];
  audienciaForm: any;
  idJuez!: number;
  idFiscal!: number;
  idDefensa!: number;
  idExpediente!: number;

  constructor(
    private TipoAudienciaService: TipoAudienciaService,
    private AudienciaService: AudienciaService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit(): void {
    this.getAllTiposAudiencia();

    this.audienciaForm = this.formBuilder.group({
      Descripcion: [''],
      Lugar: ['',Validators.compose([Validators.required, Validators.maxLength(200)])],
      TiposAudiencia: ['',Validators.compose([Validators.required])],
      Sala: ["", Validators.compose([Validators.maxLength(200)])],
      Fecha: ['', Validators.compose([Validators.required, , Validators.maxLength(50)])],

    });
  }


  onSubmit() {
    if (this.audienciaForm.invalid) {
      return;
    }
    const createAudienciaRequest: CreateAudienciaRequest = this.setCreateAudienciaRequest();
    this.spinner.show("spAudiencia")
    this.AudienciaService.create(createAudienciaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spAudiencia")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "Exito");
          this.router.navigate(["/audiencias"])
        },
        error => {
          this.spinner.hide("spAudiencia")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTiposAudiencia() {
    this.spinner.show("spTiposAudiencia")
    this.TipoAudienciaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTiposAudiencia")
          this.tiposAudiencia = data
        },
        error => {
          this.spinner.hide("spTiposAudiencia")
          this.snackBar.showError(error, "Error");
        })
  }

  setCreateAudienciaRequest(): CreateAudienciaRequest {

    const createAudienciaRequest = new CreateAudienciaRequest();

    createAudienciaRequest.sala = this.audienciaForm.controls['Sala'].value;
    createAudienciaRequest.lugar = this.audienciaForm.controls['Lugar'].value;
    createAudienciaRequest.fecha = this.audienciaForm.controls['Fecha'].value == "" ? null : this.audienciaForm.controls['Fecha'].value;
    createAudienciaRequest.descripcion = this.audienciaForm.controls['Descripcion'].value;
    createAudienciaRequest.idTipoAudiencia = this.audienciaForm.controls['TiposAudiencia'].value == "" ? null: this.audienciaForm.controls['TiposAudiencia'].value ;
    createAudienciaRequest.idDefensor = isFalsy(this.idDefensa) ? null:  this.idDefensa
    createAudienciaRequest.idFiscal = isFalsy(this.idFiscal) ? null:  this.idFiscal
    createAudienciaRequest.idJuez = isFalsy(this.idJuez) ? null:  this.idJuez
    createAudienciaRequest.idExpediente = this.idExpediente
    createAudienciaRequest.idEmpresa = 1

    return createAudienciaRequest
  }

  onReset() {
    this.audienciaForm.reset();
  }


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

  selectJuez(personaDetailDto: PersonaDetailDto) {
    this.idJuez = personaDetailDto.id;
  }
  selectFiscal(personaDetailDto: PersonaDetailDto) {
    this.idFiscal = personaDetailDto.id;
  }
  selectDefensa(personaDetailDto: PersonaDetailDto) {
    this.idDefensa = personaDetailDto.id;
  }
  selectExpediente(expedienteSummaryDto: ExpedienteSummaryDto) {
    this.idExpediente = expedienteSummaryDto.id;
  }
}
