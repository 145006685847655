import { Component } from '@angular/core';

@Component({
  selector: 'app-btn-create-audiencia',
  templateUrl: './btn-create-audiencia.component.html',
  styleUrls: ['./btn-create-audiencia.component.css']
})
export class BtnCreateAudienciaComponent {

}
