import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NotificacionAlertaDto } from '@models/notificacionAlerta/notificacionAlertaDto.model';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesAlertaService {

  constructor(private http:HttpClient) {
  }
  //notificacion!: NotificacionDto
  private notificacion = new Subject<NotificacionAlertaDto>()
  enviarNotificacionObservable = this.notificacion.asObservable()


  sendNotificacion(notificacionAlertaDto: NotificacionAlertaDto) {
    this.notificacion.next(notificacionAlertaDto)
  }

  clearNotificacion(notificacionAlertaDto: NotificacionAlertaDto) {
    this.notificacion.next(notificacionAlertaDto)
  }

  onNotificacion(): Observable<NotificacionAlertaDto> {
    return this.notificacion.asObservable();
  }


}
