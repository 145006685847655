<ngx-spinner [zIndex]="100" name="spPersona" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
  type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<div *appRole="['Personas.Ver']">
  <div *ngIf="personaDetailDto">

    <mat-tab-group>
      <mat-tab label="Persona">
        <mat-card>
          <mat-card-header>
            <!-- ... Botones y menú de acciones ... -->
          </mat-card-header>
          <mat-card-content>
            <div class="row">
              <div class="col">
                <mat-card>
                  <mat-card-header>
                    <mat-card-title>Información de Persona</mat-card-title>

                  </mat-card-header>
                  <mat-card-content>
                    <p><strong>Nombre:</strong> {{ personaDetailDto.nombre | titlecase}}</p>
                    <p><strong>Apellido:</strong> {{ personaDetailDto.apellido | titlecase}}</p>
                    <p><strong>Tipo de Documento:</strong> {{ personaDetailDto.tipoDocumento?.descripcion | titlecase}}
                    </p>
                    <p><strong>Nro. de Documento:</strong> {{ personaDetailDto.nroDocumento}}</p>
                    <p><strong>Estado Civil:</strong> {{ personaDetailDto.estadoCivil?.descripcion | titlecase}}</p>
                    <p><strong>Fecha Nacimiento:</strong> {{ personaDetailDto.fechaNacimiento | date:'short'}}</p>
                  </mat-card-content>
                </mat-card>

              </div>
              <div class="col">
                <mat-card>
                  <mat-card-header>
                    <mat-card-title>Información de Contacto</mat-card-title>

                  </mat-card-header>
                  <mat-card-content>
                    <p><strong>Teléfono Principal:</strong> {{ personaDetailDto.telefonoPrincipal }}</p>
                    <p><strong>Teléfono Alternativo:</strong> {{ personaDetailDto.telefonoAlternativo }}</p>
                    <p><strong>Correo Electrónico:</strong> {{ personaDetailDto.correo }}</p>
                    <p><strong>Ubicación:</strong> {{ personaDetailDto.ubicacion | titlecase}}</p>
                    <p><strong>Localidad:</strong> {{ personaDetailDto.localidad?.nombreCompleto | titlecase}}</p>
                    <p><strong>Codigo Postal:</strong> {{ personaDetailDto.codigoCpa }}</p>
                    <p><strong>Provincia:</strong> {{ personaDetailDto.provincia?.nombreCompleto }}</p>
                  </mat-card-content>
                </mat-card>
              </div>
              <div class="col">
                <mat-card>
                  <mat-card-header>
                    <mat-card-title>Estado y Otras Informaciones</mat-card-title>

                  </mat-card-header>
                  <mat-card-content>
                    <p [ngClass]="{'active': personaDetailDto.activo, 'inactive': !personaDetailDto.activo}">
                      <strong>Estado:</strong>{{ personaDetailDto.activo ? 'Activo' : 'Inactivo' }}
                    </p>
                    <p><strong>Fecha Alta:</strong> {{ personaDetailDto.estadoCivil?.descripcion }}</p>
                    <p><strong>Ultima Modificación:</strong> {{ personaDetailDto.estadoCivil?.descripcion }}</p>
                    <p><strong>Observaciones:</strong> {{ personaDetailDto.observacion}}</p>
                  </mat-card-content>
                </mat-card>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-tab>
      <mat-tab label="Contactos">
        <section>
          <mat-card-header>
          </mat-card-header>
          <mat-card-content>
            <app-list-contacto [idPersona]="personaDetailDto.id"></app-list-contacto>
          </mat-card-content>
        </section>
      </mat-tab>
      <mat-tab label="Expedientes">
        <section>
          <mat-card-content>
            <div class="container-fluid">
              <mat-card *appRole="['Expedientes.Ver']">
                <mat-card-content>
                  <ngx-spinner [zIndex]="100" name="spExpediente" bdColor="rgba(255,255,255,0.8)" size="default"
                    color="#009688" type="ball-atom" [fullScreen]="false">
                  </ngx-spinner>
                  <table mat-table [dataSource]="dataSourceExpediente" *ngIf=dataExpediente matSort matSortStart="asc">

                    <ng-container matColumnDef="caratula">
                      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Caratula</strong>
                      </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente">
                        <span>{{expediente.caratula | uppercase}}</span>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="cuij">
                      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Cuij</strong>
                      </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente">
                        <span>{{expediente.cuij | uppercase}}</span>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="estadoExpediente">
                      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Estado Exte.</strong>
                      </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente">
                        <span>{{expediente.estadoExpediente == undefined ? "" : expediente.estadoExpediente.descripcion
                          | uppercase}}</span>
                      </mat-cell>
                    </ng-container>

                    <ng-container matColumnDef="estadoExpedientePerito">
                      <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Estado Exte.
                          Perito</strong>
                      </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente">
                        <span>{{expediente.estadoExpedientePerito== undefined ? ""
                          :expediente.estadoExpedientePerito.descripcion | uppercase}}</span>
                      </mat-cell>
                    </ng-container>

                    <!-- Accion Column -->
                    <ng-container matColumnDef="accion">
                      <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </mat-header-cell>
                      <mat-cell mat-cell *matCellDef="let expediente" class="action-link">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                          <mat-icon>more_vert</mat-icon>
                        </button>
                        <mat-menu #menu="matMenu">
                          <button mat-menu-item [routerLink]="['/expedientes/',expediente.id]"
                            *appRole="['Expedientes.Ver']">
                            <mat-icon>visibility</mat-icon>
                            <span>Ver</span>
                          </button>
                          <button mat-menu-item [routerLink]="['/expedientes/edit/',expediente.id]"
                            *appRole="['Expedientes.Modificar']">
                            <mat-icon>edit</mat-icon>
                            <span>Editar</span>
                          </button>
                        </mat-menu>
                      </mat-cell>
                    </ng-container>



                    <mat-header-row *matHeaderRowDef="displayedColumnsExpediente"></mat-header-row>
                    <mat-row matRipple *matRowDef="let row; columns: displayedColumnsExpediente;"></mat-row>


                  </table>
                  <div class="no-data-table">
                    <span class="with-icon"
                      *ngIf="dataSourceExpediente.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
                      datos
                      para mostrar.</span>
                  </div>
                  <mat-paginator showFirstLastButtons class="paginator" *ngIf=dataExpediente [pageSize]="20"
                    [pageSizeOptions]="[5, 10, 20,50,100,200]">
                  </mat-paginator>
                </mat-card-content>
              </mat-card>
            </div>

          </mat-card-content>
        </section>
      </mat-tab>
      <mat-tab label="Documentos" *appRole="['Documentos.Listar']">
        <section>
          <mat-card>
            <mat-card-header>
            </mat-card-header>
            <mat-card-content>

            </mat-card-content>
          </mat-card>
        </section>
      </mat-tab>
    </mat-tab-group>

  </div>
</div>
