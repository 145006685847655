export class TelefonoValidator {

  static sendWhatsApp(phoneNumber: string) {
    const validPhoneNumber = this.formatPhoneNumber(phoneNumber);
    if (validPhoneNumber) {
      const whatsappLink = `https://api.whatsapp.com/send?phone=${validPhoneNumber}`;
      window.open(whatsappLink, '_blank');
    } else {
      alert('Por favor, ingrese un número de teléfono válido.');
    }
  }

  static formatPhoneNumber(phoneNumber: string): string | null {
    // Remove spaces and non-digit characters
    let cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

    // Check if the number starts with 15 and adjust it
    if (cleanedPhoneNumber.startsWith('15')) {
      cleanedPhoneNumber = '341' + cleanedPhoneNumber.substring(2);
    }

    // Check if the number starts with the country code 54
    if (!cleanedPhoneNumber.startsWith('54')) {
      cleanedPhoneNumber = '54' + cleanedPhoneNumber;
    }

    // Validate the length (54 + 10 digits for the phone number)
    const phoneRegex = /^54\d{10}$/;
    if (phoneRegex.test(cleanedPhoneNumber)) {
      return cleanedPhoneNumber;
    }

    return null;
  }
}
