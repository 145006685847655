import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EstadoCivilDto } from '@models/estadoCivil/estadoCivilDto.model';
import { isFalsy } from 'utility-types';
import { BaseDto } from '@models/base/baseDto.model';

@Injectable({
  providedIn: 'root'
})
export class EstadoCivilService {

  EstadoCivilDto:EstadoCivilDto= new EstadoCivilDto() ;
  lstEstadoCivilDto: EstadoCivilDto[] = [];

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient) {
  }

   getAll():Observable<BaseDto[]> {
     return   this.http.get<BaseDto[]>("/api/EstadoCivil/GetAll");
   }

}
