import { CreateKeyValueRequest } from "@models/base/createKeyValueRequest.model"

export class CreateTareaRequest extends CreateKeyValueRequest{
  public titulo!:	string
  public fechaAlta!: Date
  public fechaVencimiento!: Date | null
  public idEstadoTarea!:number
  public prioridad!:number | null
  public nivelAvance!:number | null
  public idEmpresa!: number
  public idUsuario!: number
  public idExpediente!: number | null
  }
