import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AudienciaDto } from '@models/audiencia/audienciaDto.model';
import { CreateAudienciaRequest } from '@models/audiencia/createAudienciaRequest.model';
import { UpdateAudienciaRequest } from '@models/audiencia/updateAudienciaRequest.model';
import { BaseDto } from '@models/base/baseDto.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { ExpedienteSummaryDto } from '@models/expediente/expedienteSummary.model';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { AudienciaService } from '@services/audiencia/audiencia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoAudienciaService } from '@services/tipoAudiencia/tipoAudiencia.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-form-audiencia-update',
  templateUrl: './form-audiencia-update.component.html',
  styleUrls: ['./form-audiencia-update.component.css']
})
export class FormAudienciaUpdateComponent {

  formBuilder: FormBuilder = new FormBuilder;
  tiposAudiencia!: BaseDto[];
  audienciaForm: any;
  idJuez!: number | null;
  idFiscal!: number | null;
  idDefensa!: number | null;
  audienciaDto!: AudienciaDto;
  expediente!: ExpedienteDto;

  constructor(
    private TipoAudienciaService: TipoAudienciaService,
    private AudienciaService: AudienciaService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnInit(): void {
    let idAudiencia = this.activatedRoute.snapshot.params['id']
    this.getAllTiposAudiencia();
    this.getAudienciaById(idAudiencia)

    this.audienciaForm = this.formBuilder.group({
      Descripcion: [''],
      Lugar: ['',Validators.compose([Validators.required, Validators.maxLength(200)])],
      TiposAudiencia: ['',Validators.compose([Validators.required])],
      Sala: ["", Validators.compose([Validators.maxLength(200)])],
      Fecha: ['', Validators.compose([Validators.required, , Validators.maxLength(50)])],

    });
  }

  getAudienciaById(id:number) {
    this.spinner.show("spUpdate")
    this.AudienciaService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spUpdate")
          this.audienciaDto = data
          this.setAudienciaDtoForm(data);
        },
        error => {
          this.spinner.hide("spUpdate")
          this.snackBar.showError(error, "Error");
        })
  }
  setAudienciaDtoForm(audienciaDto: AudienciaDto) {


    this.audienciaForm.controls['Descripcion'].setValue(audienciaDto.descripcion);
    this.audienciaForm.controls['TiposAudiencia'].setValue(audienciaDto.tipoAudiencia.id);
    this.audienciaForm.controls['Sala'].setValue(audienciaDto.sala);
    this.audienciaForm.controls['Lugar'].setValue(audienciaDto.lugar);
    this.audienciaForm.controls['Fecha'].setValue(audienciaDto.fecha);
    this.idJuez = isFalsy(audienciaDto.juez) ? null : audienciaDto.juez.id
    this.idDefensa = isFalsy(audienciaDto.defensor) ? null : audienciaDto.defensor.id
    this.idFiscal = isFalsy(audienciaDto.fiscal) ? null : audienciaDto.fiscal.id
    this.expediente = audienciaDto.expediente

  }


  onSubmit() {
    if (this.audienciaForm.invalid) {
      return;
    }
    const updateAudienciaRequest: UpdateAudienciaRequest = this.setUpdateAudienciaRequest();
    this.spinner.show("spAudiencia")
    this.AudienciaService.update(updateAudienciaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spAudiencia")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "Exito");
          this.router.navigate(["/audiencias"])
        },
        error => {
          this.spinner.hide("spAudiencia")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTiposAudiencia() {
    this.spinner.show("spTiposAudiencia")
    this.TipoAudienciaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTiposAudiencia")
          this.tiposAudiencia = data
        },
        error => {
          this.spinner.hide("spTiposAudiencia")
          this.snackBar.showError(error, "Error");
        })
  }

  setUpdateAudienciaRequest(): UpdateAudienciaRequest {

    const updateAudienciaRequest = new UpdateAudienciaRequest();
    updateAudienciaRequest.id = this.audienciaDto.id
    updateAudienciaRequest.sala = this.audienciaForm.controls['Sala'].value;
    updateAudienciaRequest.lugar = this.audienciaForm.controls['Lugar'].value;
    updateAudienciaRequest.fecha = this.audienciaForm.controls['Fecha'].value == "" ? null : this.audienciaForm.controls['Fecha'].value;
    updateAudienciaRequest.descripcion = this.audienciaForm.controls['Descripcion'].value;
    updateAudienciaRequest.idTipoAudiencia = this.audienciaForm.controls['TiposAudiencia'].value == "" ? null: this.audienciaForm.controls['TiposAudiencia'].value ;
    updateAudienciaRequest.idDefensor = isFalsy(this.audienciaDto.defensor) ? null:  this.audienciaDto.defensor.id
    updateAudienciaRequest.idFiscal = isFalsy(this.audienciaDto.fiscal) ? null:  this.audienciaDto.fiscal.id
    updateAudienciaRequest.idJuez = isFalsy(this.audienciaDto.juez) ? null:  this.audienciaDto.juez.id
    updateAudienciaRequest.idExpediente = this.audienciaDto.expediente.id
    updateAudienciaRequest.idEmpresa = 1
    return updateAudienciaRequest
  }

  onReset() {
    this.audienciaForm.reset();
  }


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

  selectJuez(personaDetailDto: PersonaDetailDto) {
    this.idJuez = personaDetailDto.id;
  }
  selectFiscal(personaDetailDto: PersonaDetailDto) {
    this.idFiscal = personaDetailDto.id;
  }
  selectDefensa(personaDetailDto: PersonaDetailDto) {
    this.idDefensa = personaDetailDto.id;
  }

}
