import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AccionesDialog } from '@models/base/identificadores.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoMovimientoService } from '@services/tipoMovimiento/tipoMovimiento.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogTipoMovimientoGenericComponent } from '../dialog-tipo-movimiento-generic/dialog-tipo-movimiento-generic.component';
import { BaseDto } from '@models/base/baseDto.model';

@Component({
  selector: 'app-list-tipo-movimiento',
  templateUrl: './list-tipo-movimiento.component.html',
  styleUrls: ['./list-tipo-movimiento.component.css']
})
export class ListTipoMovimientoComponent {

  dataSource = new MatTableDataSource<BaseDto>();
  obj!: undefined
  displayedColumns: string[] = ['descripcion', 'accion'];
  data = false

  @ViewChild(MatTable, { static: true }) table!: MatTable<BaseDto>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  constructor(
    public dialog: MatDialog,
    private tipoMovimientoService: TipoMovimientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['descripcion', 'accion'] :
        ['descripcion', 'accion'];
    });
  }


  ngOnInit() {

  }

  filtrar(){
    this.getTiposMovimiento();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getTiposMovimiento() {
    this.spinner.show('spTiposMovimiento')
    this.tipoMovimientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spTiposMovimiento')
          this.dataSource.data = data.sort((a, b) => a.descripcion.toString().localeCompare(b.descripcion.toString()));
          this.data = this.dataSource.data.length >= 1 ?  true : false
        },
        error => {
          this.spinner.hide('spTiposMovimiento')
          this.snackBar.showError( error, "Error");
        }
      )
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    dialogConfig.width = "30%"
    const dialogRef = this.dialog.open(DialogTipoMovimientoGenericComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        //this.addRowData(result.data);
        this.getTiposMovimiento();
      } else if (result.event == AccionesDialog.Modificar) {
        this.getTiposMovimiento();
        //this.updateRowData(result.data);
      } else if (result.event == AccionesDialog.Eliminar) {
        this.getTiposMovimiento();
        //this.deleteRowData(result.data);
      }
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


//   addRowData(row_obj: { descripcion: any, valor: any }) {
//     this.spinner.show()
//     let createTipoSelladoRequest = new CreateTipoSelladoRequest
//     createTipoSelladoRequest.descripcion = row_obj.descripcion
//     createTipoSelladoRequest.valor = row_obj.valor

//     let existe = false
//     this.dataSource.data.forEach((element, index) => {
//       if (element.descripcion === row_obj.descripcion)
//         existe = true
//     });

//     if (createTipoSelladoRequest && !existe) {
//       this.tiposSelladoService.create(createTipoSelladoRequest)
//         .subscribe(
//           data => {
//             this.spinner.hide()
//             this.getTiposSelladoService()
//             this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
//           },
//           error => {
//             this.spinner.hide()
//             this.snackBar.showError( error, "Error");
//           }
//         )
//     }
//     else {
//       this.spinner.hide()
//       this.snackBar.showWarn("El registro ya existe", "Error");
//     }
//   }
//   updateRowData(row_obj: { id: number; descripcion: string, valor: number }) {
//     this.spinner.show()
//     let updateTipoSelladoRequest = new UpdateTipoSelladoRequest
//     updateTipoSelladoRequest.id = row_obj.id
//     updateTipoSelladoRequest.descripcion = row_obj.descripcion
//     updateTipoSelladoRequest.valor = row_obj.valor

//     if (updateTipoSelladoRequest) {
//       this.tiposSelladoService.update(updateTipoSelladoRequest)
//         .subscribe(
//           data => {
//             this.spinner.hide()
//             this.dataSource.data = this.dataSource.data.filter((value: { id: number; descripcion: string, valor: number; }, key: any) => {
//               if (value.id == row_obj.id) {
//                 value.descripcion = row_obj.descripcion;
//                 value.valor = row_obj.valor;
//               }
//               return true;
//             });
//             this.data = this.dataSource.data.length >= 1 ?  true : false
//             this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
//           },
//           error => {
//             this.spinner.hide()
//             this.snackBar.showError( error, "Error");
//           })
//     }
//     else {
//       this.spinner.hide()
//       this.snackBar.showWarn("El registro ya existe", "Error");
//     }
//   }


// deleteRowData(row_obj: { id: number; }) {
//   this.tiposSelladoService.delete(row_obj.id)
//       .subscribe(
//         data => {
//           this.spinner.hide()
//           this.dataSource.data = this.dataSource.data.filter((value: { id: number; }, key: any) => {
//             return value.id != row_obj.id;
//           });
//           this.data = this.dataSource.data.length >= 1 ?  true : false
//           this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
//         },
//         error => {
//           this.spinner.hide()
//           this.snackBar.showError( "El registro no se puede eliminar porque tiene referencias asignadas.", "Error");
//         })

// }
}
