import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MovimientoDto } from '@models/movimiento/movimientoDto.model';
import { LoginService } from '@services/login/login.service';
import { MovimientoService } from '@services/movimiento/movimiento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoMovimientoService } from '@services/tipoMovimiento/tipoMovimiento.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog-movimiento-view',
  templateUrl: './dialog-movimiento-view.component.html',
  styleUrls: ['./dialog-movimiento-view.component.css']
})
export class DialogViewMovimientoComponent {

  movimientoDto!: MovimientoDto;
  panelOpenState = true;


  constructor(
    public dialogRef: MatDialogRef<DialogViewMovimientoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private movimientoService: MovimientoService,
    private tipoMovimientoService: TipoMovimientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) {

  }
  ngOnInit() {
    this.getMovimientoById(this.data.id)
  }

  getMovimientoById(id:number) {
    this.spinner.show("spLoadView")
    this.movimientoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spLoadView")
          this.movimientoDto = data
        },
        error => {
          this.spinner.hide("spLoadView")
          this.snackBar.showError(error, "Error");
        })
  }



}
