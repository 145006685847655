import { Component } from '@angular/core';
// import { ChartType } from 'chart.js';
// import { MultiDataSet, Label } from 'ng2-charts';

@Component({
  selector: 'app-doughnut-chart',
  templateUrl: './doughnut-chart.component.html',
  styleUrls: ['./doughnut-chart.component.css']
})

export class DoughnutChartComponent {

  // doughnutChartLabels: Label[] = ['Inquilinos', 'Propietarios', 'Garantias'];
  // doughnutChartData: MultiDataSet = [
  //   [55, 25, 20]
  // ];
  // doughnutChartType: ChartType = 'doughnut';

}
