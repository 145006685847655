import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UsuarioDto } from '@models/usuario/usuarioDto.model';
import { CreateUserRequest } from '@models/usuario/createUserRequest.model';
import { UpdateUserRequest } from '@models/usuario/updateUserRequest.model';
import { RolService } from '@services/rol/rol.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuarioService } from '@services/usuario/usuario.service';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { LoginService } from '@services/login/login.service';
import { JwtPayload } from '@models/login/token-model.model';
import { Roles } from '@models/base/identificadores.model';
import { UsuariosController } from 'src/app/controllers/usuarios.controller';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';

@Component({
  selector: 'app-form-usuario-create',
  templateUrl: './form-usuario-create.component.html',
  styleUrls: ['./form-usuario-create.component.css']
})
export class FormUsuarioCreateComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion = new MatAccordion;
  @Input() personaId!: number;
  public personaControl = new FormControl();
  titulo: string = '';
  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  usuarioForm: FormGroup;
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  public UsuarioDto: UsuarioDto = new UsuarioDto;
  isEdition: boolean = false;
  submitted = false;
  parametro: string = '';
  idRol: number = 0;
  idPersona: number = 0;
  valor: any
  errorMessage: string = "";
  hide = true;
  public filteredPersonas!: Observable<PersonaDetailDto[]>;
  createUserRequest: CreateUserRequest = new CreateUserRequest;
  updateUserRequest: UpdateUserRequest = new UpdateUserRequest;
  idUsuario!: number;
  formBuilder: FormBuilder = new FormBuilder;
  userSession!: UsuarioDto;
  roles!: RolDto[];
  get f() { return this.usuarioForm.controls; }
  possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890-)(*&%$#@!";
  lengthOfCode = 12;
  isDisabled: boolean = true;

  constructor(
    private UsuarioService: UsuarioService,
    private RolService: RolService,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private loginService: LoginService
  ) {

    this.usuarioForm = this.formBuilder.group({
      Rol: ['', Validators.compose([Validators.required])],
      NombreUsuario: ['', Validators.compose([Validators.required])],
      Nombre: ['', Validators.compose([Validators.required])],
      Apellido: ['', Validators.compose([Validators.required])],
      Correo: ['', Validators.compose([Validators.required])],
      Telefono: ['', Validators.compose([Validators.required])],
      RepetirClave: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.minLength(6)])],
      ClaveNueva: ['', Validators.compose([Validators.required, Validators.maxLength(100), Validators.minLength(6)])],
    })

  }


  ngOnInit(): void {
    this.userSession = this.loginService.getCurrentUser();
    this.getAllRoles();
  }

  selectRol(event: any) {
    this.idRol = parseInt(event)
    this.usuarioForm.controls["Rol"].setValue(this.idRol)
  }


  makeRandom(lengthOfCode: number, possible: string) {
    let text = "";
    for (let i = 0; i < lengthOfCode; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }
    return text;
  }

  onSubmit() {
    let nuevaClave = this.usuarioForm.controls['ClaveNueva'].value;
    let repetirClave = this.usuarioForm.controls['RepetirClave'].value;
    if(this.usuarioForm.valid)
    if (nuevaClave == repetirClave) {
      this.spinner.show("spUsuarioCreate");
      this.submitted = true;
      if (this.usuarioForm.valid == true) {
        this.setCreateUserRequest();
        this.UsuarioService.addUser(this.createUserRequest)
          .subscribe(
            data => {
              this.spinner.hide("spUsuarioCreate");
              this.idUsuario = data.id
              this.snackBar.showSuccess("Se agrego el registro correctamente.", "Exito");
              this.router.navigate(["/usuarios"])

            },
            error => {
              this.spinner.hide("spUsuarioCreate");
              this.snackBar.showError(error, "Error");
            })
      }
    }
    else{
      this.snackBar.showInfo("Verifique que las claves sean iguales.", "Exito");
    }

  }
  cambiarPassword() {
    this.submitted = true;
  }

  getAllRoles() {
    this.spinner.show("spRol");
    this.RolService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spRol");
          this.roles = data.filter(x => x.id != Roles.SuperAdministradores)
        },
        error => {
          this.spinner.hide("spRol");
          this.snackBar.showError(error, "Error");
        });
  }

  generarClaveAleatoria() {
    this.usuarioForm.controls["ClaveNueva"].setValue(this.makeRandom(this.lengthOfCode, this.possible))
  }


  setCreateUserRequest() {
    this.createUserRequest = new CreateUserRequest();
    this.createUserRequest.userName = this.usuarioForm.controls['NombreUsuario'].value;
    this.createUserRequest.nombre = this.usuarioForm.controls['Nombre'].value;
    this.createUserRequest.apellido = this.usuarioForm.controls['Apellido'].value;
    this.createUserRequest.correo = this.usuarioForm.controls['Correo'].value;
    this.createUserRequest.telefono = this.usuarioForm.controls['Telefono'].value;
    this.createUserRequest.password = this.usuarioForm.controls['ClaveNueva'].value;
    this.createUserRequest.idRol = this.idRol
    this.createUserRequest.idEmpresa = 1
    this.createUserRequest.activo = true
  }



  onCancel() {
    this.usuarioForm.reset();
    this.router.navigate(["/usuarios"])
  }

  trackByItems(index: number, item: any): any { return item; }

}
