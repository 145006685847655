<div class="card-container">
  <mat-card class="example-card">
    <ngx-spinner [zIndex]="100" name="spCantidadPorEstado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
    <mat-card-header>
        <mat-card-title>Tareas por Estado</mat-card-title>
        <mat-card-subtitle>Cantidad de tareas por estado</mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br>
    <mat-card-content>

      <table class="badge-table">
        <tr *ngFor="let item of cantidadPorEstadoDto">
          <td>{{item.descripcion | titlecase}}</td>
          <td>{{item.cantidad}}</td>
        </tr>
        <tr>
          <td><strong>Total</strong></td>
          <td><strong>{{calcularCantidadTotalPorEstado()}}</strong></td>
        </tr>
      </table>
  </mat-card-content>
    <mat-card-actions>
        <button mat-button [routerLink]="['/tareas']">Ver listado</button>
    </mat-card-actions>
</mat-card>
<mat-card class="example-card">
  <mat-card-header>
      <mat-card-title>Listado de tareas</mat-card-title>
      <mat-card-subtitle>Tareas pendientes</mat-card-subtitle>
  </mat-card-header>
  <mat-divider></mat-divider>
  <br>
  <mat-card-content>
      <ngx-spinner [zIndex]="100" name="spListado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
      </ngx-spinner>
      <div class="row">
          <div class="col-sm-12 col-md-6 col-lg-4">
              <mat-form-field *ngIf=data appearance="outline" class="full-width-x100">
                  <mat-label>Buscar</mat-label>
                  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
              </mat-form-field>
          </div>
      </div>

      <table mat-table [dataSource]="dataSource" *ngIf=data matSort matSortStart="asc">

          <ng-container matColumnDef="titulo">
              <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Titulo</strong>
              </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let tarea">
                  <span>{{tarea.titulo | titlecase}}</span>

              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="expediente">
              <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Expediente</strong>
              </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let tarea">
                  <div *ngIf="tarea.expediente != null">
                      <a [routerLink]="'/expedientes/'+tarea.expediente.id"><span>{{tarea.expediente == undefined ?
        "":tarea.expediente.caratula | uppercase}}</span></a>
                  </div>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="fechaAlta">
              <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Alta</strong></mat-header-cell>
              <mat-cell mat-cell *matCellDef="let tarea">
                  <span>{{tarea.fechaAlta | date:'short'}}</span>
              </mat-cell>
          </ng-container>

          <ng-container matColumnDef="prioridad">
              <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Prioridad</strong>
              </mat-header-cell>
              <mat-cell mat-cell *matCellDef="let tarea">
                  <span> {{tarea.prioridad== undefined ? "":tarea.prioridad == 1? "Baja" : tarea.prioridad ==2 ? "Media":
    "Alta"}}</span>

              </mat-cell>
          </ng-container>

          <!-- Accion Column -->
          <ng-container matColumnDef="accion">
            <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </mat-header-cell>
            <mat-cell mat-cell *matCellDef="let pericia" class="action-link">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
                <mat-menu #menu="matMenu">
                    <!-- <button mat-menu-item [routerLink]="['/pericias/',pericia.id]" *appRole="['Pericias.Ver']">
      <mat-icon>visibility</mat-icon>
      <span>Ver</span>
    </button>
                    <button mat-menu-item [routerLink]="['/pericias/edit/',pericia.id]" *appRole="['Pericias.Modificar']">
      <mat-icon>edit</mat-icon>
      <span>Editar</span>
    </button>
                    <button mat-menu-item (click)="openDialog('Eliminar',pericia)" *appRole="['Pericias.Eliminar']">
      <mat-icon>delete</mat-icon>
      <span>Eliminar</span>
    </button> -->
                </mat-menu>
            </mat-cell>
        </ng-container>
          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


      </table>
      <div class="no-data-table">
          <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
datos
para mostrar.</span>
      </div>
      <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
      </mat-paginator>
  </mat-card-content>
</mat-card>
</div>
