<h1>
    Mi Calendario</h1>
<div class="calendar-wrapper">
    <button mat-button (click)="prevDay()">Prev</button>
    <button mat-button (click)="today()">Today</button>
    <button mat-button (click)="nextDay()">Next</button>

    <mat-calendar #calendar [minDate]="minDate" [maxDate]="maxDate" (monthSelected)="monthChanged($event)" [(selected)]="selectedDate" (selectedChange)="dateChanged()"></mat-calendar>

    <button (click)="setMinDate()">Set min date</button>
    <button (click)="setMaxDate()">Set max date</button>
</div>
