import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateOrganismoParteRequest } from '@models/organismoParte/createOrganismoParteRequest.model';
import { CreateOrganismoParteResponse } from '@models/organismoParte/createOrganismoParteResponse.model';
import { OrganismoParteDto } from '@models/organismoParte/organismoParteDto.model';
import { UpdateOrganismoParteRequest } from '@models/organismoParte/updateOrganismoParteRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class OrganismoParteService {

  constructor(
    private http:HttpClient
  ) { }

  createCollection(createOrganismoParteRequest:CreateOrganismoParteRequest[]):Observable<CreateOrganismoParteResponse>
  {
    return  this.http.post<CreateOrganismoParteResponse>("/api/OrganismoParte/CreateCollection",createOrganismoParteRequest);
  }

  create(createOrganismoParteRequest:CreateOrganismoParteRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/OrganismoParte/Create",createOrganismoParteRequest);
  }

  update(updateOrganismoParteRequest:UpdateOrganismoParteRequest):Observable<any>
  {
    return  this.http.put<any>("/api/OrganismoParte/Update",updateOrganismoParteRequest);
  }

  getAll():Observable<OrganismoParteDto[]> {
    return   this.http.get<OrganismoParteDto[]>("/api/OrganismoParte/GetAll");
  }

  getById(id:number):Observable<OrganismoParteDto>{
    return  this.http.get<OrganismoParteDto>("/api/OrganismoParte/GetById?id="+id);
  }

  getAllByIdOrganismo(idOrganismo:number):Observable<OrganismoParteDto[]>{
    return  this.http.get<OrganismoParteDto[]>("/api/OrganismoParte/GetAllByIdOrganismo?idOrganismo="+idOrganismo);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/OrganismoParte/Delete?id=" + id);
    else
      return of(null)
  }
}
