export class CreateCedulaNotificacionRequest{
  public juzgado!: string
  public destinatario!: string
  public caratula!: string
  public cuij!: string
  public fecha!: string
  public detalle!: string
  public localidad!: string
  public observaciones!: string
}
