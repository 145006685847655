
import { BaseDto } from "../base/baseDto.model";
import { PaisDto } from "../pais/paisDto.model";

export class ProvinciaDto extends BaseDto {
   public nombre!: string 
   public nombreCompleto!: string 
   public fuente!: string 
   public centroideLon!: number
   public centroideLat!: number 
  public pais!: PaisDto

}
