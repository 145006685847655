import { LocalidadDto } from "@models/localidad/localidadDto.model"
import { PaisDto } from "@models/pais/paisDto.model"

export class OrganismoDto{
  public id!: number
  public descripcion!: string
  public nombre!: string
  public telefono!:	string
  public correo!:	string
  public pais!:	PaisDto
  public localidad!:	LocalidadDto
}

