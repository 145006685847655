import { Component, Inject, Optional } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { LoginService } from '@services/login/login.service';
import { MovimientoService } from '@services/movimiento/movimiento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog-movimiento-delete',
  templateUrl: './dialog-movimiento-delete.component.html',
  styleUrls: ['./dialog-movimiento-delete.component.css']
})
export class DialogMovimientoDeleteComponent {
  local_data: any;

  constructor(
    private loginService: LoginService,
    private movimientoService: MovimientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    public dialogRef: MatDialogRef<DialogMovimientoDeleteComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      this.local_data = {...data};
    }

  ngOnInit(): void {
  }

  doAction(){
    this.delete()
  }

  closeDialog(){
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }


  delete() {
    this.spinner.show("spLoadDelete")
    this.movimientoService.delete(this.data.id)
      .subscribe(
        data => {
          this.spinner.hide("spLoadDelete")
          this.snackBar.showSuccess("Se elimino el registro correctamente.", "Exito");
          this.dialogRef.close({event:AccionesDialog.Eliminar, data: this.data});
        },
        error => {
          this.spinner.hide("spLoadDelete")
          this.snackBar.showError(error, "Error");
        })
  }

}
