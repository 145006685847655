import { BaseDto } from '../base/baseDto.model';
import { EstadoCivilDto } from '../estadoCivil/estadoCivilDto.model';
import { LocalidadDto } from '../localidad/localidadDto.model';
import { ProvinciaDto } from '../provincia/provinciaDto.model';
import { TipoDocumentoDto } from '../tipoDocumento/tipoDocumentoDto.model';


export class PersonaDetailDto {

  id!: number
  nombre!: string;
  apellido!: string;
  nombreCompleto!: string;
  tipoDocumento!: TipoDocumentoDto
  idTipoDocumento!: number
  nroDocumento!: string;
  telefonoPrincipal!: string;
  telefonoAlternativo!: string;
  sexo!: string;
  correo!: string;
  fechaNacimiento!: string;
  ubicacion!: string;
  localidad!: LocalidadDto
  idLocalidad!: number
  provincia!: ProvinciaDto
  idProvincia!: number
  codigoCpa!: string;
  estadoCivil: EstadoCivilDto = new EstadoCivilDto;
  idEstadoCivil!: number
  ocupacion!: string;
  activo!: boolean
  observacion!: string;
  fechaAlta!: string;
  fechaBaja!: string;
  pais!: BaseDto
  telefonoLaboral!: string;
  ubicacionLaboral!: string;


}
