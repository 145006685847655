import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';
import { CreateProvinciaRequest } from '@models/provincia/createProvinciaRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateProvinciaRequest } from '@models/provincia/updateProvinciaRequest.model';
import { ProvinciaDto } from '@models/provincia/provinciaDto.model';

@Injectable({
  providedIn: 'root'
})
export class ProvinciaService {

  ProvinciaDto :ProvinciaDto= new ProvinciaDto() ;
  lstProvincias: ProvinciaDto[] = [];

  private id!: number;

  set setId(id:number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(private http:HttpClient) {
  }

  getAllProvincias():Observable<ProvinciaDto[]> {
    return   this.http.get<ProvinciaDto[]>("/api/Provincia/GetAllNombreProvincia");
  }


  create(createProvinciaRequest: CreateProvinciaRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Provincia/Create",createProvinciaRequest);
  }

  update(updateProvinciaRequest: UpdateProvinciaRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Provincia/Update",updateProvinciaRequest);
  }

  getAll():Observable<ProvinciaDto[]>
  {
    return  this.http.get<ProvinciaDto[]>("/api/Provincia/GetAll");
  }

  getById(id:number):Observable<ProvinciaDto>
  {
    return  this.http.get<ProvinciaDto>("/api/Provincia/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Provincia/Delete?id=" + id);
    else
      return of(null)
  }
}
