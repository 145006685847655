<ngx-spinner [zIndex]="100" name="spView" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<div class="row" *appRole="['Audiencias.Ver']">
    <div class="col-sm-12 col-md-12 col-lg-12" *ngIf="audienciaDto">

        <mat-tab-group>
            <mat-tab label="Detalle">
                <div class="mat-card-expediente">
                    <mat-card>
                        <mat-card-header>
                            <h5>Expediente:</h5>
                        </mat-card-header>
                        <mat-card-content>
                            <ngx-spinner [zIndex]="100" name="spView" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                            </ngx-spinner>

                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <span><strong>Caratula: </strong></span>
                                    <span> {{!isFalsy(audienciaDto.expediente.caratula) ? audienciaDto.expediente.caratula : "" | uppercase}}
                                    </span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <span><strong>CUIJ: </strong></span>
                                    <span> {{!isFalsy(audienciaDto.expediente.cuij) ? audienciaDto.expediente.cuij : "" }}
                                  </span>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                    <br>
                    <mat-divider></mat-divider>
                    <br>
                    <mat-card>
                        <mat-card-header>
                            <h5>Audiencia:</h5>
                        </mat-card-header>
                        <mat-card-content>
                            <ngx-spinner [zIndex]="100" name="spView" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                            </ngx-spinner>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <span><strong>Fecha:</strong></span>
                                    <span> {{audienciaDto.fecha | date:('short')}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <span><strong>Sala: </strong></span>
                                    <span> {{audienciaDto.sala | titlecase}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <span><strong>Tipo Audiencia: </strong></span>
                                    <span>{{audienciaDto.tipoAudiencia == undefined ? "" : audienciaDto.tipoAudiencia.descripcion |
                    uppercase}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <span><strong>Juez: </strong></span>
                                    <span>{{audienciaDto.juez.nombre | uppercase}} {{audienciaDto.juez.apellido | uppercase}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-sm-12 col-md-6 col-lg-6">
                                    <span><strong>Fiscal: </strong></span>
                                    <span>{{audienciaDto.fiscal.nombre | uppercase}} {{audienciaDto.juez.apellido | uppercase}}</span>
                                </div>
                            </div>
                        </mat-card-content>
                    </mat-card>
                </div>
            </mat-tab>
        </mat-tab-group>


    </div>
</div>
