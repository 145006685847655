import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared.module";
import { UsuarioService } from "@services/usuario/usuario.service";
import { BtnIrAPersonaComponent } from "@views/personas/btn-ir-a-persona/btn-ir-a-persona.component";
import { BtnDeleteUserComponent } from "@views/usuarios/btn-delete-user/btn-delete-user.component";
import { BtnNuevoUsuarioComponent } from "@views/usuarios/btn-nuevo-usuario/btn-nuevo-usuario.component";
import { DialogDeleteUserComponent } from "@views/usuarios/dialog-delete-user/dialog-delete-user.component";
import { FormUsuarioCreateComponent } from "@views/usuarios/form-usuario-create/form-usuario-create.component";
import { FormUsuarioUpdateComponent } from "@views/usuarios/form-usuario-update/form-usuario-update.component";
import { UsuariosComponent } from "@views/usuarios/listado-usuarios/usuarios.component";
import { NavPanelUsuarioComponent } from "@views/usuarios/nav-panel-usuario/nav-panel-usuario.component";
import { PersonaModule } from "./persona.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PersonaModule

    ],
    declarations: [
        UsuariosComponent,
        BtnNuevoUsuarioComponent,
        FormUsuarioUpdateComponent,
        FormUsuarioCreateComponent,
        DialogDeleteUserComponent,
        BtnDeleteUserComponent,
        BtnIrAPersonaComponent,
        NavPanelUsuarioComponent
    ],
    exports: [
        UsuariosComponent,
        BtnNuevoUsuarioComponent,
        FormUsuarioUpdateComponent,
        FormUsuarioCreateComponent,
        DialogDeleteUserComponent,
        BtnDeleteUserComponent,
        BtnIrAPersonaComponent,
        NavPanelUsuarioComponent
    ],
    providers: [
        UsuarioService
    ],
})

export class UsuarioModule { }
