import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { PersonaService } from '@services/persona/persona.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-nav-panel-expediente',
  templateUrl: './nav-panel-expediente.component.html',
  styleUrls: ['./nav-panel-expediente.component.css']
})

export class NavPanelExpedienteComponent {
  @Input() idExpediente: number = 0
  expedienteDto!: ExpedienteDto;

  constructor(
    private aRoute: ActivatedRoute,
    private ExpedienteService: ExpedienteService,
  ) { }

  ngOnInit(): void {


    this.idExpediente = this.aRoute.snapshot.params['id']
    if (isFalsy(this.idExpediente))
      this.idExpediente = this.ExpedienteService.getExpediente().id
  }
}
