import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '@helpers/helper';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { ExpedienteFilter } from '@models/expediente/expedienteFilter.model';
import { OrganismoDto } from '@models/organismo/organismoDto.model';
import { OrganismoParteDto } from '@models/organismoParte/organismoParteDto.model';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { OrganismoService } from '@services/organismo/organismo.service';
import { OrganismoParteService } from '@services/organismoParte/organismo-parte.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogOrganismoGenericComponent } from '../dialog-organismo-generic/dialog-organismo-generic.component';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-form-organismo-view',
  templateUrl: './form-organismo-view.component.html',
  styleUrls: ['./form-organismo-view.component.css']
})
export class FormOrganismoViewComponent {

  dataSourceExpediente = new MatTableDataSource<ExpedienteDto>();
  dataExpediente: boolean = false
  displayedColumnsExpediente!: string[];
  organismoDto!: OrganismoDto;
  idOrganismo: any;
  organismoParteDto!: OrganismoParteDto[];
  displayedColumnsOrganismoParte!: string[];
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private organismoService: OrganismoService,
    private snackBar: SnackBarService,
    private breakpointObserver: BreakpointObserver,
    private expedienteService: ExpedienteService,
    private organismoParteService: OrganismoParteService

  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsExpediente = result.matches ?
        ["caratula", "accion"] :
        ["caratula", "cuij", "estadoExpediente", "estadoExpedientePerito", "accion"];
    });

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsOrganismoParte = result.matches ?
        ["caratula", "accion"] :
        ["caratula", "cuij", "estadoExpediente", "estadoExpedientePerito", "accion"];
    });

  }


  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  ngOnInit(): void {
    this.idOrganismo = this.activatedRoute.snapshot.params['id']
    this.getOrganismoById(this.idOrganismo)
    this.getAllExpedienteByIdOrganismo(this.idOrganismo)
    this.getOrganismoParteIdOrganismo(this.idOrganismo)
  }
  getOrganismoById(id: number) {
    this.spinner.show("spOrganismo")
    this.organismoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spOrganismo")
          this.organismoDto = data
        },
        error => {
          this.spinner.hide("spOrganismo")
          this.snackBar.showError(error, "Error");
        })
  }

  getOrganismoParteIdOrganismo(id: number) {
    this.spinner.show("spOrganismoParte")
    this.organismoParteService.getAllByIdOrganismo(id)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.organismoParteDto = data
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        })
  }

  openDialogDelete(obj: any) {
    obj.descripcion = obj.caratula
    obj.id = obj.id
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      //this.deleteRowData(result.data);
    });
  }

  copiarAlPortapapeles(texto:string){
    Helper.copyToClipboard(texto)
  }


  getAllExpedienteByIdOrganismo(idOrganismo: number) {
    this.spinner.show('spExpediente')
    let expedienteFilter = new ExpedienteFilter
    expedienteFilter.idOrganismo = idOrganismo
    this.expedienteService.getAllExpedientesByFilter(expedienteFilter)
      .subscribe(
        data => {
          this.spinner.hide('spExpediente')
          this.dataSourceExpediente.data = data.sort((a, b) => a.caratula.toString().localeCompare(b.caratula.toString())) as ExpedienteDto[]
          this.dataExpediente = this.dataSourceExpediente.data.length >= 1 ? true : false

        },
        error => {
          this.spinner.hide('spExpediente')
          this.snackBar.showError(error, "Error");
        })
  }

  openDialog(action: any, obj: any) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%"
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    obj.descripcion = isFalsy(obj.expediente) ? null : obj.expediente.caratula

    const dialogRef = this.dialog.open(DialogOrganismoGenericComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: { data: any; }) => {
      switch (result.data.action) {
        case AccionesDialog.Ver:
          break;
        default:
          //this.getAllByIdLocalidad(this.localidadDto.id)
          break;
      }
    });
  }
}
