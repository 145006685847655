import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './shared.module';
import { DialogUpdateMovimientoComponent } from '@views/movimientos/dialog-movimiento-update/dialog-update-movimiento.component';
import { ListMovimientoComponent } from '@views/movimientos/list-movimiento/list-movimiento.component';
import { DialogCreateMovimientoComponent } from '@views/movimientos/dialog-create-movimiento/dialog-create-movimiento.component';
import { DialogViewMovimientoComponent } from '@views/movimientos/dialog-movimiento-view/dialog-movimiento-view.component';
import { BusquedasModule } from './busquedas.module';
import { DialogMovimientoDeleteComponent } from '@views/movimientos/dialog-movimiento-delete/dialog-movimiento-delete.component';



@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BusquedasModule
],
declarations: [
  DialogCreateMovimientoComponent,
  DialogUpdateMovimientoComponent,
  ListMovimientoComponent,
  DialogViewMovimientoComponent,
  DialogMovimientoDeleteComponent

],

providers: [

],
schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
exports: [
  DialogCreateMovimientoComponent,
  DialogUpdateMovimientoComponent,
  ListMovimientoComponent,
  DialogViewMovimientoComponent,
  DialogMovimientoDeleteComponent
]
})
export class MovimientoModule { }
