<button mat-raised-button color="primary" *appRole="['Perfiles.Crear']" (click)="openDialogCreate()" color="primary">
  <mat-icon>add</mat-icon>
  Nuevo perfil
</button>&nbsp;
<br>
<br>
<mat-card *appRole="['Perfiles.Listar']">
    <mat-card-content>
        <ngx-spinner bdColor="rgba(255,255,255,0.8)" name="spLoadPerfiles" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Buscar</mat-label>
            <input matInput type="search" (keyup)="applyFilter($event)">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
        <br>
        <table mat-table [dataSource]="dataSource" *ngIf=data table matSort matSortStart="asc">

            <ng-container matColumnDef="descripcion">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> <span>{{element.descripcion}}</span> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="path">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Path </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element"> <span>{{element.path | lowercase}}</span> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="icon">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Icon </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                    <mat-icon [matTooltip]="element.icon">{{element.icon}}</mat-icon>
                </mat-cell>
            </ng-container>

            <ng-container matColumnDef="menu">
                <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Menu </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element">
                    <span>{{element.menu== undefined ? "" : element.menu.descripcion}}</span>
                </mat-cell>
            </ng-container>
            <!-- Action Column -->
            <ng-container matColumnDef="accion">
                <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
                <mat-cell mat-cell *matCellDef="let element" class="action-link">

                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
          <mat-icon>more_vert</mat-icon>
        </button>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *appRole="['Perfiles.Ver']" matTooltip="Ver" matTooltipPosition="right" (click)="openDialogVer(element)">
            <mat-icon>visibility</mat-icon>
            <span>Ver</span>
          </button>
                        <button mat-menu-item *appRole="['Perfiles.Modificar']" matTooltip="Editar" matTooltipPosition="right" (click)="openDialogUpdate(element)">
            <mat-icon>edit</mat-icon>
            <span>Editar</span>
          </button>
                        <button mat-menu-item *appRole="['Perfiles.Eliminar']" matTooltip="Eliminar" matTooltipPosition="right" (click)="openDialogDelete(element)">
            <mat-icon>delete</mat-icon>
            <span>Eliminar</span>
          </button>
                    </mat-menu>
                </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </table>

        <div>

        </div>
        <div class="no-data-table">
            <span class="with-icon" *ngIf="dataSource.data.length == 0">
      <mat-icon>search_off</mat-icon>No se encontraron datos para mostrar.
    </span>
        </div>
        <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="10" [pageSizeOptions]="[5, 10, 20,50,100,200]">
        </mat-paginator>
    </mat-card-content>

</mat-card>
