import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AudienciaDto } from '@models/audiencia/audienciaDto.model';
import { CreateAudienciaRequest } from '@models/audiencia/createAudienciaRequest.model';
import { UpdateAudienciaRequest } from '@models/audiencia/updateAudienciaRequest.model';
import { CantidadPorEstadoDto } from '@models/base/cantidadPorEstadoDto.mode';
import { CantidadPorTipoDto } from '@models/base/cantidadPorTipoDto.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreatePericiaRequest } from '@models/pericia/createPericiaRequest.model';
import { PericiaDto } from '@models/pericia/periciaDto.model';
import { PericiaFilter } from '@models/pericia/periciaFilter.model';
import { UpdatePericiaRequest } from '@models/pericia/updatePericiaRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';


@Injectable({
  providedIn: 'root'
})
export class PericiaService {

  constructor(private http: HttpClient) {
  }

  create(createPericiaRequest: CreatePericiaRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Pericia/Create", createPericiaRequest);
  }

  createAndSendEmail(createPericiaRequest: CreatePericiaRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Pericia/CreateAndSendEmail", createPericiaRequest);
  }

  update(updatePericiaRequest: UpdatePericiaRequest): Observable<any> {
    return this.http.put<any>("/api/Pericia/Update", updatePericiaRequest);
  }

  getAll(): Observable<PericiaDto[]> {
    return this.http.get<PericiaDto[]>("/api/Pericia/GetAll");
  }

  getById(id: number): Observable<PericiaDto> {
    return this.http.get<PericiaDto>("/api/Pericia/GetById?id=" + id);
  }

  getAllByIdExpediente(id: number): Observable<PericiaDto[]> {
    return this.http.get<PericiaDto[]>("/api/Pericia/GetAllByIdExpediente?idExpediente=" + id);
  }

  getCantidadPorTipoDto(): Observable<CantidadPorTipoDto> {
    return this.http.get<CantidadPorTipoDto>("/api/Pericia/GetCantidadPorTipoDto");
  }

  getCantidadPorEstadoDto(): Observable<CantidadPorEstadoDto[]> {
    return this.http.get<CantidadPorEstadoDto[]>("/api/Pericia/GetCantidadPorEstadoDto");
  }


  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Pericia/Delete?id=" + id);
    else
      return of(null)
  }

  getAllByFilter(periciaFilter: PericiaFilter): Observable<PericiaDto[]> {
    return this.http.post<PericiaDto[]>('/api/Pericia/GetAllByFilter', periciaFilter);
  }

  sendEmailNotification(idPericia: number): Observable<number> {
    return this.http.post<number>('/api/Pericia/SendEmailNotification?idPericia='+idPericia, null);
  }

  getAllLugaresFrecuentes(): Observable<string[]> {
    return this.http.get<string[]>('/api/Pericia/GetAllLugaresFrecuentes');
  }

}
