import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateExpedienteParteRequest } from '@models/expedienteParte/createExpedienteParteRequest.model';
import { ExpedienteParteDto } from '@models/expedienteParte/expedienteParteDto.model';
import { PersonaDto } from '@models/persona/personaDto.model';
import { ExpedienteParteService } from '@services/expedienteParte/expedienteParte.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoParteService } from '@services/tipoParte/tipoParte.service';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { DialogCreatePersonaSimpleComponent } from '@views/personas/dialog-create-persona-simple/dialog-create-persona-simple.component';
import { DialogViewBasicPersonaComponent } from '@views/personas/dialog-view-basic-persona/dialog-view-basic-persona.component';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-form-expediente-partes',
  templateUrl: './form-expediente-partes.component.html',
  styleUrls: ['./form-expediente-partes.component.css']
})
export class FormExpedientePartesComponent {
  formBuilder: FormBuilder = new FormBuilder;
  expedienteParteForm: any;
  tiposParte!: BaseDto[];
  expedientePartes!: ExpedienteParteDto[];
  idExpediente: any;
  personaDto: any;
  dataSource = new MatTableDataSource<ExpedienteParteDto>();
  displayedColumnsExpedienteParte: string[] = ['tipoParte', 'persona', 'descripcion', 'accion'];
  data: boolean = false;
  constructor(
    public dialog: MatDialog,
    private tipoParteService: TipoParteService,
    private expedienteParteService: ExpedienteParteService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private activatedRoute: ActivatedRoute,
    private breakpointObserver: BreakpointObserver,
  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsExpedienteParte = result.matches ?
        ['tipoParte', 'persona', 'accion'] :
        ['tipoParte', 'persona', 'descripcion', 'accion'];
    });


  }

  ngOnInit() {
    let idExpediente = this.activatedRoute.snapshot.params['id']
    this.idExpediente = idExpediente
    this.getExpedienteParteByIdExpediente(idExpediente)
    this.getAllTiposParte()

    this.expedienteParteForm = this.formBuilder.group({
      Descripcion: [''],
      TipoParte: ["", Validators.compose([Validators.required])],
    });
  }

  getExpedienteParteByIdExpediente(idExpediente: any) {
    this.spinner.show("spExpedienteParte")
    this.expedienteParteService.getAllByIdExpediente(idExpediente)
      .subscribe(
        data => {
          this.spinner.hide("spExpedienteParte")
          this.expedientePartes = data
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spExpedienteParte")
          this.snackBar.showError(error, "Error");
        })
  }

  verInfoPersona(personaDto: PersonaDto) {
    const dialogConfig = new MatDialogConfig()
    dialogConfig.autoFocus = false
    dialogConfig.data = personaDto
    dialogConfig.width = "50%"

    const dialogRef = this.dialog.open(DialogViewBasicPersonaComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  onNuevaPersona(): void {
    const dialogRef = this.dialog.open(DialogCreatePersonaSimpleComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
      let idPersona = result;
    });
  }
  seleccionPersona(event: any) {
    this.personaDto = event
  }
  getAllTiposParte() {
    this.spinner.show("spTipoParte")
    this.tipoParteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTipoParte")
          this.tiposParte = data
        },
        error => {
          this.spinner.hide("spTipoParte")
          this.snackBar.showError(error, "Error");
        })
  }

  onSubmit() {
    if (this.expedienteParteForm.invalid) {
      return;
    }
    const createExpedienteParteRequest: CreateExpedienteParteRequest = this.setCreateExpedienteParteRequest();
    this.spinner.show("spExpedienteParte")
    this.expedienteParteService.create(createExpedienteParteRequest)
      .subscribe(
        data => {
          this.spinner.hide("spExpedienteParte")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "");
          this.getExpedienteParteByIdExpediente(this.idExpediente)
        },
        error => {
          this.spinner.hide("spExpedienteParte")
          this.snackBar.showError(error, "Error");
        })
  }

  setCreateExpedienteParteRequest(): CreateExpedienteParteRequest {

    const createExpedienteParteRequest = new CreateExpedienteParteRequest();

    createExpedienteParteRequest.idTipoParte = this.expedienteParteForm.controls['TipoParte'].value;
    createExpedienteParteRequest.descripcion = this.expedienteParteForm.controls['Descripcion'].value;
    createExpedienteParteRequest.idExpediente = this.idExpediente
    createExpedienteParteRequest.idPersona = this.personaDto.id

    return createExpedienteParteRequest
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }


  deleteRowData(id: number) {
    this.spinner.show('spExpedienteParte')
    this.expedienteParteService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide('spExpedienteParte')
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.getExpedienteParteByIdExpediente(this.idExpediente);
        },
        error => {
          this.spinner.hide('spExpedienteParte')
          this.snackBar.showError(error, "Error");
        });
  }
}
