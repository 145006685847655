import { Component, ElementRef, OnInit, ViewChild, } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { Roles } from '@models/base/identificadores.model';
import { JwtPayload } from '@models/login/token-model.model';
import { Menu, SubMenu } from '@models/seguridad/menu/menu.model';
import { PermisoDto } from '@models/seguridad/permisos/permisoDto.model';
import { UsuarioDto } from '@models/usuario/usuarioDto.model';
// import { EmpresaService } from '@services/empresa/empresa.service';
import { LoginService } from '@services/login/login.service';
import { UsuarioService } from '@services/usuario/usuario.service';
import { isFalsy } from 'utility-types';



@Component({
  selector: 'nav-search-perfil-logo',
  templateUrl: './nav-search-perfil-logo.component.html',
  styleUrls: ['./nav-search-perfil-logo.component.css']
})

export class NavSearchPerfilLogoComponent implements OnInit {

  archivo: ArchivoDto = new ArchivoDto
  @ViewChild("inputSearch") input?: ElementRef;
  hidden = false;
  isLoading: boolean = true;
  matTooltip?: string;
  menues!: Menu[]
  value = 'Buscar';
  user!: UsuarioDto;

  constructor(
    private loginService: LoginService,
  ) {
  }

  ngOnInit(): void {
    this.user = this.loginService.getCurrentUser()
    this.archivo.id = 1
    if (this.user)
      this.matTooltip = `Nombre y Apellido: &#13s; ${this.user.nombre + ' ' + this.user.apellido}. &#13; Usuario: &#13; ${this.user.userName}`


    if (this.user.rol.permisos)
      this.user.rol.permisos.forEach(element => {
        if (isFalsy(this.menues))
          this.menues = []

        let menu = isFalsy(element.casoUso.menu) ? undefined : this.menues.find(x => x.displayName === element.casoUso.menu.descripcion) as Menu

        if (isFalsy(menu)) {
          menu = this.addMenu(element)
          if (!isFalsy(menu)) {
            if (menu.submenues.find(x => x.displayName === element.casoUso.descripcion)) {
              let subMenu = menu.submenues.find(x => x.displayName === element.casoUso.descripcion) as SubMenu
              subMenu = this.addSubMenu(element)
              menu.submenues.push(subMenu)
            }
            else {
              let subMenu = this.addSubMenu(element)
              menu.submenues.push(subMenu)
            }
            this.menues.push(menu)
          }
        }
        else {
          if (menu.submenues.find(x => x.displayName != element.casoUso.descripcion)) {
            let subMenu = menu.submenues.find(x => x.displayName === element.casoUso.descripcion) as SubMenu
            if (subMenu == undefined) {
              let subMenu = this.addSubMenu(element)
              menu.submenues.push(subMenu)
            }
          }
        }
      });

    this.isLoading = false
  }

  addMenu(element: PermisoDto) {
    let menu!: Menu
    if (element.casoUso.menu) {
      menu = new Menu
      menu.displayName = isFalsy(element.casoUso.menu.descripcion) ? "" : element.casoUso.menu.descripcion
      menu.iconName = isFalsy(element.casoUso.menu.icon) ? "" : element.casoUso.menu.icon
      menu.route = isFalsy(element.casoUso.menu.path) ? "" : element.casoUso.menu.path
    }
    return menu
  }
  addSubMenu(element: PermisoDto) {
    let subMenu!: SubMenu
    if (element.casoUso) {
      subMenu = new SubMenu
      subMenu.displayName = isFalsy(element.casoUso.descripcion) ? "" : element.casoUso.descripcion
      subMenu.iconName = isFalsy(element.casoUso.icon) ? "" : element.casoUso.icon
      subMenu.route = isFalsy(element.casoUso.path) ? "" : element.casoUso.path
    }
    return subMenu
  }

  trackByItems(index: number, item: any): any { return item; }

}
