export class CasoFilter
{
  public caratula!: string | null
  public fechaComienzoDesde!: Date | null
  public fechaComienzoHasta!: Date | null
  public fechaAltaDesde!: Date | null
  public fechaAltaHasta!: Date | null
  public fechaFinalizacionHasta!: Date | null
  public fechaFinalizacionDesde!: Date | null
  public idEstadoCaso!: number| null
  public idTipoCasoCaso!: number| null

}
