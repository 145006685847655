import { Component, Inject, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog, EstadosTareas } from '@models/base/identificadores.model';
import { ExpedienteSummaryDto } from '@models/expediente/expedienteSummary.model';
import { CreatePericiaRequest } from '@models/pericia/createPericiaRequest.model';
import { CreateTareaRequest } from '@models/tarea/createTareaRequest.model';
import { EstadoPericiaService } from '@services/estadoPericia/estadoPericia.service';
import { LoginService } from '@services/login/login.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TareaService } from '@services/tarea/tarea.service';
import { TipoPericiaService } from '@services/tipoPericia/tipoPericia.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-create-tarea',
  templateUrl: './dialog-create-tarea.component.html',
  styleUrls: ['./dialog-create-tarea.component.css']
})
export class DialogCreateTareaComponent {

  lstEstados: BaseDto[] = [
    { id: 1, descripcion: 'Pendiente' },
    { id: 2, descripcion: 'En Proceso' },
    { id: 3, descripcion: 'Finalizado' },
  ];
  lstPrioridades: BaseDto[] = [
    { id: 1, descripcion: 'Baja' },
    { id: 2, descripcion: 'Media' },
    { id: 3, descripcion: 'Alta' },
  ];

  lstNiveles: BaseDto[] = [
    { id: 1, descripcion: 'Baja' },
    { id: 2, descripcion: 'Media' },
    { id: 3, descripcion: 'Alta' },
  ];
  formBuilder: FormBuilder = new FormBuilder;
  form: any;
  @Input() isDialog = false
  @Input() idExpediente!: number

  constructor(
    private loginService: LoginService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogCreateTareaComponent>,
    private tareaService: TareaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      Descripcion: ['',Validators.compose([Validators.required])],
      Titulo: ['',Validators.compose([Validators.required, Validators.maxLength(100)])],
      FechaVencimiento: [''],
      Prioridad: [''],
      NivelAvance: [''],
    });
  }


  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const createTareaRequest: CreateTareaRequest = this.setCreatePericiaRequest();
    this.spinner.show("spCreate")
    this.tareaService.create(createTareaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCreate")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "");
          this.dialogRef.close({event:AccionesDialog.Agregar,data:data})
        },
        error => {
          this.spinner.hide("spCreate")
          this.snackBar.showError(error, "Error");
        })
  }

  setCreatePericiaRequest(): CreateTareaRequest {

    const createTareaRequest = new CreateTareaRequest();

    createTareaRequest.descripcion = this.form.controls['Descripcion'].value;
    createTareaRequest.titulo = this.form.controls['Titulo'].value;
    createTareaRequest.fechaVencimiento = this.form.controls['FechaVencimiento'].value == "" ? null : this.form.controls['FechaVencimiento'].value;
    createTareaRequest.nivelAvance = this.form.controls['NivelAvance'].value == "" ? null : this.form.controls['NivelAvance'].value;
    createTareaRequest.prioridad = this.form.controls['Prioridad'].value == "" ? null : this.form.controls['Prioridad'].value;
    createTareaRequest.idEstadoTarea = EstadosTareas.Pendiente;
    createTareaRequest.idUsuario = this.loginService.getIdUser();
    createTareaRequest.idEmpresa = 1;
    createTareaRequest.idExpediente = this.data;
    return createTareaRequest
  }

  onReset() {
    this.form.reset();
  }



  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

  selectExpediente(expedienteSummaryDto: ExpedienteSummaryDto) {
    this.data.idExpediente = expedienteSummaryDto.id;
  }
}
