import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { AccionesDialog } from '@models/base/identificadores.model';
import { MenuDto } from '@models/seguridad/menu/menuDto.model';
import { MenuService } from '@services/menu/menu.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogUpdateMenuComponent } from '../actualizar-menu/dialog-update-menu/dialog-update-menu.component';
import { DialogCreateMenuComponent } from '../crear-menu/dialog-create-menu/dialog-create-menu.component';
import { DialogDeleteMenuComponent } from '../eliminar-menu/dialog-delete-menu/dialog-delete-menu.component';
import { DialogViewMenuComponent } from '../ver-menu/dialog-view-menu/dialog-view-menu.component';

@Component({
  selector: 'app-listado-menu',
  templateUrl: './listado-menu.component.html',
  styleUrls: ['./listado-menu.component.css']
})
export class ListadoMenuComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private menuService: MenuService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['descripcion', 'accion'] :
        ['descripcion','path','icon','nivel','idMenuPadre', 'accion'];
    });

  }

  data = false
  dataSource = new MatTableDataSource<MenuDto>();
  obj!: undefined
  displayedColumns: string[] = [];

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  ngOnInit() {
    this.getAllMenu();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openDialogCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    const dialogRef = this.dialog.open(DialogCreateMenuComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.getAllMenu();
      }
    });
  }

  openDialogUpdate(menuDto: MenuDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = menuDto
    const dialogRef = this.dialog.open(DialogUpdateMenuComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Modificar) {
        this.getAllMenu();
      }
    });
  }

  openDialogDelete(menuDto:MenuDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = menuDto
    const dialogRef = this.dialog.open(DialogDeleteMenuComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.getAllMenu();
      }
    });
  }

  openDialogVer(menuDto:MenuDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = menuDto
    const dialogRef = this.dialog.open(DialogViewMenuComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Ver) {
        this.getAllMenu();
      }
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllMenu() {
    this.spinner.show("spLoadMenu")
    this.menuService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spLoadMenu")
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spLoadMenu")
          this.snackBar.showError(error, "Error");
        }
      )
  }


}
