import { CreateKeyValueRequest } from "@models/base/createKeyValueRequest.model"

export class CreateContactoRequest extends CreateKeyValueRequest {
  public idPersona!: number;
  public idEmpresa!: number;
  public idUsuario!: number;
  public idTipoContacto!: number;
  public valorContacto!: string;
  public preferido!: boolean;
  public empresaAsociada!: string;
  public fechaUltimoContacto!: Date | null;
  public tipoRelacion!: string;
  public fuenteContacto!: string;
  public notasSeguimiento!: string;
  public estadoContacto!: string;
  public categoriasEtiquetas!: string;
  public historialInteracciones!: string;
  public preferenciasContacto!: string;

}
