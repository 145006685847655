import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { UnlockPersonaRequest } from '@models/persona/unlockPersonaRequest.model';
import { UpdatePersonaRequest } from '@models/persona/updatePersonaRequest.model';
import { PersonaService } from '@services/persona/persona.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-form-persona-reactive',
  templateUrl: './form-persona-reactive.component.html',
  styleUrls: ['./form-persona-reactive.component.css']
})
export class FormPersonaReactiveComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FormPersonaReactiveComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PersonaDetailDto,
    private PersonaService: PersonaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) { }

  ngOnInit(): void {
  }

  reactivar(personaDetailDto: PersonaDetailDto): void {
    this.spinner.show("spPersona");
    let unlockPersonaRequest = new UnlockPersonaRequest
    unlockPersonaRequest.idPersona = personaDetailDto.id
    this.PersonaService.unlockPersona(unlockPersonaRequest)
      .subscribe(data => {
        if (data = true) {
          this.spinner.hide("spPersona");
          this.snackBar.showSuccess("Se activó la persona de manera correcta.", "Exito")
          this.dialogRef.close({ event: AccionesDialog.Eliminar });
        }
      },
        error => {
          this.spinner.hide("spPersona");
          this.snackBar.showError(error, "Error");
          this.dialogRef.close({ event: AccionesDialog.Cancelar });
        }
      )
  }
  onNoClick(): void {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

}
