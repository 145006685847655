export class UpdatePersonaRequest {

   public id!: number;
   public nombre!: string;
   public apellido!: string;
   public idTipoDocumento!: number | null ;
   public nroDocumento !: string;
   public telefonoPrincipal !: string;
   public telefonoAlternativo !: string;
   public sexo !: string | null;
   public correo !: string;
   public fechaNacimiento  !: string ;
   public ubicacion !: string;
   public idLocalidad !: number | null ;
   public idProvincia !: number | null ;
   public codigoCpa !: string;
   public idEstadoCivil !: number | null;
   public ocupacion !: string;
   public activo !: boolean;
   public observacion !: string;
   public idPais !: number| null  ;
   public telefonoLaboral !: string;
   public ubicacionLaboral !: string;

}
