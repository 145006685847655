import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgxEditorModule } from "ngx-editor";
import { BtnSendEmailComponent } from "../views/email/btn-send-email/btn-send-email.component";
import { SendEmailComponent } from "../views/email/send-email/send-email.component";
import { ArchivoModule } from "./archivo.module";
import { SharedModule } from "./shared.module";

@NgModule({
  imports: [
      CommonModule,
      SharedModule,
      ArchivoModule
  ],
  declarations: [
    SendEmailComponent,
    BtnSendEmailComponent
  ],
  providers: [
  ],
  exports: [
    SendEmailComponent,
    BtnSendEmailComponent
  ]
})
export class EmailModule { }
