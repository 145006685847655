<form [formGroup]="form">
    <div>
        <mat-form-field appearance="outline" class="full-width-x100">
            <ngx-spinner *ngIf="loadBar" [zIndex]="-1" name="spBusquedaPersona" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
            <mat-label>{{nombre}}</mat-label>
            <input [disabled]="disable" type="search" placeholder="Buscar por nombre o doc." matInput [matAutocomplete]="auto" formControlName="name" />
            <button disabled mat-icon-button matSuffix>
          <mat-icon>search</mat-icon>
      </button>
            <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let persona of personas$ | async ;let i=index;trackBy:trackByItems" [value]="persona.nombreCompleto" (click)="selectValue(persona)" (onSelectionChange)="selectValue(persona)">
                    <span #tooltip="matTooltip" matTooltipPosition="right" [matTooltipDisabled]="!disabledDocumento.value" matTooltip={{persona.nroDocumento}}>{{persona.nombreCompleto | titlecase}} - {{persona.nroDocumento}}</span>
                </mat-option>
            </mat-autocomplete>
            <button mat-icon-button matSuffix *ngIf="personaDetailDto" (click)="editarPersona(personaDetailDto.id)" matTooltip="Editar persona" matTooltipPosition="below">
    <mat-icon color="primary">info</mat-icon>
    </button>
            <button mat-icon-button matSuffix (click)="onNuevaPersona()" matTooltip="Nueva persona" matTooltipPosition="below">
        <mat-icon color="primary">add</mat-icon>
        </button>
            <button mat-icon-button matSuffix matTooltip="Limpiar" *ngIf="entitySelect" (click)="selectValue(null)">
    <mat-icon>clear</mat-icon>
    </button>
        </mat-form-field>
        <!-- <mat-checkbox [formControl]="disabledDocumento" matTooltip="Ver Nro. Doc." class="example-disabled-checkbox">
  </mat-checkbox> -->
    </div>

</form>