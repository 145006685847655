import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CasoDto } from '@models/caso/casoDto.model';
import { CasoService } from '@services/caso/caso.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogCasoCreateComponent } from '@views/casos/dialog-caso-create/dialog-caso-create.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, map, startWith } from 'rxjs';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-buscar-caso',
  templateUrl: './buscar-caso.component.html',
  styleUrls: ['./buscar-caso.component.css']
})
export class BuscarCasoComponent {
  @Input() disable: boolean = false
  entitySelect!: number | null;
  @Output() eventoCaso = new EventEmitter<BaseDto>();
  @Input() idCasoInput: number = 0;
  @ViewChild('typehead', { read: MatAutocompleteTrigger }) autoTrigger!: MatAutocompleteTrigger;
  casosDto!: BaseDto[];
  @Input() casoEntrada: BaseDto = new BaseDto;
  @Output() eventEntity = new EventEmitter<BaseDto>();
  expedienteDto!: BaseDto;
  public entityControl = new FormControl();

  public filteredCaso!: Observable<BaseDto[]>;
  casoDto!: CasoDto;

  constructor(
    private dialog: MatDialog,
    private casoService: CasoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {

    this.getAll()

    // if (this.idCasoInput && this.idCasoInput > 0) {
    //   this.getById(this.idCasoInput)
    //   this.entityControl.setValue(this.casoEntrada.descripcion)
    // }
    this.filteredCaso = this.entityControl.valueChanges.pipe(
      startWith(''),
      map((term: any) => this.findOption(term))
    );

  }

  // ngOnChanges(): void {
  //   if (this.idCasoInput != 0)
  //     this.getById(this.idCasoInput)
  //   this.entityControl.setValue(this.casoEntrada.descripcion)
  // }


  selectValue(event: any) {
    if (isFalsy(event)) {
      this.entitySelect = null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }


  findOption(val: string) {
    if (this.casosDto)
      for (let i = 0; i < this.casosDto.length; i++) {
        if (this.casosDto[i].descripcion.toUpperCase() == val.toUpperCase()) {
          //if (this.casosDto[i].id != 0)
            //this.getById(this.casosDto[i].id)
        }
      }
    if (this.casosDto)
      return this.casosDto.filter((expediente) => new RegExp(val, 'gi').test(expediente.descripcion));
    else
      return []
  }

  actualizarCasos(){}



  // getById(id: any) {
  //   this.spinner.show("spBusqueda");
  //   this.casoService.getById(id)
  //     .subscribe(
  //       data => {
  //         this.spinner.hide("spBusqueda");
  //         this.casoEntrada = data
  //         this.eventEntity.emit(data)
  //         this.casoDto = data
  //       },
  //       error => {
  //         this.spinner.hide("spBusqueda");
  //         this.snackBar.showError(error, "Error");
  //       });
  // }


  getAll() {
    this.spinner.show("spBusqueda");
    this.casoService.getAllCaratula()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda");
          this.casosDto = data
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spBusqueda");
        });
  }

  openDialog(action: any, obj: any) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%"
    dialogConfig.autoFocus = false;
    dialogConfig.data = obj
    obj.descripcion =  isFalsy(obj.expediente)? null: obj.expediente.caratula

    const dialogRef = this.dialog.open(DialogCasoCreateComponent, dialogConfig);

    dialogRef.afterClosed().subscribe((result: { data: any;action: any; }) => {
      switch (result.action) {
        case AccionesDialog.Agregar:
          this.getAll()
          break;
        default:
          break;
      }
    });
  }


  trackByItems(index: number, item: any): any { return item; }
}
