import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { MenuDto } from '@models/seguridad/menu/menuDto.model';
import { UpdateMenuRequest } from '@models/seguridad/menu/updateMenuRequest.model';
import { MenuService } from '@services/menu/menu.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogUpdateMenuComponent } from './dialog-update-menu/dialog-update-menu.component';

@Component({
  selector: 'app-actualizar-menu',
  templateUrl: './actualizar-menu.component.html',
  styleUrls: ['./actualizar-menu.component.css']
})
export class ActualizarMenuComponent implements OnInit {

  formBuilder = new FormBuilder;
  form: any;
  menuDto!: MenuDto;
  get f() { return this.form.controls; }
  constructor(
    public dialogRef: MatDialogRef<DialogUpdateMenuComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private menuService: MenuService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Icono: ["", Validators.compose([Validators.required,])],
      Path: ["", Validators.compose([])],
      Nivel: ["", Validators.compose([Validators.required])],
      IdMenuPadre: ["", Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    this.getById(this.data.id)
  }

  doAction() {

    let updateMenuRequest: UpdateMenuRequest = new UpdateMenuRequest
    updateMenuRequest.id = this.menuDto.id
    updateMenuRequest.descripcion = this.form.controls["Descripcion"].value
    updateMenuRequest.icon = this.form.controls["Icono"].value.value.toLowerCase()
    updateMenuRequest.path = this.form.controls["Path"].value.value.toLowerCase()
    updateMenuRequest.nivel = this.form.controls["Nivel"].value
    updateMenuRequest.idMenuPadre = this.form.controls["IdMenuPadre"].value
    this.update(updateMenuRequest)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  update(updateMenuRequest: UpdateMenuRequest) {
    this.spinner.show("spUpdateMenu")
    this.menuService.update(updateMenuRequest)
      .subscribe(
        data => {
          this.spinner.hide("spUpdateMenu")
          this.snackBar.showSuccess("Registro actualizado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Modificar });
        },
        error => {
          this.spinner.hide("spUpdateMenu")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getById(id: number) {
    this.spinner.show("spUpdateMenu")
    this.menuService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spUpdateMenu")
          this.menuDto = data
          this.seteaForm(data)
        },
        error => {
          this.spinner.hide("spUpdateMenu")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  seteaForm(menuDto: MenuDto) {
    this.form.controls["Descripcion"].setValue(menuDto.descripcion)
    this.form.controls["Icono"].setValue(menuDto.icon)
    this.form.controls["Path"].setValue(menuDto.path)
    this.form.controls["Nivel"].setValue(menuDto.nivel)
    this.form.controls["IdMenuPadre"].setValue(menuDto.idMenuPadre)
  }

}
