export class UpdateExpedienteRequest{
  public id!: number
  public descripcion!: string
  public ubicacion!:	string
  public caratula!:	string
  public cuij!:	string
  public numero!:	string
  public demanda!:	string
  public demandado!:	string
  public causa!:	string
  public idEstadoExpedientePerito!:	number
  public idEstadoExpediente!:number
  public idOrganismo!:	number
  public idTipoExpediente!: number
  public idCaso!: number
  public idEstadoProcesal!: number
  public claveSisfeExpediente!:	string
  public sufijo!:	string
  public bis!:	string
  public fechaIngresoMEU!: Date | null
  public montoDemanda: number = 0
  }
