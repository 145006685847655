import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UsuarioDto } from '@models/usuario/usuarioDto.model';
import { UpdateRolInUserRequest } from '@models/usuario/updateRolInUserRequest.model';
import { UpdateUserRequest } from '@models/usuario/updateUserRequest.model';
import { RolService } from '@services/rol/rol.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuarioService } from '@services/usuario/usuario.service';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { PersonaService } from '@services/persona/persona.service';
import { Roles } from '@models/base/identificadores.model';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';

@Component({
  selector: 'app-form-usuario-update',
  templateUrl: './form-usuario-update.component.html',
  styleUrls: ['./form-usuario-update.component.css']
})
export class FormUsuarioUpdateComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion = new MatAccordion;
  @Input() personaId!: number;
  public personaControl = new FormControl();
  titulo: string = '';
  subMenuActive: boolean = false;
  stringObject: any;
  stringJson: any;
  usuarioForm!: FormGroup;
  public personaDetailDto: PersonaDetailDto = new PersonaDetailDto;
  UsuarioDto: UsuarioDto = new UsuarioDto;
  submitted = false;
  parametro: string = '';
  idRol: number = 0;
  isDisabled: boolean = false;
  idPersona: number = 0;
  valor: any
  errorMessage: string = "";
  personas: PersonaDetailDto[] = [];
  hide = true;
  public filteredPersonas!: Observable<PersonaDetailDto[]>;
  updateUserRequest: UpdateUserRequest = new UpdateUserRequest;
  idUsuario!: number;
  formBuilder: FormBuilder = new FormBuilder;
  get f() { return this.usuarioForm.controls; }
  roles: RolDto[] = [ ]
  constructor(
    private UsuarioService: UsuarioService,
    private RolService: RolService,
    private aRoute: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private PersonaService: PersonaService
  ) {

    this.usuarioForm = this.formBuilder.group({
      IdPersona: [''],
      IdRol: [''],
      Rol: [''],
      NombreUsuario: [''],
      NombreCompleto: [''],
      ClaveNueva: [''],
    })
    this.usuarioForm.controls["NombreCompleto"].disable();
    this.usuarioForm.controls["NombreUsuario"].disable();
  }



  ngOnInit(): void {
    const routeParams = this.aRoute.snapshot.paramMap;
    this.idUsuario = Number(routeParams.get('id'));
    this.getUsuarioById(this.idUsuario)
    this.getAllRoles()
  }



  getUsuarioById(id: number) {
    this.spinner.show();
    this.UsuarioService.getUserById(id)
      .subscribe(
        data => {
          this.spinner.hide();
          this.UsuarioDto = data
          this.setFormularioUsuario()
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        });
  }
  selectRol(event: any) {
    this.idRol = parseInt(event)
  }

  goToFormPersona() {
    if (this.idPersona != 0)
      this.router.navigate(["/personas", this.idPersona])
  }

  onSubmit() {
    this.submitted = true;
    if (this.usuarioForm.valid == true) {
      this.spinner.show();
      let updateRolInUserRequest = new UpdateRolInUserRequest
      updateRolInUserRequest.userName = this.usuarioForm.controls['NombreUsuario'].value
      updateRolInUserRequest.idRol = this.idRol;

      this.UsuarioService.updateRolInUser(updateRolInUserRequest).subscribe(
        data => {
          this.spinner.hide();
          this.snackBar.showSuccess(data.text, "Exito")
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )

    }
  }


  getAllRoles() {
    this.spinner.show();
    this.RolService.getAll()
      .subscribe(
        data => {
          this.spinner.hide();
          this.roles = data.filter(x => x.id != Roles.SuperAdministradores)
          this.roles = data
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        });
  }


  setFormularioUsuario() {
    this.usuarioForm.controls['NombreUsuario'].setValue(this.UsuarioDto.userName)
    this.usuarioForm.controls['NombreCompleto'].setValue(this.UsuarioDto.nombre + ' ' + this.UsuarioDto.apellido)
    this.usuarioForm.controls['Rol'].setValue(this.UsuarioDto.rol.id)
    this.usuarioForm.controls['IdRol'].setValue(this.UsuarioDto.rol.id)
  }

  onCancel() {
    this.usuarioForm.reset();
    this.router.navigate(["/usuarios"])
  }

  editarPersona(idPersona:number) {
    this.getPersonaByIdAndRoute(idPersona)
  }

  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spPersona")
    this.PersonaService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.PersonaService.setPersona(data)
          this.router.navigate(["/personas/editar"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }

  trackByItems(index: number, item: any): any { return item; }
}
