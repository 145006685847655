import { UpdateKeyValueRequest } from "@models/base/updateKeyValueRequest.model"

export class UpdateTareaRequest extends UpdateKeyValueRequest{
  public titulo!:	string
  public fechaUltimaActualizacion!: Date
  public fechaVencimiento!: Date
  public idEstadoTarea!:number
  public idExpediente!:number
  public prioridad!:number
  public nivelAvance!:number
  }
