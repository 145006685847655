import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { DeleteKeyValueRequest } from '@models/base/deleteKeyValueRequest.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateOrganismoRequest } from '@models/organismo/createOrganismoRequest.model';
import { UpdateOrganismoRequest } from '@models/organismo/updateOrganismoRequest.model';
import { OrganismoService } from '@services/organismo/organismo.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-organismo-generic',
  templateUrl: './dialog-organismo-generic.component.html',
  styleUrls: ['./dialog-organismo-generic.component.css']
})
export class DialogOrganismoGenericComponent {


  local_data: any;
  form: any;
  formBuilder: FormBuilder = new FormBuilder;
  elseTemplate = false
  localidad!: BaseDto;
  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogOrganismoGenericComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private organismoService: OrganismoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {
    this.local_data = { ...data };
    this.form = this.formBuilder.group({
      Descripcion: ['', Validators.compose([Validators.maxLength(200)])],
      Nombre: ['', Validators.compose([Validators.required,Validators.maxLength(200)])],
      Telefono: [''],
      Correo: ['',Validators.compose([Validators.email, Validators.maxLength(50)])],
    })

    if (this.local_data.action == AccionesDialog.Eliminar) {
      this.form.controls["Descripcion"].setValue(this.local_data.descripcion)
    }
  }

  ngOnInit(): void {
    if (this.local_data.action == AccionesDialog.Modificar || this.local_data.action == AccionesDialog.Ver)
      this.completaFormulario()
  }
  completaFormulario() {
    this.form.controls['Descripcion'].value = this.local_data.descripcion
    this.form.controls['Nombre'].value = isFalsy(this.local_data.nombre) ? "" : this.local_data.nombre
    this.form.controls['Telefono'].value = isFalsy(this.local_data.telefono) ? "" : this.local_data.telefono
    this.form.controls['Correo'].value = isFalsy(this.local_data.correo) ? "" : this.local_data.correo
    this.localidad = this.local_data.localidad

  }
  doAction() {
    switch (this.local_data.action) {
      case AccionesDialog.Agregar:
        this.create()
        break;
      case AccionesDialog.Modificar:
        this.update()
        break;
      case AccionesDialog.Eliminar:
        this.delete()
        break;
      default:
        break;
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  seleccionaLocalidad(data: BaseDto) {
    this.localidad = data
  }


   create() {
    if (this.form.invalid) {
      return;
    }
    const createOrganismoRequest: CreateOrganismoRequest = new CreateOrganismoRequest
    createOrganismoRequest.descripcion = isFalsy(this.form.controls['Descripcion'].value) ? null : this.form.controls['Descripcion'].value;
    createOrganismoRequest.nombre = isFalsy(this.form.controls['Nombre'].value) ? null : this.form.controls['Nombre'].value;
    createOrganismoRequest.correo = isFalsy(this.form.controls['Correo'].value) ? null : this.form.controls['Correo'].value;
    createOrganismoRequest.telefono = isFalsy(this.form.controls['Telefono'].value) ? null : this.form.controls['Telefono'].value;
    createOrganismoRequest.idLocalidad = this.localidad.id
    createOrganismoRequest.idPais = 1

    this.spinner.show("spLoadGeneric")

     this.organismoService.create(createOrganismoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spLoadGeneric")
         this.dialogRef.close({ data: this.local_data });
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "Exito");
        },
        error => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showError(error, "Error");
        })
  }

  update() {
    if (this.form.invalid) {
      return;
    }
    const updateOrganismoRequest: UpdateOrganismoRequest = new UpdateOrganismoRequest
    updateOrganismoRequest.id = this.local_data.id
    updateOrganismoRequest.descripcion = isFalsy(this.form.controls['Descripcion'].value) ? null : this.form.controls['Descripcion'].value;
    updateOrganismoRequest.nombre = isFalsy(this.form.controls['Nombre'].value) ? null : this.form.controls['Nombre'].value;
    updateOrganismoRequest.correo = isFalsy(this.form.controls['Correo'].value) ? null : this.form.controls['Correo'].value;
    updateOrganismoRequest.telefono = isFalsy(this.form.controls['Telefono'].value) ? null : this.form.controls['Telefono'].value;
    updateOrganismoRequest.idLocalidad =this.localidad.id
    updateOrganismoRequest.idPais = 1
    this.spinner.show("spLoadGeneric")

    this.organismoService.update(updateOrganismoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "Exito");
          this.dialogRef.close({ data: this.local_data });
        },
        error => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showError(error, "Error");
        })
  }

  delete() {
    this.spinner.show("spLoadGeneric")
    this.organismoService.delete(this.local_data.id)
      .subscribe(
        data => {
          this.spinner.hide("spLoadGeneric")
          this.dialogRef.close({ data: this.local_data });
          this.snackBar.showSuccess("Se elimino el registro correctamente.", "Exito");
        },
        error => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showError(error, "Error");
        })
  }

}
