import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AudienciaDto } from '@models/audiencia/audienciaDto.model';
import { CreateAudienciaRequest } from '@models/audiencia/createAudienciaRequest.model';
import { UpdateAudienciaRequest } from '@models/audiencia/updateAudienciaRequest.model';
import { CantidadPorEstadoDto } from '@models/base/cantidadPorEstadoDto.mode';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreatePericiaRequest } from '@models/pericia/createPericiaRequest.model';
import { PericiaDto } from '@models/pericia/periciaDto.model';
import { UpdatePericiaRequest } from '@models/pericia/updatePericiaRequest.model';
import { CreateTareaRequest } from '@models/tarea/createTareaRequest.model';
import { TareaDto } from '@models/tarea/tareaDto.model';
import { TareaFilter } from '@models/tarea/tareaFilter.model';
import { UpdateTareaRequest } from '@models/tarea/updateTareaRequest.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';


@Injectable({
  providedIn: 'root'
})
export class TareaService {

  constructor(private http: HttpClient) {
  }

  create(createTareaRequest: CreateTareaRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Tarea/Create", createTareaRequest);
  }

  update(updateTareaRequest: UpdateTareaRequest): Observable<any> {
    return this.http.put<any>("/api/Tarea/Update", updateTareaRequest);
  }

  getAllByFilter(tareaFilter: TareaFilter): Observable<TareaDto[]> {
    return this.http.post<TareaDto[]>("/api/Tarea/GetAllByFilter", tareaFilter);
  }

  getAll(): Observable<TareaDto[]> {
    return this.http.get<TareaDto[]>("/api/Tarea/GetAll");
  }

  getById(id: number): Observable<TareaDto> {
    return this.http.get<TareaDto>("/api/Tarea/GetById?id=" + id);
  }

  getAllByIdExpediente(id: number): Observable<TareaDto[]> {
    return this.http.get<TareaDto[]>("/api/Tarea/GetAllByIdExpediente?idExpediente=" + id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Tarea/Delete?id=" + id);
    else
      return of(null)
  }

  getCantidadPorEstadoDto(): Observable<CantidadPorEstadoDto[]> {
    return this.http.get<CantidadPorEstadoDto[]>("/api/Tarea/GetCantidadPorEstadoDto");
  }

}
