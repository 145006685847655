import { Component } from '@angular/core';

@Component({
  selector: 'app-dialog-view-contacto',
  templateUrl: './dialog-view-contacto.component.html',
  styleUrls: ['./dialog-view-contacto.component.css']
})
export class DialogViewContactoComponent {

}
