import { BaseDto } from "@models/base/baseDto.model";

export class PersonaDto extends BaseDto {

   nombre!: string ;
   apellido!: string ;
   nombreCompleto!: string;
   tipoDocumento!: string ;
   nroDocumento!: string ;
   correo!: string ;
   telefonoPrincipal!: string;
   telefonoAlternativo!: string;
   activo!: boolean;

}
