import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CasoUsoDto } from '@models/seguridad/casosUso/casoUsoDto.model';
import { PermisoDto } from '@models/seguridad/permisos/permisoDto.model';
import { CreateRolRequest } from '@models/seguridad/roles/createRolRequest.model';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { RolService } from '@services/rol/rol.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogCreateRolComponent } from './dialog-create-rol/dialog-create-rol.component';

@Component({
  selector: 'app-crear-rol',
  templateUrl: './crear-rol.component.html',
  styleUrls: ['./crear-rol.component.css']
})
export class CrearRolComponent implements OnInit {

  formBuilder = new FormBuilder;
  form: any;
  permisoDto!:PermisoDto;
  casosUso!:CasoUsoDto[]
  roles!:RolDto[]
  casoUsoDto!:CasoUsoDto
  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogCreateRolComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private RolService: RolService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any

  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
    })
  }

  ngOnInit(): void {
  }
  doAction() {
    let createRolRequest: CreateRolRequest = new CreateRolRequest
    createRolRequest.descripcion = this.form.controls["Descripcion"].value
    this.create(createRolRequest)
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  create(createRolRequest: CreateRolRequest) {
    this.spinner.show("spCrearRol")
    this.RolService.create(createRolRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCrearRol")
          this.snackBar.showSuccess("Registro agregado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Agregar });
        },
        error => {
          this.spinner.hide("spCrearRol")
          this.snackBar.showError(error, "Error");
        }
      )
  }


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
