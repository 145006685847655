<div class="container-fluid">
    <div class="row no-gutter">
        <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
        <div class="col-sm-12 col-md-6 col-lg-3">
            <div class="login d-flex align-items-center py-5">
                <div class="container">
                    <div class="row">
                        <div class="fondoBlanco">

                            <h3 class="login-heading mb-4" *ngIf="entorno != 'Produccion'"><b style="color: red;">GPerito Entorno:  </b>{{entorno}}</h3>
                            <h3 *ngIf="modoRecuperacion " class="login-heading mb-4">Complete los pasos para recuperar su cuenta</h3>
                            <div>
                                <app-recover-password *ngIf="modoRecuperacion"></app-recover-password>
                            </div>

                            <a color="primary" *ngIf="modoRecuperacion" (click)="goToLogin()">Volver a Iniciar Sesion</a>
                            <div>
                                <app-iniciar-sesion *ngIf="!modoRecuperacion"></app-iniciar-sesion>
                            </div>

                            <br>
                            <a class="small" *ngIf="!modoRecuperacion" (click)="RecuperarCuenta()">Recuperar contraseña.</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
