import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { BaseDto } from '@models/base/baseDto.model';
import { CreateDocumentoRequest } from '@models/documento/createDocumentoRequest.model';
import { AsuntoService } from '@services/asunto/asuntos.service';
import { DocumentoService } from '@services/documento/documento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-asocia-documento-nuevo-entidad',
  templateUrl: './asocia-documento-nuevo-entidad.component.html',
  styleUrls: ['./asocia-documento-nuevo-entidad.component.css']
})
export class AsociaDocumentoNuevoEntidadComponent implements OnInit {

  selectedAsuntoStatus: boolean = false
  selectedDocumentoStatus: boolean = false
  lstAsuntos: BaseDto[] = [];
  lstDocumentos: BaseDto[] = []
  formDocuementos!: FormGroup;
  idAsunto: number = 0
  idDocumento!: number
  @Input() idEntidad!: number
  @Input() idTipoEntidad!: number
  @Input() idAsuntoEntrada: number = 0
  @Output() documentoChange = new EventEmitter<number>();
  fileError: string = "";
  theFile: any;
  fileUploadState: boolean = false
  createDocumentoRequest: CreateDocumentoRequest = new CreateDocumentoRequest

  get f() { return this.formDocuementos.controls; }

  constructor(
    private formBuilder: FormBuilder,
    private spinner: NgxSpinnerService,
    private documentoService: DocumentoService,
    private AsuntoService: AsuntoService,
    private snackBar: SnackBarService,
  ) {
  }

  ngOnInit(): void {
    this.formDocuementos = this.formBuilder.group({
      Asuntos: ['', Validators.compose([Validators.required])],
      FileUpload: ['', Validators.compose([Validators.required])]
    });

    this.getAllAsuntos()
  }
  selectAsunto(event: any) {
    this.selectedAsuntoStatus = true;
    this.idAsunto = event.value
  }

  selectDocumento(event: any) {
    this.selectedDocumentoStatus = true;
    this.idDocumento = event.value
  }

  getAllAsuntos() {
    this.spinner.show("spBusquedaAsunto")
    this.AsuntoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaAsunto")
          this.lstAsuntos = data
        },
        error => {
          this.spinner.hide("spBusquedaAsunto")
          this.snackBar.showError( error, "Error");
        }
      )
  }


  cancelUpload() {
    this.createDocumentoRequest = new CreateDocumentoRequest
    this.fileUploadState = false
    this.formDocuementos.controls["Asuntos"].setValue(0)
    this.idAsunto = 0
  }

  agregarDocumento() {
    this.setCreateDocumentoRequest()
    this.createDocumento(this.createDocumentoRequest)
  }

  seleccionArchivo(archivo:ArchivoDto){
    if(archivo.content != ""){
      this.createDocumentoRequest.fileContent = archivo.content
      this.createDocumentoRequest.fileName = archivo.descripcion
      this.createDocumentoRequest.fileType = archivo.contentType
      this.fileUploadState = true
    }
  }

  setCreateDocumentoRequest() {
    this.createDocumentoRequest.idAsunto = this.idAsunto
    this.createDocumentoRequest.idTipoEntidad = this.idTipoEntidad
    this.createDocumentoRequest.idEntidad = this.idEntidad
    this.createDocumentoRequest.nombre = "Documento: " + this.idTipoEntidad + "-" + this.idEntidad
  }

  createDocumento(createDocumentoRequest: CreateDocumentoRequest) {
    this.spinner.show("fileUpload")
    this.documentoService.create(createDocumentoRequest)
      .subscribe(
        data => {
          this.spinner.hide("fileUpload")
          this.snackBar.showSuccess("Documento creado correctamente.", "Exito");
          this.cancelUpload()
          this.documentoChange.emit(data.id);
        },
        error => {
          this.spinner.hide("fileUpload")
          this.snackBar.showError( error, "Error");
        }
      )
  }

  trackByItems(index: number, item: any): any { return item; }

}
