
export class TareaFilter {
  public titulo!:	string
  public descripcion!:	string
  public fechaModificacionDesde!: Date
  public fechaModificacionHasta!: Date
  public fechaAltaDesde!: Date
  public fechaAltaHasta!: Date
  public idEstadoTarea!:number
  public prioridad!:number
  public nivelAvance!:number
  public idUsuario!:number
  public idExpediente!:number
}
