<ngx-spinner [zIndex]="100" name="spExpediente" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
</ngx-spinner>
<div class="row" *appRole="['Expedientes.Ver']">
    <div *ngIf="expedienteDto">

        <mat-tab-group>
            <mat-tab label="Expediente">
                <mat-card>
                    <mat-card-header>
                        <button mat-stroked-button color="primary" [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>Acciones</button>
              <mat-menu #menu="matMenu">
                <button mat-menu-item  (click)="generarCedulaNotificacion(expedienteDto)">
                  Generar cedula notificación</button>
                            <button mat-menu-item [routerLink]="['/expedientes/edit/'+ expedienteDto.id]">
                Editar</button>
                            <button mat-menu-item (click)="openDialogDelete(expedienteDto)">
                Eliminar</button>
                        </mat-menu>
                    </mat-card-header>
                    <br>
                    <div class="container">
                        <mat-card>
                            <mat-card-header>
                            </mat-card-header>
                            <mat-card-content>
                                <h5>Caso: {{expedienteDto.caso == undefined ? "" : expedienteDto.caso.caratula | uppercase}}</h5>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <br>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <span><h5>Caratula: {{expedienteDto.caratula | uppercase}} <button class="icon-display" mat-icon-button (click)="copiarAlPortapapeles(expedienteDto.caratula)" matTooltip="Copiar al portapapeles" aria-label="Copiar caratula expediente al portapapeles">
                                  <mat-icon>content_copy</mat-icon>
                                </button></h5></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Cuij: </strong></span>
                                <span> {{expedienteDto.cuij}} <button class="icon-display" mat-icon-button (click)="copiarAlPortapapeles(expedienteDto.cuij)" matTooltip="Copiar al portapapeles" aria-label="Copiar cuij al portapapeles">
                                  <mat-icon>content_copy</mat-icon>
                                </button></span>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Organismo: </strong></span>
                                <span>{{expedienteDto.organismo == undefined ? "" : expedienteDto.organismo.nombre |
                titlecase}} <button class="icon-display" mat-icon-button (click)="copiarAlPortapapeles(expedienteDto.organismo.nombre)" matTooltip="Copiar al portapapeles" aria-label="Copiar organismo al portapapeles">
                  <mat-icon>content_copy</mat-icon>
                </button></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Demanda: </strong></span>
                                <span>{{expedienteDto.demanda | uppercase}}</span>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Demandado: </strong></span>
                                <span>{{expedienteDto.demandado | uppercase}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Causa: </strong></span>
                                <span>{{expedienteDto.causa | uppercase}}</span>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Numero: </strong></span>
                                <span>{{expedienteDto.numero | uppercase}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Sufijo: </strong></span>
                                <span>{{expedienteDto.sufijo| uppercase}}</span>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Bis: </strong></span>
                                <span>{{expedienteDto.bis | uppercase}}</span>
                            </div>
                        </div>
                        <div class="row">

                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Ubicación: </strong></span>
                                <span>{{expedienteDto.ubicacion | titlecase}}</span>
                            </div>

                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Clave Siste: </strong></span>
                                <span>{{expedienteDto.claveSisfeExpediente}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Fecha ingreso MEU: </strong></span>
                                <span>{{expedienteDto.fechaIngresoMEU | date:'short'}}</span>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Monto demanda: </strong></span>
                                <span>{{expedienteDto.montoDemanda | currency}}</span>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-6">

                                <span><strong>Tipo expediente:</strong></span>
                                <span>{{expedienteDto.tipoExpediente == undefined ? "": expedienteDto.tipoExpediente.descripcion
                  |
                  uppercase}}</span>
                            </div>
                        </div>

                        <div class="row">

                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Estado expediente: </strong></span>
                                <span>{{expedienteDto.estadoExpediente == undefined ? "":
                  expedienteDto.estadoExpediente.descripcion
                  |
                  uppercase}}</span>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-6">
                                <span><strong>Estado expediente perito: </strong></span>
                                <span>{{expedienteDto.estadoExpedientePerito == undefined ? "":
                  expedienteDto.estadoExpedientePerito.descripcion | uppercase}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <span><strong>Fecha Alta:</strong></span>
                                <span>{{expedienteDto.fechaAlta | date:'short'}}</span>
                            </div>
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <span><strong>Ultima modificación:</strong></span>
                                <span>{{expedienteDto.fechaModificacion | date:'short'}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-12 col-lg-12">
                                <span><strong>Descripción:</strong></span>
                                <span>{{expedienteDto.descripcion}}</span>
                            </div>
                        </div>
                    </mat-card-content>

                </mat-card>
            </mat-tab>
            <mat-tab label="Partes">
                <section>
                    <mat-card>
                        <app-form-expediente-partes></app-form-expediente-partes>
                    </mat-card>
                </section>
            </mat-tab>
            <mat-tab label="Notas">
                <section>
                    <app-list-expediente-nota [idExpediente]="expedienteDto.id" *appRole="['ExpedientesNotas.Listar']"></app-list-expediente-nota>
                </section>

            </mat-tab>
            <mat-tab label="Tareas">
                <section>
                    <app-list-tarea [idExpediente]="expedienteDto.id" *appRole="['Tareas.Listar']"></app-list-tarea>
                </section>
            </mat-tab>
            <mat-tab label="Pericias" *appRole="['Pericias.Listar']">
                <section>
                    <mat-card>
                        <mat-card-header>
                            <div *appRole="['Pericias.Crear']">
                                <button mat-raised-button color="primary" (click)="onNuevaPericia()" #tooltip="matTooltip" matTooltip="Crear pericia">
                  <mat-icon>add</mat-icon>
                  Nueva pericia
                </button>
                            </div>
                        </mat-card-header>
                        <br>
                        <div *appRole="['Pericias.Listar']">

                            <mat-card-content *ngIf="dataSourcePericias.data.length >= 1">
                                <ngx-spinner [zIndex]="100" name="spPericias" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
                                <table mat-table [dataSource]="dataSourcePericias">


                                    <ng-container matColumnDef="lugarPericia">
                                        <th mat-header-cell *matHeaderCellDef> <strong>Lugar</strong></th>
                                        <td mat-cell *matCellDef="let element"> {{element.lugar}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="fechaPropuesta">
                                        <th mat-header-cell *matHeaderCellDef> <strong>Fecha Propuesta</strong> </th>
                                        <td mat-cell *matCellDef="let element"> {{element.fechaPropuesta | date:('short')}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="fechaRealiza">
                                        <th mat-header-cell *matHeaderCellDef> <strong>Fecha Realiza</strong> </th>
                                        <td mat-cell *matCellDef="let element"> {{element.fechaRealizada | date:('short')}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tipoPericia">
                                        <th mat-header-cell *matHeaderCellDef> <strong>Tipo Pericia</strong> </th>
                                        <td mat-cell *matCellDef="let element">
                                            {{element.tipoPericia? element.tipoPericia.descripcion: "" | titlecase}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="estadoPericia">
                                        <th mat-header-cell *matHeaderCellDef> <strong>Estado</strong> </th>
                                        <td mat-cell *matCellDef="let element"> {{element.estadoPericia? element.estadoPericia.descripcion: "" | titlecase}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="accion" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </th>
                                        <td mat-cell *matCellDef="let pericia" class="action-link">
                                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item [routerLink]="['/pericias/',pericia.id]" *appRole="['Pericias.Ver']">
                          <mat-icon>visibility</mat-icon>
                          <span>Ver</span>
                        </button>
                                                <button mat-menu-item [routerLink]="['/pericias/edit/',pericia.id]" *appRole="['Pericias.Modificar']">
                          <mat-icon>edit</mat-icon>
                          <span>Editar</span>
                        </button>
                                                <button mat-menu-item (click)="openDialogPericia('Eliminar',pericia)" *appRole="['Pericias.Eliminar']">
                          <mat-icon>delete</mat-icon>
                          <span>Eliminar</span>
                        </button>
                                            </mat-menu>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsPericias"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsPericias;"></tr>
                                </table>
                            </mat-card-content>
                        </div>
                    </mat-card>
                </section>
            </mat-tab>
            <mat-tab label="Audiencias" *appRole="['Audiencias.Listar']">
                <section>
                    <mat-card>
                        <mat-card-header>
                            <div *appRole="['Audiencias.Crear']">
                                <button mat-raised-button color="primary" (click)="onNuevaAudiencia()" #tooltip="matTooltip" matTooltip="Crear audiencia">
                  <mat-icon>add</mat-icon>
                  Nueva audiencia
                </button>
                            </div>
                        </mat-card-header>
                        <br>
                        <div *appRole="['Audiencias.Listar']">

                            <mat-card-content *ngIf="dataSourceAudiencia.data.length >= 1">


                                <ngx-spinner [zIndex]="100" name="spAudiencias" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
                                <table mat-table [dataSource]="dataSourceAudiencia">

                                    <ng-container matColumnDef="lugar">
                                        <th mat-header-cell *matHeaderCellDef> <strong>Lugar</strong> </th>
                                        <td mat-cell *matCellDef="let element"> {{element.lugar}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="sala">
                                        <th mat-header-cell *matHeaderCellDef> <strong>Sala</strong> </th>
                                        <td mat-cell *matCellDef="let element"> {{element.sala}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="fecha">
                                        <th mat-header-cell *matHeaderCellDef> <strong>Fecha</strong> </th>
                                        <td mat-cell *matCellDef="let element"> {{element.fecha | date:('short')}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="tipoAudiencia">
                                        <th mat-header-cell *matHeaderCellDef> <strong>Tipo Audiencia</strong> </th>
                                        <td mat-cell *matCellDef="let element"> {{element.tipoAudiencia== undefined ? "" : element.tipoAudiencia.descripcion}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="accion" stickyEnd>
                                        <th mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </th>
                                        <td mat-cell *matCellDef="let pericia">
                                            <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                        <mat-icon>more_vert</mat-icon>
                      </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item [routerLink]="['/audiencias/',pericia.id]" *appRole="['Audiencias.Ver']">
                          <mat-icon>visibility</mat-icon>
                          <span>Ver</span>
                        </button>
                                                <button mat-menu-item [routerLink]="['/audiencias/edit/',pericia.id]" *appRole="['Audiencias.Modificar']">
                          <mat-icon>edit</mat-icon>
                          <span>Editar</span>
                        </button>
                                                <button mat-menu-item (click)="openDialogAudiencia('Eliminar',pericia)" *appRole="['Audiencias.Eliminar']">
                          <mat-icon>delete</mat-icon>
                          <span>Eliminar</span>
                        </button>
                                            </mat-menu>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumnsAudiencias"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumnsAudiencias;"></tr>
                                </table>
                            </mat-card-content>
                        </div>
                    </mat-card>
                </section>
            </mat-tab>
            <mat-tab label="Movimientos" *appRole="['Movimientos.Listar']">
                <section>
                    <mat-card>
                        <app-list-movimiento [idExpediente]="expedienteDto.id" *appRole="['Movimientos.Listar']"></app-list-movimiento>
                    </mat-card>
                </section>
            </mat-tab>
        </mat-tab-group>

    </div>
</div>
