import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogTipoMovimientoGenericComponent } from '@views/tipos-movimiento/dialog-tipo-movimiento-generic/dialog-tipo-movimiento-generic.component';
import { ListTipoMovimientoComponent } from '@views/tipos-movimiento/list-tipo-movimiento/list-tipo-movimiento.component';
import { SharedModule } from './shared.module';



@NgModule({
  declarations: [
    DialogTipoMovimientoGenericComponent,
    ListTipoMovimientoComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    DialogTipoMovimientoGenericComponent,
    ListTipoMovimientoComponent]
})
export class TipoMovimientoModule { }
