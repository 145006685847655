import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { AsuntosComponent } from "../views/asuntos/asuntos/asuntos.component";
import { BtnNuevoAsuntoComponent } from "../views/asuntos/btn-nuevo-asunto/btn-nuevo-asunto.component";
import { DialogAsuntosComponent } from "../views/asuntos/dialog-asuntos/dialog-asuntos.component";
import { SharedModule } from "./shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        BtnNuevoAsuntoComponent,
        DialogAsuntosComponent,
        AsuntosComponent,
    ],
    exports: [
        BtnNuevoAsuntoComponent,
        DialogAsuntosComponent,
        AsuntosComponent,
    ]
})

export class AsuntoModule { }
