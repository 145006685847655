import { Component, OnInit } from '@angular/core';
import { Roles } from '@models/base/identificadores.model';
import { LoginService } from '@services/login/login.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { VersionApiService } from '@services/versionApi/version-api.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-api-version',
  templateUrl: './api-version.component.html',
  styleUrls: ['./api-version.component.css']
})
export class ApiVersionComponent implements OnInit {
  versionApi: string = "";
  versionWeb: string = "";
  usuarioCliente!: boolean;

  constructor(
    private versionApiService: VersionApiService,
    private snackBar: SnackBarService,
    private loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.getCurrentVersionApi()
    this.versionWeb = environment.buildNumber
  }

  private getCurrentVersionApi() {
    this.versionApiService.getCurrenApiVersion().subscribe(
      data => {
        this.versionApi = data.text
      },
      error => {
        this.snackBar.showError(error, "Error");
      })
  }
}

