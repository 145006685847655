import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import { AccionesDialog } from '@models/base/identificadores.model';
import { MovimientoDto } from '@models/movimiento/movimientoDto.model';
import { MovimientoService } from '@services/movimiento/movimiento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogCreateMovimientoComponent } from '../dialog-create-movimiento/dialog-create-movimiento.component';
import { DialogUpdateCasoUsoComponent } from '@views/seguridad/casos-uso/actualizar-caso-uso/dialog-update-caso-uso/dialog-update-caso-uso.component';
import { DialogUpdateMovimientoComponent } from '../dialog-movimiento-update/dialog-update-movimiento.component';
import { DialogViewMovimientoComponent } from '../dialog-movimiento-view/dialog-movimiento-view.component';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { DialogMovimientoDeleteComponent } from '../dialog-movimiento-delete/dialog-movimiento-delete.component';

@Component({
  selector: 'app-list-movimiento',
  templateUrl: './list-movimiento.component.html',
  styleUrls: ['./list-movimiento.component.css']
})
export class ListMovimientoComponent {

  @Input() idExpediente!: number
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<MovimientoDto>();

  constructor(
    private movimientoService: MovimientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["expediente", "accion"] :
        ["expediente", "tipoMovimiento", "importe", "fechaMovimiento", "accion"];
    });
  }

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    if (this.idExpediente)
      this.getAllMovimientosByIdExpediente(this.idExpediente)
  }

  filtrar() {
    this.getAll()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAll() {
    this.spinner.show('spListado')
    this.movimientoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllMovimientosByIdExpediente(idExpediente: number) {
    this.spinner.show('spListado')
    this.movimientoService.getAllByIdExpediente(idExpediente)
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }

  openDialog(action: any, obj: any) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%"
    dialogConfig.autoFocus = false;
    obj.idExpediente = isFalsy(this.idExpediente) ? null : this.idExpediente
    dialogConfig.data = obj
    var dialogRef= null;

    switch (action) {
      case AccionesDialog.Agregar:
        dialogRef = this.dialog.open(DialogCreateMovimientoComponent, dialogConfig);
        break;

      case AccionesDialog.Ver:
        dialogRef = this.dialog.open(DialogViewMovimientoComponent, dialogConfig);
        break;

      case AccionesDialog.Modificar:
        dialogRef = this.dialog.open(DialogUpdateMovimientoComponent, dialogConfig);
        break;

      case AccionesDialog.Eliminar:
        dialogRef = this.dialog.open(DialogMovimientoDeleteComponent, dialogConfig);
        break;
    }

    if(!isFalsy(dialogRef)){
      dialogRef.afterClosed().subscribe((result: { data:any, event: any; }) => {

          if(result?.event != AccionesDialog.Cancelar && !isFalsy(this.idExpediente))
          this.getAllMovimientosByIdExpediente(this.idExpediente)
          else if(result?.event == AccionesDialog.Cancelar && !isFalsy(this.idExpediente))
          this.getAllMovimientosByIdExpediente(this.idExpediente)
          else
          this.getAll()
      });
    }

  }

  getTotal() {
    return this.dataSource.data.map(t => t.importe).reduce((acc, value) => acc + value, 0);
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
