import { BreakpointObserver } from '@angular/cdk/layout';
import { FlatTreeControl, NestedTreeControl } from '@angular/cdk/tree';
import { Component } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener, MatTreeNestedDataSource } from '@angular/material/tree';
import { Router } from '@angular/router';

interface Desplegables {
  name: string;
  children?: Desplegables[];
  url?: string;
  icons?: string;
}

interface TreeFlatNode {
  expandable: boolean;
  name: string;
  level: number;
}

const TREE_DATA: Desplegables[] = [
  {
    name: 'Seguridad',
    children: [
      {
        name: 'Gestionar menues',
        url: '/menus',
      },
      {
        name: 'Gestionar permisos',
        url: '/permisos',
      },
      {
        name: 'Gestionar roles',
        url: '/roles',
      },
      {
        name: 'Gestionar perfiles',
        url: '/perfiles',
      },
    ],
  },
  {
    name: 'Expediente',
    children: [
      {
        name: 'Gestionar organismos',
        url:'/organismos'
      },
      {
        name: 'Gestionar tipos expedientes',
        url:'/tiposexpedientes'
      },
    ]
  },
  {
    name: 'Contable',
    children: [
      {
        name: 'Gestionar tipos de movimientos',
        url:'/tiposmovimientos'
      },
    ],
  },
];
@Component({
  selector: 'app-menues-desplegables',
  templateUrl: './menues-desplegables.component.html',
  styleUrls: ['./menues-desplegables.component.css']
})
export class MenuesDesplegablesComponent {

  constructor(private breakpointObserver: BreakpointObserver) {
    this.dataSource.data = TREE_DATA;
  }

  treeControl = new NestedTreeControl<Desplegables>(node => node.children);
  dataSource = new MatTreeNestedDataSource<Desplegables>();


  hasChild = (_: number, node: Desplegables) => !!node.children && node.children.length > 0;

}
