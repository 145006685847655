<mat-card>
    <mat-card-header>
        <button mat-raised-button *appRole="['ExpedientesNotas.Crear']" matTooltip="
    Nueva nota" color="primary" (click)="openDialog('Agregar',{})" color="primary">
      <mat-icon>add</mat-icon>
      Nueva nota
    </button>
        <br>
        <br>
    </mat-card-header>
    <mat-card-content *appRole="['ExpedientesNotas.Listar']">
        <ngx-spinner [zIndex]="100" name="spLoadList" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <div class="nota-container">
            <div class="nota" *ngFor="let item of expedienteNotas">
                <mat-accordion *appRole="['ExpedientesNotas.Ver']">
                    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                {{item.fechaAlta | date:("short")}}
                            </mat-panel-title>
                            <mat-panel-description>
                                Usuario:{{item.usuario.nombre}} - Ultima actualización: {{item.fechaUltimaActualizacion | date:("short")}}
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div [innerHTML]="item.descripcion"></div>
                        <mat-action-row>
                            <button mat-mini-fab matTooltip="Editar" (click)="openDialog('Modificar',{item})" *appRole="['ExpedientesNotas.Modificar']" color="primary" aria-label="Example mini fab with a heart icon">
              <mat-icon>edit</mat-icon>
            </button>
                            <button mat-icon-button matTooltip="Eliminar" (click)="openDialog('Eliminar',{item})" *appRole="['ExpedientesNotas.Eliminar']" color="warn" aria-label="Example icon-button with a heart icon">
              <mat-icon>delete</mat-icon>
            </button>
                        </mat-action-row>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>
        </div>

    </mat-card-content>
</mat-card>