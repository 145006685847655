import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AccionesDialog } from '@models/base/identificadores.model';
import { ExpedienteSinClave } from '@models/expediente/expedienteSinClave.model';

@Component({
  selector: 'app-dialog-expedientes-enviados',
  templateUrl: './dialog-expedientes-enviados.component.html',
  styleUrls: ['./dialog-expedientes-enviados.component.css']
})
export class DialogExpedientesEnviadosComponent {


  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<ExpedienteSinClave>();
  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;
  data!: boolean;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  constructor(
    public dialogRef: MatDialogRef<DialogExpedientesEnviadosComponent>,
    private breakpointObserver: BreakpointObserver,
    @Optional() @Inject(MAT_DIALOG_DATA) public expedienteSinClave: ExpedienteSinClave[]
    ) {
      this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
        this.displayedColumns = result.matches ?
          [ "caratula","correoEnviado"] :
          [ "caratula", "cuij", "juzgado", "correo", "correoEnviado", "fallaEnvioCorreo"];
      });
    }

  ngOnInit(): void {
    this.dataSource.data = this.expedienteSinClave
    this.data = this.dataSource.data.length >= 1 ? true : false
  }


  closeDialog(){
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }
}
