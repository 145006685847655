import { CUSTOM_ELEMENTS_SCHEMA, Component, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ExpedienteNotaDto } from '@models/expedienteNota/expedienteNotaDto.model';
import { ExpedienteNotaService } from '@services/expedienteNota/expedienteNota.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogExpedienteNotaCreateComponent } from '../dialog-expediente-nota-create/dialog-expediente-nota-create.component';
import { AccionesDialog } from '@models/base/identificadores.model';
import { DialogExpedienteNotaGenericComponent } from '../dialog-expediente-nota-generic/dialog-expediente-nota-generic.component';

@Component({
  selector: 'app-list-expediente-nota',
  templateUrl: './list-expediente-nota.component.html',
  styleUrls: ['./list-expediente-nota.component.css']

})
export class ListExpedienteNotaComponent {


  @Input() idExpediente!: number
  expedienteNotas!: ExpedienteNotaDto[];
  panelOpenState = false;

  constructor(
    private dialog: MatDialog,
    private expedienteNotaService: ExpedienteNotaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) {
  }
  ngOnInit() {
    if (!isFalsy(this.idExpediente))
      this.getAllByIdExpediente(this.idExpediente)
  }


  getAllByIdExpediente(idExpediente: number) {
    this.spinner.show("spLoadList")
    this.expedienteNotaService.getAllByIdExpediente(idExpediente)
      .subscribe(
        data => {
          this.spinner.hide("spLoadList")
          this.expedienteNotas = data.sort((b, a) => a.fechaAlta.toString().localeCompare(b.fechaAlta.toString()))
        },
        error => {
          this.spinner.hide("spLoadList")
          this.snackBar.showError(error, "Error");
        })
  }

  openDialog(action: any, obj: any) {
    obj.action = action;
    obj.idExpediente = this.idExpediente;
    if (obj.action != AccionesDialog.Cancelar && obj.action != AccionesDialog.Agregar) {
      obj.id = obj.item.id;
      obj.descripcion = obj.item.descripcion;
      obj.item = obj.item;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%",
      dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogExpedienteNotaGenericComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event != AccionesDialog.Cancelar) {
        this.getAllByIdExpediente(this.idExpediente)
      }
    });
  }


}
