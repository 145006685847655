<div *appRole="['Documentos.Crear']">

    <ngx-spinner name="fileUpload" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="formDocuementos">
        <div class="row">
            <div class=" col-lg-3">
                <ngx-spinner name="spBusquedaAsunto" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <mat-form-field class="full-width" appearance="outline">
                    <mat-label>Asunto</mat-label>
                    <mat-select placeholder="Seleccione opcion" formControlName="Asuntos" (selectionChange)="selectAsunto($event)">
                        <mat-option [value]="asunto.id" *ngFor="let asunto of lstAsuntos ;let i=index;trackBy:trackByItems">
                            {{asunto.descripcion}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class=" col-lg-6" *ngIf="idAsunto != 0">
                <app-file-upload (eventoArchivo)="seleccionArchivo($event)"></app-file-upload>
                <input type="text" hidden formControlName="FileUpload">
            </div>
            <div class=" col-lg-3">
                <button mat-raised-button (click)="agregarDocumento()" [disabled]="!formDocuementos || idAsunto === 0 || fileUploadState ===false" color="primary"><mat-icon>post_add</mat-icon>
            Agregar documento</button>
            </div>
        </div>
    </form>
</div>
