<mat-card *appRole="['Pericias.Modificar']">
    <mat-card-header>
        <mat-card-title>
            <h2>Editar pericia</h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <ngx-spinner [zIndex]="100" name="spUpdate" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
        </ngx-spinner>
        <form [formGroup]="periciaForm">
            <div class="row">
                <p><strong>Expediente</strong></p>
                <p>{{expediente? expediente.caratula :"" | uppercase}}</p>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Lugar</mat-label>
                        <input matInput type="text" maxlength="200" style="text-transform: uppercase;" formControlName="Lugar" />
                        <mat-error *ngIf="periciaForm.controls['Lugar'].hasError('required')">Este campo es
                            <strong>requerido</strong>.</mat-error>
                        <mat-error *ngIf="periciaForm.controls['Lugar'].hasError('maxlength')">Supero el largo maximo.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <ngx-spinner [zIndex]="-1" name="spEstadosPericia" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Estado Pericia</mat-label>
                        <mat-select placeholder="Seleccione opcion" formControlName="EstadoPericia">
                            <mat-option [value]="estadoPericia.id" *ngFor="let estadoPericia of lstEstadosPericias ;let i=index;trackBy:trackByItems">
                                {{estadoPericia.descripcion}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <ngx-spinner [zIndex]="-1" name="spTiposPericia" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Tipo Pericia</mat-label>
                        <mat-select placeholder="Seleccione opcion" formControlName="TipoPericia">
                            <mat-option [value]="tipoPericia.id" *ngFor="let tipoPericia of lstTiposPericia ;let i=index;trackBy:trackByItems">
                                {{tipoPericia.descripcion}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <mat-label>Fecha Propuesta</mat-label>
                        <input matInput type="datetime-local" formControlName="FechaPropuesta" placeholder="MM/DD/YYYY HH:MM">
                        <mat-error *ngIf="periciaForm.controls['FechaPropuesta'].hasError('required')">Este campo es
                            <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <mat-label>Fecha Realización</mat-label>
                        <input matInput type="datetime-local" formControlName="FechaRealizada" placeholder="MM/DD/YYYY HH:MM">
                        <mat-datepicker-toggle matIconSuffix [for]="pickerFechaRealizada"></mat-datepicker-toggle>
                        <mat-datepicker #pickerFechaRealizada></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col">
                  <mat-form-field appearance="outline" class="full-width-x100">
                      <mat-label>Fecha Finalización</mat-label>
                      <input matInput type="datetime-local" formControlName="FechaFinalizada" placeholder="MM/DD/YYYY HH:MM">
                      <mat-datepicker-toggle matIconSuffix [for]="pickerFechaFinalizada"></mat-datepicker-toggle>
                      <mat-datepicker #pickerFechaFinalizada></mat-datepicker>
                  </mat-form-field>
              </div>
                <div class="col">
                    <mat-checkbox class="example-margin" formControlName="NotificacionRealizada"> Notificados</mat-checkbox>
                </div>
            </div>

            <div class="row">

                <mat-form-field class="example-full-width col-lg-12" appearance="outline">
                    <mat-label>Descripcion</mat-label>
                    <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
                    <mat-error *ngIf="periciaForm.controls['Descripcion'].hasError('maxlength')">Este campo es
                        <strong>requerido</strong>.</mat-error>
                    <mat-hint align="start">Amplie información</mat-hint>
                    <mat-hint align="end">{{message.value.length}} / 2000</mat-hint>
                </mat-form-field>
            </div>
        </form>
    </mat-card-content>
    <mat-card-actions align="end">
        <button mat-raised-button color="primary" name="Guardar" [disabled]="!periciaForm.valid || periciaDto.expediente.id == undefined" (click)="onSubmit()">Guardar</button>
    </mat-card-actions>
</mat-card>
