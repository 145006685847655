import { BaseDto } from "@models/base/baseDto.model"

export class TokenModel {
  id!: string
  auth_Token!: string
  expires_In!: number
  refresh_Token!: string
  expires_Time!: number
}

export class JwtHead {
  alg!: string
  typ!: string
}

export class JwtPayload {
  nameid!: string
  unique_name!: string
  nombreApellido!: string
  role!: string
  nbf!: number
  exp!: number
  iat!: number
  empresa: BaseDto = { id: 1, descripcion: "GPerito" }
}

