<mat-card *appRole="['Expedientes.Crear']">
    <mat-card-header>
        <mat-card-title>
            <h2>Nuevo expediente</h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="expedienteForm">
            <ngx-spinner [zIndex]="100" name="spExpediente" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <div class="row">
                <div class="col">
                    <app-buscar-caso (eventEntity)="selectCaso($event)"></app-buscar-caso>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Caratula</mat-label>
                        <input matInput type="text" maxlength="200" style="text-transform: uppercase;" formControlName="Caratula" />
                        <mat-error *ngIf="expedienteForm.controls['Caratula'].hasError('required')">Este campo es
                            <strong>requerido</strong>.</mat-error>
                        <mat-error *ngIf="expedienteForm.controls['Caratula'].hasError('maxlength')">Supero el largo maximo.</mat-error>
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <ngx-spinner [zIndex]="-1" name="spOrganismos" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Organismo</mat-label>
                        <mat-select placeholder="Seleccione opcion" formControlName="Organismo">
                            <mat-option [value]="organismo.id" *ngFor="let organismo of organismos ;let i=index;trackBy:trackByItems">
                                {{organismo.nombre}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="expedienteForm.controls['Organismo'].hasError('required')">Supero el largo maximo.</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Cuij</mat-label>
                        <input matInput type="text" mask="00-00000000-0" formControlName="Cuij" />
                        <mat-error *ngIf="expedienteForm.controls['Cuij'].hasError('required')">Este campo es
                            <strong>requerido</strong>.</mat-error>
                    </mat-form-field>

                </div>
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Demanda</mat-label>
                        <input matInput type="text" maxlength="200" style="text-transform: uppercase;" formControlName="Demanda" />
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Demandado</mat-label>
                        <input matInput type="text" maxlength="200" style="text-transform: uppercase;" formControlName="Demandado" />
                    </mat-form-field>
                </div>

                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Causa</mat-label>
                        <input matInput type="text" maxlength="200" style="text-transform: uppercase;" formControlName="Causa" />
                    </mat-form-field>
                </div>
            </div>
            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Numero</mat-label>
                        <input matInput type="text" maxlength="200" style="text-transform: uppercase;" formControlName="Numero" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Sufijo</mat-label>
                        <input matInput type="text" maxlength="200" style="text-transform: uppercase;" formControlName="Sufijo" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Bis</mat-label>
                        <input matInput type="text" maxlength="200" style="text-transform: uppercase;" formControlName="Bis" />
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Ubicacion</mat-label>
                        <input matInput type="text" maxlength="200" style="text-transform: capitalize;" formControlName="Ubicacion" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <mat-label>Fecha Ingreso MEU</mat-label>
                        <input matInput [matDatepicker]="picker" formControlName="FechaIngresoMEU">
                        <mat-hint>MM/DD/YYYY</mat-hint>
                        <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Clave SISFE</mat-label>
                        <input matInput type="text" formControlName="ClaveSisfeExpediente" />
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field class="full-width-x100" appearance="outline">
                        <mat-label>Monto Demanda</mat-label>
                        <input type="text" currencyMask placeholder="Monto Demanda" formControlName="MontoDemanda" matInput>
                    </mat-form-field>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <ngx-spinner [zIndex]="-1" name="spEstadosExpedientePerito" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Estado Expediente Perito</mat-label>
                        <mat-select placeholder="Seleccione opcion" formControlName="EstadoExpedientesPerito">
                            <mat-option [value]="estadoExpedientesPerito.id" *ngFor="let estadoExpedientesPerito of estadosExpedientesPerito ;let i=index;trackBy:trackByItems">
                                {{estadoExpedientesPerito.descripcion}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <ngx-spinner [zIndex]="-1" name="spEstadosExpediente" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Estado Expediente</mat-label>
                        <mat-select placeholder="Seleccione opcion" formControlName="EstadoExpedientes">
                            <mat-option [value]="estadoExpediente.id" *ngFor="let estadoExpediente of estadosExpedientes ;let i=index;trackBy:trackByItems">
                                {{estadoExpediente.descripcion}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <ngx-spinner [zIndex]="-1" name="spTiposExpedientes" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Tipo Expediente</mat-label>
                        <mat-select placeholder="Seleccione opcion" formControlName="TipoExpediente">
                            <mat-option [value]="tipoExpediente.id" *ngFor="let tipoExpediente of tiposExpedientes ;let i=index;trackBy:trackByItems">
                                {{tipoExpediente.descripcion}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="expedienteForm.controls['TipoExpediente'].hasError('required')">Este campo es
                            <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
                <div class="col">
                    <mat-form-field appearance="outline" class="full-width-x100">
                        <ngx-spinner [zIndex]="-1" name="spEstadosProcesal" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                        <mat-label>Estado Procesal</mat-label>
                        <mat-select placeholder="Seleccione opcion" formControlName="EstadoProcesal">
                            <mat-option [value]="estadoProcesal.id" *ngFor="let estadoProcesal of estadosProcesal ;let i=index;trackBy:trackByItems">
                                {{estadoProcesal.descripcion}}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="expedienteForm.controls['EstadoProcesal'].hasError('required')">Este campo es
                            <strong>requerido</strong>.</mat-error>
                    </mat-form-field>
                </div>
            </div>


            <div class="row">
                <mat-form-field class="example-full-width col-lg-12" appearance="outline">
                    <mat-label>Descripcion</mat-label>
                    <textarea matInput #message name="Descripcion" formControlName="Descripcion"></textarea>
                    <mat-error *ngIf="expedienteForm.controls['Descripcion'].hasError('maxlength')">Este campo es
                        <strong>requerido</strong>.</mat-error>
                    <mat-hint align="start">Amplie información</mat-hint>
                    <mat-hint align="end">{{message.value.length}} / 2000</mat-hint>
                </mat-form-field>
            </div>
            <mat-card-actions align="end">
                <button mat-raised-button color="primary" style="margin-right:3px;" name="Guardar" [disabled]="!expedienteForm.valid" (click)="onSubmit()">Agregar</button>
                <!-- <button mat-button color="warn" style="margin-right:3px;" name="Cancelar" (click)="expedienteForm.reset()">Cancelar</button> -->
            </mat-card-actions>
        </form>
    </mat-card-content>
</mat-card>