import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { BaseDto } from '@models/base/baseDto.model';
import { UpdateTipoPericiaRequest } from '@models/tipoPericia/updateTipoPericiaRequest.model';
import { CreateTipoPericiaRequest } from '@models/tipoPericia/createTipoPericiaRequest.model';

@Injectable({
  providedIn: 'root'
})
export class TipoPericiaService {

  constructor(private http:HttpClient) {
  }

  create(createTipoPeritoRequest:CreateTipoPericiaRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/TipoPericia/Create",createTipoPeritoRequest);
  }

  update(updateTipoPeritoRequest:UpdateTipoPericiaRequest):Observable<any>
  {
    return  this.http.put<any>("/api/TipoPericia/Update",updateTipoPeritoRequest);
  }

  getAll():Observable<BaseDto[]> {
    return   this.http.get<BaseDto[]>("/api/TipoPericia/GetAll");
  }

  getById(id:number):Observable<BaseDto>{
    return  this.http.get<BaseDto>("/api/TipoPericia/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/TipoPericia/Delete?id=" + id);
    else
      return of(null)
  }

}
