import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { AppTestComponent } from '@views/app-test/app-test.component';
import { CanDeactivateGuard } from '@directives/can-deactivate/can-deactivate.guard';
import { AuthGuardService } from '@guards/auth-guard/auth-guard.service';
import {  } from '@guards/rol-client-guard/rol-client.guard';
import {  } from '@guards/rol-user-guard/rol-user.guard';
import { AsuntosComponent } from '@views/asuntos/asuntos/asuntos.component';
import { ConfiguracionComponent } from '@views/configuracion/configuracion/configuracion.component';
// import { EmpresaComponent } from '@views/configuracion/empresa/empresa.component';
import { DashboardComponent } from '@views/dashboard/dashboard/dashboard.component';
import { CreateDocumentComponent } from '@views/documentos/create-document/create-document.component';
import { DocumentosComponent } from '@views/documentos/documentos.component';
import { EditarDocumentoComponent } from '@views/documentos/editar-documento/editar-documento.component';
import { FormDocumentoComponent } from '@views/documentos/form-documento/form-documento.component';
import { BtnSendEmailComponent } from '@views/email/btn-send-email/btn-send-email.component';
import { InicioComponent } from '@views/inicio/inicio.component';
import { LoginComponent } from '@views/login/login/login.component';
import { PerfilUsuarioComponent } from '@views/perfil-usuario/perfil-usuario.component';
import { PersonasComponent } from '@views/personas/personas/personas.component';
import { FormPersonaCreateComponent } from '@views/personas/form-persona-create/form-persona-create.component';
import { FormPersonaUpdateComponent } from '@views/personas/form-persona-update/form-persona-update.component';
import { DocumentosPersonasComponent } from '@views/documentos/documentos-entidades/documentos-personas/documentos-personas.component';
import { UsuariosComponent } from '@views/usuarios/listado-usuarios/usuarios.component';
import { FormUsuarioCreateComponent } from '@views/usuarios/form-usuario-create/form-usuario-create.component';
import { FormUsuarioUpdateComponent } from '@views/usuarios/form-usuario-update/form-usuario-update.component';
import { ListadoMenuComponent } from '@views/seguridad/menus/listar-menu/listado-menu.component';
import { ListadoPermisoComponent } from '@views/seguridad/permisos/listar-permiso/listado-permiso.component';
import { ListadoCasoUsoComponent } from '@views/seguridad/casos-uso/listar-caso-uso/listado-caso-uso.component';
import { ListadoRolComponent } from '@views/seguridad/roles/listado-rol/listado-rol.component';
import { ListExpedienteComponent } from '@views/expedientes/list-expediente/list-expediente.component';
import { FormExpedienteCreateComponent } from '@views/expedientes/form-expediente-create/form-expediente-create.component';
import { FormExpedienteViewComponent } from '@views/expedientes/form-expediente-view/form-expediente-view.component';
import { FormExpedienteUpdateComponent } from '@views/expedientes/form-expediente-update/form-expediente-update.component';
import { FormAudienciaViewComponent } from '@views/audiencias/form-audiencia-view/form-audiencia-view.component';
import { FormAudienciaUpdateComponent } from '@views/audiencias/form-audiencia-update/form-audiencia-update.component';
import { FormAudienciaCreateComponent } from '@views/audiencias/form-audiencia-create/form-audiencia-create.component';
import { ListAudienciaComponent } from '@views/audiencias/list-audiencia/list-audiencia.component';
import { FormPericiaViewComponent } from '@views/pericias/form-pericia-view/form-pericia-view.component';
import { FormPericiaUpdateComponent } from '@views/pericias/form-pericia-update/form-pericia-update.component';
import { ListPericiaComponent } from '@views/pericias/list-pericia/list-pericia.component';
import { FormPericiaCreateComponent } from '@views/pericias/form-pericia-create/form-pericia-create.component';
import { ListCasoComponent } from '@views/casos/list-caso/list-caso.component';
import { FormCasoCreateComponent } from '@views/casos/form-caso-create/form-caso-create.component';
import { FormCasoUpdateComponent } from '@views/casos/form-caso-update/form-caso-update.component';
import { FormCasoViewComponent } from '@views/casos/form-caso-view/form-caso-view.component';
import { ListMovimientoComponent } from '@views/movimientos/list-movimiento/list-movimiento.component';
import { MenuesDesplegablesComponent } from '@views/menues-desplegables/menues-desplegables.component';
import { ListTipoMovimientoComponent } from '@views/tipos-movimiento/list-tipo-movimiento/list-tipo-movimiento.component';
import { ListTipoPericiaComponent } from '@views/tipos-pericia/list-tipo-pericia/list-tipo-pericia.component';
import { ListOrganismosComponent } from '@views/organismos/list-organismos/list-organismos.component';
import { ListTareaComponent } from '@views/tareas/list-tarea/list-tarea.component';
import { FormPersonaViewComponent } from '@views/personas/form-persona-view/form-persona-view/form-persona-view.component';
import { FormOrganismoViewComponent } from '@views/organismos/form-organismo-view/form-organismo-view.component';

const routes: Routes = [

  { path: 'login', component: LoginComponent },
  {
    path: '', component: InicioComponent,
    canActivate: [AuthGuardService],
    data: {
      title: 'Inicio',
      breadcrumb: [
        {
          label: 'Inicio',
          info: 'home'
        }
      ]
    },
    children: [
      {
        path: 'appTest',
        component: AppTestComponent,
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuardService],
        data: {
          title: 'Dashboard',
          breadcrumb: [
            {
              label: 'Dashboard',
              info: 'home'
            }
          ]
        },
      },
      {
        path: 'perfilUsuarios',
        component: PerfilUsuarioComponent,
        canActivate: [AuthGuardService],
        data: {
          title: 'Perfil Usuario',
          breadcrumb: [
            {
              label: 'Perfil Usuario',
              url: ''
            }
          ]
        },
      },
      {
        path: 'configuracion',
        component: ConfiguracionComponent,
        canActivate: [AuthGuardService ],
        data: {
          title: 'Configuración',
          breadcrumb: [
            {
              label: 'Configuración',
              url: ''
            }
          ]
        },
      },
      {
        path: 'personas',
        canActivate: [AuthGuardService ],
        data: {
          title: 'Listado Personas',
          breadcrumb: [
            {
              label: 'Listado Personas',
              url: ''
            }
          ]
        },
        children: [
          {
            path: '',
            component: PersonasComponent,
            canActivate: [AuthGuardService ],
            data: {
              title: 'Listado de personas',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: ''
                }
              ]
            }
          },
          {
            path: 'create',
            component: FormPersonaCreateComponent,
            canActivate: [AuthGuardService ],
            data: {
              title: 'Nueva persona',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Nueva persona',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'edit/:id',
            component: FormPersonaUpdateComponent,
            canActivate: [AuthGuardService],
            canDeactivate: [CanDeactivateGuard],
            data: {
              title: 'Modificar persona',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Modificar Persona',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: FormPersonaViewComponent,
            canActivate: [AuthGuardService],
            canDeactivate: [CanDeactivateGuard],
            data: {
              title: 'Ver persona',
              breadcrumb: [
                {
                  label: 'Listado de personas',
                  url: 'personas'
                },
                {
                  label: 'Ver Persona',
                  url: ''
                }
              ]
            },
          }
        ]
      },

      {
        path: 'usuarios',
        children: [
          {
            path: '',
            component: UsuariosComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de usuarios',
              breadcrumb: [
                {
                  label: 'Listado de usuarios',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'create',
            component: FormUsuarioCreateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Usuario nuevo',
              breadcrumb: [
                {
                  label: 'Listado de usuarios',
                  url: '/usuarios'
                },
                {
                  label: 'Nuevo usuario',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'edit/:id',
            component: FormUsuarioUpdateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Modificar Usuario ',
              breadcrumb: [
                {
                  label: 'Listado de usuarios',
                  url: '/usuarios'
                },
                {
                  label: 'Modificar usuario ',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: FormUsuarioUpdateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Ver Usuario ',
              breadcrumb: [
                {
                  label: 'Listado de usuarios',
                  url: '/usuarios'
                },
                {
                  label: 'Ver usuario ',
                  url: ''
                }
              ]
            },
          },
        ]
      },

      {
        path: 'asuntos',
        component: AsuntosComponent,
        canActivate: [AuthGuardService ],
        data: {
          title: 'Listado de asuntos',
          breadcrumb: [
            {
              label: 'Listado de asuntos',
              url: ''
            }
          ]
        }
      },
      {
        path: 'expedientes',
        children: [
          {
            path: '',
            component: ListExpedienteComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de expedientes',
              breadcrumb: [
                {
                  label: 'Listado expedientes',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'create',
            component: FormExpedienteCreateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Nuevo expediente',
              breadcrumb: [
                {
                  label: 'Listado de expedientes',
                  url: '/expedientes'
                },
                {
                  label: 'Nuevo expediente',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'edit/:id',
            component: FormExpedienteUpdateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Modificar expediente',
              breadcrumb: [
                {
                  label: 'Listado de expedientes',
                  url: '/expedientes'
                },
                {
                  label: 'Modificar expediente',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: FormExpedienteViewComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Ver Expediente',
              breadcrumb: [
                {
                  label: 'Listado de expedientes',
                  url: '/expedientes'
                },
                {
                  label: 'Ver expediente ',
                  url: ''
                }
              ]
            },
          },
        ]
      },

      // {
      //   path: 'empresa',
      //   component: EmpresaComponent,
      //   canActivate: [AuthGuardService  ],
      //   data: {
      //     title: '',
      //     breadcrumb: [
      //       {
      //         label: 'Empresa',
      //         url: ''
      //       }
      //     ]
      //   },
      // },
      {
        path: 'fileupload',
        component: BtnSendEmailComponent,
        canActivate: [AuthGuardService  ],
        data: {

        },
      },
      {
        path: 'documentos',
        component: DocumentosComponent,
        canActivate: [AuthGuardService ],
        data: {
          title: 'Documentos',
          breadcrumb: [
            {
              label: 'Documentos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'documento',
        component: FormDocumentoComponent,
        canActivate: [AuthGuardService ],
        data: {
          title: 'Documento',
          breadcrumb: [
            {
              label: 'Documento',
              url: ''
            }
          ]
        },
        children: [
          {
            path: 'create',
            component: CreateDocumentComponent,
            canActivate: [AuthGuardService ],
            data: {
              title: 'Nuevo documento',
              breadcrumb: [
                {
                  label: 'Listado documentos',
                  url: 'documentos'
                },
                {
                  label: 'Nuevo documento ',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'edit/:id',
            component: EditarDocumentoComponent,
            canActivate: [AuthGuardService ],
            data: {
              title: 'Modificar documento',
              breadcrumb: [
                {
                  label: 'Listado de documentos',
                  url: 'documentos'
                },
                {
                  label: 'Modificar documento',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: EditarDocumentoComponent,
            canActivate: [AuthGuardService ],
            data: {
              title: 'Ver documento',
              breadcrumb: [
                {
                  label: 'Listado de documentos',
                  url: 'documentos'
                },
                {
                  label: 'Ver documento',
                  url: ''
                }
              ]
            },
          }
        ]
      },
      {
        path: 'menus',
        component: ListadoMenuComponent,
        canActivate: [AuthGuardService ],
        data: {
          title: 'Listado de menues',
          breadcrumb: [
            {
              label: 'Listado de menues',
              url: ''
            }
          ]
        },
      },
      {
        path: 'permisos',
        component: ListadoPermisoComponent,
        canActivate: [AuthGuardService ],
        data: {
          title: 'Listado de permisos',
          breadcrumb: [
            {
              label: 'Listado de permisos',
              url: ''
            }
          ]
        },
      },
      {
        path: 'perfiles',
        component: ListadoCasoUsoComponent,
        canActivate: [AuthGuardService ],
        data: {
          title: 'Listado de casos de uso',
          breadcrumb: { skip: true }
        },
      },
      {
        path: 'roles',
        component: ListadoRolComponent,
        canActivate: [AuthGuardService ],
        data: {
          title: 'Listado de roles',
          breadcrumb: [
            {
              label: 'Listado de roles',
              url: ''
            }
          ]
        },
      },
      {
        path: 'audiencias',
        children: [
          {
            path: '',
            component: ListAudienciaComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de udiencias',
              breadcrumb: [
                {
                  label: 'Listado de udiencias',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'create',
            component: FormAudienciaCreateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Nueva audiencias',
              breadcrumb: [
                {
                  label: 'Listado de audiencias',
                  url: '/audiencias'
                },
                {
                  label: 'Nueva audiencia',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'edit/:id',
            component: FormAudienciaUpdateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Modificar audiencia',
              breadcrumb: [
                {
                  label: 'Listado de audiencias',
                  url: '/audiencias'
                },
                {
                  label: 'Modificar audiencia',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: FormAudienciaViewComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Ver audiencia',
              breadcrumb: [
                {
                  label: 'Listado de audiencias',
                  url: '/audiencias'
                },
                {
                  label: 'Ver audiencia ',
                  url: ''
                }
              ]
            },
          },
        ]
      },
      {
        path: 'pericias',
        children: [
          {
            path: '',
            component: ListPericiaComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de pericias',
              breadcrumb: [
                {
                  label: 'Listado de pericias',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'create',
            component: FormPericiaCreateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Nueva pericia',
              breadcrumb: [
                {
                  label: 'Listado de pericia',
                  url: '/pericias'
                },
                {
                  label: 'Nueva pericia',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'edit/:id',
            component: FormPericiaUpdateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Modificar pericia',
              breadcrumb: [
                {
                  label: 'Listado de pericias',
                  url: '/pericias'
                },
                {
                  label: 'Modificar pericia',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: FormPericiaViewComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Ver pericia',
              breadcrumb: [
                {
                  label: 'Listado de pericias',
                  url: '/pericias'
                },
                {
                  label: 'Ver pericia ',
                  url: ''
                }
              ]
            },
          },
        ]
      },
      {
        path: 'casos',
        children: [
          {
            path: '',
            component: ListCasoComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de casos',
              breadcrumb: [
                {
                  label: 'Listado de casos',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'create',
            component: FormCasoCreateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Nuevo caso',
              breadcrumb: [
                {
                  label: 'Listado de casos',
                  url: '/casos'
                },
                {
                  label: 'Nuevo caso',
                  url: ''
                }
              ]
            },
          },
          {
            path: 'edit/:id',
            component: FormCasoUpdateComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Modificar caso',
              breadcrumb: [
                {
                  label: 'Listado de casos',
                  url: '/casos'
                },
                {
                  label: 'Modificar caso',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: FormCasoViewComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Ver caso',
              breadcrumb: [
                {
                  label: 'Listado de casos',
                  url: '/casos'
                },
                {
                  label: 'Ver caso ',
                  url: ''
                }
              ]
            },
          },
        ]
      },
      {
        path: 'movimientos',
        children: [
          {
            path: '',
            component: ListMovimientoComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de movimientos',
              breadcrumb: [
                {
                  label: 'Listado de movimientos',
                  url: ''
                }
              ]
            },
          },

        ]
      },
      {
        path: 'tiposmovimientos',
        children: [
          {
            path: '',
            component: ListTipoMovimientoComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de tipos de movimientos',
              breadcrumb: [
                {
                  label: 'Listado de tipos de movimientos',
                  url: ''
                }
              ]
            },
          },

        ]
      },
      {
        path: 'tipospericia',
        children: [
          {
            path: '',
            component: ListTipoPericiaComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de tipos de pericia',
              breadcrumb: [
                {
                  label: 'Listado de tipos de pericia',
                  url: ''
                }
              ]
            },
          },

        ]
      },
      {
        path: 'organismos',
        children: [
          {
            path: '',
            component: ListOrganismosComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de organismos',
              breadcrumb: [
                {
                  label: 'Listado de organismos',
                  url: ''
                }
              ]
            },
          },
          {
            path: ':id',
            component: FormOrganismoViewComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Ver organismo',
              breadcrumb: [
                {
                  label: 'Listado de organismos',
                  url: '/organismos'
                }
              ]
            },
          },


        ]
      },
      {
        path: 'menusdesplegables',
        component: MenuesDesplegablesComponent,
        canActivate: [AuthGuardService  ],
        data: {
          title: 'Menues desplegables',
          breadcrumb: [
            {
              label: 'Menues desplegables',
              url: '/menusdesplegables'
            },
          ]
        },
      },
      {
        path: 'tareas',
        children: [
          {
            path: '',
            component: ListTareaComponent,
            canActivate: [AuthGuardService  ],
            data: {
              title: 'Listado de tareas',
              breadcrumb: [
                {
                  label: 'Listado de tareas',
                  url: ''
                }
              ]
            },
          },

        ]
      },
      {
        path: "**", redirectTo: "/dashboard", pathMatch: 'full'
      }
    ]
  },


  {
    path: "**", redirectTo: "/dashboard", pathMatch: 'full'
  }
]

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [RouterModule]

})
export class AppRoutingModule { }
