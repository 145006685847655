import { CreateKeyValueRequest } from "@models/base/createKeyValueRequest.model"

export class CreateMovimientoRequest extends CreateKeyValueRequest {
  public idExpediente!: number
  public idEmpresa!: number
  public idUsuario!: number
  public idTipoMovimiento!: number
  public idTipoMonedaOrigen!: number
  public idTipoMonedaLocal!:number
  public importe!: number

  public activo: boolean = true;
  public fechaMovimiento!: Date
}
