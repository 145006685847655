
import { IBaseDto } from "./iBaseDto.model";

export class BaseDto implements IBaseDto{
public id!:number;
public descripcion!:string
}



