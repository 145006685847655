import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateExpedienteNotaRequest } from '@models/expedienteNota/createExpedienteNotaRequest.model';
import { UpdateExpedienteNotaRequest } from '@models/expedienteNota/updateExpedienteNotaRequest.model';
import { ExpedienteNotaService } from '@services/expedienteNota/expedienteNota.service';
import { LoginService } from '@services/login/login.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { UsuarioService } from '@services/usuario/usuario.service';
import { Editor } from 'ngx-editor';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog-expediente-nota-generic',
  templateUrl: './dialog-expediente-nota-generic.component.html',
  styleUrls: ['./dialog-expediente-nota-generic.component.css']
})
export class DialogExpedienteNotaGenericComponent {

  action: string;
  local_data: any;
  form: any;
  formBuilder: FormBuilder = new FormBuilder;
  editor!: Editor
  get f() { return this.form.controls; }

  constructor(
    private loginService: LoginService,
    public dialogRef: MatDialogRef<DialogExpedienteNotaGenericComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private expedienteNotaService: ExpedienteNotaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {
    this.local_data = { ...data };
    this.action = this.local_data.action;

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
    })

    this.editor = new Editor();

    if (this.action != AccionesDialog.Agregar) {
      this.form.controls["Descripcion"].setValue(this.local_data.descripcion)
    }
  }

  doAction() {
    switch (this.action) {
      case AccionesDialog.Agregar:
        this.create()
        break;
      case AccionesDialog.Modificar:
        this.update()
        break;
      case AccionesDialog.Eliminar:
        this.delete()
        break;
      default:
        break;
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  ngOnInit(): void {

  }
  ngOnDestroy(): void {
    this.editor.destroy();
  }

  create() {
    if (this.form.invalid) {
      return;
    }
    const createExpedienteNotaRequest: CreateExpedienteNotaRequest = new CreateExpedienteNotaRequest
    createExpedienteNotaRequest.descripcion = this.form.controls['Descripcion'].value == "" ? null : this.form.controls['Descripcion'].value;
    createExpedienteNotaRequest.idExpediente = this.data.idExpediente
    createExpedienteNotaRequest.idEmpresa = 1
    createExpedienteNotaRequest.idUsuario = this.loginService.getIdUser()
    this.spinner.show("spLoad")
    this.expedienteNotaService.create(createExpedienteNotaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spLoad")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        },
        error => {
          this.spinner.hide("spLoad")
          this.snackBar.showError(error, "Error");
        })
  }

  update() {
    if (this.form.invalid) {
      return;
    }
    const updateExpedienteNotaRequest: UpdateExpedienteNotaRequest = new UpdateExpedienteNotaRequest
    updateExpedienteNotaRequest.id = this.data.id
    updateExpedienteNotaRequest.descripcion = this.form.controls['Descripcion'].value == "" ? null : this.form.controls['Descripcion'].value;
    updateExpedienteNotaRequest.idExpediente = this.data.idExpediente
    updateExpedienteNotaRequest.idEmpresa = 1
    updateExpedienteNotaRequest.idUsuario = this.loginService.getIdUser()

    this.spinner.show("spLoad")
    this.expedienteNotaService.update(updateExpedienteNotaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spLoad")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        },
        error => {
          this.spinner.hide("spLoad")
          this.snackBar.showError(error, "Error");
        })
  }

  delete() {
    if (this.form.invalid) {
      return;
    }
    this.spinner.show("spLoad")
    this.expedienteNotaService.delete(this.local_data.id)
      .subscribe(
        data => {
          this.spinner.hide("spLoad")
          this.snackBar.showSuccess("Se elimino el registro correctamente.", "");
          this.dialogRef.close({ event: this.action, data: this.local_data });
        },
        error => {
          this.spinner.hide("spLoad")
          this.snackBar.showError(error, "Error");
        })
  }
}
