<div class="list-group" *appRole="['Personas.Ver']">
    <button type="button" [routerLinkActive]="['active']" routerLink='/personas/edit' class="list-group-item list-group-item-action">
<div class="with-icon">
  <mat-icon >person_pin</mat-icon><span class="aligned-with-icon"> Persona</span>
</div>
</button>

    <button type="button" *appRole="['Documentos.Ver']" [routerLinkActive]="['active']" routerLink='/documentospersona' class="list-group-item list-group-item-action">
      <div class="with-icon">
        <mat-icon>folder</mat-icon> <span class="aligned-with-icon">Documentos</span>
      </div>
  </button>
</div>