import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateExpedienteNotaRequest } from '@models/expedienteNota/createExpedienteNotaRequest.model';
import { ExpedienteNotaDto } from '@models/expedienteNota/expedienteNotaDto.model';
import { UpdateExpedienteNotaRequest } from '@models/expedienteNota/updateExpedienteNotaRequest.model';
import { ExpedienteParteDto } from '@models/expedienteParte/expedienteParteDto.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';

@Injectable({
  providedIn: 'root'
})
export class ExpedienteNotaService {

  constructor(
    private http:HttpClient
  ) { }

  create(createExpedienteNotaRequest:CreateExpedienteNotaRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/ExpedienteNota/Create",createExpedienteNotaRequest);
  }

  update(updateExpedienteNotaRequest:UpdateExpedienteNotaRequest):Observable<any>
  {
    return  this.http.put<any>("/api/ExpedienteNota/Update",updateExpedienteNotaRequest);
  }

  getAll():Observable<ExpedienteNotaDto[]> {
    return   this.http.get<ExpedienteNotaDto[]>("/api/ExpedienteNota/GetAll");
  }

  getById(id:number):Observable<ExpedienteParteDto>{
    return  this.http.get<ExpedienteParteDto>("/api/ExpedienteParte/GetById?id="+id);
  }

  getAllByIdExpediente(idExpediente:number):Observable<ExpedienteNotaDto[]>{
    return  this.http.get<ExpedienteNotaDto[]>("/api/ExpedienteNota/GetAllByIdExpediente?idExpediente="+idExpediente);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/ExpedienteNota/Delete?id=" + id);
    else
      return of(null)
  }
}
