import { UpdateKeyValueRequest } from "@models/base/updateKeyValueRequest.model"

export class UpdatePericiaRequest extends UpdateKeyValueRequest{

public notificacionRealizada!:	boolean
public lugar!:	string
public fechaRealizada!: Date | string
public fechaPropuesta!: Date | string
public fechaFinalizada!: Date | string
public fechaAlta!: Date
public idExpediente!: number
public idEstadoPericia!: number
public idTipoPericia!: number
public idEmpresa!: number
public activo!:boolean
}
