import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CasoUsoDto } from '@models/seguridad/casosUso/casoUsoDto.model';
import { MenuDto } from '@models/seguridad/menu/menuDto.model';
import { CasoUsoService } from '@services/casoUso/casoUso.service';
import { MenuService } from '@services/menu/menu.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogViewCasoUsoComponent } from './dialog-view-caso-uso/dialog-view-caso-uso.component';

@Component({
  selector: 'app-ver-caso-uso',
  templateUrl: './ver-caso-uso.component.html',
  styleUrls: ['./ver-caso-uso.component.css']
})
export class VerCasoUsoComponent implements OnInit {

  formBuilder = new FormBuilder;
  form: any;
  menus!: MenuDto[];
  casoUsoDto!:CasoUsoDto;
  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogViewCasoUsoComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private CasoUsoService: CasoUsoService,
    private menuService: MenuService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])],
      Icono: ["", Validators.compose([Validators.required,])],
      Path: ["", Validators.compose([Validators.required])],
      Menu: ["", Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    this.getAllMenus()
    this.getById(this.data.id)
    this.form.disable()
  }

  getAllMenus() {
    this.spinner.show("spBusquedaMenu")
    this.menuService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaMenu")
          this.menus = data
        },
        error => {
          this.spinner.hide("spBusquedaMenu")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  getById(id:number) {
    this.spinner.show("spModificarCasoUso")
    this.CasoUsoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spModificarCasoUso")
          this.casoUsoDto = data
          this.seteaForm(data)
        },
        error => {
          this.spinner.hide("spModificarCasoUso")
          this.snackBar.showError(error, "Error");
        }
      )
  }



  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }


  seteaForm(casoUsoDto: CasoUsoDto) {
    this.form.controls["Descripcion"].setValue(casoUsoDto.descripcion)
    this.form.controls["Icono"].setValue(casoUsoDto.icon).value.toLowerCase()
    this.form.controls["Path"].setValue(casoUsoDto.path).value.toLowerCase()
    this.form.controls["Menu"].setValue(casoUsoDto.menu.id)
    this.form.disable()
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
