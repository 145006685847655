import { UpdateKeyValueRequest } from "@models/base/updateKeyValueRequest.model"

export class UpdateMovimientoRequest extends UpdateKeyValueRequest {
  public idExpediente!: number

  public idUsuario!: number
  public idTipoMonedaOrigen!: number
  public idTipoMonedaLocal!:number
  public idEmpresa!: number
  public activo!: boolean
  public idTipoMovimiento!: number
  public importe!: number
  public fechaMovimiento!: Date
}
