import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CantidadPorEstadoDto } from '@models/base/cantidadPorEstadoDto.mode';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-expedientes',
  templateUrl: './dashboard-expedientes.component.html',
  styleUrls: ['./dashboard-expedientes.component.css']
})
export class DashboardExpedientesComponent {

  cardLayout:any

  formBuilder: FormBuilder = new FormBuilder;
  form!: FormGroup;
  cantidadPorEstadoDto: CantidadPorEstadoDto[] = [];
  cantidadPorEstadoPeritoDto: CantidadPorEstadoDto[] = [];
  cantidadPorTipoDto: CantidadPorEstadoDto[] = [];
  constructor(

    private expedienteService: ExpedienteService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
  ){}

  ngOnInit(): void {

      this.getCantidadPorEstadoPeritoDto()
      this.getCantidadPorEstadoDto()
      this.getCantidadPorTipoDto()
  }


  getCantidadPorEstadoPeritoDto() {
    this.spinner.show("spCantidadPorEstadoPerito")
    this.expedienteService.getCantidadPorEstadoPeritoDto().subscribe(
      data => {
        this.spinner.hide("spCantidadPorEstadoPerito")
        this.cantidadPorEstadoPeritoDto = data
      },
      error => {
        this.spinner.hide("spCantidadPorEstadoPerito")
        this.snackBar.showError(error, "Error");
      })
  }
  calcularCantidadTotalExpedientePorEstadoPerito(): number {
    let cantidadTotal = 0;
    for (const item of this.cantidadPorEstadoPeritoDto) {
      cantidadTotal += item.cantidad;
    }
    return cantidadTotal;
  }

  getCantidadPorEstadoDto() {
    this.spinner.show("spCantidadPorEstado")
    this.expedienteService.getCantidadPorEstadoDto().subscribe(
      data => {
        this.spinner.hide("spCantidadPorEstado")
        this.cantidadPorEstadoDto = data
      },
      error => {
        this.spinner.hide("spCantidadPorEstado")
        this.snackBar.showError(error, "Error");
      })
  }
  calcularCantidadTotalExpedientePorEstado(): number {
    let cantidadTotal = 0;
    for (const item of this.cantidadPorEstadoDto) {
      cantidadTotal += item.cantidad;
    }
    return cantidadTotal;
  }

  getCantidadPorTipoDto() {
    this.spinner.show("spCantidadPorTipo")
    this.expedienteService.getCantidadPorTipoDto().subscribe(
      data => {
        this.spinner.hide("spCantidadPorTipo")
        this.cantidadPorTipoDto = data
      },
      error => {
        this.spinner.hide("spCantidadPorTipo")
        this.snackBar.showError(error, "Error");
      })
  }
  calcularCantidadTotalPorTipo(): number {
    let cantidadTotal = 0;
    for (const item of this.cantidadPorTipoDto) {
      cantidadTotal += item.cantidad;
    }
    return cantidadTotal;
  }
}
