<mat-card>
    <mat-card-header>
        <div *appRole="['Tareas.Crear']">
            <button mat-raised-button color="primary" (click)="openDialogCreate()" #tooltip="matTooltip" matTooltip="Crear tarea">
        <mat-icon>add</mat-icon>
        Nueva tarea
      </button>
        </div>

        <br>
        <br>
    </mat-card-header>
    <mat-card-content>
        <div class="container-fluid">
            <mat-card *appRole="['Tareas.Listar']">
                <mat-card-header>
                    <form [formGroup]="formFilter">

                        <div class="row">
                            <div class="col">
                                <mat-form-field class="full-width-x100" appearance="outline">
                                    <mat-label>Titulo</mat-label>
                                    <input matInput type="text" maxlength="200" formControlName="Titulo" />
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="outline" class="full-width-x100">
                                    <mat-label>Estado Tarea </mat-label>
                                    <mat-select placeholder="Seleccione opcion" formControlName="EstadoTarea">
                                        <mat-option [value]="estado.id" *ngFor="let estado of lstEstados ;let i=index;trackBy:trackByItems">
                                            {{estado.descripcion}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-form-field appearance="outline" class="full-width-x100">
                                    <mat-label>Prioridad </mat-label>
                                    <mat-select placeholder="Seleccione opcion" formControlName="Prioridad">
                                        <mat-option [value]="prioridad.id" *ngFor="let prioridad of lstPrioridades ;let i=index;trackBy:trackByItems">
                                            {{prioridad.descripcion}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col" *ngIf="checkedFiltroAvanzado">
                                <mat-form-field appearance="outline">
                                    <mat-label>Rango de fecha</mat-label>
                                    <mat-date-range-input [rangePicker]="picker">
                                        <input matStartDate formControlName="FechaAltaDesde" placeholder="Fecha desde">
                                        <input matEndDate formControlName="FechaAltaHasta" placeholder="Fecha hasta">
                                    </mat-date-range-input>
                                    <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-date-range-picker #picker></mat-date-range-picker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <button mat-stroked-button color="primary" (click)="filtrar()">
                  <mat-icon>filter_alt</mat-icon>
                  Filtrar
                </button>
                            </div>
                            <div class="col-sm-12 col-md-6 col-lg-3">
                                <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
                                    Filtros Avanzados </mat-slide-toggle>
                            </div>
                        </div>
                    </form>
                    <br>
                    <br>
                </mat-card-header>

                <mat-card-content>
                    <ngx-spinner [zIndex]="100" name="spListado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
                    </ngx-spinner>
                    <br>
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <mat-form-field *ngIf=data appearance="outline" class="full-width-x100">
                                <mat-label>Buscar</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                            </mat-form-field>
                        </div>
                    </div>

                    <table mat-table [dataSource]="dataSource" *ngIf=data matSort matSortStart="asc">

                        <ng-container matColumnDef="titulo">
                            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Titulo</strong>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let tarea">
                                <span>{{tarea.titulo | titlecase}}</span>

                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="expediente">
                            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Expediente</strong>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let tarea">
                                <div *ngIf="tarea.expediente != null">
                                    <a [routerLink]="'/expedientes/'+tarea.expediente.id"><span>{{tarea.expediente == undefined ?
                      "":tarea.expediente.caratula | uppercase}}</span></a>
                                </div>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="fechaAlta">
                            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Alta</strong></mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let tarea">
                                <span>{{tarea.fechaAlta | date:'short'}}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="nivelAvance">
                            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header><strong>Nivel
                  Avance</strong></mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let tarea">
                                <span> {{tarea.nivelAvance== undefined ? "":tarea.nivelAvance == 1? "Baja" : tarea.nivelAvance ==2 ?
                  "Media": "Alta"}}</span>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="prioridad">
                            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Prioridad</strong>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let tarea">
                                <span> {{tarea.prioridad== undefined ? "":tarea.prioridad == 1? "Baja" : tarea.prioridad ==2 ? "Media":
                  "Alta"}}</span>

                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="estado">
                            <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Estado</strong>
                            </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let tarea">
                                <div *ngIf="tarea.estadoTarea != null">
                                    <span [ngClass]="tarea.estadoTarea.id == 1 ? 'estadoPendiente': tarea.estadoTarea.id == 2 ? 'estadoEnProceso' : tarea.estadoTarea.id == 3 ? 'estadoCompletada': ''">{{tarea.estadoTarea.descripcion
                    | titlecase}}</span>
                                </div>


                            </mat-cell>
                        </ng-container>


                        <!-- Accion Column -->
                        <ng-container matColumnDef="accion">
                            <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </mat-header-cell>
                            <mat-cell mat-cell *matCellDef="let tarea" class="action-link">
                                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
                  <mat-icon>more_vert</mat-icon>
                </button>
                                <mat-menu #menu="matMenu">
                                    <button mat-menu-item (click)="openDialogView(tarea)" *appRole="['Tareas.Ver']">
                    <mat-icon>visibility</mat-icon>
                    <span>Ver</span>
                  </button>
                                    <button mat-menu-item (click)="openDialogUpdate(tarea)" *appRole="['Tareas.Modificar']">
                    <mat-icon>edit</mat-icon>
                    <span>Editar</span>
                  </button>
                                    <button mat-menu-item (click)="openDialogDelete(tarea)" *appRole="['Tareas.Eliminar']">
                    <mat-icon>delete</mat-icon>
                    <span>Eliminar</span>
                  </button>
                                </mat-menu>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


                    </table>
                    <div class="no-data-table">
                        <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
              datos
              para mostrar.</span>
                    </div>
                    <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
                    </mat-paginator>
                </mat-card-content>

            </mat-card>
        </div>
    </mat-card-content>

</mat-card>
