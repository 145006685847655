import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";import { FormAudienciaCreateComponent } from "@views/audiencias/form-audiencia-create/form-audiencia-create.component";
import { FormAudienciaUpdateComponent } from "@views/audiencias/form-audiencia-update/form-audiencia-update.component";
import { FormAudienciaViewComponent } from "@views/audiencias/form-audiencia-view/form-audiencia-view.component";
import { ListAudienciaComponent } from "@views/audiencias/list-audiencia/list-audiencia.component";
import { ExpedienteModule } from "./expediente.module";
import { PersonaModule } from "./persona.module";
import { SharedModule } from "./shared.module";
import { BtnCreateAudienciaComponent } from "@views/audiencias/btn-create-audiencia/btn-create-audiencia.component";
import { DialogCreateAudienciaComponent } from "@views/audiencias/dialog-create-audiencia/dialog-create-audiencia.component";
import { BusquedasModule } from "./busquedas.module";

@NgModule({
  imports: [
      CommonModule,
      SharedModule,
      PersonaModule,
      BusquedasModule
  ],
  declarations: [
    FormAudienciaCreateComponent,
    FormAudienciaUpdateComponent,
    FormAudienciaViewComponent,
    ListAudienciaComponent,
    BtnCreateAudienciaComponent,
    DialogCreateAudienciaComponent
  ],

  providers: [

  ],
  exports: [
    FormAudienciaCreateComponent,
    FormAudienciaUpdateComponent,
    FormAudienciaViewComponent,
    ListAudienciaComponent,
    BtnCreateAudienciaComponent,
    DialogCreateAudienciaComponent
  ],
})
export class AudienciaModule { }
