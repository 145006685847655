import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EntidadDto } from '@models/base/entidadDto.model';
import {PersonaService} from "@services/persona/persona.service"



@Component({
  selector: 'app-documentos',
  templateUrl: './documentos.component.html',
  styleUrls: ['./documentos.component.css']
})
export class DocumentosComponent implements OnInit {
  idPersona: string|null="";
  subMenuActive:boolean=false;
  @Input() entidad!: EntidadDto
  constructor(
    private PersonaService:PersonaService,
    private aRoute: ActivatedRoute,
    private router: Router,
  ) {

   }

  ngOnInit(): void {
    // this.entidad.idEntidad = 5
    // this.entidad.idTipoEntidad=7
    this.idPersona = this.aRoute.snapshot.paramMap.get('id');
    if (this.idPersona !="" || this.idPersona != null){
      this.subMenuActive=true;
    }
  }
  goToFormPersona(){
    this.router.navigate(["/personas",this.idPersona])
  }
  goToFormPropietario(){
    this.router.navigate(["/propietario",this.idPersona])
  }
  goToFormGarantia(){
    this.router.navigate(["/garantia",this.idPersona])
  }
  goToFormContrato(){
    this.router.navigate(["/contrato",this.idPersona])
  }
  goToFormInquilino(){
    this.router.navigate(["/inquilino",this.idPersona])
  }
  goToFormDocumento(){
    this.router.navigate(["/documentos-personas",this.idPersona])
  }

}
