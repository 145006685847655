<form [formGroup]="FormGroup">
    <mat-vertical-stepper #stepper>
        <mat-step [editable]="isEditable">
            <ng-template matStepLabel>Ingrese su Usuario</ng-template>
            <mat-form-field appearance="outline">
                <mat-label>Usuario</mat-label>
                <input matInput placeholder="Nombre de usuario" formControlName="Usuario">
                <!-- <mat-error *ngIf="p.Usuario.errors?.['required']">Ingrese nombre de usuario.</mat-error> -->
            </mat-form-field>
            <div>
                <button mat-raised-button color="primary" matStepperNext>Siguiente</button>
            </div>
        </mat-step>
        <mat-step>
            <ng-template matStepLabel>Recuperación</ng-template>
            <div>
                <br>
                <button mat-raised-button color="primary" type="submit" [disabled]="!FormGroup.valid" name="Ingresar" (click)="recoverPassword()" color="primary" value="Recuperar">
                  <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Recupear
                </button>
                <button mat-button (click)="stepper.reset()" (click)="resetForm()">Volver</button>
            </div>
            <br>
            <mat-icon *ngIf="mensaje">check_circle_outline</mat-icon>
            <p *ngIf="mensaje">{{mensajeDescripcion}}</p>
        </mat-step>

    </mat-vertical-stepper>
    <br>
    <br>
</form>
