import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CreateArchivoRequest } from '@models/archivo/createArchivoRequest.model';
import { GetFileContentByIdArchivoRequest } from '@models/archivo/getFileContentByIdArchivoRequest.model';
import { UpdateArchivoRequest } from '@models/archivo/updateArchivoRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { isFalsy } from 'utility-types'
import { ArchivoDto } from '@models/archivo/archivoDto.model';

@Injectable({
  providedIn: 'root'
})
export class ArchivoService {

  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient
  ) { }



  create(createArchivoRequest: CreateArchivoRequest): Observable<CreateResponseBase> {

    return this.http.post<CreateResponseBase>("/api/Archivo/Create", createArchivoRequest);
  }

  update(updateArchivoRequest: UpdateArchivoRequest): Observable<any> {
    return this.http.put<any>("/api/Archivo/Update", updateArchivoRequest);
  }

  getById(id: number): Observable<ArchivoDto> {
    return this.http.get<ArchivoDto>("/api/Archivo/GetById?id=" + id);
  }

  getAll(): Observable<ArchivoDto[]> {
    return this.http.get<ArchivoDto[]>("/api/Archivo/GetAll");
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Archivo/Delete?id=" + id);
    else
      return of(null)
  }

  getFileContentByIdArchivo(id: number): Observable<GetFileContentByIdArchivoRequest> {
    return this.http.get<GetFileContentByIdArchivoRequest>("/api/Archivo/getFileContentByIdArchivo?id=" + id);
  }
}
