import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreateKeyValueRequest } from '@models/base/createKeyValueRequest.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { UpdateKeyValueRequest } from '@models/base/updateKeyValueRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoMovimientoService } from '@services/tipoMovimiento/tipoMovimiento.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-tipo-movimiento-generic',
  templateUrl: './dialog-tipo-movimiento-generic.component.html',
  styleUrls: ['./dialog-tipo-movimiento-generic.component.css']
})
export class DialogTipoMovimientoGenericComponent {


  local_data: any;
  form: any;
  formBuilder: FormBuilder = new FormBuilder;
  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogTipoMovimientoGenericComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private tipoMovimientoService: TipoMovimientoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {
    this.local_data = { ...data };

    this.form = this.formBuilder.group({
      Descripcion: ['', Validators.compose([Validators.required, Validators.maxLength(200)])],
    })

    if (this.local_data.action == AccionesDialog.Eliminar) {
      this.form.controls["Descripcion"].setValue(this.local_data.descripcion)
    }
  }

  ngOnInit(): void {
    if (this.local_data.action == AccionesDialog.Modificar || this.local_data.action == AccionesDialog.Ver)
      this.completaFormulario()
  }
  completaFormulario() {
    this.form.controls['Descripcion'].value = this.local_data.descripcion
    this.form.controls['TipoMovimiento'].value = this.local_data.tipoMovimiento.id
    this.form.controls['Importe'].value = this.local_data.importe
    this.form.controls['FechaMovimiento'].value = this.local_data.fechaMovimiento
    if (this.local_data.importe < 0)
      this.form.controls['EgresoIngreso'].value = "-1"
  }
  doAction() {
    switch (this.local_data.action) {
      case AccionesDialog.Agregar:
        this.create()
        break;
      case AccionesDialog.Modificar:
        this.update()
        break;
      case AccionesDialog.Eliminar:
        this.delete()
        break;
      default:
        break;
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }


  create() {
    if (this.form.invalid) {
      return;
    }
    const createKeyValueRequest: CreateKeyValueRequest = new CreateKeyValueRequest
    createKeyValueRequest.descripcion = isFalsy(this.form.controls['Descripcion'].value) ? null : this.form.controls['Descripcion'].value;
    this.spinner.show("spLoadGeneric")

    this.tipoMovimientoService.create(createKeyValueRequest)
      .subscribe(
        data => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "Exito");
          this.dialogRef.close({ data: this.local_data });
        },
        error => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showError(error, "Error");
        })
  }

  update() {
    if (this.form.invalid) {
      return;
    }
    const updateKeyValueRequest: UpdateKeyValueRequest = new UpdateKeyValueRequest
    updateKeyValueRequest.id = this.local_data.id
    updateKeyValueRequest.descripcion = isFalsy(this.form.controls['Descripcion'].value) ? null : this.form.controls['Descripcion'].value;

    this.spinner.show("spLoadGeneric")

    this.tipoMovimientoService.update(updateKeyValueRequest)
      .subscribe(
        data => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "Exito");
          this.dialogRef.close({ data: this.local_data });
        },
        error => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showError(error, "Error");
        })
  }

  delete() {
    if (this.form.invalid) {
      return;
    }
    this.spinner.show("spLoadGeneric")
    this.tipoMovimientoService.delete(this.local_data.id)
      .subscribe(
        data => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showSuccess("Se elimino el registro correctamente.", "");
          this.dialogRef.close({ data: this.local_data });
        },
        error => {
          this.spinner.hide("spLoadGeneric")
          this.snackBar.showError(error, "Error");
        })
  }
}
