import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { BaseDto } from '@models/base/baseDto.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { isFalsy } from 'utility-types';
import { ExpedienteSummaryDto } from '@models/expediente/expedienteSummary.model';

@Component({
  selector: 'app-buscar-expediente',
  templateUrl: './buscar-expediente.component.html',
  styleUrls: ['./buscar-expediente.component.css']
})

export class BuscarExpedienteComponent implements OnInit {
  @Input() disable: boolean = false
  entitySelect!: number | null;
  @Input() idExpedienteInput: number = 0;
  @ViewChild('typehead', { read: MatAutocompleteTrigger }) autoTrigger!: MatAutocompleteTrigger;
  expedientesSummaryDto!: ExpedienteSummaryDto[];
  @Input() expedienteEntrada: ExpedienteDto = new ExpedienteDto;
  @Output() eventEntity = new EventEmitter<ExpedienteDto>();
  expedienteDto!: ExpedienteDto;
  public entityControl = new FormControl();

  public filteredExpedientes!: Observable<ExpedienteSummaryDto[]>;

  constructor(
    private ExpedienteService: ExpedienteService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {

    this.getAllExpedienteSummary()

    if (this.idExpedienteInput && this.idExpedienteInput > 0) {
      this.getExpedienteById(this.idExpedienteInput)
      this.entityControl.setValue(this.expedienteEntrada.caratula)
    }
    this.filteredExpedientes = this.entityControl.valueChanges.pipe(
      startWith(''),
      map((term: any) => this.findOption(term))
    );

  }


  ngOnChanges(): void {
    if (this.idExpedienteInput != 0)
      this.getExpedienteById(this.idExpedienteInput)
    this.entityControl.setValue(this.expedienteEntrada.caratula)
  }


  selectValue(event: any) {
    if (isFalsy(event)) {
      this.entitySelect = null
      this.entityControl.setValue("")
    }
    this.eventEntity.emit(event)
  }


  findOption(val: string) {
    if (this.expedientesSummaryDto)
      for (let i = 0; i < this.expedientesSummaryDto.length; i++) {
        if (this.expedientesSummaryDto[i].descripcion.toUpperCase() == val.toUpperCase()) {
          if (this.expedientesSummaryDto[i].id != 0)
            this.getExpedienteById(this.expedientesSummaryDto[i].id)
        }
      }
    if (this.expedientesSummaryDto)
      return this.expedientesSummaryDto.filter((expediente) => new RegExp(val, 'gi').test(expediente.descripcion));
    else
      return []
  }



  getExpedienteById(id: any) {
    this.spinner.show("spBusqueda");
    this.ExpedienteService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda");
          this.expedienteEntrada = data
          this.eventEntity.emit(data)
          this.expedienteDto = data
        },
        error => {
          this.spinner.hide("spBusqueda");
          this.snackBar.showError(error, "Error");
        });
  }


  getAllExpedienteSummary() {
    this.spinner.show("spBusqueda");
    this.ExpedienteService.getAllExpedienteSummary()
      .subscribe(
        data => {
          this.spinner.hide("spBusqueda");
          this.expedientesSummaryDto = data
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spBusqueda");
        });
  }


  trackByItems(index: number, item: any): any { return item; }
}
