import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { DeletePersonaRequest } from '@models/persona/deletePersonaRequest.model';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { PersonaService } from '@services/persona/persona.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-dialog-delete-persona',
  templateUrl: './dialog-delete-persona.component.html',
  styleUrls: ['./dialog-delete-persona.component.css']
})
export class DialogDeletePersonaComponent implements OnInit {

  constructor(
    @Inject(MatDialogRef) public dialogRef: MatDialogRef<DialogDeletePersonaComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PersonaDetailDto,
    private PersonaService: PersonaService,
    public dialog: MatDialog,
    private snackBar: SnackBarService,

  ) { }

  ngOnInit(): void {
  }

  eliminar(): void {
    this.PersonaService.delete(this.data.id)
      .subscribe(data => {
        if (data = true) {
          this.snackBar.showSuccess("Se elimino el registro correctamente.", "Exito")
          this.dialogRef.close({event:AccionesDialog.Eliminar});
        }
      },
        (error: any) => {
          this.snackBar.showError( error, "Error");
          this.dialogRef.close({event:AccionesDialog.Cancelar});
        }
      )
  }

  onNoClick(): void {
    this.dialogRef.close({event:AccionesDialog.Cancelar});
  }

}
