import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CasoUsoDto } from '@models/seguridad/casosUso/casoUsoDto.model';
import { PermisoDto } from '@models/seguridad/permisos/permisoDto.model';
import { RolDto } from '@models/seguridad/roles/rolDto.model';
import { UpdateRolRequest } from '@models/seguridad/roles/updateRolRequest.model';
import { RolService } from '@services/rol/rol.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogUpdateRolComponent } from './dialog-update-rol/dialog-update-rol.component';

@Component({
  selector: 'app-actualizar-rol',
  templateUrl: './actualizar-rol.component.html',
  styleUrls: ['./actualizar-rol.component.css']
})
export class ActualizarRolComponent implements OnInit {

  formBuilder = new FormBuilder;
  form: any;
  permisoDto!: PermisoDto;
  roles!: RolDto[]
  rolDto!: RolDto
  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogUpdateRolComponent>,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private RolService: RolService,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    this.form = this.formBuilder.group({
      Descripcion: ["", Validators.compose([Validators.required,])]
    })
  }

  ngOnInit(): void {
    this.getById(this.data.id)
  }

  getById(id: number) {
    this.spinner.show("spModificarRol")
    this.RolService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spModificarRol")
          this.seteaForm(data)
        },
        error => {
          this.spinner.hide("spModificarRol")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  doAction() {

    let updateRolRequest: UpdateRolRequest = new UpdateRolRequest
    updateRolRequest.id = this.rolDto.id
    updateRolRequest.descripcion = this.form.controls["Descripcion"].value
    this.update(updateRolRequest)
  }

  update(updateRolRequest: UpdateRolRequest) {
    this.spinner.show("spModificarRol")
    this.RolService.update(updateRolRequest)
      .subscribe(
        data => {
          this.spinner.hide("spModificarRol")
          this.snackBar.showSuccess("Registro modificado correctamente.", "Exito");
          this.dialogRef.close({ event: AccionesDialog.Modificar });
        },
        error => {
          this.spinner.hide("spModificarRol")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  closeDialog() {
    this.dialogRef.close({ event: AccionesDialog.Cancelar });
  }

  seteaForm(rolDto: RolDto) {
    this.rolDto = rolDto
    this.form.controls["Descripcion"].setValue(rolDto.descripcion)
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

}
