import { Component, Inject, Optional, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSelectionList, MatSelectionListChange } from '@angular/material/list';
import { CreateCedulaNotificacionResponse } from '@models/documento/createCedulaNotificacionResponse.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog-acciones-documento',
  templateUrl: './dialog-acciones-documento.component.html',
  styleUrls: ['./dialog-acciones-documento.component.css']
})
export class DialogAccionesDocumentoComponent {
  data_local!: CreateCedulaNotificacionResponse[];
  archivosSeleccionados: any;
  constructor(
    public dialogRef: MatDialogRef<DialogAccionesDocumentoComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
  ) {
  }

  ngOnInit(): void {
  }

  onSelectionChange(event: MatSelectionListChange) {
    this.archivosSeleccionados = event.source.selectedOptions.selected.map(option => option.value);
  }

  downloadFiles(){
    this.archivosSeleccionados.forEach((file: { fileContent: string; fileName: string; }) => {
      this.downloadFile(file.fileContent, file.fileName)
    });
  }

  downloadFile(fileContent:string, fileName:string): void {
    if (fileContent && fileName) {
      const byteCharacters = atob(fileContent);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'application/pdf' });

      const a = document.createElement('a');
      a.href = window.URL.createObjectURL(blob);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

}
