import { BreakpointObserver } from '@angular/cdk/layout';
import { Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Helper } from '@helpers/helper';
import { AudienciaDto } from '@models/audiencia/audienciaDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateCedulaNotificacionRequest } from '@models/documento/createCedulaNotificacionRequest.model';
import { DeleteExpedienteRequest } from '@models/expediente/deleteExpedienteRequest.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { PericiaDto } from '@models/pericia/periciaDto.model';
import { AudienciaService } from '@services/audiencia/audiencia.service';
import { DocumentoService } from '@services/documento/documento.service';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { PericiaService } from '@services/pericia/pericia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogCreateAudienciaComponent } from '@views/audiencias/dialog-create-audiencia/dialog-create-audiencia.component';
import { DialogAccionesDocumentoComponent } from '@views/documentos/dialog-acciones-documento/dialog-acciones-documento.component';
import { DialogCreateCedulaNotificacionComponent } from '@views/documentos/generarArchivos/dialog-create-cedula-notificacion/dialog-create-cedula-notificacion.component';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { DialogCreatePericiaComponent } from '@views/pericias/dialog-create-pericia/dialog-create-pericia.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-form-expediente-view',
  templateUrl: './form-expediente-view.component.html',
  styleUrls: ['./form-expediente-view.component.css'],

})
export class FormExpedienteViewComponent {
  expedienteDto!: ExpedienteDto;
  audiencias!: AudienciaDto[];
  pericias!: PericiaDto[];

  dataSourcePericias = new MatTableDataSource<PericiaDto>();
  displayedColumnsPericias: string[] = ['lugarPericia', 'fechaPropuesta', 'fechaRealiza', 'tipoPericia', 'estadoPericia', 'accion'];

  dataSourceAudiencia = new MatTableDataSource<AudienciaDto>();
  displayedColumnsAudiencias: string[] = ['lugar', 'sala', 'fecha', 'tipoAudiencia', 'accion'];
  idExpediente!: number;
  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private ExpedienteService: ExpedienteService,
    private snackBar: SnackBarService,
    private audienciaService: AudienciaService,
    private periciasService: PericiaService,
    private breakpointObserver: BreakpointObserver,
    private documentoService: DocumentoService

  ) {

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsAudiencias = result.matches ?
        ['lugar', 'fecha', 'accion'] :
        ['lugar', 'sala', 'fecha', 'tipoAudiencia', 'accion'];
    });

    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumnsPericias = result.matches ?
        ['lugarPericia', 'fechaPropuesta', 'accion'] :
        ['lugarPericia', 'fechaPropuesta', 'fechaRealiza', 'tipoPericia', 'estadoPericia', 'accion'];
    });
  }

  ngOnInit(): void {
    this.idExpediente = this.activatedRoute.snapshot.params['id']
    this.getExpedienteById(this.idExpediente)
    this.getAudienciaByIdExpediente(this.idExpediente)
    this.getPericiasByIdExpediente(this.idExpediente)
  }
  getExpedienteById(id: number) {
    this.spinner.show("spExpediente")
    this.ExpedienteService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spExpediente")
          this.expedienteDto = data
          this.ExpedienteService.setExpedienteDto(data)
        },
        error => {
          this.spinner.hide("spExpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  getAudienciaByIdExpediente(id: number) {
    this.spinner.show("spAudiencias")
    this.audienciaService.getAllByIdExpediente(id)
      .subscribe(
        data => {
          this.spinner.hide("spAudiencias")
          this.audiencias = data
          this.dataSourceAudiencia.data = data
        },
        error => {
          this.spinner.hide("spEspAudienciasxpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  getPericiasByIdExpediente(id: number) {
    this.spinner.show("spPericias")
    this.periciasService.getAllByIdExpediente(id)
      .subscribe(
        data => {
          this.spinner.hide("spPericias")
          this.pericias = data
          this.dataSourcePericias.data = data
        },
        error => {
          this.spinner.hide("spPericias")
          this.snackBar.showError(error, "Error");
        })
  }

  eliminarPericia(id: number) {
    this.spinner.show("spPericias")
    this.periciasService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide("spPericias")
          this.pericias = data
          this.dataSourcePericias.data = data
          this.getPericiasByIdExpediente(this.expedienteDto.id);
        },
        error => {
          this.spinner.hide("spPericias")
          this.snackBar.showError(error, "Error");
        })
  }


  onNuevaPericia(): void {
    const dialogRef = this.dialog.open(DialogCreatePericiaComponent, {
      data: { idExpediente: this.idExpediente },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.getPericiasByIdExpediente(this.idExpediente)
    });
  }

  onNuevaAudiencia(): void {
    const dialogRef = this.dialog.open(DialogCreateAudienciaComponent, {
      data: { idExpediente: this.idExpediente },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result)
        this.getAudienciaByIdExpediente(this.idExpediente)
    });
  }

  openDialogPericia(action: any, obj: any) {
    obj.action = action;
    obj.descripcion = ""
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.eliminarPericia(result.data)
      }
    });
  }

  openDialogAudiencia(action: any, obj: any) {
    obj.action = action;
    obj.descripcion = ""
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.eliminarAudiencia(result.data)
      }
    });
  }

  eliminarAudiencia(id: number) {
    this.spinner.show("spAudiencias")
    this.audienciaService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide("spAudiencias")
          this.audiencias = data
          this.dataSourceAudiencia.data = data
          this.getAudienciaByIdExpediente(this.expedienteDto.id);
        },
        error => {
          this.spinner.hide("spAudiencias")
          this.snackBar.showError(error, "Error");
        })
  }

  openDialogDelete(obj: any) {
    obj.descripcion = obj.caratula
    obj.id = obj.id
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      this.deleteRowData(result.data);
    });
  }

  deleteRowData(id: number) {
    let deleteExpedienteRequest = new DeleteExpedienteRequest
    deleteExpedienteRequest.id = id
    this.spinner.show('spExpediente')
    this.ExpedienteService.deleteExpediente(deleteExpedienteRequest)
      .subscribe(
        data => {
          this.spinner.hide('spExpediente')
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.router.navigate(["/expedientes"])
        },
        error => {
          this.spinner.hide('spExpediente')
          this.snackBar.showError(error, "Error");
        });
  }

  copiarAlPortapapeles(texto: string) {
    Helper.copyToClipboard(texto)
  }

  generarCedulaNotificacion(expedienteDto: ExpedienteDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = expedienteDto

    const dialogRef = this.dialog.open(DialogCreateCedulaNotificacionComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {

      const dialogConfig2 = new MatDialogConfig();
      dialogConfig2.autoFocus = false;
      dialogConfig2.data = result
      dialogConfig2.width= '500px'

      const dialogRef2 = this.dialog.open(DialogAccionesDocumentoComponent, dialogConfig2);

      dialogRef2.afterClosed().subscribe(result2 => {

        dialogRef.close()

      });
    });
  }
}
