import { CreateKeyValueRequest } from "@models/base/createKeyValueRequest.model"

export class CreatePericiaRequest extends CreateKeyValueRequest{
public notificacionRealizada!:	boolean
public lugar!:	string
public fechaRealizada!: Date
public fechaPropuesta!: Date
public fechaFinalizada!: Date
public fechaAlta!: Date
public idExpediente!: number
public idEstadoPericia!: number
public idTipoPericia!: number
public idEmpresa!: number
public activo!: boolean
}
