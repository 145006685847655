import { CommonModule } from "@angular/common";
import { CalendarioComponent } from "@views/calendario/calendario/calendario.component";
import { SharedModule } from "./shared.module";
import { NgModule } from "@angular/core";

@NgModule({
  imports: [
      CommonModule,
      SharedModule,
  ],
  declarations: [
    CalendarioComponent

  ],
  providers: [
  ],
  exports: [
    CalendarioComponent
  ]
})

export class CalendarioModule { }
