import { UpdateKeyValueRequest } from "@models/base/updateKeyValueRequest.model"

export class UpdateCasoRequest extends UpdateKeyValueRequest
{
  public caratula!: string
  public activo!: boolean
  public fechaComienzo!: Date
  public fechaFinalizacion!: Date
  public idEmpresa!: number
  public idTipoCaso!: number
  public idEstadoCaso!: number

}
