import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { Router } from '@angular/router';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
//VAlidaciones
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { isFalsy } from 'utility-types';
import { EstadoExpedientePeritoService } from '@services/estadoExpedientePerito/estadoExpedientePerito.service';
import { TipoExpedienteService } from '@services/tipoExpediente/tipoExpediente.service';
import { EstadoExpedienteService } from '@services/estadoExpediente/estadoExpediente.service';
import { CreateExpedienteRequest } from '@models/expediente/createExpedienteRequest.model';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { OrganismoService } from '@services/organismo/organismo.service';
import { OrganismoDto } from '@models/organismo/organismoDto.model';
import { EstadoProcesalService } from '@services/estadoProcesal/estado-procesal.service';
import { provideNgxMask } from 'ngx-mask';
import { EstadosExpediente, EstadosExpedientePerito, EstadosProcesal } from '@models/base/identificadores.model';

@Component({
  selector: 'app-form-expediente-create',
  templateUrl: './form-expediente-create.component.html',
  styleUrls: ['./form-expediente-create.component.css'],
})

export class FormExpedienteCreateComponent implements OnInit {
  formBuilder: FormBuilder = new FormBuilder;
  estadosExpedientes!: BaseDto[];
  estadosExpedientesPerito!: BaseDto[];
  expedienteForm!: FormGroup;
  tiposExpedientes!: BaseDto[];
  organismos!: OrganismoDto[];
  estadosProcesal!: BaseDto[];
  casoDto!: BaseDto;
  get f() { return this.expedienteForm.controls; }

  constructor(
    private ExpedienteService: ExpedienteService,
    private TipoExpedienteService: TipoExpedienteService,
    private EstadoExpedienteService: EstadoExpedienteService,
    private OrganismoService: OrganismoService,
    private router: Router,
    private snackBar: SnackBarService,
    private EstadoExpedientePeritoService: EstadoExpedientePeritoService,
    private spinner: NgxSpinnerService,
    private estadoProcesalService: EstadoProcesalService

  ) { }

  ngOnInit(): void {
    this.getAllEstadosExpediente();
    this.getAllEstadosExpedientePerito();
    this.getAllTiposExpedientes();
    this.getAllEstadosProcesal();
    this.getAllOrganismos();

    this.expedienteForm = this.formBuilder.group({
      Descripcion: [''],
      Ubicacion: [''],
      Caratula: ["", Validators.compose([Validators.required, Validators.maxLength(200)])],
      Cuij: ['', Validators.compose([Validators.required, , Validators.maxLength(50)])],
      Numero: [''],
      Demanda: [''],
      TipoExpediente: [''],
      EstadoProcesal: [''],
      Demandado: [''],
      Causa: [''],
      EstadoExpedientes: [''],
      EstadoExpedientesPerito: [''],
      Organismo: ['', Validators.compose([Validators.required])],
      ClaveSisfeExpediente: [''],
      Sufijo: [''],
      Bis: [''],
      FechaIngresoMEU: [''],
      MontoDemanda: [''],

    });
    this.expedienteForm.controls['EstadoExpedientes'].setValue(EstadosExpediente.EnTramite);
    this.expedienteForm.controls['EstadoExpedientesPerito'].setValue(EstadosExpedientePerito.SinVinculacion);
    this.expedienteForm.controls['EstadoProcesal'].setValue(EstadosProcesal.AdmisiónDemanda);
  }


  onSubmit() {
    if (this.expedienteForm.invalid) {
      return;
    }
    const createExpedienteRequest: CreateExpedienteRequest = this.setCreateExpedienteRequest();
    this.spinner.show("spExpediente")
    this.ExpedienteService.create(createExpedienteRequest)
      .subscribe(
        data => {
          this.spinner.hide("spExpediente")
          this.snackBar.showSuccess("Se agrego el registro correctamente.", "");
          this.router.navigate(["/expedientes/" + data.id])
        },
        error => {
          this.spinner.hide("spExpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadosExpediente() {
    this.spinner.show("spEstadosExpediente")
    this.EstadoExpedienteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spEstadosExpediente")
          this.estadosExpedientes = data
        },
        error => {
          this.spinner.hide("spEstadosExpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadosProcesal() {
    this.spinner.show("spEstadosProcesal")
    this.estadoProcesalService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spEstadosProcesal")
          this.estadosProcesal = data
        },
        error => {
          this.spinner.hide("spEstadosProcesal")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadosExpedientePerito() {
    this.spinner.show("spEstadosExpedientePerito")
    this.EstadoExpedientePeritoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spEstadosExpedientePerito")
          this.estadosExpedientesPerito = data
        },
        error => {
          this.spinner.hide("spEstadosExpedientePerito")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTiposExpedientes() {
    this.spinner.show("spTiposExpedientes")
    this.TipoExpedienteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTiposExpedientes")
          this.tiposExpedientes = data
        },
        error => {
          this.spinner.hide("spTiposExpedientes")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllOrganismos() {
    this.spinner.show("spOrganismos")
    this.OrganismoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spOrganismos")
          this.organismos = data
        },
        error => {
          this.spinner.hide("spOrganismos")
          this.snackBar.showError(error, "Error");
        })
  }


  setCreateExpedienteRequest(): CreateExpedienteRequest {

    const createExpedienteRequest = new CreateExpedienteRequest();

    createExpedienteRequest.descripcion = this.expedienteForm.controls['Descripcion'].value;
    createExpedienteRequest.ubicacion = this.expedienteForm.controls['Ubicacion'].value;
    createExpedienteRequest.caratula = this.expedienteForm.controls['Caratula'].value;
    createExpedienteRequest.cuij = this.expedienteForm.controls['Cuij'].value;
    createExpedienteRequest.numero = this.expedienteForm.controls['Numero'].value;
    createExpedienteRequest.demanda = this.expedienteForm.controls['Demanda'].value;
    createExpedienteRequest.idTipoExpediente = isFalsy(this.expedienteForm.controls['TipoExpediente'].value) ? null : this.expedienteForm.controls['TipoExpediente'].value;
    createExpedienteRequest.demandado = this.expedienteForm.controls['Demandado'].value;
    createExpedienteRequest.causa = this.expedienteForm.controls['Causa'].value;
    createExpedienteRequest.idEstadoExpediente = isFalsy(this.expedienteForm.controls['EstadoExpedientes'].value) ? null : this.expedienteForm.controls['EstadoExpedientes'].value;
    createExpedienteRequest.idEstadoExpedientePerito = isFalsy(this.expedienteForm.controls['EstadoExpedientesPerito'].value) ? null : this.expedienteForm.controls['EstadoExpedientesPerito'].value;
    createExpedienteRequest.idOrganismo = isFalsy(this.expedienteForm.controls['Organismo'].value) ? null : this.expedienteForm.controls['Organismo'].value;
    createExpedienteRequest.claveSisfeExpediente = this.expedienteForm.controls['ClaveSisfeExpediente'].value;
    createExpedienteRequest.sufijo = this.expedienteForm.controls['Sufijo'].value;
    createExpedienteRequest.bis = this.expedienteForm.controls['Bis'].value;
    createExpedienteRequest.fechaIngresoMEU = this.expedienteForm.controls['FechaIngresoMEU'].value;
    createExpedienteRequest.montoDemanda = isFalsy(this.expedienteForm.controls['MontoDemanda'].value) ? 0 : this.expedienteForm.controls['MontoDemanda'].value;
    createExpedienteRequest.idEstadoProcesal = isFalsy(this.expedienteForm.controls['EstadoProcesal'].value) ? null : this.expedienteForm.controls['EstadoProcesal'].value;
    createExpedienteRequest.idCaso = this.casoDto.id

    return createExpedienteRequest
  }

  onReset() {
    this.expedienteForm.reset();
  }


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

  selectCaso(casoDto: BaseDto) {
    this.casoDto = casoDto
    this.expedienteForm.controls['Caratula'].setValue(this.casoDto.descripcion);
    const cadena = this.casoDto.descripcion
    const cPos = cadena.indexOf("C/ ");
    const sPos = cadena.indexOf(" S/ ");

    const demanda = cadena.substring(0, cPos).trim();
    const demandado = cadena.substring(cPos + 3, sPos).trim();
    const causa = cadena.substring(sPos + 3).trim();
    this.expedienteForm.controls['Demanda'].setValue(demanda);
    this.expedienteForm.controls['Demandado'].setValue(demandado);
    this.expedienteForm.controls['Causa'].setValue(causa);
  }
}

