<h1 mat-dialog-title><strong>Generar cedula de notificación</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <ngx-spinner [zIndex]="100" name="spLoadGeneric" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
    </ngx-spinner>
    <form [formGroup]="form">
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12" >
            <mat-form-field appearance="outline" class="full-width-x100">
                <ngx-spinner [zIndex]="-1" name="spExpedienteParte" bdColor="rgba(255,255,255,0.8)" size="small" color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
                <mat-label>Partes</mat-label>
                <mat-select placeholder="Seleccione opcion" multiple formControlName="ExpedientesPartes">
                    <mat-option [value]="expedienteParte" *ngFor="let expedienteParte of expedientePartes ">
                        {{expedienteParte.persona.nombreCompleto}}</mat-option>

                </mat-select>
            </mat-form-field>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-12 col-lg-12">
          <ngx-editor-menu [editor]="editorDetalle"> </ngx-editor-menu>
                                        <ngx-editor [editor]="editorDetalle" formControlName="Detalle" [disabled]="false" [placeholder]="'Escriba aqui...'">
                                        </ngx-editor>
          </div>
        </div>
        </div>
    </form>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions align="end">
    <button mat-raised-button  color="primary" (click)="generarCedulaNotificacion()" [disabled]="!form.valid || enProceso">

      Generar</button>
</div>
