import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormGroup, FormBuilder,  Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ArchivoDto } from '@models/archivo/archivoDto.model';
import { GetFileContentByIdArchivoRequest } from '@models/archivo/getFileContentByIdArchivoRequest.model';
import { BaseDto } from '@models/base/baseDto.model';
import { EntidadDto } from '@models/base/entidadDto.model';
import { DocumentoDto } from '@models/documento/documentoDto.model';
import { UpdateDocumentoRequest } from '@models/documento/updateDocumentoRequest.model';
import { ArchivoService } from '@services/archivo/archivo.service';
import { AsuntoService } from '@services/asunto/asuntos.service';
import { DocumentoService } from '@services/documento/documento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { UtilidadesController } from '@controllers/utilidades.controller';

@Component({
  selector: 'app-editar-documento',
  templateUrl: './editar-documento.component.html',
  styleUrls: ['./editar-documento.component.css']
})
export class EditarDocumentoComponent implements OnInit {

  formDocuementos!: FormGroup;
  formBuilderDocumentos!: FormBuilder;
  lstAsuntos: BaseDto[] = [];
  fileError: string = "";
  imageBaseData: string = "";
  theFile: any;
  fileUploadState: boolean = false
  fileImage: boolean = false;
  updateDocumentoRequest: UpdateDocumentoRequest = new UpdateDocumentoRequest
  idDocumento!: number;
  documento!: DocumentoDto
  archivoActual!: ArchivoDto
  formBuilder: FormBuilder = new FormBuilder;
  get f() { return this.formDocuementos.controls; }
  spinnerFile: string = "spinnerFile"
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  lstEtiquetas: String[] = [
  ];
  //Fin Etiqueta

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: EntidadDto,
    private spinner: NgxSpinnerService,
    private documentoService: DocumentoService,
    private AsuntoService: AsuntoService,
    private snackBar: SnackBarService,
    private router: Router,
    private aRoute: ActivatedRoute,
    private ArchivoService: ArchivoService
  ) {

    this.formDocuementos = this.formBuilder.group({
      Asuntos: ['', Validators.compose([Validators.required])],
      NombreDocumento: ['', Validators.compose([Validators.required])],
      Descripcion: ['', Validators.compose([Validators.maxLength(200)])],
      Etiquetas: [''],
      FileUpload: ['', Validators.compose([Validators.required])],
    })
  }


  ngOnInit() {

    const routeParams = this.aRoute.snapshot.paramMap;
    this.idDocumento = Number(routeParams.get('id'));

    this.getDocumentoById(this.idDocumento)

  }

  seleccionArchivo(archivo: ArchivoDto) {
    if (archivo.content != "") {
      this.updateDocumentoRequest.fileContent = archivo.content
      this.updateDocumentoRequest.fileName = archivo.descripcion
      this.updateDocumentoRequest.fileType = archivo.contentType
      this.formDocuementos.controls["FileUpload"].setValue("Archivo Cargado")
    }
  }

  getDocumentoById(idDocumento: number) {
    this.spinner.show()
    this.documentoService.getById(idDocumento)
      .subscribe(
        async data => {
          this.spinner.hide()
          this.documento = data
          this.setDocumentoForm(data)
          await this.getAllAsuntos()
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  setDocumentoForm(documento: DocumentoDto) {

    this.updateDocumentoRequest.idDocumento = documento.id
    this.updateDocumentoRequest.nombre = documento.nombre
    this.updateDocumentoRequest.descripcion = documento.descripcion
    this.updateDocumentoRequest.idAsunto = documento.asunto.id
    this.updateDocumentoRequest.etiquetas = documento.etiquetas

    this.formDocuementos.controls["Asuntos"].setValue(documento.asunto.id)
    this.formDocuementos.controls["Descripcion"].setValue(documento.descripcion)
    this.formDocuementos.controls["NombreDocumento"].setValue(documento.nombre)
    this.formDocuementos.controls["FileUpload"].setValue("Archivo Cargado")
    this.lstEtiquetas = UtilidadesController.dividirCadena(documento.etiquetas, ",")
    this.getArchivoById(documento.archivo.id)

  }

  getArchivoById(id: number) {
    this.spinner.show("spinnerFile")
    this.ArchivoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spinnerFile")
          this.archivoActual = data
          this.updateDocumentoRequest.archivo = data
          this.updateDocumentoRequest.idFile = data.id
          this.updateDocumentoRequest.fileContent = data.content
          this.updateDocumentoRequest.fileName = data.descripcion
          this.updateDocumentoRequest.fileType = data.contentType
        },
        error => {
          this.spinner.hide("spinnerFile")
          this.snackBar.showError(error, "Error");
        }
      )
  }

  selectAsunto(event: any) { }

  descargarArchivo(archivo: ArchivoDto) {
    this.spinner.show("spinnerFile")
    this.ArchivoService.getFileContentByIdArchivo(archivo.id).subscribe
      (
        data => {
          this.spinner.hide("spinnerFile")
          this.downloadFile(data, archivo)
        },
        error => {
          this.spinner.hide("spinnerFile");
          this.snackBar.showError(error, "Error");
        }
      )

  }

  downloadFile(getFileContentByIdArchivoRequest: GetFileContentByIdArchivoRequest, archivo: ArchivoDto) {
    const link = document.createElement("a");
    let tipoArchivo: string = ""
    link.href = getFileContentByIdArchivoRequest.content;
    link.download = archivo.descripcion.trim()
    link.click();
  }

  guardarDocumento() {
    this.setUpdateDocumentoRequest()
    this.updateDocumento(this.updateDocumentoRequest)
  }

  setUpdateDocumentoRequest() {
    let idAsunto = this.formDocuementos.controls["Asuntos"].value
    let nombreDocumento = this.formDocuementos.controls["NombreDocumento"].value
    let descripcion = this.formDocuementos.controls["Descripcion"].value
    let etiquetas = UtilidadesController.unirCadena(this.lstEtiquetas)

    this.updateDocumentoRequest.idAsunto = idAsunto
    this.updateDocumentoRequest.descripcion = descripcion
    this.updateDocumentoRequest.etiquetas = etiquetas
    this.updateDocumentoRequest.nombre = nombreDocumento
  }


  getAllAsuntos() {
    this.AsuntoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide()
          this.lstAsuntos = data
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  updateDocumento(updateDocumentoRequest: UpdateDocumentoRequest) {
    this.spinner.show()
    this.documentoService.update(updateDocumentoRequest)
      .subscribe(
        data => {
          this.spinner.hide()
          this.snackBar.showError("Documento actualizado correctamente.", "Exito");
          this.router.navigate(["/documentos"])
        },
        error => {
          this.spinner.hide()
          this.snackBar.showError(error, "Error");
        }
      )
  }

  //Etiquetas
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    if (value) {
      this.lstEtiquetas.push(value);
    }

    this.formDocuementos.controls["Etiquetas"].setValue("")
  }

  remove(etiqueta: String): void {
    const index = this.lstEtiquetas.indexOf(etiqueta);

    if (index >= 0) {
      this.lstEtiquetas.splice(index, 1);
    }
  }

  trackByItems(index: number, item: any): any { return item; }

}
