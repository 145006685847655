import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { PersonaSummaryDto } from '@models/persona/personaSummaryDto.mode';
import { PersonaService } from '@services/persona/persona.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { AccionesDialog } from '@models/base/identificadores.model';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogDeletePersonaComponent } from '../dialog-delete-persona/dialog-delete-persona.component';
import { FormPersonaReactiveComponent } from '../form-persona-reactive/form-persona-reactive.component';
import { PersonaDto } from '@models/persona/personaDto.model';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { PersonaFilter } from '@models/persona/personaFilter.model';
import { TipoDocumentoService } from '@services/tipoDocumento/tipoDocumento.service';
import { TipoDocumentoDto } from '@models/tipoDocumento/tipoDocumentoDto.model';

@Component({
  selector: 'app-listado-personas',
  templateUrl: './listado-personas.component.html',
  styleUrls: ['./listado-personas.component.css']
})
export class ListadoPersonasComponent implements OnInit {
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<PersonaDto>()
  lstPersonaDto!: PersonaDto[]
  formBuilder: FormBuilder = new FormBuilder;
  form: FormGroup;
  lstTiposDocumento!: TipoDocumentoDto[];
  constructor(
    private personaService: PersonaService,
    private tipoDocumentoService: TipoDocumentoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["nombreCompleto", "accion"] :
        ["nombreCompleto", "documento", "correo", "estado", "accion"];
    });

    this.form = this.formBuilder.group({
      Activo: [true],
      Nombre: [''],
      Apellido: [''],
      NroDocumento: [''],
      TipoDocumento: [''],
      Correo: [''],
    })
  }


  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }

  ngOnInit(): void {
    this.getAllTiposDocumento();
  }

  applyFilter(event: any) {
    if (event) {
      const filterValue = (event.target as HTMLInputElement).value;
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }


  getAllPersonas() {
    this.spinner.show('spListado')
    this.personaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spListado');
          this.dataSource.data = data.sort((a, b) => a.nombreCompleto.toString().localeCompare(b.nombreCompleto.toString())) as PersonaDto[]
          this.lstPersonaDto = this.dataSource.data
          this.data = this.dataSource.data.length >= 1 ? true : false

          let estado = this.form.controls["TiposEstado"].value
          this.filterTiposEstado(estado)
        },
        error => {
          this.spinner.hide('spListado');
          this.snackBar.showError(error, "Error");
        })
  }

  getAllPersonasByFilter(personaFilter:PersonaFilter) {
    this.spinner.show('spListado')
    this.personaService.getAllPersonasByFilter(personaFilter)
      .subscribe(
        data => {
          this.spinner.hide('spListado');
          this.dataSource.data = data.sort((a, b) => a.nombreCompleto.toString().localeCompare(b.nombreCompleto.toString())) as PersonaDto[]
          this.lstPersonaDto = this.dataSource.data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado');
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTiposDocumento() {
    this.spinner.show('spBusquedaTipoDoc')
    this.tipoDocumentoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spBusquedaTipoDoc');
          this.lstTiposDocumento = data
        },
        error => {
          this.spinner.hide('spBusquedaTipoDoc');
          this.snackBar.showError(error, "Error");
        })
  }

  filterTiposEstado(event: string) {
    // this.dataSource.data = this.personas
    switch (event) {
      case "Todos":
        break;
      case "Activos":
        this.dataSource.data = this.dataSource.data.filter(x => x.activo == true)
        break;
      case "Baja":
        this.dataSource.data = this.dataSource.data.filter(x => x.activo == false)
        break;
      default:
        break;
    }
  }

  filtrar() {
    let personaFilter = new PersonaFilter
    personaFilter.activo = this.form.controls["Activo"].value
    personaFilter.nombre = this.form.controls["Nombre"].value
    personaFilter.apellido = this.form.controls["Apellido"].value
    personaFilter.correo = this.form.controls["Correo"].value
    personaFilter.idTipoDocumento = this.form.controls["TipoDocumento"].value
    personaFilter.nroDocumento = this.form.controls["NroDocumento"].value
    this.getAllPersonasByFilter(personaFilter)
  }

  editarPersona(personaDto: PersonaDto) {
    this.router.navigate(["/personas/edit/" + personaDto.id])
  }

  openDialog(personaDto: PersonaDto): void {
    let personaDetailDto = new PersonaDetailDto
    personaDetailDto.id = personaDto.id
    personaDetailDto.nombre = personaDto.nombre
    personaDetailDto.apellido = personaDto.apellido
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = personaDetailDto

    const dialogRef = this.dialog.open(DialogDeletePersonaComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.filtrar()
      }
    });
  }

  reactivar(personaDto: PersonaDto): void {
    let personaDetailDto = new PersonaDetailDto
    personaDetailDto.id = personaDto.id
    personaDetailDto.nombre = personaDto.nombre
    personaDetailDto.apellido = personaDto.apellido
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.data = personaDetailDto

    const dialogRef = this.dialog.open(FormPersonaReactiveComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.filtrar()
      }
    });
  }


  isFalsy(valor: any) {
    return isFalsy(valor)
  }


}
