<body>
    <nav-search-perfil-logo></nav-search-perfil-logo>
    <div style="padding-top: 1%; padding: 1%;">
        <xng-breadcrumb class="xng-breadcrumb-root" [separator]="iconTemplate">
            <ng-container *xngBreadcrumbItem="let data; let breadcrumb; let info = info;let label = label; let first = first">
                <mat-icon *ngIf="info">{{ info }}</mat-icon>
                <ng-container *ngIf="!first">
                    <span class="xng-breadcrumb-trail">{{ breadcrumb | titlecase }}</span></ng-container>
                <ng-container *ngIf="first">
                    <span class="xng-breadcrumb-trail"><mat-icon >home</mat-icon></span></ng-container>
            </ng-container>
        </xng-breadcrumb>
        <ng-template #iconTemplate class="xng-breadcrumb-separator">
            <mat-icon>arrow_right</mat-icon>
        </ng-template>
        <br>
        <div class="container-fluid p-3 my-3">
            <router-outlet [@scrollAnimation]="state"></router-outlet>
        </div>

    </div>
</body>
