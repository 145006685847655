import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { CreateEstadoExpedienteRequest } from '@models/estadoExpedientes/createEstadoExpedienteRequest.model';
import { UpdateEstadoExpedienteRequest } from '@models/estadoExpedientes/updateEstadoExpedienteRequest.model';
import { BaseDto } from '@models/base/baseDto.model';

@Injectable({
  providedIn: 'root'
})
export class EstadoExpedienteService {

  constructor(private http:HttpClient) {
  }

  create(createEstadoExpedienteRequest:CreateEstadoExpedienteRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/EstadoExpediente/Create",createEstadoExpedienteRequest);
  }

  update(updateEstadoExpedienteRequest:UpdateEstadoExpedienteRequest):Observable<any>
  {
    return  this.http.put<any>("/api/EstadoExpediente/Update",updateEstadoExpedienteRequest);
  }

  getAll():Observable<BaseDto[]> {
    return   this.http.get<BaseDto[]>("/api/EstadoExpediente/GetAll");
  }

  getById(id:number):Observable<BaseDto>{
    return  this.http.get<BaseDto>("/api/EstadoExpediente/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/EstadoExpediente/Delete?id=" + id);
    else
      return of(null)
  }

}
