import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ListTareaComponent } from "@views/tareas/list-tarea/list-tarea.component";
import { SharedModule } from "./shared.module";
import { DialogUpdateTareaComponent } from "@views/tareas/dialog-update-tarea/dialog-update-tarea.component";
import { DialogCreateTareaComponent } from "@views/tareas/dialog-create-tarea/dialog-create-tarea.component";
import { DialogViewTareaComponent } from "@views/tareas/dialog-view-tarea/dialog-view-tarea.component";

@NgModule({
  declarations: [
    ListTareaComponent,
    DialogCreateTareaComponent,
    DialogUpdateTareaComponent,
    DialogViewTareaComponent

  ],
  imports: [
    CommonModule,
    SharedModule
  ],
  exports:[
    ListTareaComponent,
    DialogCreateTareaComponent,
    DialogUpdateTareaComponent,
    DialogViewTareaComponent]
})
export class TareaModule { }
