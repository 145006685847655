import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { startWith } from 'rxjs/operators';
import { PersonaService } from '@services/persona/persona.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { PersonaDto } from '@models/persona/personaDto.model';
import { DialogCreatePersonaSimpleComponent } from '@views/personas/dialog-create-persona-simple/dialog-create-persona-simple.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-buscar-persona',
  templateUrl: './buscar-persona.component.html',
  styleUrls: ['./buscar-persona.component.css']
})
export class BuscarPersonaComponent implements OnInit {

  public filteredPersonas!: Observable<PersonaDto[]>;
  personas: PersonaDto[] = []
  personaDetailDto!: PersonaDetailDto
  public entityControl = new FormControl();
  @Output() eventEntity = new EventEmitter<PersonaDetailDto>();
  @Input() personaEntrada: PersonaDetailDto = new PersonaDetailDto;
  @Input() idPersonaInput!: number | null;
  @Input() disable: boolean = false
  @Input() nombre: string = "Persona"
  @Input() loadBar: boolean = true

  private idPersona?: number;
  entitySelect!: number | null;
  disabledDocumento: FormControl = new FormControl
  esPersona: boolean = false;

  personas$!: Observable<PersonaDetailDto[]>;
  form = this.formBuilder.group({
    name: [null],
  });
  constructor(
    public dialog: MatDialog,
    private personaService: PersonaService,
    private spinner: NgxSpinnerService,
    private snackBar: SnackBarService,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    this.eventEntity = new EventEmitter<PersonaDetailDto>();


  }

  ngOnInit(): void {
    this.personas$ = this.form.get('name')!.valueChanges.pipe(
      distinctUntilChanged(),
      debounceTime(500),
      tap(() => this.spinner.show("spBusquedaPersona")),
      filter((name) => !!name),
      switchMap(name => this.personaService.getAllByName(name==null?"":name)),
      tap(() => this.spinner.hide("spBusquedaPersona")),
    );

  }

  onNuevaPersona(): void {
    const dialogRef = this.dialog.open(DialogCreatePersonaSimpleComponent, {
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }


  ngOnChanges(): void {
    if (this.idPersonaInput != 0)
      this.getPersonaById(this.idPersonaInput)
    this.entityControl.setValue(this.personaEntrada.nombreCompleto)
  }


  selectValue(event: any) {
    this.eventEntity.emit(event)
  }


  getAllPersona() {
    this.spinner.show("spBusquedaPersona");
    this.personaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spBusquedaPersona");
          this.personas = data
        },
        error => {
          this.snackBar.showError(error, "Error");
          this.spinner.hide("spBusquedaPersona");
        });
  }

  getPersonaById(id: any) {
    this.spinner.show("spBusquedaPersona");
    if (!isFalsy(id))
      this.personaService.getPersonaById(id)
        .subscribe(
          data => {
            this.spinner.hide("spBusquedaPersona");
            this.personaEntrada = data
            this.eventEntity.emit(data)
            this.personaDetailDto = data
            this.esPersona = true
          },
          error => {
            this.spinner.hide("spBusquedaPersona");
            this.snackBar.showError(error, "Error");
          });
  }

  editarPersona(idPersona: number) {
    this.getPersonaByIdAndRoute(idPersona)
  }

  getPersonaByIdAndRoute(idPersona: number) {
    this.spinner.show("spPersona")
    this.personaService.getPersonaById(idPersona)
      .subscribe(
        data => {
          this.spinner.hide("spPersona")
          this.personaService.setPersona(data)
          this.router.navigate(["/personas/edit"])
        },
        error => {
          this.spinner.hide("spPersona")
          this.snackBar.showError(error, "Error");
        });
  }

  trackByItems(index: number, item: any): any { return item; }

}
