import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { CantidadPorEstadoDto } from '@models/base/cantidadPorEstadoDto.mode';
import { EstadosTareas } from '@models/base/identificadores.model';
import { TareaDto } from '@models/tarea/tareaDto.model';
import { TareaFilter } from '@models/tarea/tareaFilter.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TareaService } from '@services/tarea/tarea.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dashboard-tareas',
  templateUrl: './dashboard-tareas.component.html',
  styleUrls: ['./dashboard-tareas.component.css']
})
export class DashboardTareasComponent {

  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<TareaDto>();
  cardLayout:any

  formBuilder: FormBuilder = new FormBuilder;
  form!: FormGroup;
  cantidadPorEstadoDto: CantidadPorEstadoDto[] = [];
  constructor(

    private tareaService: TareaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
  ){
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["titulo", "accion"] :
        ["titulo", "expediente", "fechaAlta",  "prioridad"];
    });
  }


  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {

      this.getCantidadPorEstadoDto()
      let tareaFilter = new TareaFilter()
      tareaFilter.idEstadoTarea = EstadosTareas.Pendiente
      this.getAllTareaFilter(tareaFilter)
  }



  getCantidadPorEstadoDto() {
    this.spinner.show("spCantidadPorEstado")
    this.tareaService.getCantidadPorEstadoDto().subscribe(
      data => {
        this.spinner.hide("spCantidadPorEstado")
        this.cantidadPorEstadoDto = data
      },
      error => {
        this.spinner.hide("spCantidadPorEstado")
        this.snackBar.showError(error, "Error");
      })
  }
  calcularCantidadTotalPorEstado(): number {
    let cantidadTotal = 0;
    for (const item of this.cantidadPorEstadoDto) {
      cantidadTotal += item.cantidad;
    }
    return cantidadTotal;
  }


  getAllTareaFilter(tareaFilter: TareaFilter) {
    this.spinner.show('spListado')
    this.tareaService.getAllByFilter(tareaFilter)
      .subscribe(
        data => {
          this.spinner.hide('spListado')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spListado')
          this.snackBar.showError(error, "Error");
        })
  }

}
