import { Component, Inject, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CreateCedulaNotificacionRequest } from '@models/documento/createCedulaNotificacionRequest.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { ExpedienteParteDto } from '@models/expedienteParte/expedienteParteDto.model';
import { DocumentoService } from '@services/documento/documento.service';
import { ExpedienteParteService } from '@services/expedienteParte/expedienteParte.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { Editor } from 'ngx-editor';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog-create-cedula-notificacion',
  templateUrl: './dialog-create-cedula-notificacion.component.html',
  styleUrls: ['./dialog-create-cedula-notificacion.component.css']
})
export class DialogCreateCedulaNotificacionComponent {

  editorDetalle!: Editor
  form: any;
  formBuilder: FormBuilder = new FormBuilder;
  expedientePartes!:ExpedienteParteDto[];
  enProceso: boolean = false;
  get f() { return this.form.controls; }

  constructor(
    public dialogRef: MatDialogRef<DialogCreateCedulaNotificacionComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: ExpedienteDto,
    private expedienteParteService: ExpedienteParteService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private documentoService: DocumentoService
  ) {


    this.form = this.formBuilder.group({
      Detalle: ['', Validators.compose([Validators.required])],
      ExpedientesPartes: ['', Validators.compose([Validators.required])],
    })
  }

  ngOnInit(): void {
    this.editorDetalle = new Editor();
    this.getExpedienteParteByIdExpediente(this.data.id)

  }

  closeDialog() {
    this.dialogRef.close({ event: null });
  }
  ngOnDestroy(): void {
    this.editorDetalle.destroy();
  }

  getExpedienteParteByIdExpediente(idExpediente: any) {
    this.spinner.show("spExpedienteParte")
    this.expedienteParteService.getAllByIdExpediente(idExpediente)
      .subscribe(
        data => {
          this.spinner.hide("spExpedienteParte")
          this.expedientePartes = data
        },
        error => {
          this.spinner.hide("spExpedienteParte")
          this.snackBar.showError(error, "Error");
        })
  }

  generarCedulaNotificacion(){

    let detalle = this.form.controls["Detalle"].value;
    let expedientesPartes: ExpedienteParteDto[] = this.form.controls["ExpedientesPartes"].value;
    let expedienteDto = this.data;
    let lstCreateCedulaNotificacionRequest:CreateCedulaNotificacionRequest[] = []

    expedientesPartes.forEach(parte => {

      let createCedulaNotificacionRequest = new CreateCedulaNotificacionRequest
    createCedulaNotificacionRequest.juzgado = expedienteDto.organismo.nombre
    createCedulaNotificacionRequest.localidad =  expedienteDto.organismo.localidad.nombreCompleto.toUpperCase()
    createCedulaNotificacionRequest.caratula = expedienteDto.caratula.toUpperCase()
    createCedulaNotificacionRequest.cuij = expedienteDto.cuij
    createCedulaNotificacionRequest.detalle = detalle
    createCedulaNotificacionRequest.destinatario = parte.persona.nombreCompleto.toUpperCase()

    lstCreateCedulaNotificacionRequest.push(createCedulaNotificacionRequest)
    });

    this.enProceso = true;
    this.spinner.show('spLoadGeneric')
    this.documentoService.generarCedulaNotificacion(lstCreateCedulaNotificacionRequest)
      .subscribe(
        data => {
          this.enProceso = false;
          this.spinner.hide('spLoadGeneric')
          this.snackBar.showSuccess("Cedulas generadas con exito.", "Exito");
          this.dialogRef.close({ data });
        },
        error => {
          this.enProceso = false;
          this.spinner.hide('spLoadGeneric')
          this.snackBar.showError(error, "Error");
        });
  }
}
