import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { AccionesDialog } from '@models/base/identificadores.model';
import { CasoUsoDto } from '@models/seguridad/casosUso/casoUsoDto.model';
import { CasoUsoService } from '@services/casoUso/casoUso.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialogUpdateCasoUsoComponent } from '../actualizar-caso-uso/dialog-update-caso-uso/dialog-update-caso-uso.component';
import { DialogCreateCasoUsoComponent } from '../crear-caso-uso/dialog-create-caso-uso/dialog-create-caso-uso.component';
import { DialogDeleteCasoUsoComponent } from '../eliminar-caso-uso/dialog-delete-caso-uso/dialog-delete-caso-uso.component';
import { DialogViewCasoUsoComponent } from '../ver-caso-uso/dialog-view-caso-uso/dialog-view-caso-uso.component';

@Component({
  selector: 'app-listado-caso-uso',
  templateUrl: './listado-caso-uso.component.html',
  styleUrls: ['./listado-caso-uso.component.css']
})
export class ListadoCasoUsoComponent implements OnInit {

  constructor(
    public dialog: MatDialog,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private CasoUsoService: CasoUsoService
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ['descripcion', 'accion'] :
        ['descripcion','path','icon', 'menu','accion'];
    });


  }

  data = false
  dataSource = new MatTableDataSource<CasoUsoDto>();
  obj!: undefined
  displayedColumns: string[] = [];

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;

  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }
  ngOnInit() {
    this.getAllCasosUso();
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  openDialogCreate() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    const dialogRef = this.dialog.open(DialogCreateCasoUsoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Agregar) {
        this.getAllCasosUso();
      }
    });
  }

  openDialogUpdate(casoUso:CasoUsoDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = casoUso
    const dialogRef = this.dialog.open(DialogUpdateCasoUsoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Modificar) {
        this.getAllCasosUso()
      }
    });
  }

  openDialogDelete(casoUso:CasoUsoDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = casoUso
    const dialogRef = this.dialog.open(DialogDeleteCasoUsoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.getAllCasosUso()
      }
    });
  }

  openDialogVer(casoUso:CasoUsoDto) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = "50%"
    dialogConfig.data = casoUso
    const dialogRef = this.dialog.open(DialogViewCasoUsoComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Ver) {
        this.getAllCasosUso()
      }
    });
  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getAllCasosUso() {
    this.spinner.show("spLoadPerfiles")
    this.CasoUsoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spLoadPerfiles")
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide("spLoadPerfiles")
          this.snackBar.showError(error, "Error");
        }
      )
  }

}
