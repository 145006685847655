import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LocalidadDto } from '@models/localidad/localidadDto.model';

import { isFalsy } from 'utility-types';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { CreateExpedienteRequest } from '@models/expediente/createExpedienteRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { UpdateExpedienteRequest } from '@models/expediente/updateExpedienteRequest.model';
import { CreateOrganismoRequest } from '@models/organismo/createOrganismoRequest.model';
import { UpdateOrganismoRequest } from '@models/organismo/updateOrganismoRequest.model';
import { OrganismoDto } from '@models/organismo/organismoDto.model';
import { numbers } from '@material/slider';

@Injectable({
  providedIn: 'root'
})
export class OrganismoService {

  constructor(private http:HttpClient) {
  }

  create(createOrganismoRequest:CreateOrganismoRequest):Observable<CreateResponseBase>
  {
    return  this.http.post<CreateResponseBase>("/api/Organismo/Create",createOrganismoRequest);
  }

  update(updateOrganismoRequest:UpdateOrganismoRequest):Observable<any>
  {
    return  this.http.put<any>("/api/Organismo/Update",updateOrganismoRequest);
  }

  getAll():Observable<OrganismoDto[]> {
    return   this.http.get<OrganismoDto[]>("/api/Organismo/GetAll");
  }

  getAllByIdLocalidad(idLocalidad:number):Observable<OrganismoDto[]> {
    return   this.http.get<OrganismoDto[]>("/api/Organismo/GetAllByIdLocalidad?idLocalidad="+idLocalidad);
  }

  getById(id:number):Observable<OrganismoDto>{
    return  this.http.get<OrganismoDto>("/api/Organismo/GetById?id="+id);
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Organismo/Delete?id=" + id);
    else
      return of(null)
  }

}
