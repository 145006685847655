import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, Input, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { AudienciaDto } from '@models/audiencia/audienciaDto.model';
import { AccionesDialog } from '@models/base/identificadores.model';
import { AudienciaService } from '@services/audiencia/audiencia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { DialogDeleteComponent } from '@views/genericos/dialog-delete/dialog-delete.component';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-list-audiencia',
  templateUrl: './list-audiencia.component.html',
  styleUrls: ['./list-audiencia.component.css']
})
export class ListAudienciaComponent {

  @Input() idExpediente!: number
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<AudienciaDto>();

  constructor(
    private AudienciaService: AudienciaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private dialog: MatDialog
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["expediente", "accion"] :
        ["expediente", "lugar","sala", "fecha", "tipoAudiencia", "accion"];
    });
  }

  @ViewChild(MatTable, { static: true }) table!: MatTable<any>;
  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  ngOnInit(): void {
    if (this.idExpediente)
      this.getAllAudienciasByIdExpediente(this.idExpediente)
  }

  filtrar() {
    this.getAllAudiencias()
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  getAllAudiencias() {
    this.spinner.show('spAudiencia')
    this.AudienciaService.getAll()
      .subscribe(
        data => {
          this.spinner.hide('spAudiencia')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spAudiencia')
          this.snackBar.showError(error, "Error");
        })
  }

  getAllAudienciasByIdExpediente(idExpediente: number) {
    this.spinner.show('spAudiencia')
    this.AudienciaService.getAllByIdExpediente(idExpediente)
      .subscribe(
        data => {
          this.spinner.hide('spAudiencia')
          this.dataSource.data = data
          this.data = this.dataSource.data.length >= 1 ? true : false
        },
        error => {
          this.spinner.hide('spAudiencia')
          this.snackBar.showError(error, "Error");
        })
  }

  openDialog(action: any, obj: { action?: any; }) {
    obj.action = action;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "50%",
      dialogConfig.autoFocus = false;
    dialogConfig.data = obj

    const dialogRef = this.dialog.open(DialogDeleteComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result.event == AccionesDialog.Eliminar) {
        this.deleteRowData(result.data);
      }
    });
  }

  deleteRowData(id: number) {
    this.spinner.show('spAudiencia')
    this.AudienciaService.delete(id)
      .subscribe(
        data => {
          this.spinner.hide('spAudiencia')
          this.snackBar.showSuccess("Registro dado de baja correctamente.", "Exito");
          this.getAllAudiencias();
        },
        error => {
          this.spinner.hide('spAudiencia')
          this.snackBar.showError(error, "Error");
        });
  }


  trackByItems(index: number, item: any): any { return item; }
}
