<button mat-raised-button color="primary" *appRole="['Permisos.Crear']" (click)="openDialogCreate()" color="primary">
  <mat-icon>add</mat-icon>
  Nuevo permiso
</button>&nbsp;
<br>
<br>
<div class="container-fluid">
    <mat-card *appRole="['Permisos.Ver']">
        <mat-card-header>
            <div class="row">
                <div class="col">
                    <button mat-stroked-button color="primary" (click)="filtrar()">
            <mat-icon>filter_alt</mat-icon>
            Filtrar
          </button>
                </div>
            </div>
            <br>
            <br>
        </mat-card-header>
        <mat-card-content>
            <ngx-spinner [zIndex]="100" name="spPermisos" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <br>
            <div class="row">
                <div class="col-lg-4 col-sm-12">
                    <mat-form-field *ngIf=data appearance="outline" class="full-width-x100">
                        <mat-label>Buscar</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                    </mat-form-field>
                </div>
            </div>


            <table mat-table [dataSource]="dataSource" *ngIf=data table matSort matSortStart="asc">

                <ng-container matColumnDef="descripcion">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Descripción </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> <span>{{element.descripcion}}</span> </mat-cell>
                </ng-container>

                <ng-container matColumnDef="token">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Token </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> <span>{{element.token}}</span> </mat-cell>
                </ng-container>

                <ng-container matColumnDef="casoUso">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Caso Uso </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> <span>{{isFalsy(element.casoUso)? "" :
              element.casoUso.descripcion}}</span>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="rol">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> Rol </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element"> <span>{{isFalsy(element.rol)? "" :
              element.rol.descripcion}}</span>
                    </mat-cell>
                </ng-container>
                <ng-container matColumnDef="accion">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> Acción </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let element" class="action-link">

                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item *appRole="['Permisos.Ver']" matTooltip="Ver" matTooltipPosition="right" (click)="openDialogVer(element)">
                <mat-icon>visibility</mat-icon>
                <span>Ver</span>
              </button>
                            <button mat-menu-item *appRole="['Permisos.Modificar']" matTooltip="Editar" matTooltipPosition="right" (click)="openDialogUpdate(element)">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
                            <button mat-menu-item *appRole="['Permisos.Eliminar']" matTooltip="Eliminar" matTooltipPosition="right" (click)="openDialogDelete(element)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>
            </table>
            <div class="no-data-table">
                <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
          datos
          para mostrar.</span>
            </div>
            <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</div>
