import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AudienciaDto } from '@models/audiencia/audienciaDto.model';
import { CreateAudienciaRequest } from '@models/audiencia/createAudienciaRequest.model';
import { UpdateAudienciaRequest } from '@models/audiencia/updateAudienciaRequest.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';
import { Observable, of } from 'rxjs';
import { isFalsy } from 'utility-types';


@Injectable({
  providedIn: 'root'
})
export class AudienciaService {

  constructor(private http: HttpClient) {
  }

  create(createAudienciaRequest: CreateAudienciaRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Audiencia/Create", createAudienciaRequest);
  }

  update(updateAudienciaRequest: UpdateAudienciaRequest): Observable<any> {
    return this.http.put<any>("/api/Audiencia/Update", updateAudienciaRequest);
  }

  getAll(): Observable<AudienciaDto[]> {
    return this.http.get<AudienciaDto[]>("/api/Audiencia/GetAll");
  }

  getById(id: number): Observable<AudienciaDto> {
    return this.http.get<AudienciaDto>("/api/Audiencia/GetById?id=" + id);
  }

  getAllByIdExpediente(id: number): Observable<AudienciaDto[]> {
    return this.http.get<AudienciaDto[]>("/api/Audiencia/GetAllByIdExpediente?idExpediente=" + id);
  }


  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/Audiencia/Delete?id=" + id);
    else
      return of(null)
  }

}
