<!-- <button mat-icon-button class="close-button" [mat-dialog-close]="true">
<mat-icon class="close-icon" color="warn">close</mat-icon>
</button> -->
<h1 mat-dialog-title>Eliminar <strong>{{data.nombre + ' ' + data.apellido | titlecase}}</strong></h1>
<div mat-dialog-content cdkTrapFocus [cdkTrapFocusAutoCapture]="true">
    <p><strong>¿Estás seguro de que deseas eliminar el perfil de esta Persona?</strong></p>
    <p>Toda la información asociada a este perfil de persona se eliminará de forma permanente.
        <span class="text-danger">Esta operación no se puede deshacer.</span>
    </p>
</div>
<mat-divider></mat-divider>
<div mat-dialog-actions>
    <button *appRole="['Personas.Eliminar']" mat-raised-button color="warn" (click)="eliminar()">
    Eliminar
</button>
    <button mat-button cdkFocusInitial (click)="onNoClick()">Cancelar</button>

</div>