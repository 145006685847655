import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { BaseDto } from '@models/base/baseDto.model';
import { CasoDto } from '@models/caso/casoDto.model';
import { UpdateCasoRequest } from '@models/caso/updateCasoRequest.model';
import { CasoService } from '@services/caso/caso.service';
import { EstadoCasoService } from '@services/estadoCaso/estadoCaso.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TipoCasoService } from '@services/tipoCaso/tipoCaso.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-form-caso-update',
  templateUrl: './form-caso-update.component.html',
  styleUrls: ['./form-caso-update.component.css']
})
export class FormCasoUpdateComponent {

  formBuilder: FormBuilder = new FormBuilder;
  tiposCaso!: BaseDto[];
  estadosCaso!: BaseDto[];
  casoForm: any;
  casoDto!: CasoDto;
  constructor(
    private tipoCasoService: TipoCasoService,
    private casoService: CasoService,
    private estadoCasoService: EstadoCasoService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnInit(): void {
    let idCaso = this.activatedRoute.snapshot.params['id']
    this.getAllTiposCaso();
    this.getAllEstadoCaso();
    this.getCasoById(idCaso)
    this.casoForm = this.formBuilder.group({
      Descripcion: [''],
      Caratula: ['', Validators.compose([Validators.required])],
      FechaComienzo: ['', Validators.compose([Validators.required])],
      FechaFinalizacion: [''],
      Activo: [''],
      TipoCaso: ['',],
      EstadoCaso: [''],
    });
  }

  getCasoById(id: number) {
    this.spinner.show("spUpdate")
    this.casoService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spUpdate")
          this.casoDto = data
          this.setAudienciaDtoForm(data);
        },
        error => {
          this.spinner.hide("spUpdate")
          this.snackBar.showError(error, "Error");
        })
  }
  setAudienciaDtoForm(casoDto: CasoDto) {
    this.casoForm.controls['Caratula'].setValue(casoDto.caratula);
    this.casoForm.controls['FechaComienzo'].setValue(casoDto.fechaComienzo);
    this.casoForm.controls['FechaFinalizacion'].setValue(casoDto.fechaFinalizacion);
    this.casoForm.controls['TipoCaso'].setValue(casoDto.tipoCaso.id);
    this.casoForm.controls['EstadoCaso'].setValue(casoDto.estadoCaso.id);
    this.casoForm.controls['Descripcion'].setValue(casoDto.descripcion);
  }


  onSubmit() {
    if (this.casoForm.invalid) {
      return;
    }
    const updateCasoRequest: UpdateCasoRequest = this.setUpdateCasoRequest();
    this.spinner.show("spCaso")
    this.casoService.update(updateCasoRequest)
      .subscribe(
        data => {
          this.spinner.hide("spCaso")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "Exito");
          this.router.navigate(["/casos"])
        },
        error => {
          this.spinner.hide("spCaso")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTiposCaso() {
    this.spinner.show("spTiposCaso")
    this.tipoCasoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTiposCaso")
          this.tiposCaso = data
        },
        error => {
          this.spinner.hide("spTiposCaso")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadoCaso() {
    this.spinner.show("spEstadosCaso")
    this.estadoCasoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spEstadosCaso")
          this.estadosCaso = data
        },
        error => {
          this.spinner.hide("spEstadosCaso")
          this.snackBar.showError(error, "Error");
        })
  }

  setUpdateCasoRequest(): UpdateCasoRequest {

    const updateCasoRequest = new UpdateCasoRequest();
    updateCasoRequest.id = this.casoDto.id
    updateCasoRequest.caratula = this.casoForm.controls['Caratula'].value;
    updateCasoRequest.fechaComienzo = this.casoForm.controls['FechaComienzo'].value;
    updateCasoRequest.fechaFinalizacion = this.casoForm.controls['FechaFinalizacion'].value;
    updateCasoRequest.idEmpresa = 1
    updateCasoRequest.idEstadoCaso = this.casoForm.controls['EstadoCaso'].value;
    updateCasoRequest.idTipoCaso = this.casoForm.controls['TipoCaso'].value;
    updateCasoRequest.descripcion = this.casoForm.controls['Descripcion'].value;

    return updateCasoRequest
  }

  onReset() {
    this.casoForm.reset();
  }


  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}
