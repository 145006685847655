import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatListModule } from '@angular/material/list';
import { CantidadPorEstadoDto } from '@models/base/cantidadPorEstadoDto.mode';
import { RangoFechasValores } from '@models/base/identificadores.model';
import { HotToastService } from '@ngneat/hot-toast';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { map } from 'rxjs';

@Component({
  selector: 'app-dashboard-general',
  templateUrl: './dashboard-general.component.html',
  styleUrls: ['./dashboard-general.component.css'],
  providers: [DatePipe],
})
export class DashboardGeneralComponent {
  cardLayout:any

  formBuilder: FormBuilder = new FormBuilder;
  form!: FormGroup;
  cantidadPorEstadoDto: CantidadPorEstadoDto[] = [];
  cantidadPorEstadoPeritoDto: CantidadPorEstadoDto[] = [];
  cantidadPorTipoDto: CantidadPorEstadoDto[] = [];
  constructor(

    private expedienteService: ExpedienteService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private breakpointObserver: BreakpointObserver,
  ){}

  ngOnInit(): void {

      this.getCantidadPorEstadoPeritoDto()
      this.getCantidadPorEstadoDto()
      this.getCantidadPorTipoDto()
  }

  // barChartOptions: ChartOptions = {
  //   responsive: true,
  //   legend: { position: 'left' },
  //   animation: {
  //     duration: 1000,
  //       easing: 'linear',
  //   }
  // };
  // barChartLabels: Label[] = [];
  // barChartType: ChartType = 'pie';
  // barChartLegend = true;
  // barChartPlugins = [];
  // barChartData: SingleDataSet = [];
  // lineChartColors: Color[] = [
  //   {
  //     borderColor: 'blue',
  //     backgroundColor: 'rgba(255,255,0,0.28)',
  //   },
  // ];

  // lineChartLegend = true;
  // lineChartPlugins = [];
  // lineChartType = 'line';


  // constructor(
  //   private dashboardService: DashboardService,
  //   private snackBar: SnackBarService,
  //   private spinner: NgxSpinnerService,
  //   private datepipe: DatePipe
  // ) {


  //   this.form = this.formBuilder.group({
  //     FechaDesde: [new Date((new Date().getTime() - RangoFechasValores.Bimestral)), Validators.compose([Validators.required,])],
  //     FechaHasta: [new Date(new Date().getTime()), Validators.compose([Validators.required,])],
  //   })
  // }

  // ngOnInit(): void {
  //   this.getContadorEntidades()
  //   this.getContadorDetalleServicioPropiedad()

  //   let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
  //   let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;

  //   this.getContadorPagosPorFormaPago(fechaDesde, fechaHasta)
  //   this.getContadorPagos(fechaDesde, fechaHasta)

  // }


  getCantidadPorEstadoPeritoDto() {
    this.spinner.show("spCantidadPorEstadoPerito")
    this.expedienteService.getCantidadPorEstadoPeritoDto().subscribe(
      data => {
        this.spinner.hide("spCantidadPorEstadoPerito")
        this.cantidadPorEstadoPeritoDto = data
      },
      error => {
        this.spinner.hide("spCantidadPorEstadoPerito")
        this.snackBar.showError(error, "Error");
      })
  }
  calcularCantidadTotalExpedientePorEstadoPerito(): number {
    let cantidadTotal = 0;
    for (const item of this.cantidadPorEstadoPeritoDto) {
      cantidadTotal += item.cantidad;
    }
    return cantidadTotal;
  }

  getCantidadPorEstadoDto() {
    this.spinner.show("spCantidadPorEstado")
    this.expedienteService.getCantidadPorEstadoDto().subscribe(
      data => {
        this.spinner.hide("spCantidadPorEstado")
        this.cantidadPorEstadoDto = data
      },
      error => {
        this.spinner.hide("spCantidadPorEstado")
        this.snackBar.showError(error, "Error");
      })
  }
  calcularCantidadTotalExpedientePorEstado(): number {
    let cantidadTotal = 0;
    for (const item of this.cantidadPorEstadoDto) {
      cantidadTotal += item.cantidad;
    }
    return cantidadTotal;
  }

  getCantidadPorTipoDto() {
    this.spinner.show("spCantidadPorTipo")
    this.expedienteService.getCantidadPorTipoDto().subscribe(
      data => {
        this.spinner.hide("spCantidadPorTipo")
        this.cantidadPorTipoDto = data
      },
      error => {
        this.spinner.hide("spCantidadPorTipo")
        this.snackBar.showError(error, "Error");
      })
  }
  calcularCantidadTotalPorTipo(): number {
    let cantidadTotal = 0;
    for (const item of this.cantidadPorTipoDto) {
      cantidadTotal += item.cantidad;
    }
    return cantidadTotal;
  }

  // private getContadorDetalleServicioPropiedad() {
  //   let fechaDesde = this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd') as string;
  //   let fechaHasta = this.datepipe.transform(new Date().getTime(), 'yyyy-MM-dd') as string;

  //   this.spinner.show("spGetContadorDetalleServicioPropiedad")
  //   this.dashboardService.getContadorDetalleServicioPropiedad(fechaDesde,fechaHasta).subscribe(
  //     data => {
  //       this.spinner.hide("spGetContadorDetalleServicioPropiedad")
  //       this.contadorDetalleServicioPropiedadDto = data
  //     },
  //     error => {
  //       this.spinner.hide("spGetContadorDetalleServicioPropiedad")
  //       this.snackBar.showError(error, "Error");
  //     })
  // }

  // filtrarPagos() {
  //   let fechaDesde = this.datepipe.transform(this.form.controls["FechaDesde"].value, 'yyyy-MM-dd') as string;
  //   let fechaHasta = this.datepipe.transform(this.form.controls["FechaHasta"].value, 'yyyy-MM-dd') as string;
  //   this.getContadorPagosPorFormaPago(fechaDesde, fechaHasta)
  //   this.getContadorPagos(fechaDesde, fechaHasta)
  // }

  // private getContadorPagos(fechaDesde: string, fechaHasta: string) {
  //   this.spinner.show("spGetContadorPagos")
  //   this.dashboardService.getContadorPagos(fechaDesde, fechaHasta).subscribe(
  //     data => {
  //       this.spinner.hide("spGetContadorPagos")
  //       this.contadorPagosDto = data
  //     },
  //     error => {
  //       this.spinner.hide("spGetContadorPagos")
  //       this.snackBar.showError(error, "Error");
  //     })
  // }

  // private getContadorPagosPorFormaPago(fechaDesde: string, fechaHasta: string) {
  //   this.spinner.show("spContadoPagosPorFormaPagoDto")
  //   this.dashboardService.getContadorPagosPorFormaPago(fechaDesde, fechaHasta).subscribe(
  //     data => {
  //       this.spinner.hide("spContadoPagosPorFormaPagoDto")
  //       this.contadoPagosPorFormaPagoDto = data
  //       this.barChartLabels = ['Efectivo', 'Trasferencia', 'Tarjeta de crédito', 'Cheque propio', 'Cheque Diferido', 'Cheque Tercero', 'Tarjeta de débito','Depósito en banco','Monedero electrónico', 'Otro'];
  //       this.barChartData = [
  //         data.efectivo, data.trasferencia, data.tarjetaCredito, data.chequePropio, data.chequeDiferido, data.chequeTercero, data.tarjetaDebito, data.depositoEnBanco, data.monederoElectronico, data.otro]
  //     },
  //     error => {
  //       this.spinner.hide("spContadoPagosPorFormaPagoDto")
  //       this.snackBar.showError(error, "Error");
  //     })
  // }

}
