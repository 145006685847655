import { Component } from '@angular/core';
import { async } from '@angular/core/testing';
import { Router, ActivatedRoute } from '@angular/router';
import { AudienciaDto } from '@models/audiencia/audienciaDto.model';
import { PericiaDto } from '@models/pericia/periciaDto.model';
import { AudienciaService } from '@services/audiencia/audiencia.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-form-audiencia-view',
  templateUrl: './form-audiencia-view.component.html',
  styleUrls: ['./form-audiencia-view.component.css']
})
export class FormAudienciaViewComponent {
  audienciaDto!: AudienciaDto;
  constructor(
    private audienciaService: AudienciaService,
    private router: Router,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute

  ) { }

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.params['id']
    this.getAudienciaById(id)
  }

  async getAudienciaById(id: number) {
    this.spinner.show("spView")
    await this.audienciaService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spView")
          this.audienciaDto = data
        },
        error => {
          this.spinner.hide("spView")
          this.snackBar.showError(error, "Error");
        })
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }
}
