<div class="row content" *appRole="['Usuarios.Ver']">
    <div class="col-sm-12 col-md-6 col-lg-2">
        <app-nav-panel-usuario></app-nav-panel-usuario>
    </div>

    <div class="col-lg-10">
        <mat-expansion-panel expanded=true>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    Datos Usuario
                </mat-panel-title>
                <mat-icon style="margin-right: 10px;">account_circle</mat-icon>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ngx-spinner bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false"></ngx-spinner>
            <form [formGroup]="usuarioForm">
                <div class="row">
                    <div class="col-md-3 mb-3">
                        <mat-form-field appearance="outline">
                            <input type="hidden" formControlName="IdPersona" value="{{idPersona}}" />
                            <mat-label>Nombre Completo</mat-label>
                            <input matInput type="text" maxlength="50" formControlName="NombreCompleto">
                            <mat-icon matSuffix color="primary" (click)="editarPersona(idPersona)" matTooltip="Editar persona" matTooltipPosition="below">info</mat-icon>
                        </mat-form-field>
                    </div>
                    <div class="col-lg-3 mb-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Nombre de usuario</mat-label>
                            <input matInput type="text" maxlength="50" formControlName="NombreUsuario">
                        </mat-form-field>
                    </div>
                    <div class="col-md-3 mb-3">
                        <mat-form-field appearance="outline">
                            <mat-label>Rol</mat-label>
                            <input type="hidden" formControlName="IdRol" value="{{idRol}}" />
                            <mat-select required #SelectRol placeholder="Seleccione opcion" formControlName="Rol" (selectionChange)="selectRol($event.value)">
                                <mat-option [value]="rol.id" *ngFor="let rol of roles ;let i=index;trackBy:trackByItems">
                                    {{rol.descripcion}}</mat-option>
                            </mat-select>
                            <!-- <mat-error *ngIf="f.Rol.errors?.required">Este campo es <strong>requerido</strong>.
                            </mat-error> -->
                        </mat-form-field>
                    </div>
                </div>
                <div class="col-auto my-1">
                    <button mat-raised-button *appRole="['Usuarios.Modificar']" color="primary" type="submit" name="Guardar" [disabled]='usuarioForm.invalid' (click)="onSubmit()" style="margin-right:3px;" value="Guardar">
            Guardar
        </button>
                    <button mat-button color="warn" name="Guardar" (click)="onCancel();$event.preventDefault()" style="margin-right:3px;" value="Guardar">Cancelar</button>
                </div>
            </form>
        </mat-expansion-panel>

    </div>

</div>
