import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/modules/shared.module';
import { LineChartComponent } from '@views/dashboard/graficosEjemplos/line-chart/line-chart.component';
import { PersonaModule } from './persona.module';
import { NotificacionModule } from './notificacion.module';
import { DoughnutChartComponent } from '@views/dashboard/graficosEjemplos/doughnut-chart/doughnut-chart.component';
import { DashboardComponent } from '@views/dashboard/dashboard/dashboard.component';
import { CalendarioModule } from './calendario.module';
import { DashboardGeneralComponent } from '@views/dashboard/dashboard-general/dashboard-general.component';
import { DashboardExpedientesComponent } from '@views/dashboard/dashboard-expedientes/dashboard-expedientes.component';
import { DashboardPericiasComponent } from '@views/dashboard/dashboard-pericias/dashboard-pericias.component';
import { DashboardMovimientosComponent } from '@views/dashboard/dashboard-movimientos/dashboard-movimientos.component';
import { DashboardTareasComponent } from '@views/dashboard/dashboard-tareas/dashboard-tareas.component';
import { FullCalendarModule } from '@fullcalendar/angular';
//Charts



@NgModule({
  imports:[
      CommonModule,
      SharedModule,
      PersonaModule,
      NotificacionModule,
      CalendarioModule,
      FullCalendarModule
  ],
  declarations:[
    //Chart
    LineChartComponent,
    DoughnutChartComponent,
    DashboardGeneralComponent,
    DashboardExpedientesComponent,
    DashboardPericiasComponent,
    DashboardMovimientosComponent,
    DashboardTareasComponent,
    DashboardComponent,
  ],
  exports:[
    //Chart
    LineChartComponent,
    DoughnutChartComponent,
    DashboardGeneralComponent,
    DashboardExpedientesComponent,
    DashboardPericiasComponent,
    DashboardMovimientosComponent,
    DashboardTareasComponent,
    DashboardComponent,
  ]
})
export class DashboardModule { }
