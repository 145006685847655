import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/modules/shared.module";
import { AsociaDocumentoEntidadComponent } from "../views/documentos/asocia-documento-entidad/asocia-documento-entidad.component";
import { AsociaDocumentoNuevoEntidadComponent } from "../views/documentos/asocia-documento-nuevo-entidad/asocia-documento-nuevo-entidad.component";
import { BtnAddDocumentoComponent } from "../views/documentos/btn-add-documento/btn-add-documento.component";
import { BtnNuevoDocumentoComponent } from "../views/documentos/btn-nuevo-documento/btn-nuevo-documento.component";
import { CreateDocumentComponent } from "../views/documentos/create-document/create-document.component";
import { DialogCreateDocumentComponent } from "../views/documentos/dialog-create-document/dialog-create-document.component";
import { DialogEditarDocumentoComponent } from "../views/documentos/dialog-editar-documento/dialog-editar-documento.component";
import { DocumentosPersonasComponent } from "../views/documentos/documentos-entidades/documentos-personas/documentos-personas.component";
import { ListadoDocumentosEntidadComponent } from "../views/documentos/documentos-entidades/listado-documentos-entidad/listado-documentos-entidad.component";
import { DocumentosComponent } from "../views/documentos/documentos.component";
import { EditarDocumentoComponent } from "../views/documentos/editar-documento/editar-documento.component";
import { FormDocumentoComponent } from "../views/documentos/form-documento/form-documento.component";
import { ListadoDocumentosComponent } from "../views/documentos/listado-documentos/listado-documentos.component";
import { TipoDocumentoService } from "../services/tipoDocumento/tipoDocumento.service";
import { ArchivoModule } from "./archivo.module";
import { AsuntoModule } from "./asunto.module";
import { PersonaModule } from "./persona.module";
import { DialogCreateCedulaNotificacionComponent } from "@views/documentos/generarArchivos/dialog-create-cedula-notificacion/dialog-create-cedula-notificacion.component";
import { DialogAccionesDocumentoComponent } from "@views/documentos/dialog-acciones-documento/dialog-acciones-documento.component";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AsuntoModule,
        PersonaModule,
        PersonaModule,
        ArchivoModule,
    ],
    declarations: [
        DocumentosComponent,
        FormDocumentoComponent,
        ListadoDocumentosComponent,
        EditarDocumentoComponent,
        BtnAddDocumentoComponent,
        CreateDocumentComponent,
        DialogCreateDocumentComponent,
        ListadoDocumentosEntidadComponent,
        DialogEditarDocumentoComponent,
        BtnNuevoDocumentoComponent,
        AsociaDocumentoEntidadComponent,
        AsociaDocumentoNuevoEntidadComponent,
        DocumentosPersonasComponent,
        EditarDocumentoComponent,
        DialogCreateCedulaNotificacionComponent,
        DialogAccionesDocumentoComponent
    ],

    providers: [
        TipoDocumentoService
    ],
    exports: [
        DocumentosComponent,
        FormDocumentoComponent,
        ListadoDocumentosComponent,
        EditarDocumentoComponent,
        BtnAddDocumentoComponent,
        CreateDocumentComponent,
        DialogCreateDocumentComponent,
        ListadoDocumentosEntidadComponent,
        DialogEditarDocumentoComponent,
        BtnNuevoDocumentoComponent,
        AsociaDocumentoEntidadComponent,
        AsociaDocumentoNuevoEntidadComponent,
        DocumentosPersonasComponent,
        EditarDocumentoComponent,
        DialogCreateCedulaNotificacionComponent,
        DialogAccionesDocumentoComponent]
})
export class DocumentoModule { }
