import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { BuscarLocalidadComponent } from "@views/busquedas/buscar-localidad/buscar-localidad.component";

import { LocalidadService } from "../services/localidad/localidad.service";
import { SharedModule } from "./shared.module";

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        BuscarLocalidadComponent,],
    exports: [
        BuscarLocalidadComponent,
    ],
    providers: [
        LocalidadService,
    ]

})

export class LocalidadModule { }
