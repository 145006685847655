import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { LoginService } from '@services/login/login.service';
import { PersonaService } from '@services/persona/persona.service';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-nav-panel-persona',
  templateUrl: './nav-panel-persona.component.html',
  styleUrls: ['./nav-panel-persona.component.css']
})
export class NavPanelPersonaComponent implements OnInit {
  @Input() idPersona: number = 0
  personaDetailDto!: PersonaDetailDto;

  constructor(
    private loginService: LoginService,
    private aRoute: ActivatedRoute,
    private PersonaService: PersonaService,
  ) { }

  ngOnInit(): void {


    this.idPersona = this.aRoute.snapshot.params['id']
    if (isFalsy(this.idPersona))
      this.idPersona = this.PersonaService.getPersona().id
  }
}
