import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UtilidadesController } from 'src/app/controllers/utilidades.controller';
import { ParametrosConfiguracion } from '@models/base/identificadores.model';
import { ParametroSistemaDto } from '@models/parametros-sistema/parametroSistemaDto.model';
import { ParametroSistemaValues } from '@models/parametros-sistema/parametrosSistemaValues.model';
import { UpdateParametroSistemaRequest } from '@models/parametros-sistema/updateParametroSistemaRequest.model';
import { isFalsy } from 'utility-types';
import { SnackBarService } from '../snackBar/snack-bar-service.service';

@Injectable({
  providedIn: 'root'
})
export class ParametroSistemaService {
  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  constructor(
    private http: HttpClient) {

  }
  getValuesByName(names: string[]): Observable<ParametroSistemaDto[]> {
    let cadena = ''
    names.forEach(element => {
      cadena = '&names=' + element
    });
    return this.http.get<ParametroSistemaDto[]>("/api/ParametroSistema/GetValuesByNames?" + cadena)
  }

  getValueByName(names: string): Observable<ParametroSistemaDto> {
    return this.http.get<ParametroSistemaDto>("/api/ParametroSistema/GetValueByName?names=" + names)
  }

  update(updateParametroSistemaRequest: UpdateParametroSistemaRequest): Observable<any> {
    return this.http.put<any>("/api/ParametroSistema/Update", updateParametroSistemaRequest);
  }

  getAll(): Observable<ParametroSistemaDto[]> {
    return this.http.get<ParametroSistemaDto[]>("/api/ParametroSistema/GetAll")
  }

  getById(id: number): Observable<ParametroSistemaDto> {
    return this.http.get<ParametroSistemaDto>("/api/ParametroSistema/GetByid?id=" + id)
  }

  delete(id: number): Observable<any> {
    if (!isFalsy(id))
      return this.http.delete<any>("/api/ParametroSistema/Delete?id=" + id);
    else
      return of(null)
  }


  setParametrosSistemaLocalStorage() {
    this.getAll()
      .subscribe(
        data => {
          var parametroSistemaValues = new ParametroSistemaValues()
          data.forEach(element => {
            switch (element.id) {
              //Correo
              case ParametrosConfiguracion.Sender:
                parametroSistemaValues.Sender = element.valor
                break
              case ParametrosConfiguracion.SmtpServer:
                parametroSistemaValues.SmtpServer = ""//element.valor
                break
              case ParametrosConfiguracion.Port:
                parametroSistemaValues.Port = ""//element.valor
                break
              case ParametrosConfiguracion.Username:
                parametroSistemaValues.Username = ""//element.valor
                break
              case ParametrosConfiguracion.Password:
                parametroSistemaValues.Password = ""//element.valor
                break
              case ParametrosConfiguracion.SslTls:
                const SslTls = JSON.parse(element.valor)
                parametroSistemaValues.SslTls = ""//element.valor
                break
              //Notificaciones
              case ParametrosConfiguracion.DuracionAlertas:
                parametroSistemaValues.DuracionAlertas = element.valor
                break
              //Comprobantes
              // case ParametrosConfiguracion.NotaResumen:
              //   parametroSistemaValues.NotaResumen = ""//element.valor
              //   break
              // case ParametrosConfiguracion.NotaRecibo:
              //   parametroSistemaValues.NotaRecibo = ""//element.valor
              //   break
              // case ParametrosConfiguracion.NotaLiquidacion:
              //   parametroSistemaValues.NotaLiquidacion = ""//element.valor
              //   break
              //Parametros Contables
              // case ParametrosConfiguracion.Iva:
              //   parametroSistemaValues.Iva = ""//element.valor
              //   break
              // case ParametrosConfiguracion.Caja:
              //   parametroSistemaValues.Caja = ""//element.valor
              //   break
              // case ParametrosConfiguracion.CuentaContableDefecto:
              //   parametroSistemaValues.CuentaContableDefecto = ""//element.valor
              //   break
              //General
              // case ParametrosConfiguracion.DuracionMesesArchivosTemporales:
              //   parametroSistemaValues.DuracionMesesArchivosTemporales = ""//element.valor
              //   break
              // default:
              //   break
            }
          });
          this.setParametrosSistemas(parametroSistemaValues)
        },
        error => {
        }
      );

  }

  private setParametrosSistemas(parametroSistemaValues: ParametroSistemaValues) {
    localStorage.removeItem("parametroSistemaValues")
    localStorage.setItem("parametroSistemaValues", UtilidadesController.encrypt(JSON.stringify(parametroSistemaValues)))
  }

  public  getParametrosSistemasValues(): ParametroSistemaValues {
    let parametroSistemaValues = new ParametroSistemaValues
    let parameter = localStorage.getItem("parametroSistemaValues")
    if (!isFalsy(parameter)) {
      let parameterDesncrypt = UtilidadesController.decrypt(parameter)
      parametroSistemaValues = JSON.parse(parameterDesncrypt) as ParametroSistemaValues
    }

    if (isFalsy(parametroSistemaValues))
      this.setParametrosSistemaLocalStorage()

    return parametroSistemaValues
  }


}





