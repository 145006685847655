import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";import { FormPericiaCreateComponent } from "@views/pericias/form-pericia-create/form-pericia-create.component";
import { FormPericiaUpdateComponent } from "@views/pericias/form-pericia-update/form-pericia-update.component";
import { FormPericiaViewComponent } from "@views/pericias/form-pericia-view/form-pericia-view.component";
import { ListPericiaComponent } from "@views/pericias/list-pericia/list-pericia.component";
import { ExpedienteModule } from "./expediente.module";
import { SharedModule } from "./shared.module";
import { BtnCreatePericiaComponent } from "@views/pericias/btn-create-pericia/btn-create-pericia.component";
import { DialogCreatePericiaComponent } from "@views/pericias/dialog-create-pericia/dialog-create-pericia.component";
import { BusquedasModule } from "./busquedas.module";
import { FullCalendarModule } from "@fullcalendar/angular";

@NgModule({
  imports: [
      CommonModule,
      SharedModule,
      BusquedasModule,
  ],
  declarations: [
    FormPericiaCreateComponent,
    FormPericiaUpdateComponent,
    FormPericiaViewComponent,
    ListPericiaComponent,
    BtnCreatePericiaComponent,
    DialogCreatePericiaComponent
  ],

  providers: [

  ],
  exports: [
    FormPericiaCreateComponent,
    FormPericiaUpdateComponent,
    FormPericiaViewComponent,
    ListPericiaComponent,
    BtnCreatePericiaComponent,
    DialogCreatePericiaComponent
  ],
})
export class PericiaModule { }
