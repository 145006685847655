import { Component, Inject } from '@angular/core';
import { EmailValidator, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Helper } from '@helpers/helper';
import { CorreoValidator } from '@helpers/validations/email.validator';
import { TelefonoValidator } from '@helpers/validations/telefono.validator';
import { isFalsy } from 'utility-types';

@Component({
  selector: 'app-dialog-view-basic-persona',
  templateUrl: './dialog-view-basic-persona.component.html',
  styleUrls: ['./dialog-view-basic-persona.component.css']
})
export class DialogViewBasicPersonaComponent {

  personaForm!: FormGroup
  correoValido!: boolean
  formBuilder: FormBuilder = new FormBuilder;
  constructor(
    public dialogRef: MatDialogRef<DialogViewBasicPersonaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,

  ) {

    this.personaForm = this.formBuilder.group({
      //Persona
      Telefono: ["", Validators.compose([Validators.required])],
      CorreoElectronico: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(50)])]
    })

    this.personaForm.controls["Telefono"].setValue(this.data?.telefonoPrincipal);
    this.personaForm.controls["CorreoElectronico"].setValue(this.data.correo);
  }


  copiarAlPortapapeles(texto:string){
    Helper.copyToClipboard(texto)
  }

  sendWhatsApp(){
    let telefonoWS =  this.personaForm.controls["Telefono"].value
    let telefonoValidado = TelefonoValidator.formatPhoneNumber(telefonoWS)

    if(!isFalsy(telefonoValidado))
      TelefonoValidator.sendWhatsApp(telefonoValidado)
  }

  sendEmail(){
    let correoElectronico =  this.personaForm.controls["CorreoElectronico"].value
    CorreoValidator.sendEmail(correoElectronico, this.data.expediente.caratula,"")
  }
}
