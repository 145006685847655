<div class="card-container">
  <mat-card>
    <ngx-spinner [zIndex]="100" name="spCantidadPorTipo" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
      type="ball-atom" [fullScreen]="false"></ngx-spinner>
    <mat-card-header>
      <mat-card-title>Movimientos por Tipo</mat-card-title>
      <mat-card-subtitle>Cantidad de movimientos por tipo</mat-card-subtitle>
    </mat-card-header>
    <mat-divider></mat-divider>
    <br>
    <mat-card-content>
      <table class="badge-table">
        <tr *ngFor="let item of cantidadPorTipoDto">
          <td>{{item.descripcion | titlecase}}</td>
          <td>{{item.cantidad}}</td>
        </tr>
        <tr>
          <td><strong>Total</strong></td>
          <td><strong>{{calcularCantidadTotalMovimientoPorTipo()}}</strong></td>
        </tr>
      </table>
    </mat-card-content>
    <mat-card-actions>
      <button mat-button [routerLink]="['/movimientos']">Ver listado</button>
    </mat-card-actions>
  </mat-card>
</div>
