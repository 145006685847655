<div *appRole="['Documentos.Adjuntar']">
    <div class="row">

        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="container" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" multiple (change)="fileBrowseHandler($event)" />
                <mat-icon color="primary">file_upload</mat-icon>
                <h5>Arrastra y suelta el archivo aquí o</h5>
                <button mat-stroked-button for="fileDropRef" color="primary">Buscar archivo</button>
            </div>
        </div>
        <div class="col-sm-12 col-md-6 col-lg-6">
            <div class="files-list">
                <div class="single-file" *ngFor="let file of files;let i=index;trackBy:trackByItems">
                    <div class="icon">
                        <mat-icon color="primary">attach_file</mat-icon>
                    </div>
                    <div class="info">
                        <h4 class="name">
                            {{ file?.name }}
                        </h4>
                        <p class="size">
                            {{ formatBytes(file.size,2) }}
                        </p>
                    </div>
                    <div class="delete" (click)="deleteFile(i)">
                        <mat-icon color="warn">delete</mat-icon>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
