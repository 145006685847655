import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectionStrategy,  Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { GetFileContentByIdArchivoRequest } from '@models/archivo/getFileContentByIdArchivoRequest.model';
import { DocumentoEntidadDto } from '@models/documento/documentoEntidadDto.model';
import { ArchivoService } from '@services/archivo/archivo.service';
import { DocumentoService } from '@services/documento/documento.service';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';

@Component({
  selector: 'app-listado-documentos-entidad',
  templateUrl: './listado-documentos-entidad.component.html',
  styleUrls: ['./listado-documentos-entidad.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ListadoDocumentosEntidadComponent implements OnInit, OnChanges {
  data = false
  displayedColumns: string[] = [];
  dataSource = new MatTableDataSource<DocumentoEntidadDto>();
  errorMessage: string = "";
  getFileContentByIdArchivoRequest!: GetFileContentByIdArchivoRequest
  @Input() idEntidad: number = 0
  @Input() idTipoEntidad: number = 0
  @Input() documentoChange!: number
  constructor(
    private DocumentoService: DocumentoService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,
    private ArchivoService: ArchivoService,
    private breakpointObserver: BreakpointObserver
  ) {
    this.breakpointObserver.observe(['(max-width: 600px)']).subscribe(result => {
      this.displayedColumns = result.matches ?
        ["nombre", "asunto", "descargar"] :
        ["nombre", "asunto", "descargar"];
    });
  }


  private paginator!: MatPaginator;
  private sort!: MatSort;

  @ViewChild(MatSort) set matSort(ms: MatSort) {
    this.sort = ms;
    this.setDataSourceAttributes();
  }

  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {
    this.paginator = mp;
    this.setDataSourceAttributes();
  }

  setDataSourceAttributes() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    if (this.paginator && this.sort) {
      this.applyFilter(null);
    }
  }


  ngOnInit(): void {
    if (this.idEntidad != 0 && this.idTipoEntidad != 0)
      this.getByIdTipoEntidadAndIdEntidad(this.idTipoEntidad, this.idEntidad);

  }

  ngOnChanges(): void {
    if (this.idEntidad != 0 && this.idTipoEntidad != 0)
      this.getByIdTipoEntidadAndIdEntidad(this.idTipoEntidad, this.idEntidad);
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.data = this.dataSource.data.length >= 1 ? true : false

  }

  applyFilter(event: any) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  getByIdTipoEntidadAndIdEntidad(idTipoEntidad: number, idEntidad: number) {
    this.spinner.show()
    this.DocumentoService.getByIdTipoEntidadAndIdEntidad(idTipoEntidad, idEntidad)
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataSource.data = data as DocumentoEntidadDto[]
          this.data = this.dataSource.data.length >= 1 ? true : false

        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        })
  }

  descargarArchivo(documento: DocumentoEntidadDto) {
    this.ArchivoService.getFileContentByIdArchivo(documento.idArchivo).subscribe
      (
        data => {
          this.getFileContentByIdArchivoRequest = data
          this.downloadPdf(data, documento)
        },
        error => {
          this.spinner.hide();
          this.snackBar.showError(error, "Error");
        }
      )

  }

  downloadPdf(getFileContentByIdArchivoRequest: GetFileContentByIdArchivoRequest, documento: DocumentoEntidadDto) {
    const link = document.createElement("a");
    let tipoArchivo: string = ""
    link.href = getFileContentByIdArchivoRequest.content;
    switch (getFileContentByIdArchivoRequest.contentType) {
      case "application/pdf": {
        tipoArchivo = ".pdf"
        break;
      }
      case "image/png": {
        tipoArchivo = ".png"
        break;
      }
      case "image/jpeg": {
        tipoArchivo = ".jpeg"
        break;
      }
      case "application/msword": {
        tipoArchivo = ".doc"
        break;
      }
      case "application/vnd.ms-excel": {
        tipoArchivo = ".xls"
        break;
      }
      case "application/image/gif": {
        tipoArchivo = ".gif"
        break;
      }
    }
    link.download = documento.descripcion.trim() + tipoArchivo
    link.click();
  }
}
