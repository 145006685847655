<form [formGroup]="loginForm">
    <mat-form-field appearance="outline">
        <mat-label>Usuario</mat-label>
        <input type="text" maxlength="50" matInput placeholder="Ingrese usuario" formControlName="Usuario">
        <!-- <mat-error *ngIf="f.Usuario.errors?.required">Ingrese nombre de usuario.</mat-error>
        <mat-error *ngIf="f.Usuario.errors?.maxlength">Supero el maximo de caracteres.</mat-error> -->
    </mat-form-field>
    <br>
    <mat-form-field appearance="outline">
        <mat-label>Contraseña</mat-label>
        <input type="password" maxlength="50" matInput placeholder="Ingrese usuario" formControlName="Clave">
        <!-- <mat-error *ngIf="f.Clave.errors?.required">Ingrese una contraseña.</mat-error>
        <mat-error *ngIf="f.Clave.errors?.maxlength">Supero el maximo de caracteres.</mat-error> -->
    </mat-form-field>
    <br>
    <br>
</form>

<button mat-raised-button type="submit" [disabled]="!loginForm.valid" name="Ingresar" (click)="loguearse()" color="primary" value="Ingresar">
  <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  Ingresar
</button>
