export class CreateUserRequest {

    public userName! : string;
    public password! : string;
    public nombre! : string;
    public apellido! : string;
    public correo!:string;
    public telefono!:string;
    public activo! : boolean;
    public idRol! : number;
    public idEmpresa! : number;

}
