import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogUpdateContactoComponent } from '@views/contactos/dialog-update-contacto/dialog-update-contacto.component';
import { DialogCreateContactoComponent } from '@views/contactos/dialog-create-contacto/dialog-create-contacto.component';
import { DialogViewContactoComponent } from '@views/contactos/dialog-view-contacto/dialog-view-contacto.component';
import { ListContactoComponent } from '@views/contactos/list-contacto/list-contacto.component';
import { SharedModule } from './shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
      ListContactoComponent,
      DialogViewContactoComponent,
      DialogCreateContactoComponent,
      DialogUpdateContactoComponent
    ],

    providers: [
    ],
    exports: [
      ListContactoComponent,
      DialogViewContactoComponent,
      DialogCreateContactoComponent,
      DialogUpdateContactoComponent
    ],
    schemas: []
})
export class ContactoModule { }
