<h1 mat-dialog-title>Acción realizada :: <strong>Modificar</strong></h1>
<br>
<mat-dialog-content>
  <ngx-spinner [zIndex]="100" name="spCreate" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688"
    type="ball-atom" [fullScreen]="false">
  </ngx-spinner>
  <form [formGroup]="form">
    <div class="row">
      <div class="col">
        <mat-form-field appearance="outline" class="full-width-x100">
          <ngx-spinner [zIndex]="-1" name="spTipoContacto" bdColor="rgba(255,255,255,0.8)" size="small"
            color="#009688" type="line-scale" [fullScreen]="false"></ngx-spinner>
          <mat-label>Tipo contacto</mat-label>
          <mat-select placeholder="Seleccione opcion" formControlName="TipoContacto">
            <mat-option [value]="tipoContacto.id" *ngFor="let tipoContacto of tiposContacto">
              {{tipoContacto.descripcion}}</mat-option>
          </mat-select>
          <mat-error *ngIf="form.controls['TipoContacto'].hasError('required')">Este campo es
            <strong>requerido</strong>.</mat-error>
        </mat-form-field>
      </div>
      <div class="col">


        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Valor</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="ValorContacto" />
          <mat-error *ngIf="form.controls['ValorContacto'].hasError('required')">Este campo es
            <strong>requerido</strong>.</mat-error>
          <mat-error *ngIf="form.controls['ValorContacto'].hasError('maxlength')">Supero el largo maximo.</mat-error>
        </mat-form-field>
      </div>
      <div class="col">
        <mat-checkbox formControlName="Preferido">Preferido</mat-checkbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Empresa Asociada</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="EmpresaAsociada" />
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Nivel Avance</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="NivelAvance" />
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Tipo Relacion</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="TipoRelacion" />
        </mat-form-field>
      </div>

    </div>
    <div class="row">


      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Fuente Contacto</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="FuenteContacto" />
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field appearance="outline">
          <mat-label>Fecha ultimo contacto</mat-label>
          <input matInput [matDatepicker]="picker" placeholder="MM/DD/YYYY" formControlName="FechaUltimoContacto">
          <mat-hint>DD/MM/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Notas Seguimiento</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="NotasSeguimiento" />
        </mat-form-field>
      </div>

    </div>
    <div class="row">

      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Estado Contacto</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="EstadoContacto" />
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Categorias Etiquetas</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="CategoriasEtiquetas" />
        </mat-form-field>
      </div>

      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Historial Interacciones</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="HistorialInteracciones" />
        </mat-form-field>
      </div>
    </div>
    <div class="row">

      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Preferencias Contacto</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="PreferenciasContacto" />
        </mat-form-field>
      </div>
      <div class="col">
        <mat-form-field class="full-width-x100" appearance="outline">
          <mat-label>Descripcion</mat-label>
          <input matInput type="text" maxlength="100"  formControlName="Descripcion" />
        </mat-form-field>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button color="primary" name="Agregar" [disabled]="!form.valid"
    (click)="onSubmit()">Agregar</button>
</mat-dialog-actions>
