export class CreateDocumentoRequest {
    public fileContent!: string;
    public fileType!: string;
    public fileName!: string;
    public idTipoEntidad?: number;
    public idEntidad?: number;
    public idAsunto?: number;
    public etiquetas!: string;
    public nombre!: string;
    public descripcion!: string;
}
