import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
//VAlidaciones
import { NgxSpinnerService } from 'ngx-spinner';
import { BaseDto } from '@models/base/baseDto.model';
import { isFalsy } from 'utility-types';
import { EstadoExpedientePeritoService } from '@services/estadoExpedientePerito/estadoExpedientePerito.service';
import { TipoExpedienteService } from '@services/tipoExpediente/tipoExpediente.service';
import { EstadoExpedienteService } from '@services/estadoExpediente/estadoExpediente.service';
import { ExpedienteService } from '@services/expediente/expediente.service';
import { OrganismoService } from '@services/organismo/organismo.service';
import { OrganismoDto } from '@models/organismo/organismoDto.model';
import { ExpedienteDto } from '@models/expediente/expedienteDto.model';
import { UpdateExpedienteRequest } from '@models/expediente/updateExpedienteRequest.model';
import { EstadoProcesalService } from '@services/estadoProcesal/estado-procesal.service';
import { CasoDto } from '@models/caso/casoDto.model';

@Component({
  selector: 'app-form-expediente-update',
  templateUrl: './form-expediente-update.component.html',
  styleUrls: ['./form-expediente-update.component.css']
})
export class FormExpedienteUpdateComponent {

  expedienteDto!: ExpedienteDto
  formBuilder: FormBuilder = new FormBuilder;
  estadosExpedientes!: BaseDto[];
  estadosExpedientesPerito!: BaseDto[];
  expedienteForm!: FormGroup;
  tiposExpedientes!: BaseDto[];
  organismos!: OrganismoDto[];
  estadosProcesal!: BaseDto[];
  get f() { return this.expedienteForm.controls; }

  constructor(
    private ExpedienteService: ExpedienteService,
    private TipoExpedienteService: TipoExpedienteService,
    private EstadoExpedienteService: EstadoExpedienteService,
    private OrganismoService: OrganismoService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBar: SnackBarService,
    private EstadoExpedientePeritoService: EstadoExpedientePeritoService,
    private spinner: NgxSpinnerService,
    private estadoProcesalService: EstadoProcesalService

  ) { }

  ngOnInit(): void {
    let idExpediente = this.activatedRoute.snapshot.params['id']
    this.getAllEstadosExpediente();
    this.getAllEstadosExpedientePerito();
    this.getAllTiposExpedientes();
    this.getAllOrganismos()
    this.getAllEstadosProcesal();
    this.getExpedienteById(idExpediente)
    this.expedienteForm = this.formBuilder.group({
      Descripcion: [''],
      Ubicacion: [''],
      Caratula: ["", Validators.compose([Validators.required, Validators.maxLength(200)])],
      Cuij: ['', Validators.compose([Validators.required, , Validators.maxLength(50)])],
      Numero: [''],
      Demanda: [''],
      Causa: [''],
      TipoExpediente: [''],
      Demandado: [''],
      EstadoExpedientes: [''],
      EstadoProcesal: [''],
      EstadoExpedientesPerito: [''],
      Organismo: ['',Validators.compose([Validators.required])],
      ClaveSisfeExpediente: [''],
      Sufijo: [''],
      Bis: [''],
      FechaIngresoMEU: [''],
      MontoDemanda: [''],
    });
  }

  onSubmit() {
    if (this.expedienteForm.invalid) {
      return;
    }
    const updateExpedienteRequest: UpdateExpedienteRequest = this.setUpdateExpedienteRequest();
    this.spinner.show("spExpediente")
    this.ExpedienteService.update(updateExpedienteRequest)
      .subscribe(
        data => {
          this.spinner.hide("spExpediente")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "Exito");
          this.router.navigate(["/expedientes/"+updateExpedienteRequest.id])
        },
        error => {
          this.spinner.hide("spExpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  getExpedienteById(id:number) {
    this.spinner.show("spExpediente")
    this.ExpedienteService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spExpediente")
          this.expedienteDto = data
          this.setExpedienteDtoForm(data);
          this.ExpedienteService.setExpedienteDto(data)
        },
        error => {
          this.spinner.hide("spExpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  setExpedienteDtoForm(expedienteDto: ExpedienteDto) {
    this.expedienteForm.controls['Descripcion'].setValue(expedienteDto.descripcion);
    this.expedienteForm.controls['Ubicacion'].setValue(expedienteDto.ubicacion);
    this.expedienteForm.controls['Caratula'].setValue(expedienteDto.caratula);
    this.expedienteForm.controls['Cuij'].setValue(expedienteDto.cuij);
    this.expedienteForm.controls['Numero'].setValue(expedienteDto.numero);
    this.expedienteForm.controls['Demanda'].setValue(expedienteDto.demanda);
    this.expedienteForm.controls['Causa'].setValue(expedienteDto.causa);
    this.expedienteForm.controls['TipoExpediente'].setValue(expedienteDto.tipoExpediente == undefined? null :expedienteDto.tipoExpediente.id);
    this.expedienteForm.controls['Demandado'].setValue(expedienteDto.demandado);
    this.expedienteForm.controls['EstadoExpedientes'].setValue(expedienteDto.estadoExpediente == undefined ? null : expedienteDto.estadoExpediente.id);
    this.expedienteForm.controls['EstadoExpedientesPerito'].setValue(expedienteDto.estadoExpedientePerito == undefined ? null : expedienteDto.estadoExpedientePerito.id);
    this.expedienteForm.controls['Organismo'].setValue(expedienteDto.organismo == undefined ? null : expedienteDto.organismo.id);
    this.expedienteForm.controls['ClaveSisfeExpediente'].setValue(expedienteDto.claveSisfeExpediente);
    this.expedienteForm.controls['Sufijo'].setValue(expedienteDto.sufijo);
    this.expedienteForm.controls['Bis'].setValue(expedienteDto.bis);
    this.expedienteForm.controls['FechaIngresoMEU'].setValue(expedienteDto.fechaIngresoMEU);
    this.expedienteForm.controls['MontoDemanda'].setValue(expedienteDto.montoDemanda);
    this.expedienteForm.controls['EstadoProcesal'].setValue(expedienteDto.estadoProcesal == undefined ? null : expedienteDto.estadoProcesal.id);
  }

  getAllEstadosExpediente() {
    this.spinner.show("spEstadosExpediente")
    this.EstadoExpedienteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spEstadosExpediente")
          this.estadosExpedientes = data
        },
        error => {
          this.spinner.hide("spEstadosExpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadosProcesal() {
    this.spinner.show("spEstadosProcesal")
    this.estadoProcesalService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spEstadosProcesal")
          this.estadosProcesal = data
        },
        error => {
          this.spinner.hide("spEstadosProcesal")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllEstadosExpedientePerito() {
    this.spinner.show("spEstadosExpedientePerito")
    this.EstadoExpedientePeritoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spEstadosExpedientePerito")
          this.estadosExpedientesPerito = data
        },
        error => {
          this.spinner.hide("spEstadosExpedientePerito")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllTiposExpedientes() {
    this.spinner.show("spTiposExpediente")
    this.TipoExpedienteService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spTiposExpediente")
          this.tiposExpedientes = data
        },
        error => {
          this.spinner.hide("spTiposExpediente")
          this.snackBar.showError(error, "Error");
        })
  }

  getAllOrganismos() {
    this.spinner.show("spOrganismos")
    this.OrganismoService.getAll()
      .subscribe(
        data => {
          this.spinner.hide("spOrganismos")
          this.organismos = data
        },
        error => {
          this.spinner.hide("spOrganismos")
          this.snackBar.showError(error, "Error");
        })
  }

  setUpdateExpedienteRequest(): UpdateExpedienteRequest {

    const updateExpedienteRequest = new UpdateExpedienteRequest();

    updateExpedienteRequest.id = this.expedienteDto.id
    updateExpedienteRequest.idCaso = this.expedienteDto.caso.id;
    updateExpedienteRequest.idEstadoProcesal = isFalsy(this.expedienteForm.controls['EstadoProcesal'].value) ? null:  this.expedienteForm.controls['EstadoProcesal'].value;
    updateExpedienteRequest.descripcion = this.expedienteForm.controls['Descripcion'].value;
    updateExpedienteRequest.ubicacion = this.expedienteForm.controls['Ubicacion'].value;
    updateExpedienteRequest.caratula = this.expedienteForm.controls['Caratula'].value;
    updateExpedienteRequest.cuij = this.expedienteForm.controls['Cuij'].value;
    updateExpedienteRequest.numero = this.expedienteForm.controls['Numero'].value;
    updateExpedienteRequest.demanda = this.expedienteForm.controls['Demanda'].value;
    updateExpedienteRequest.idTipoExpediente =isFalsy(this.expedienteForm.controls['TipoExpediente'].value) ? null: this.expedienteForm.controls['TipoExpediente'].value;
    updateExpedienteRequest.demandado = this.expedienteForm.controls['Demandado'].value;
    updateExpedienteRequest.causa = this.expedienteForm.controls['Causa'].value;
    updateExpedienteRequest.idEstadoExpediente = isFalsy(this.expedienteForm.controls['EstadoExpedientes'].value) ? null: this.expedienteForm.controls['EstadoExpedientes'].value;
    updateExpedienteRequest.idEstadoExpedientePerito = this.expedienteForm.controls['EstadoExpedientesPerito'].value;
    updateExpedienteRequest.idOrganismo = isFalsy(this.expedienteForm.controls['Organismo'].value) ? null : this.expedienteForm.controls['Organismo'].value;
    updateExpedienteRequest.claveSisfeExpediente = this.expedienteForm.controls['ClaveSisfeExpediente'].value;
    updateExpedienteRequest.sufijo = this.expedienteForm.controls['Sufijo'].value;
    updateExpedienteRequest.bis = this.expedienteForm.controls['Bis'].value;
    updateExpedienteRequest.fechaIngresoMEU = this.expedienteForm.controls['FechaIngresoMEU'].value;
    updateExpedienteRequest.montoDemanda = isFalsy(this.expedienteForm.controls['MontoDemanda'].value) ? 0 : this.
    expedienteForm.controls['MontoDemanda'].value;
    return updateExpedienteRequest
  }

  onReset() {
    this.expedienteForm.reset();
  }

  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }
}

