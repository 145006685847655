import { Component, Inject, Input, OnInit } from '@angular/core';
import { EntidadDto } from '@models/base/entidadDto.model';
import { DialogCreateDocumentComponent } from '../dialog-create-document/dialog-create-document.component';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-btn-add-documento',
  template: `
    <button mat-button (click)="openDialog()" mat-raised-button color="primary"> <mat-icon> add </mat-icon>
    Nuevo documento </button>`
  ,
})
export class BtnAddDocumentoComponent implements OnInit {

  @Input() entidad!: EntidadDto
  constructor(
    @Inject(MatDialog) public dialog: MatDialog
  ) { }

  ngOnInit(): void {
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.width = '1250',
    dialogConfig.data = this.entidad

    const dialogRef = this.dialog.open(DialogCreateDocumentComponent, dialogConfig);
  }

}
