import { EventEmitter, Injectable, } from '@angular/core'
import { HttpClient, } from '@angular/common/http'
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CreatePersonaRequest } from '@models/persona/createPersonaRequest.model';
//Necesario para listado de personas
import { UpdatePersonaRequest } from '@models/persona/updatePersonaRequest.model';
import { DeletePersonaRequest } from '@models/persona/deletePersonaRequest.model';
import { SnackBarService } from '../snackBar/snack-bar-service.service';
import { isFalsy } from 'utility-types';
import { PersonaDetailDto } from '@models/persona/personaDetailDto.model';
import { PersonaDto } from '@models/persona/personaDto.model';
import { UnlockPersonaRequest } from '@models/persona/unlockPersonaRequest.model';
import { PersonaFilter } from '@models/persona/personaFilter.model';
import { CreateResponseBase } from '@models/base/createResponseBase.model';


@Injectable({
  providedIn: 'root'
})
export class PersonaService {
  public personaDetailDto!: PersonaDetailDto;
  public idPersona!: number
  private personaSource = new BehaviorSubject(new PersonaDetailDto);
  currentPersona = this.personaSource.asObservable();
  private id!: number;

  set setId(id: number) {
    this.id = id;
  }

  get getId() {
    return this.id;
  }

  private personas: PersonaDto[] = [];
  personaAgregada = new EventEmitter<PersonaDto>();

  constructor(
    private http: HttpClient,
    private snackBar: SnackBarService) {
  }

  agregarPersona(personaDetailDto: PersonaDetailDto) {
    let persona = new PersonaDto
    persona.nombre = personaDetailDto.nombre
    persona.nombreCompleto = personaDetailDto.nombreCompleto
    persona.tipoDocumento = personaDetailDto.tipoDocumento.descripcion
    persona.nroDocumento = personaDetailDto.nroDocumento
    persona.correo = personaDetailDto.correo

    this.personas.push(persona);
    this.personaAgregada.emit(persona);
  }

  obtenerPersonas() {
    return this.personas;
  }

  setPersona(personaDetailDto: PersonaDetailDto) {
    localStorage.removeItem("personaDetailDto")
    localStorage.setItem("personaDetailDto", JSON.stringify(personaDetailDto))
    this.personaDetailDto = personaDetailDto
  }

  setPersonaById(id: number) {
    if (id != 0)
      this.getPersonaById(id).subscribe(
        data => {
          localStorage.removeItem("personaDetailDto")
          localStorage.setItem("personaDetailDto", JSON.stringify(data))
          this.personaDetailDto = data
        },
        error => {
          this.snackBar.showError(error, "Error");
        })
  }

  getPersona(): PersonaDetailDto {
    let persona = JSON.parse(localStorage.getItem("personaDetailDto") as string == "" ? "" : localStorage.getItem("personaDetailDto") as string) as PersonaDetailDto
    if (isFalsy(persona))
      return this.personaDetailDto
    else
      return persona
  }

  changePersona(personaDetailDto: PersonaDetailDto) {
    this.personaSource.next(personaDetailDto)
  }

  // private readonly urlBase = `${environment.apiUrl}`;

  validaPersonaByNroDocYTipoDoc(idPersona: number, nroDocumento: string, idTipoDocumento: number) {
    return this.http.get<boolean>("/api/Persona/ValidaPersonaByNroDocYTipoDoc?idPersona=" + idPersona + "&nroDocumento=" + nroDocumento + "&idTipoDocumento=" + idTipoDocumento);
  }

  getPersonaById(id: any): Observable<PersonaDetailDto> {
    return this.http.get<PersonaDetailDto>("api/Persona/GetPersonaById?idPersona=" + id);
  }

  getAll(): Observable<PersonaDto[]> {
    return this.http.get<PersonaDto[]>("/api/Persona/GetAll");
  }

  getAllPersonasByFilter(personaFilter:PersonaFilter):Observable<PersonaDto[]> {
    return   this.http.post<PersonaDto[]>("/api/Persona/GetAllPersonasByFilter",personaFilter);
  }

  getAllByName(nombrePersona: string): Observable<PersonaDetailDto[]> {
    return this.http.get<PersonaDetailDto[]>("/api/Persona/GetAllByName?nombrePersona=" + nombrePersona);
  }

  getPersonaByNroDocYTipoDoc(idTipoDocumento: number, nroDocumento: string): Observable<PersonaDetailDto> {
    return this.http.get<PersonaDetailDto>("/api/Persona/GetPersonaByNroDocYTipoDoc?nroDocumento=" + nroDocumento + "&idTipoDocumento=" + idTipoDocumento);
  }

  create(createPersonaRequest: CreatePersonaRequest): Observable<CreateResponseBase> {
    return this.http.post<CreateResponseBase>("/api/Persona/Create", createPersonaRequest);
  }

  update(updatePersonaRequest: UpdatePersonaRequest) {
    return this.http.put("/api/Persona/Update", updatePersonaRequest);
  }

  delete(id: number): Observable<boolean> {
    return this.http.delete<boolean>("/api/Persona/Delete?id=" + id);
  }

  unlockPersona(unlockPersonaRequest: UnlockPersonaRequest) {
    return this.http.post("/api/Persona/UnlockPersona", unlockPersonaRequest);
  }



}


