import { Component, Inject, Input, Optional } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BaseDto } from '@models/base/baseDto.model';
import { EstadosTareas } from '@models/base/identificadores.model';
import { ExpedienteSummaryDto } from '@models/expediente/expedienteSummary.model';
import { CreateTareaRequest } from '@models/tarea/createTareaRequest.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TareaService } from '@services/tarea/tarea.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { isFalsy } from 'utility-types';
import { DialogCreateTareaComponent } from '../dialog-create-tarea/dialog-create-tarea.component';
import { UpdateTareaRequest } from '@models/tarea/updateTareaRequest.model';
import { TareaDto } from '@models/tarea/tareaDto.model';

@Component({
  selector: 'app-dialog-update-tarea',
  templateUrl: './dialog-update-tarea.component.html',
  styleUrls: ['./dialog-update-tarea.component.css']
})
export class DialogUpdateTareaComponent {

  lstEstados: BaseDto[] = [
    { id: 1, descripcion: 'Pendiente' },
    { id: 2, descripcion: 'En Proceso' },
    { id: 3, descripcion: 'Finalizado' },
  ];
  lstPrioridades: BaseDto[] = [
    { id: 1, descripcion: 'Baja' },
    { id: 2, descripcion: 'Media' },
    { id: 3, descripcion: 'Alta' },
  ];

  lstNiveles: BaseDto[] = [
    { id: 1, descripcion: 'Baja' },
    { id: 2, descripcion: 'Media' },
    { id: 3, descripcion: 'Alta' },
  ];
  formBuilder: FormBuilder = new FormBuilder;
  form: any;
  @Input() isDialog = false
  local_data: any;
  tareaDto!: TareaDto;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogUpdateTareaComponent>,
    private tareaService: TareaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) {
    this.local_data = { ...data };
  }

  ngOnInit(): void {
    this.getById(this.data.id)
    this.form = this.formBuilder.group({
      Descripcion: ['', Validators.compose([Validators.required])],
      Titulo: ['', Validators.compose([Validators.required, Validators.maxLength(100)])],
      FechaVencimiento: [''],
      Prioridad: [''],
      NivelAvance: [''],
      EstadoTarea: [''],
    });
  }


  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    const updateTareaRequest: UpdateTareaRequest = this.setCreatePericiaRequest()
    this.spinner.show("spUpdate")
    this.tareaService.update(updateTareaRequest)
      .subscribe(
        data => {
          this.spinner.hide("spUpdate")
          this.snackBar.showSuccess("Se modifico el registro correctamente.", "Exito");
          this.dialogRef.close({ data: this.local_data });
        },
        error => {
          this.spinner.hide("spUpdate")
          this.snackBar.showError(error, "Error");
        })

  }

  getById(id:number){
    this.spinner.show("spUpdate")
    this.tareaService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spUpdate")
          this.tareaDto = data
          this.setForm(data)
        },
        error => {
          this.spinner.hide("spUpdate")
          this.snackBar.showError(error, "Error");
        })
  }
  setForm(data: TareaDto) {
    this.form.controls['Titulo'].setValue(data.titulo);
    this.form.controls['FechaVencimiento'].setValue(data.fechaVencimiento);
    this.form.controls['NivelAvance'].setValue(data.nivelAvance);
    this.form.controls['Prioridad'].setValue(data.prioridad);
    this.form.controls['Descripcion'].setValue(data.descripcion);
    this.form.controls['EstadoTarea'].setValue(data.estadoTarea.id);
  }

  setCreatePericiaRequest(): UpdateTareaRequest {

    const updateTareaRequest = new UpdateTareaRequest();
    updateTareaRequest.id = this.local_data.id
    updateTareaRequest.idExpediente = this.local_data.expediente == undefined ? null: this.local_data.expediente.id
    updateTareaRequest.descripcion = this.form.controls['Descripcion'].value;
    updateTareaRequest.titulo = this.form.controls['Titulo'].value;
    updateTareaRequest.fechaVencimiento = this.form.controls['FechaVencimiento'].value == "" ? null : this.form.controls['FechaVencimiento'].value;
    updateTareaRequest.nivelAvance = this.form.controls['NivelAvance'].value == "" ? null : this.form.controls['NivelAvance'].value;
    updateTareaRequest.prioridad = this.form.controls['Prioridad'].value == "" ? null : this.form.controls['Prioridad'].value;
    updateTareaRequest.idEstadoTarea = this.form.controls['EstadoTarea'].value == "" ? null : this.form.controls['EstadoTarea'].value;
    updateTareaRequest.fechaUltimaActualizacion = new Date()
    return updateTareaRequest
  }

  onReset() {
    this.form.reset();
  }



  isFalsy(valor: any) {
    return isFalsy(valor)
  }

  trackByItems(index: number, item: any): any { return item; }

  selectExpediente(expedienteSummaryDto: ExpedienteSummaryDto) {
    this.data.idExpediente = expedienteSummaryDto.id;
  }
}
