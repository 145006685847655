import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TareaDto } from '@models/tarea/tareaDto.model';
import { SnackBarService } from '@services/snackBar/snack-bar-service.service';
import { TareaService } from '@services/tarea/tarea.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-dialog-view-tarea',
  templateUrl: './dialog-view-tarea.component.html',
  styleUrls: ['./dialog-view-tarea.component.css']
})
export class DialogViewTareaComponent {
  local_data: any;
  tareaDto!: TareaDto;
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DialogViewTareaComponent>,
    private tareaService: TareaService,
    private snackBar: SnackBarService,
    private spinner: NgxSpinnerService,

  ) {
    this.local_data = { ...data };
  }

  ngOnInit(): void {
    this.getById(this.data.id);
  }

  getById(id: number) {
    this.spinner.show("spView")
    this.tareaService.getById(id)
      .subscribe(
        data => {
          this.spinner.hide("spView")
          this.tareaDto = data
        },
        error => {
          this.spinner.hide("spView")
          this.snackBar.showError(error, "Error");
        })
  }
}
