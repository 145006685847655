<div *appRole="['Organismos.Crear']">
    <button mat-raised-button color="primary" (click)="openDialog('Agregar',{})" #tooltip="matTooltip" matTooltip="Crear pericia">
    <mat-icon>add</mat-icon>
    Nuevo Organismo
  </button>
</div>
<br>
<div class="container-fluid">
    <mat-card *appRole="['Organismos.Listar']">
        <mat-card-header>
            <form [formGroup]="formFilter">

                <div class="row">
                    <div class="col-lg-8">
                        <app-buscar-localidad (eventoLocalidad)="seleccionaLocalidad($event)"></app-buscar-localidad>
                    </div>
                    <div class="col-4">
                        <button mat-stroked-button color="primary" [disabled]="this.localidadDto == undefined" (click)="filtrar()">
              <mat-icon>filter_alt</mat-icon>
              Filtrar
            </button>
                    </div>
                </div>
                <!-- <div class="col" *ngIf="checkedFiltroAvanzado">

                    </div> -->
                <div class="row">

                    <!-- <div class="col">
            <mat-slide-toggle class="example-margin" (change)="filtrosAvanzados($event)" color="primary">
              Filtros Avanzados </mat-slide-toggle>
          </div> -->
                </div>
            </form>
            <br>
            <br>
        </mat-card-header>
        <mat-card-content>
            <ngx-spinner [zIndex]="100" name="spListado" bdColor="rgba(255,255,255,0.8)" size="default" color="#009688" type="ball-atom" [fullScreen]="false">
            </ngx-spinner>
            <br>
            <div class="row">
                <div class="col-lg-4 col-sm-12">
                    <mat-form-field *ngIf=data appearance="outline" class="full-width-x100">
                        <mat-label>Buscar</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input>
                    </mat-form-field>
                </div>
            </div>

            <table mat-table [dataSource]="dataSource" *ngIf=data matSort matSortStart="asc">

                <ng-container matColumnDef="nombre">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Nombre</strong> </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let organismo">
                        {{organismo.nombre == undefined ? "": organismo.nombre | uppercase}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="telefono">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Telefono</strong>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let organismo">
                        {{organismo.telefono == undefined ? "": organismo.telefono | uppercase}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="correo">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Correo</strong> </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let organismo">
                        {{organismo.correo== undefined ? "": organismo.correo | uppercase}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="localidad">
                    <mat-header-cell mat-header-cell *matHeaderCellDef mat-sort-header> <strong>Localidad</strong>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let organismo">
                        {{organismo.localidad== undefined ? "": organismo.localidad.nombreCompleto | uppercase}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="accion">
                    <mat-header-cell mat-header-cell *matHeaderCellDef> <strong>Acción</strong> </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let organismo" class="action-link">
                        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
              <mat-icon>more_vert</mat-icon>
            </button>
                        <mat-menu #menu="matMenu">
                            <button mat-menu-item [routerLink]="['/organismos/',organismo.id]" *appRole="['Organismos.Modificar']">
                <mat-icon>visibility</mat-icon>
                <span>Ver</span>
              </button>
                            <button mat-menu-item (click)="openDialog('Modificar',organismo)">
                <mat-icon>edit</mat-icon>
                <span>Editar</span>
              </button>
                            <button mat-menu-item (click)="openDialog('Eliminar',organismo)">
                <mat-icon>delete</mat-icon>
                <span>Eliminar</span>
              </button>
                        </mat-menu>
                    </mat-cell>
                </ng-container>

                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row matRipple *matRowDef="let row; columns: displayedColumns;"></mat-row>


            </table>
            <div class="no-data-table">
                <span class="with-icon" *ngIf="dataSource.data.length == 0"><mat-icon>search_off</mat-icon>No se encontraron
          datos
          para mostrar.</span>
            </div>
            <mat-paginator showFirstLastButtons class="paginator" *ngIf=data [pageSize]="20" [pageSizeOptions]="[5, 10, 20,50,100,200]">
            </mat-paginator>
        </mat-card-content>
    </mat-card>
</div>
